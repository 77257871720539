import {
  useCourseAlbums,
  useDeleteAlbum,
} from 'features/course/courseHooks/courseAlbumHooks';
import { Button, Dropdown, Skeleton, Popconfirm as PopConfirm } from 'antd';
import styled from 'styled-components';
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { Album as AlbumType } from 'features/course/types';
import { Link, generatePath, useParams } from 'react-router-dom';
import PATHS from 'router/paths';
import { useState } from 'react';

const AlbumsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  gap: 10px;
  grid-row-gap: 25px;
`;

const AlbumThumbnailWrapper = styled.div`
  width: 185px;
  height: 185px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AlbumWrapper = styled.div`
  width: 185px;
  cursor: pointer;
  position: relative;

  .album-info {
    margin-top: 8px;
  }

  .album-name {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 600;
  }

  .album-items {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
  }

  &:hover {
    .album-name,
    .album-items {
      text-decoration: underline;
    }
  }

  .album-menu-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
`;

const CreateAlbumBtn = () => {
  const { id } = useParams();
  const { t } = useTranslation(LOCALES.COURSE);
  return (
    <Link to={generatePath(PATHS.app.albumForm.create, { id })}>
      <AlbumWrapper>
        <AlbumThumbnailWrapper>
          <PlusOutlined style={{ fontSize: 30 }} />
        </AlbumThumbnailWrapper>
        <div className='album-info'>
          <p className='album-name'>{t('createAlbum')}</p>
        </div>
      </AlbumWrapper>
    </Link>
  );
};

const Album = ({
  album,
  onConfirmDelete,
  deleteAlbumLoading,
}: {
  album: AlbumType;
  onConfirmDelete: () => void;
  deleteAlbumLoading: boolean;
}) => {
  const { id } = useParams();
  const { t } = useTranslation(LOCALES.COURSE);

  const [deletingAlbumId, setDeletingAlbumId] = useState(-1);

  return (
    <AlbumWrapper>
      <Link
        to={generatePath(PATHS.app.courses.details.album, {
          id,
          albumId: album.id,
        })}>
        <AlbumThumbnailWrapper>
          <img
            src={'https://wiki.dave.eu/images/4/47/Placeholder.png'}
            alt='album'
          />
        </AlbumThumbnailWrapper>
      </Link>

      <Link
        to={generatePath(PATHS.app.courses.details.album, {
          id,
          albumId: album.id,
        })}>
        <div className='album-info'>
          <p className='album-name'>{album.name}</p>
          <p className='album-items'>{`${album.assets.length} ${
            album.assets.length > 1 ? t('items') : t('item')
          }`}</p>
        </div>
      </Link>

      <Dropdown
        overlayStyle={{ width: 100 }}
        overlayClassName='pop-confirm-menu'
        menu={{
          items: [
            {
              label: (
                <Link
                  to={generatePath(PATHS.app.albumForm.edit, {
                    id,
                    albumId: album.id,
                  })}>
                  {t('update')}
                </Link>
              ),
              key: '0',
            },
            { type: 'divider' },
            {
              label: (
                <p
                  className='styled-table__action-item color-error'
                  onClick={() => setDeletingAlbumId(album.id)}>
                  {t('delete')}
                </p>
              ),
              key: 'Delete',
            },
          ],
        }}
        trigger={['click']}>
        <PopConfirm
          placement='topRight'
          title={t('deleteAlbumWarning')}
          onConfirm={onConfirmDelete}
          okText={t('delete')}
          cancelText={t('cancel')}
          okButtonProps={{ loading: deleteAlbumLoading }}
          cancelButtonProps={{ disabled: deleteAlbumLoading }}
          open={deletingAlbumId === album.id}
          onCancel={() => setDeletingAlbumId(-1)}>
          <Button
            icon={<EllipsisOutlined />}
            shape='circle'
            className='album-menu-btn'></Button>
        </PopConfirm>
      </Dropdown>
    </AlbumWrapper>
  );
};

const CourseAlbums = () => {
  const { courseAlbums, getCourseAlbumsLoading } = useCourseAlbums();
  const { handleDeleteAlbum, deleteAlbumLoading } = useDeleteAlbum();
  const { id } = useParams();

  return (
    <div>
      <AlbumsGrid className=''>
        <CreateAlbumBtn />
        {getCourseAlbumsLoading
          ? [1, 2, 3].map(index => (
              <AlbumWrapper key={index}>
                <Skeleton.Image style={{ width: 185, height: 185 }} active />
              </AlbumWrapper>
            ))
          : courseAlbums.map(album => (
              <Album
                key={album.id}
                album={album}
                deleteAlbumLoading={deleteAlbumLoading}
                onConfirmDelete={() => {
                  if (id) {
                    handleDeleteAlbum({ courseId: id, albumId: album.id });
                  }
                }}
              />
            ))}
      </AlbumsGrid>
    </div>
  );
};

export default CourseAlbums;

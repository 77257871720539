import { useEffect } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
  useURLQueryParams,
} from 'app/hooks';
import {
  getPlaylistAssets,
  addPlaylistAssets,
  deletePlaylistAsset,
  orderPlaylistAssets,
  setPlaylistAssets,
  setShouldReorderAssets,
} from 'features/playlist/playlistSlice';
import {
  UsePlaylistAssets,
  UseAddPlaylistAssetsReturnType,
  UseDeletePlaylistAssetReturnType,
  PlaylistAsset,
} from 'features/playlist/types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';

export const usePlaylistAssets: UsePlaylistAssets = () => {
  const playlistAssets = useAppSelector(state => state.playlist.playlistAssets);
  const getPlaylistAssetsFailed = useAppSelector(
    state => state.playlist.getPlaylistAssetsFailed,
  );
  const getPlaylistAssetsLoading = useAppSelector(
    state => state.playlist.getPlaylistAssetsLoading,
  );
  const playlistAssetsPaginationConfig = useAppSelector(
    state => state.playlist.playlistAssetsPaginationConfig,
  );

  const dispatch = useAppDispatch();

  const params = useURLQueryParams([]);
  const onPageChange = usePagination();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getPlaylistAssets({ id, params }));
    }
  }, [params, dispatch, id]);

  useFailed(getPlaylistAssetsFailed);

  const refreshCurrentPage = () => {
    if (id) {
      dispatch(getPlaylistAssets({ id, params }));
    }
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      playlistAssets.length === 1 && params.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(params.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterAdding = () => {
    if (params.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    getPlaylistAssetsLoading,
    playlistAssets,
    getPlaylistAssetsFailed,

    playlistAssetsPaginationConfig: {
      ...playlistAssetsPaginationConfig,
      onChange: onPageChange,
    },
    refreshAfterAdding,
    refreshAfterDeleting,
    refreshCurrentPage,
  };
};

export const useDeletePlaylistAsset = (
  callback: () => void,
): UseDeletePlaylistAssetReturnType => {
  const { id: playlistId } = useParams();
  const { t } = useTranslation(LOCALES.MESSAGE);

  const deletePlaylistAssetLoading = useAppSelector(
    state => state.playlist.deletePlaylistAssetLoading,
  );
  const deletePlaylistAssetFailed = useAppSelector(
    state => state.playlist.deletePlaylistAssetFailed,
  );
  const deletePlaylistAssetSuccess = useAppSelector(
    state => state.playlist.deletePlaylistAssetSuccess,
  );

  const dispatch = useAppDispatch();

  const handleDeletePlaylistAsset = (assetId: number) => {
    if (playlistId) {
      dispatch(deletePlaylistAsset({ playlistId, assetId }));
    }
  };

  useSuccess(
    deletePlaylistAssetSuccess,
    t<string>('ASSET_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deletePlaylistAssetFailed);

  return {
    deletePlaylistAssetLoading,
    deletePlaylistAssetFailed,
    deletePlaylistAssetSuccess,
    handleDeletePlaylistAsset,
  };
};

export const useAddPlaylistAssets = (
  callback: () => void,
): UseAddPlaylistAssetsReturnType => {
  const { id: playlistId } = useParams();
  const { t } = useTranslation(LOCALES.MESSAGE);

  const addPlaylistAssetsLoading = useAppSelector(
    state => state.playlist.addPlaylistAssetsLoading,
  );
  const addPlaylistAssetsFailed = useAppSelector(
    state => state.playlist.addPlaylistAssetsFailed,
  );
  const addPlaylistAssetsSuccess = useAppSelector(
    state => state.playlist.addPlaylistAssetsSuccess,
  );

  const dispatch = useAppDispatch();

  const handleAddPlaylistAssets = (assetIds: number[]) => {
    if (playlistId) {
      dispatch(addPlaylistAssets({ playlistId, assetIds }));
    }
  };

  useSuccess(
    addPlaylistAssetsSuccess,
    t<string>('ASSET_ADDED_SUCCESSFULLY'),
    callback,
  );

  useFailed(addPlaylistAssetsFailed);

  return {
    addPlaylistAssetsLoading,
    addPlaylistAssetsFailed,
    addPlaylistAssetsSuccess,
    handleAddPlaylistAssets,
  };
};

export const useOrderPlaylistAsset = (refreshPage: () => void) => {
  const { id: playlistId } = useParams();
  const orderPlaylistAssetsLoading = useAppSelector(
    state => state.playlist.orderPlaylistAssetsLoading,
  );
  const orderPlaylistAssetsFailed = useAppSelector(
    state => state.playlist.orderPlaylistAssetsFailed,
  );
  const orderPlaylistAssetsSuccess = useAppSelector(
    state => state.playlist.orderPlaylistAssetsSuccess,
  );

  const dispatch = useAppDispatch();

  const handleOrderPlaylistAsset = (orderedData: PlaylistAsset[]) => {
    if (playlistId) {
      const ids = orderedData.map(({ id }) => id);
      dispatch(orderPlaylistAssets({ playlistId: Number(playlistId), ids }));
      dispatch(setPlaylistAssets(orderedData));
    }
  };

  const updateShouldReorderAssets = (shouldReorder: boolean) => {
    dispatch(setShouldReorderAssets(shouldReorder));
  };

  useFailed(orderPlaylistAssetsFailed, '', refreshPage);

  return {
    orderPlaylistAssetsLoading,
    orderPlaylistAssetsFailed,
    orderPlaylistAssetsSuccess,
    handleOrderPlaylistAsset,
    updateShouldReorderAssets,
  };
};

const en = {
  workspace: 'Workspace',
  createWorkspace: 'Add workspace',
  listInvitation: 'Invitations',
  student: 'Students',
  listStudents: 'Student list',
  createStudent: 'Add student',
  classes: 'Classes',
  listClasses: 'Class list',
  createClass: 'Add class',
  dashboard: 'Dashboard',
  settings: 'Setting',
  archive: 'Archive',
  books: 'Books',
  listBooks: 'Book list',
  createBook: 'Add new book',
  files: 'Files',
  listFiles: 'Files',
  createFile: 'Add new files',
  staff: 'Staffs',
  listStaff: 'Staff list',
  createStaff: 'Add staff',
  roles: 'Roles',
  activities: 'Action',
  logout: 'Logout',
  changeWorkspace: 'Change workspace',
  confirmLogout: 'Logout confirmation',
  playList: 'Playlist',
  listPlayList: 'Playlist list',
  sureComfirm: 'Are you sure you want to logout?',
  profile: 'Profile',
  TREE_FILE: 'File management',
  loadingProfile: 'Loading...',
  profileLoaded: 'Success',
  profileLoadFailed: 'Failled',
  Vietnamese: 'Vietnamese',
  English: 'English',
  youDontHavePermission:
    "You don't have permission to access this feature. Please contact to your workspace administrator for detail information.",
  path_student: 'Students',
  path_courses: 'Classes',
  path_book: 'Books',
  path_playlist: 'Playlist list',
  path_assets: 'File management',
  path_branch: 'Branches',
  path_check_in: 'Check In',
  check_in: 'Check in',
  path_staff: 'Staffs',
  path_role: 'Roles',
  path_hidden: "You don't have permission to access this feature.",
  poweredBy: 'Powered by',
  messageError: 'An error has occurred.',
  changePassword: 'Change password',
  workspaces: 'Workspaces',
  invitations: 'Invitations',
  phoneNumber: 'Phone Number',

  invitationCode: 'Invitation Code',
  download: 'Download',

  keyword: 'Keyword',
  enterKeyword: 'Enter Keyword',
};

export default en;

import { useState, useEffect } from 'react';
import { Table, Modal, Typography, Space, Image, Row } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Playlist } from 'features/playlist/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  usePlaylistsModal,
  useResetPlaylistState,
  PLAYLIST_MODAL_QUERY_PARAMS,
} from 'features/playlist/playlistHooks';
import { useTableSorting } from 'app/hooks';
import { TableRowSelection } from 'antd/es/table/interface';
import SearchBox from 'components/Common/SearchBox';

const PlaylistsModal = ({
  isOpen,
  onCancel,
  addingLoading,
  onSubmit,
  addingSuccess,
  initValues = [],
  isAcceptEmpty = false,
}: {
  isOpen: boolean;
  onCancel: () => void;
  addingLoading: boolean;
  onSubmit: (ids: number[]) => void;
  addingSuccess?: any;
  initValues?: number[];
  isAcceptEmpty?: boolean;
}) => {
  const { t } = useTranslation(LOCALES.PLAYLIST);

  const { handleSorting, getColumnSorting } = useTableSorting(
    PLAYLIST_MODAL_QUERY_PARAMS.ORDERING,
  );

  const { playlists, getPlaylistsLoading, paginationConfig, goToFirstPage } =
    usePlaylistsModal(isOpen);
  useResetPlaylistState();

  const [selectedPlaylistIds, setSelectedPlaylistIds] = useState<any[]>([]);

  useEffect(() => {
    if (isOpen) {
      setSelectedPlaylistIds(initValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const columns: ColumnsType<Playlist> = [
    {
      title: t('name'),
      key: 'name',
      ...getColumnSorting('name'),

      render: record => {
        return (
          <Space>
            <Image
              placeholder={true}
              style={{ objectFit: 'contain' }}
              width={30}
              height={30}
              src={record.photo}
            />
            {record.name}
          </Space>
        );
      },
    },

    { title: t('description'), key: 'description', dataIndex: 'descriptions' },

    {
      title: t('createdBy'),
      key: 'createdBy',
      width: 120,
      render: ({ created_by }) => created_by?.name,
    },
  ];

  const rowSelection: TableRowSelection<Playlist> = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedPlaylistIds(selectedRowKeys);
    },
  };

  useEffect(() => {
    if (addingSuccess) {
      goToFirstPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingSuccess]);

  return (
    <Modal
      open={isOpen}
      width={1100}
      onCancel={onCancel}
      onOk={() => onSubmit(selectedPlaylistIds)}
      cancelButtonProps={{ disabled: addingLoading }}
      okButtonProps={{
        loading: addingLoading,
        disabled: !isAcceptEmpty && !selectedPlaylistIds.length,
      }}
      okText={t('add')}
      cancelText={t('cancel')}>
      <Row justify='space-between'>
        <Typography.Title level={4}>{t('playlists')}</Typography.Title>
        <SearchBox searchKey={PLAYLIST_MODAL_QUERY_PARAMS.SEARCH}></SearchBox>
      </Row>

      <Table
        rowSelection={{
          ...rowSelection,
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedPlaylistIds,
        }}
        loading={getPlaylistsLoading}
        dataSource={playlists}
        columns={columns}
        pagination={paginationConfig}
        rowKey='id'
        onChange={handleSorting}
      />
    </Modal>
  );
};

export default PlaylistsModal;

import Filter from './Filter';
import { HasFilterPageContent, PageHeader } from 'components/Common/Page';
import { useState, Fragment, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Popconfirm as PopConfirm,
  Tag,
  Dropdown,
  Space,
} from 'antd';
import {
  useAssets,
  useAssetsDataSource,
  useCreateAsset,
  useDeleteAsset,
  usePlayFile,
  useGetAssetInvitationCode,
  useResetAssetState,
  useUpdateAsset,
} from 'features/asset/assetHooks';
import {
  FolderOutlined,
  LoadingOutlined,
  QrcodeOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import FolderForm from './FolderForm';
import EditingFileForm from './EditingFileForm';
import { preventDefault } from 'utils/assets';
import type { ColumnsType } from 'antd/es/table';
import { Asset } from 'features/asset/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useTableSorting } from 'app/hooks';
import type { MenuProps } from 'antd';
import DirectoryUploader from 'components/Uploader/DirectoryUploader';
import FileUploader from 'components/Uploader/FileUploader';
import { RcFile } from 'antd/es/upload';
import QRCodeModal from 'components/Common/QRCodeModal';
import color from 'config/color';
import FileExtensionIcon from 'components/Common/FileExtensionIcon';
import { getDisplayingDate } from 'utils';
import AssetsModal from 'components/AssetsModal';

const Assets = () => {
  const { t } = useTranslation(LOCALES.ASSET);
  const { getPlayFileAction, downloadFileLoading, getFileStyle } =
    usePlayFile();

  const {
    getAssetsLoading,
    assets,
    loadingAssetId,
    isLoadingMoreAssets,
    paginationConfig,
    getChildrenAssets,
    urlQueryParams,
    refreshAfterCreating,
    refreshAfterDeleting,
    refreshCurrentPage,
  } = useAssets();

  useResetAssetState();

  const directoryUploadRef = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);

  const triggerDirectoryUpload = () => {
    directoryUploadRef.current?.click();
  };

  const triggerFileUpload = () => {
    fileUploadRef.current?.click();
  };

  const [parentIdOfCreatingFile, setParentIdOfCreatingFile] = useState<any>();

  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  const { handleSorting, getColumnSorting } = useTableSorting();
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [creatingFolderName, setCreatingFolderName] = useState('');
  const [parentIdOfCreatingAsset, setParentIdOfCreatingAsset] = useState<any>();
  const [openedPopConfirmDelete, setOpenedPopConfirmDelete] =
    useState<any>(null);
  const [editingFolder, setEditingFolder] = useState<any>();
  const [isFileFormOpen, setIsFileFormOpen] = useState(false);
  const [editingFile, setEditingFile] = useState<any>();

  const [isAssetsModalOpen, setIsAssetsModalOpen] = useState(false);
  const [movingAsset, setMovingAsset] = useState<Asset | undefined>();

  const {
    invitationCode,
    handleGetAssetInvitationCode,
    getAssetInvitationCodeLoading,
  } = useGetAssetInvitationCode();
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

  const onGetInvitationCode = (id: number) => {
    setIsQRCodeModalOpen(true);
    handleGetAssetInvitationCode(id);
  };

  const onResetState = () => {
    setIsCreatingFolder(false);
    setCreatingFolderName('');
    setEditingFolder(undefined);
    setParentIdOfCreatingAsset(undefined);
    setOpenedPopConfirmDelete(undefined);
    setParentIdOfCreatingFile(undefined);
    setEditingFile(undefined);
    setIsFileFormOpen(false);
    setMovingAsset(undefined);
  };

  const { handleCreateAsset, createAssetLoading } = useCreateAsset(
    onResetState,
    refreshAfterCreating,
  );

  const { handleUpdateAsset, updateAssetLoading } = useUpdateAsset(() => {
    if (movingAsset) {
      refreshCurrentPage();
      setExpandedRowKeys([]);
    }
    onResetState();
  });

  const { deleteAssetLoading, handleDeleteAsset } = useDeleteAsset(() => {
    onResetState();
    refreshAfterDeleting();
    setExpandedRowKeys([]);
  });

  useEffect(() => {
    setExpandedRowKeys([]);
  }, [urlQueryParams]);

  const handleMoveAsset = (ids: number[]) => {
    const targetId = ids?.[0];

    if (movingAsset && targetId) {
      handleUpdateAsset({
        name: movingAsset.name,
        id: movingAsset.id,
        parent: targetId,
      });
    }
  };

  const dataSource = useAssetsDataSource(assets, parentIdOfCreatingAsset);

  const openSubfolderForm = (parentAsset: Asset) => {
    onResetState();

    setParentIdOfCreatingAsset(parentAsset.id);

    const rowKey = parentAsset.id;

    if (!expandedRowKeys.includes(rowKey)) {
      setExpandedRowKeys([...expandedRowKeys, rowKey]);
    }

    if (parentAsset.has_children && !parentAsset.children?.length) {
      getChildrenAssets({ parent: parentAsset.id });
    }
  };

  const createFolder = () => {
    handleCreateAsset({
      folder: true,
      name: creatingFolderName,
      parent: parentIdOfCreatingAsset,
    });
  };

  const updateFolder = () => {
    handleUpdateAsset({
      name: creatingFolderName,
      id: editingFolder.id,
    });
  };

  const onUploadFile = (file: RcFile) => {
    handleCreateAsset({
      uid: file.uid,
      name: file.name,
      parent: parentIdOfCreatingFile,
      path_file: file,
    });
  };

  const uploadItems: MenuProps['items'] = [
    {
      label: (
        <p
          onClick={() => {
            triggerDirectoryUpload();
            setParentIdOfCreatingFile(undefined);
          }}
          className='styled-table__action-item '>
          {t('uploadFolder')}
        </p>
      ),
      key: 'uploadFolder',
    },
    {
      label: (
        <p
          onClick={() => {
            triggerFileUpload();
            setParentIdOfCreatingFile(undefined);
          }}
          className='styled-table__action-item '>
          {t('uploadFile')}
        </p>
      ),
      key: 'uploadFile',
    },
  ];

  let columns: ColumnsType<Asset> = [
    {
      title: t('name'),
      key: 'name',
      ...getColumnSorting('name'),
      width: 600,
      render: (_, rc) => {
        if (rc.isLoadMoreRecord) {
          return (
            <span
              onClick={() => {
                rc.parent &&
                  getChildrenAssets({
                    parent: rc.parent,
                    page: rc.nextPage,
                    isLoadMore: true,
                  });
              }}
              style={{
                cursor: loadingAssetId ? 'not-allow' : 'pointer',
                color: '#1677ff',
              }}>
              {t('loadMore')}{' '}
              {loadingAssetId === rc.parent && <LoadingOutlined />}
            </span>
          );
        }

        if (rc.isSubfolderFormRecord) {
          return (
            <FolderForm
              value={creatingFolderName}
              onChange={setCreatingFolderName}
              loading={createAssetLoading}
              onCancel={onResetState}
              onSubmit={createFolder}
            />
          );
        }

        return (
          <div>
            {rc.id === editingFolder?.id ? (
              <FolderForm
                value={creatingFolderName}
                onChange={setCreatingFolderName}
                loading={updateAssetLoading}
                onCancel={onResetState}
                onSubmit={updateFolder}
              />
            ) : (
              <div style={getFileStyle(rc)} onClick={getPlayFileAction(rc)}>
                {rc.folder ? (
                  <FolderOutlined style={{ color: color.primary }} />
                ) : (
                  <FileExtensionIcon
                    isCached={rc.isCached}
                    extension={rc.extension || ''}
                  />
                )}{' '}
                <span>
                  {rc.name}{' '}
                  {loadingAssetId === rc.id && !isLoadingMoreAssets && (
                    <LoadingOutlined style={{ color: color.primary }} />
                  )}
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t('tags'),
      key: 'tags',
      dataIndex: 'tags',
      width: 300,
      render: (tags: string) => {
        return tags
          ? tags.split(',').map(tag => (
              <Tag style={{ marginTop: 5 }} key={tag}>
                {tag}
              </Tag>
            ))
          : '';
      },
      ...getColumnSorting('tags'),
    },

    {
      title: t('lastModified'),
      key: 'updated_at',
      dataIndex: 'updated_at',
      ...getColumnSorting('updated_at'),
      width: 200,
      render: (date, asset) =>
        !asset.isSubfolderFormRecord &&
        !asset.isLoadMoreRecord &&
        getDisplayingDate(date),
    },
    {
      width: 100,
      title: t('invitationCode'),
      key: 'invitationCode',
      dataIndex: 'invitation_code',
      render: (_, asset) =>
        !asset.folder &&
        !asset.isSubfolderFormRecord &&
        !asset.isLoadMoreRecord && (
          <Button
            onClick={() => onGetInvitationCode(asset.id)}
            type='text'
            icon={<QrcodeOutlined />}></Button>
        ),
      align: 'center',
    },
    {
      width: 50,
      title: '',
      key: 'action',
      render: (asset: Asset) => {
        if (asset.isLoadMoreRecord || asset.isSubfolderFormRecord) {
          return null;
        }

        const uploadFolder = {
          label: (
            <p
              className='styled-table__action-item'
              onClick={() => {
                triggerDirectoryUpload?.();
                setParentIdOfCreatingFile(asset.id);
              }}>
              {t('uploadFolder')}
            </p>
          ),
          key: 'uploadFolder',
        };

        const uploadFile = {
          label: (
            <p
              className='styled-table__action-item'
              onClick={() => {
                triggerFileUpload();
                setParentIdOfCreatingFile(asset.id);
              }}>
              {t('uploadFile')}
            </p>
          ),
          key: 'uploadFile',
        };

        const createSubfolder = {
          label: (
            <p
              className='styled-table__action-item'
              onClick={() => openSubfolderForm(asset)}>
              {t('createSubfolder')}
            </p>
          ),
          key: 'createSubfolder',
        };

        const editItem = {
          label: (
            <p
              className='styled-table__action-item'
              onClick={() => {
                if (asset.folder && editingFolder?.id !== asset.id) {
                  setEditingFolder(asset);
                  setCreatingFolderName(asset.name);
                } else {
                  setEditingFile(asset);
                  setIsFileFormOpen(true);
                }
              }}>
              {asset.folder ? t('updateFolder') : t('updateFile')}
            </p>
          ),
          key: 'Edit',
        };

        const moveItem = {
          label: (
            <p
              className='styled-table__action-item'
              onClick={() => {
                setIsAssetsModalOpen(true);
                setMovingAsset(asset);
              }}>
              {t('move')}
            </p>
          ),
          key: 'Move',
        };

        const deleteItem = {
          label: (
            <p
              className='styled-table__action-item color-error'
              onClick={() => setOpenedPopConfirmDelete(asset.id)}>
              {asset.folder ? t('deleteFolder') : t('deleteFile')}
            </p>
          ),
          key: 'Delete',
        };

        const folderItemsOnly = [uploadFolder, uploadFile, createSubfolder];
        const mutualItems = [
          editItem,
          moveItem,
          { type: 'divider', key: 'divider' },
          deleteItem,
        ];

        const items: MenuProps['items'] = asset.folder
          ? [...folderItemsOnly, ...mutualItems]
          : mutualItems;

        return (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            overlayStyle={{ width: 200 }}
            overlayClassName='styled-table__actions'
            placement='bottomRight'>
            <PopConfirm
              open={openedPopConfirmDelete === asset.id}
              placement='leftBottom'
              title={t('deleteWarning')}
              onConfirm={(e: any) => {
                handleDeleteAsset(asset.id);
                preventDefault(e);
              }}
              onCancel={(e: any) => {
                preventDefault(e);
                setOpenedPopConfirmDelete(null);
              }}
              okText={t('deleteFile')}
              okButtonProps={{
                loading: deleteAssetLoading,
              }}
              cancelButtonProps={{
                disabled: deleteAssetLoading,
              }}
              cancelText={t('cancel')}>
              <Button size='small' type='text'>
                <EllipsisOutlined />
              </Button>
            </PopConfirm>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Filter loading={false} />
      <HasFilterPageContent>
        <PageHeader title={t('files')}>
          <Space>
            <div style={{ cursor: 'pointer' }}>
              {isCreatingFolder ? (
                <FolderForm
                  value={creatingFolderName}
                  onChange={setCreatingFolderName}
                  loading={createAssetLoading}
                  onCancel={onResetState}
                  onSubmit={createFolder}
                />
              ) : (
                <Button
                  type='primary'
                  onClick={() => {
                    onResetState();
                    setIsCreatingFolder(true);
                  }}>
                  {' '}
                  {t('create')}
                </Button>
              )}
            </div>

            <Dropdown
              menu={{ items: uploadItems }}
              trigger={['click']}
              overlayStyle={{ width: 170 }}
              overlayClassName='styled-table__actions'
              placement='bottomLeft'>
              <Button type='primary'>{t('upload')}</Button>
            </Dropdown>
          </Space>
        </PageHeader>
        <div>
          <Table
            loading={
              getAssetsLoading ||
              (movingAsset && updateAssetLoading) ||
              downloadFileLoading
            }
            columns={columns}
            dataSource={dataSource}
            rowKey={rc => rc.id}
            pagination={paginationConfig}
            expandable={{
              onExpand: (isExpanded, asset) => {
                if (isExpanded && !asset.expanded) {
                  getChildrenAssets({ parent: asset.id });
                }
              },
              expandedRowKeys: expandedRowKeys,
              onExpandedRowsChange: setExpandedRowKeys,
            }}
            onChange={handleSorting}
          />

          <EditingFileForm
            isOpen={isFileFormOpen}
            handleUpdateAsset={handleUpdateAsset}
            updateAssetLoading={updateAssetLoading}
            onClose={() => {
              setIsFileFormOpen(false);
              setEditingFile(undefined);
            }}
            editingFile={editingFile}
          />
          <DirectoryUploader onUpload={onUploadFile} ref={directoryUploadRef} />
          <FileUploader onUpload={onUploadFile} ref={fileUploadRef} />

          <QRCodeModal
            qrCode={invitationCode}
            isOpen={isQRCodeModalOpen}
            onClose={() => setIsQRCodeModalOpen(false)}
            title={t('fileCode')}
            isLoading={getAssetInvitationCodeLoading}
          />
        </div>
      </HasFilterPageContent>

      <AssetsModal
        isOpen={isAssetsModalOpen}
        onClose={() => setIsAssetsModalOpen(false)}
        onSubmit={ids => {
          handleMoveAsset(ids);
          setIsAssetsModalOpen(false);
        }}
        movingAsset={movingAsset}
      />
    </Fragment>
  );
};

export default Assets;

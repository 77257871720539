import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import branchAPI from './branchAPI';
import {
  BaseBranch,
  Branch,
  BranchState,
  GetBranchesQueryParams,
} from './types';
import { resetStoreAction } from 'config';
import { ListResponse } from 'types';

export const getAllBranches = createAppAsyncThunk(
  'staff/getAllBranches',
  async (_, { rejectWithValue }) => {
    try {
      const response: ListResponse<Branch> = await branchAPI.getAllBranches();
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getBranches = createAppAsyncThunk(
  'branch/getBranches',
  async (params: GetBranchesQueryParams, { rejectWithValue }) => {
    try {
      const response: ListResponse<Branch> = await branchAPI.getBranches(
        params,
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createBranch = createAppAsyncThunk(
  'branch/createBranch',
  async (branch: BaseBranch, { rejectWithValue }) => {
    try {
      const response = await branchAPI.createBranch(branch);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateBranch = createAppAsyncThunk(
  'branch/updateBranch',
  async (branch: Branch, { rejectWithValue }) => {
    try {
      const response = await branchAPI.updateBranch(branch);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteBranch = createAppAsyncThunk(
  'branch/deleteBranch',
  async (id: number, { rejectWithValue }) => {
    try {
      await branchAPI.deleteBranch(id);

      return id;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: BranchState = {
  getAllBranchesLoading: true,
  allBranches: [],
  getAllBranchesFailed: undefined,

  getBranchesLoading: false,
  branches: [],
  getBranchesFailed: undefined,
  paginationConfig: {},

  createBranchLoading: false,
  createBranchSuccess: undefined,
  createBranchFailed: undefined,

  updateBranchLoading: false,
  updateBranchSuccess: undefined,
  updateBranchFailed: undefined,

  deleteBranchLoading: false,
  deleteBranchSuccess: undefined,
  deleteBranchFailed: undefined,
};

export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    resetBranchState() {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getAllBranches.pending, (state, action) => {
        state.getAllBranchesLoading = true;
      })
      .addCase(getAllBranches.fulfilled, (state, action) => {
        state.getAllBranchesLoading = false;
        state.allBranches = action.payload.results;
      })
      .addCase(getAllBranches.rejected, (state, action) => {
        state.getAllBranchesLoading = false;
        state.getAllBranchesFailed = action.payload;
      })

      .addCase(getBranches.pending, (state, action) => {
        const {
          meta: {
            arg: { page, page_size },
          },
        } = action;

        state.getBranchesLoading = true;
        state.paginationConfig.current = Number(page);
        state.paginationConfig.pageSize = Number(page_size);
      })
      .addCase(getBranches.fulfilled, (state, action) => {
        state.getBranchesLoading = false;
        state.branches = action.payload.results;
        state.paginationConfig.total = action.payload.count;
      })
      .addCase(getBranches.rejected, (state, action) => {
        state.getBranchesLoading = false;
        state.getBranchesFailed = action.payload;
      })

      .addCase(createBranch.pending, state => {
        state.createBranchLoading = true;
        state.createBranchFailed = undefined;
      })
      .addCase(createBranch.fulfilled, (state, action) => {
        state.createBranchLoading = false;
        state.createBranchSuccess = action.payload;
      })
      .addCase(createBranch.rejected, (state, action) => {
        state.createBranchLoading = false;
        state.createBranchFailed = action.payload;
      })

      .addCase(updateBranch.pending, state => {
        state.updateBranchLoading = true;
        state.updateBranchFailed = undefined;
      })
      .addCase(updateBranch.fulfilled, (state, { payload }) => {
        state.updateBranchLoading = false;
        state.updateBranchSuccess = payload;

        state.branches = state.branches.map(branch =>
          branch.id !== payload.id ? branch : payload,
        );
      })
      .addCase(updateBranch.rejected, (state, action) => {
        state.updateBranchLoading = false;
        state.updateBranchFailed = action.payload;
      })

      .addCase(deleteBranch.pending, state => {
        state.deleteBranchLoading = true;
        state.deleteBranchFailed = undefined;
      })
      .addCase(deleteBranch.fulfilled, (state, action) => {
        state.deleteBranchLoading = false;
        state.deleteBranchSuccess = action.payload;
      })
      .addCase(deleteBranch.rejected, (state, action) => {
        state.deleteBranchLoading = false;
        state.deleteBranchFailed = action.payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const { resetBranchState } = branchSlice.actions;

export default branchSlice.reducer as Reducer<BranchState>;

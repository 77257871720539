import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left: 15px;
  height: 55px;
  width: 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  & > * {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
  }

  .styled-dropdown {
    &__title {
      color: $heading-color;
    }

    &__name {
      margin-top: 3px;
      color: #475161;
      font-weight: 800;
    }

    &__dropdown-icon {
      margin-left: 3px;
      top: 1px;
      position: relative;
    }
  }
`;

export const StyledDropdown = ({ items, title, text, onClickTitle }: any) => {
  return (
    <Dropdown menu={{ items: items }} trigger={['click']}>
      <Wrapper>
        <h5 className='styled-dropdown__title' onClick={() => onClickTitle?.()}>
          {title}
        </h5>
        <h5 className='styled-dropdown__name'>
          {text}
          <span className='styled-dropdown__dropdown-icon'>
            <CaretDownOutlined style={{ color: '#9ca3af' }} />
          </span>
        </h5>
      </Wrapper>
    </Dropdown>
  );
};

const en = {
  branches: 'Branches',

  create: 'Create',
  cancel: 'Cancel',
  update: 'Update',

  add: 'Add',
  name: 'Name',
  createBranch: 'Create branch',
  updateBranch: 'Update branch',

  deleteWarning: 'Are you sure want to delete this branch?',
  delete: 'Delete',
  keyword: 'Keyword',
  enterKeyword: 'Enter Keyword',
  search: 'Search',
  nameIsRequired: 'Please Input Branch Name',
  locationIsRequired: 'Please Input Location',
  addressIsRequired: 'Please Input Address',

  location: 'Location',
  address: 'Address',
};

export default en;

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { persistReducer, persistStore, PersistConfig } from 'redux-persist';
import { setupInstantsInterceptor } from 'utils/requester';
import { AuthStateType } from 'features/auth/types';
import authReducer from 'features/auth/authSlice';
import profileReducer from 'features/profile/profileSlice';
import studentReducer from 'features/student/studentSlice';
import courseReducer from 'features/course/courseSlice';
import assetReducer from 'features/asset/assetSlice';
import playlistReducer from 'features/playlist/playlistSlice';
import bookReducer from 'features/book/bookSlice';
import bookDetailsReducer from 'features/bookDetails/bookDetailsSlice';
import roleReducer from 'features/role/roleSlice';
import staffReducer from 'features/staff/staffSlice';
import branchReducer from 'features/branch/branchSlice';
import checkInReducer from 'features/checkIn/checkInSlice';

const authPersistConfig: PersistConfig<AuthStateType> = {
  key: 'auth',
  storage: storage,
  whitelist: [
    'token',
    'refreshToken',
    'workspace',
    'workspaces',
    'email',
    'accountProfile',
    'language',
  ],
};

const store = configureStore({
  reducer: {
    auth: persistReducer<AuthStateType, any>(authPersistConfig, authReducer),
    profile: profileReducer,
    student: studentReducer,
    course: courseReducer,
    asset: assetReducer,
    playlist: playlistReducer,
    book: bookReducer,
    bookDetails: bookDetailsReducer,
    role: roleReducer,
    staff: staffReducer,
    branch: branchReducer,
    checkIn: checkInReducer,
  },

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

setupInstantsInterceptor(store);
const persistor = persistStore(store);

export { store, persistor };

import requester from 'utils/requester';
import { getNonTenantEndpoint } from 'utils';
import { AuthAPI } from './types';
import { getWorkspaceFormData, multipartFormConfig } from 'utils/formHelper';

const authAPI: AuthAPI = {
  register(user) {
    return requester.post(getNonTenantEndpoint('auth/register/'), user);
  },

  login(user) {
    return requester.post(getNonTenantEndpoint('auth/login/'), user);
  },

  getWorkspaces() {
    return requester.get(getNonTenantEndpoint('workspace/'));
  },

  createWorkspace(workspace) {
    return requester.post(
      getNonTenantEndpoint('workspace/'),
      getWorkspaceFormData(workspace),
      multipartFormConfig,
    );
  },

  updateWorkspace(workspace) {
    return requester.put(
      `workspace/${workspace.id}/`,
      getWorkspaceFormData(workspace),
      multipartFormConfig,
    );
  },

  getAccountProfile() {
    return requester.get(getNonTenantEndpoint('auth/userinfo/'));
  },

  updateAccountProfile(accountProfile) {
    return requester.post(getNonTenantEndpoint('auth/update/'), accountProfile);
  },

  changePassword(data) {
    return requester.post(getNonTenantEndpoint('auth/change-password/'), data);
  },

  getStaffInvitations(params) {
    return requester.get(getNonTenantEndpoint('staff-invitation/'), params);
  },

  acceptStaffInvitation(id) {
    return requester.get(
      getNonTenantEndpoint(`staff-invitation/${id}/accept-invitation/`),
    );
  },
};

export default authAPI;

const vi = {
  create: 'Tạo mới',
  cancel: 'Huỷ',
  update: 'Cập nhật',
  createStudent: 'Tạo mới học sinh',
  updateStudent: 'Cập nhật học sinh',
  add: 'Thêm',

  students: 'Danh sách học sinh',
  name: 'Tên học sinh',
  englishName: 'Tên tiếng anh',
  phoneNumber: 'Số điện thoại',
  dateOfBirth: 'Ngày sinh',
  link: 'Liên kết',
  deleteWarning: 'Bạn có chắc muốn xóa học sinh này không?',
  delete: 'Xóa',
  editInformation: 'Cập nhật thông tin học sinh',
  no: 'STT',

  pleaseInputStudentName: 'Vui lòng nhập tên học sinh',

  search: 'Tìm kiếm',
  enterName: 'Nhập tên',
  enterEnglishName: 'Nhập tên tiếng Anh',
  enterPhoneNumber: 'Nhập số điện thoại',
  keyword: 'Từ khoá',
  enterKeyword: 'Nhập từ khoá',

  learnedHours: 'Thời gian học',
  inviteCode: 'Mã mời',

  addStudent: 'Thêm học sinh',
};

export default vi;

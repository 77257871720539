import { useEffect } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  getBookAssets,
  addBookAssets,
  deleteBookAsset,
  getBookReaders,
  orderBookAssets,
  setBookAssets,
  setShouldReorderBookAssets,
} from 'features/bookDetails/bookDetailsSlice';
import {
  UseAddBookAssetsReturnType,
  UseDeleteBookAssetReturnType,
  GetBookReadersState,
  BookAsset,
} from 'features/bookDetails/types';

import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';
import { useParams } from 'react-router-dom';
import { getBook } from './bookDetailsSlice';
import { GetBookState } from './types';

export const useBook = (): GetBookState => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const book = useAppSelector(state => state.bookDetails.book);
  const getBookFailed = useAppSelector(
    state => state.bookDetails.getBookFailed,
  );
  const getBookLoading = useAppSelector(
    state => state.bookDetails.getBookLoading,
  );

  useEffect(() => {
    if (id) {
      dispatch(getBook(Number(id)));
    }
  }, [dispatch, id]);

  useFailed(getBookFailed);

  return {
    book,
    getBookLoading,
    getBookFailed,
  };
};

export const useBookAssets = () => {
  const bookAssets = useAppSelector(state => state.bookDetails.bookAssets);
  const getBookAssetsFailed = useAppSelector(
    state => state.bookDetails.getBookAssetsFailed,
  );
  const bookAssetsPaginationConfig = useAppSelector(
    state => state.bookDetails.bookAssetsPaginationConfig,
  );
  const getBookAssetsLoading = useAppSelector(
    state => state.bookDetails.getBookAssetsLoading,
  );

  const dispatch = useAppDispatch();

  const params = useURLQueryParams([]);
  const onPageChange = usePagination();
  const { id } = useParams();

  useEffect(() => {
    id && dispatch(getBookAssets({ id, params }));
  }, [params, dispatch, id]);

  useFailed(getBookAssetsFailed);

  const refreshCurrentPage = () => {
    id && dispatch(getBookAssets({ id, params }));
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage = bookAssets.length === 1 && params.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(params.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterAdding = () => {
    if (params.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    bookAssets,
    getBookAssetsLoading,
    getBookAssetsFailed,
    bookAssetsPaginationConfig: {
      ...bookAssetsPaginationConfig,
      onChange: onPageChange,
    },
    refreshAfterAdding,
    refreshAfterDeleting,
    refreshCurrentPage,
  };
};

export const useDeleteBookAsset = (
  callback: () => void,
): UseDeleteBookAssetReturnType => {
  const { id: bookId } = useParams();
  const { t } = useTranslation(LOCALES.MESSAGE);
  const deleteBookAssetSuccess = useAppSelector(
    state => state.bookDetails.deleteBookAssetSuccess,
  );
  const deleteBookAssetFailed = useAppSelector(
    state => state.bookDetails.deleteBookAssetFailed,
  );
  const deleteBookAssetLoading = useAppSelector(
    state => state.bookDetails.deleteBookAssetLoading,
  );

  const dispatch = useAppDispatch();

  const handleDeleteBookAsset = (assetId: number) => {
    if (bookId) {
      dispatch(deleteBookAsset({ bookId, assetId }));
    }
  };

  useSuccess(
    deleteBookAssetSuccess,
    t<string>('ASSET_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteBookAssetFailed);

  return {
    deleteBookAssetSuccess,
    deleteBookAssetFailed,
    deleteBookAssetLoading,
    handleDeleteBookAsset,
  };
};

export const useAddBookAssets = (
  callback: () => void,
): UseAddBookAssetsReturnType => {
  const { id: bookId } = useParams();
  const { t } = useTranslation(LOCALES.MESSAGE);
  const addBookAssetsSuccess = useAppSelector(
    state => state.bookDetails.addBookAssetsSuccess,
  );
  const addBookAssetsFailed = useAppSelector(
    state => state.bookDetails.addBookAssetsFailed,
  );
  const addBookAssetsLoading = useAppSelector(
    state => state.bookDetails.addBookAssetsLoading,
  );

  const dispatch = useAppDispatch();

  const handleAddBookAssets = (assetIds: number[]) => {
    if (bookId) {
      dispatch(addBookAssets({ bookId, assetIds }));
    }
  };

  useSuccess(
    addBookAssetsSuccess,
    t<string>('ASSET_ADDED_SUCCESSFULLY'),
    callback,
  );

  useFailed(addBookAssetsFailed);

  return {
    addBookAssetsSuccess,
    addBookAssetsLoading,
    addBookAssetsFailed,
    handleAddBookAssets,
  };
};

export const useOrderBookAsset = (onFailed: () => void) => {
  const { id: bookId } = useParams();
  const orderBookAssetsFailed = useAppSelector(
    state => state.bookDetails.orderBookAssetsFailed,
  );

  const dispatch = useAppDispatch();

  const handleOrderBookAsset = (orderedData: BookAsset[]) => {
    if (bookId) {
      const ids = orderedData.map(({ id }) => id);
      dispatch(orderBookAssets({ bookId: Number(bookId), ids }));
      dispatch(setBookAssets(orderedData));
    }
  };

  const updateShouldReorderBookAssets = (shouldReorder: boolean) => {
    dispatch(setShouldReorderBookAssets(shouldReorder));
  };

  useFailed(orderBookAssetsFailed, '', onFailed);

  return {
    handleOrderBookAsset,
    updateShouldReorderBookAssets,
  };
};

export const useBookReaders = (): GetBookReadersState => {
  const bookReaders = useAppSelector(state => state.bookDetails.bookReaders);
  const getBookReadersFailed = useAppSelector(
    state => state.bookDetails.getBookReadersFailed,
  );
  const bookReadersPaginationConfig = useAppSelector(
    state => state.bookDetails.bookReadersPaginationConfig,
  );
  const getBookReadersLoading = useAppSelector(
    state => state.bookDetails.getBookReadersLoading,
  );

  const dispatch = useAppDispatch();

  const params = useURLQueryParams([]);
  const onPageChange = usePagination();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getBookReaders({ id, params }));
  }, [params, dispatch, id]);

  useFailed(getBookReadersFailed);

  return {
    bookReaders,
    getBookReadersLoading,
    getBookReadersFailed,
    bookReadersPaginationConfig: {
      ...bookReadersPaginationConfig,
      onChange: onPageChange,
    },
  };
};

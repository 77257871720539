const en = {
  workspaces: 'Danh sách Workspace',
  createWorkspace: 'Tạo mới Workspace',

  invitations: 'Danh sách lời mời',
  no: 'STT',
  WorkspaceName: 'Tên Workspace',
  position: 'Chức vụ',
  inviteDate: 'Ngày mời',
  actions: 'Hành động',
  invitationAcceptedSuccessfully: 'Đồng ý lời mời thành công',
  invitationAcceptedFailed: 'Đồng ý lời mời thất bại',
  accept: 'Đồng ý',

  profile: 'Profile',
  generalInformation: 'Thông tin cơ bản',
  changePassword: 'Đổi mật khẩu',

  name: 'Tên',
  address: 'Địa chỉ',
  email: 'Email',
  phoneNumber: 'Số điện thoại',
  workspace: 'Workspace',
  url: 'Đường dẫn website',
  cancel: 'Thoát',
  update: 'Cập nhật',
  create: 'Tạo mới',
  updateWorkspace: 'Cập nhật workspace',

  acceptWarning: 'Bạn muốn đồng ý lời mời này?',
};

export default en;

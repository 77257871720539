import { Skeleton, Space, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const StyledTextSkeleton = styled(Skeleton)`
  .ant-skeleton-title {
    width: 100px !important;
  }
`;

const InfoItem = ({
  label,
  value,
  loading,
}: {
  label: string;
  value?: string | number;
  loading: boolean;
}) => {
  return (
    <Space>
      <Text type='secondary'>{label} :</Text>
      {loading ? (
        <StyledTextSkeleton active paragraph={false} />
      ) : (
        <Text>{value}</Text>
      )}
    </Space>
  );
};

export default InfoItem;

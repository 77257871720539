import { Button, Space, Spin } from 'antd';
import WorkspaceCard from './Card';
import { PageHeader } from 'components/Common/Page';
import { useWorkspaces } from 'features/auth/authHooks';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { Link } from 'react-router-dom';
import PATHS from 'router/paths';

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  height: calc(100vh - 64px);
  padding: 0 30px;
`;

const Workspaces = () => {
  const { workspaces, getWorkspacesLoading } = useWorkspaces();
  const { t } = useTranslation(LOCALES.WORKSPACE);

  return (
    <Spin spinning={getWorkspacesLoading} size='large'>
      <Wrapper>
        <PageHeader title={t('workspaces')}>
          <Link to={PATHS.account.createWorkspace}>
            <Button type='primary'>{t('createWorkspace')}</Button>
          </Link>
        </PageHeader>
        <div style={{ minHeight: 257 }}>
          <Space size={32} wrap>
            {workspaces.map(workspace => (
              <WorkspaceCard {...workspace} key={workspace.name} />
            ))}
          </Space>
        </div>
      </Wrapper>
    </Spin>
  );
};

export default Workspaces;

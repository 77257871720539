import { Row, Button } from 'antd';
import { LOCALES } from 'app/i18n';
import { SmallFilterPanel } from 'components/Common/Page';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/Common/SearchBox';
const Filter = ({ openStudentsModal }: { openStudentsModal: () => void }) => {
  const { t } = useTranslation(LOCALES.STUDENT);

  return (
    <SmallFilterPanel>
      <Row justify='space-between'>
        <SearchBox style={{ marginBottom: 0 }} />

        <Button
          onClick={openStudentsModal}
          type='primary'
          style={{ marginLeft: 50 }}>
          {t('addStudent')}
        </Button>
      </Row>
    </SmallFilterPanel>
  );
};

export default Filter;

import React, { Fragment } from 'react';
import { PageContent } from 'components/Common/Page';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { Form, Row, Col, Input, Button, DatePicker } from 'antd';
import FlatFormItem from 'components/Common/FlatFormItem';
import DirectUploader from 'components/Common/DirectUploader';
import { useNavigate } from 'react-router-dom';
import PATHS from 'router/paths';
import { useInitProfileForm, useUpdateAccountProfile } from '../authHooks';
import { CLIENT_DATE_FORMAT } from 'config';
import PhoneFormItem from 'components/Common/PhoneFormItem';

const FormItem = Form.Item;

const AccountProfile = () => {
  const { t } = useTranslation(LOCALES.PROFILE);
  const { t: validationT } = useTranslation(LOCALES.VALIDATION);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { initValue, getAccountProfileLoading } = useInitProfileForm(form);
  const {
    handleUpdateAccountProfile,
    updateAccountProfileLoading,
  } = useUpdateAccountProfile();

  const onFinish = (vals: any) => {
    const { address, birthday, first_name } = vals;
    handleUpdateAccountProfile({
      address,
      birthday: birthday.format('DD/MM/YYYY'),
      first_name,
    });
  };

  return (
    <Fragment>
      <PageContent style={{ padding: 32 }}>
        <Form
          disabled={getAccountProfileLoading || updateAccountProfileLoading}
          initialValues={initValue}
          style={{ height: '100%' }}
          form={form}
          layout='vertical'
          onFinish={onFinish}>
          <Row justify='center' align='middle' style={{ marginTop: 20 }}>
            <FormItem name='picture' style={{ marginBottom: 0 }}>
              <DirectUploader label={t<string>('avatar')} />
            </FormItem>
          </Row>

          <Row gutter={64} style={{ marginTop: 60 }}>
            <Col span={12}>
              <FlatFormItem label={t('name')} name='first_name'>
                <Input bordered={false} />
              </FlatFormItem>
            </Col>

            <Col span={12}>
              <FlatFormItem label={t('birthDate')} name='birthday'>
                <DatePicker
                  style={{ width: '100%' }}
                  format={CLIENT_DATE_FORMAT}
                  bordered={false}
                />
              </FlatFormItem>
            </Col>
          </Row>

          <Row gutter={64}>
            <Col span={12}>
              <FlatFormItem
                label={t('email')}
                name='email'
                rules={[
                  {
                    type: 'email',
                    message: validationT<string>('invalidEmail'),
                  },
                  {
                    required: true,
                    message: validationT<string>('pleaseInputEmail'),
                  },
                ]}>
                <Input disabled bordered={false} />
              </FlatFormItem>
            </Col>
            <Col span={12}>
              <PhoneFormItem required disabled />
            </Col>
          </Row>

          <FlatFormItem label={t('address')} name='address'>
            <Input bordered={false} />
          </FlatFormItem>

          <Row justify='center' style={{ marginTop: 120 }}>
            <Button
              disabled={updateAccountProfileLoading}
              onClick={() => navigate(PATHS.account.root)}
              style={{ marginRight: 10 }}>
              {t('cancel')}
            </Button>
            <Button
              loading={updateAccountProfileLoading}
              style={{ marginLeft: 10 }}
              htmlType='submit'
              type='primary'>
              {t('save')}
            </Button>
          </Row>
        </Form>
      </PageContent>
    </Fragment>
  );
};

export default AccountProfile;

import { Menu, Skeleton } from 'antd';
import { LOCALES } from 'app/i18n';
import { PageHeader } from 'components/Common/Page';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useRoles } from 'features/role/roleHooks';
import {
  generatePath,
  NavLink,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getClassName } from 'utils/style';
import PATHS from 'router/paths';

const Wrapper = styled.div`
  width: 250px;
  padding: 24px;
  border-right: 1px solid rgba(5, 5, 5, 0.06);

  .ant-menu-light {
    border-inline-end: none !important;
  }

  .ant-menu-item:has(.active-link) {
    color: #da271c;
    background-color: #fff1ed;

    &::after {
      position: absolute;
      inset-block: 0;
      inset-inline-end: 0;
      border-inline-end: 0px solid #da271c;
      content: '';
    }
  }

  .ant-menu-item:has(.not-active-link) {
    color: rgba(0, 0, 0, 0.88);

    &::after {
      border-width: 0;
      border-bottom-color: transparent;
    }
  }
`;

const StaffGroups = () => {
  const { t } = useTranslation(LOCALES.STAFF);
  const { roles, getRolesLoading, getStaffsLoading } = useRoles();
  const navigate = useNavigate();
  const { roleId } = useParams();

  useEffect(() => {
    if (!roleId && roles.length) {
      navigate(generatePath(PATHS.app.staffs.group, { roleId: roles[0].id }));
    }
  }, [roleId, roles, navigate]);

  return (
    <Wrapper>
      <PageHeader title={t('staffGroups')} />

      {getRolesLoading || false ? (
        <div style={{ paddingTop: 5 }}>
          <Skeleton.Input active />
          <Skeleton.Input active style={{ marginTop: 10 }} />
          <Skeleton.Input active style={{ marginTop: 10 }} />
        </div>
      ) : (
        <Menu
          items={roles.map((role, index) => ({
            disabled: getStaffsLoading,
            key: index,
            label: (
              <NavLink
                className={getClassName}
                to={generatePath(PATHS.app.staffs.group, { roleId: role.id })}
                style={{ fontWeight: 500 }}>
                {role.name} {`(${role.numbers_of_staff})`}
              </NavLink>
            ),
          }))}></Menu>
      )}
    </Wrapper>
  );
};

export default StaffGroups;

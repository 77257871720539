import Dexie, { Table } from 'dexie';
import { Album, AlbumAsset } from 'features/course/types';
import { ListResponse } from 'types';

interface CachedFile {
  id?: number;
  fileId: number;
  file: {
    data: Blob;
    headers: {
      'content-type': string;
    };
  };
}

class AssetDB extends Dexie {
  files!: Table<CachedFile>;

  constructor() {
    super('localFilesDB');
    this.version(1).stores({
      files: `
        ++id,
        fileId,
        content`,
    });
  }
}
const db = new AssetDB();

const addCachedStatusToAlbum = (
  album: Album,
  dict: {
    [key: number]: any;
  },
) => {
  const nextAssets: AlbumAsset[] = album.assets
    .filter(albumAsset => !!albumAsset.asset)
    .map((albumAsset) => {
      const asset = albumAsset.asset;
      const result = {
        ...albumAsset,
        asset: {
          ...asset,
          isCached: !!dict[asset.id],
        },
      };
      return result;
    });
  return {
    ...album,
    assets: nextAssets,
  };
};

const dbAPI = {
  addCachedStatus: async <T extends { id: number }>(
    response: ListResponse<T>,
  ): Promise<ListResponse<T>> => {
    const dict: {
      [key: number]: any;
    } = {};
    const cachedFiles = await db.files.toArray();

    cachedFiles.forEach(file => {
      dict[file.fileId] = true;
    });

    const nextResults = response.results.map(file => {
      return {
        ...file,
        isCached: dict[file.id],
      };
    });

    return { ...response, results: nextResults };
  },

  addCachedStatusToAlbum: async (album: Album): Promise<Album> => {
    const dict: {
      [key: number]: any;
    } = {};
    const cachedFiles = await db.files.toArray();

    cachedFiles.forEach(file => {
      dict[file.fileId] = true;
    });

    return addCachedStatusToAlbum(album, dict);
  },

  addCachedStatusToAlbums: async (albums: Album[]): Promise<Album[]> => {
    const dict: {
      [key: number]: any;
    } = {};
    const cachedFiles = await db.files.toArray();

    cachedFiles.forEach(file => {
      dict[file.fileId] = true;
    });
    return albums.map(album => addCachedStatusToAlbum(album, dict));
  },

  getById(id: number) {
    return db.files.get({ fileId: id });
  },

  addCachedFile(id: number, response: any) {
    db.files.add({
      fileId: id,
      file: {
        data: response.data,
        headers: {
          'content-type': response.headers['content-type'],
        },
      },
    });
  },
};

export default dbAPI;

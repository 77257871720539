import { Row, Input, Button } from 'antd';
import { useSearch } from 'app/hooks';
import { LOCALES } from 'app/i18n';
import FlatFormItem from 'components/Common/FlatFormItem';
import { SmallFilterPanel } from 'components/Common/Page';
import { useTranslation } from 'react-i18next';

const Filter = ({ openAssetsModal }: { openAssetsModal: () => void }) => {
  const { t } = useTranslation(LOCALES.ASSET);
  const { searchValue, onSearch } = useSearch();

  return (
    <SmallFilterPanel>
      <Row justify='space-between'>
        <FlatFormItem style={{ marginBottom: 0 }} label={t('keyword')}>
          <Input
            value={searchValue}
            onChange={({ target: { value } }) => {
              onSearch(value);
            }}
            style={{ width: 350 }}
            bordered={false}
            placeholder={t('enterKeyword') || ''}
          />
        </FlatFormItem>

        <Button
          onClick={openAssetsModal}
          type='primary'
          style={{ marginLeft: 50 }}>
          {t('addAsset')}
        </Button>
      </Row>
    </SmallFilterPanel>
  );
};

export default Filter;

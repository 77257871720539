const en = {
  createAccount: 'Create an Account',
  easyToManage: 'Quản lý trung tâm dễ dàng và hiệu quả',
  comprehensiveSolution: 'Comprehensive Solution',
  loginToAccount: 'Login into your account',
  emailOrUsername: 'Email or Username',
  password: 'Password',
  remember: 'Remember',
  forgotPassword: 'Forgot password?',
  login: 'Login',
  phoneNumber: 'Phone number',
  email: 'Email',
};

export default en;

import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import PATHS from 'router/paths';
import { useTranslation } from 'react-i18next';
import { WalletOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { LOCALES } from 'app/i18n';
import { PageHeader, MenuWrapper } from 'components/Common/Page';

const getClassName = ({
  isActive,
  isPending,
}: {
  isActive: boolean;
  isPending: boolean;
}) =>
  isPending ? 'pending-link' : isActive ? 'active-link' : 'not-active-link';

const ProfileMenu = () => {
  const { t } = useTranslation(LOCALES.WORKSPACE);

  const menuItems: MenuProps['items'] = [
    {
      label: (
        <NavLink to={PATHS.account.profile.root} className={getClassName} end>
          {t('generalInformation')}
        </NavLink>
      ),
      icon: <WalletOutlined />,
      key: PATHS.account.profile.root,
    },
    {
      label: (
        <NavLink
          to={PATHS.account.profile.changePassword}
          className={getClassName}>
          {t('changePassword')}
        </NavLink>
      ),
      icon: <WalletOutlined />,
      key: PATHS.account.profile.changePassword,
    },
  ];

  return (
    <MenuWrapper style={{ marginRight: 0, marginBottom: 20 }}>
      <Menu
        style={{ borderBottom: 'none' }}
        mode='horizontal'
        items={menuItems}></Menu>
    </MenuWrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 30px;

  .ant-menu {
    background: transparent;
    box-shadow: rgb(231, 231, 231) 0px -1px 0px inset;

    .ant-menu-item:first-child {
      margin-left: -16px;
    }
  }
`;

const Developers = () => {
  const { t } = useTranslation(LOCALES.WORKSPACE);

  return (
    <Wrapper>
      <PageHeader
        level={4}
        style={{ marginBottom: 0 }}
        title={t('profile')}></PageHeader>{' '}
      <ProfileMenu />
      <div>
        <Outlet />
      </div>
    </Wrapper>
  );
};

export default Developers;

import styled from "styled-components";
import breakpoints from "config/breakpoints";
import color from "config/color";

export const SignInWrapper = styled.div`
  display: flex;
  background-color: ${color.red};
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .left-image {
    img,
    svg {
      width: 100%;
      height: 250px;
    }
  }
`;

export const FormWrapper = styled.div`
  height: 773px;
  background-color: ${color.white};
  border-radius: 50px;
  display: flex;
  overflow: hidden;
  width: 1265px;

  @media only screen and ${breakpoints?.device?.xl} {
    width: 85%;
    height: auto;
  }

  .ant-row {
    width: 100%;
  }
`;

export const LeftFormWrapper = styled.div`
  background-color: ${color.greyLight};
  padding-right: 75px;
  padding-left: 85px;
  width: 100%;
  height: 100%;
`;

export const LeftHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 56px;
  padding-bottom: 140px;

  .logo svg {
    height: 48px;
  }
`;

export const PositionLogo = styled.div`
  display: none;

  @media only screen and ${breakpoints?.device?.xl} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;

    position: absolute;
    top: 45px;

    .logo svg {
      object-fit: contain;
      width: 100%;
      height: 48px;
    }
  }

  @media only screen and ${breakpoints?.device?.sm} {
    width: 80%;

    .logo svg {
      height: 32px;
    }

    .ant-btn {
      font-size: 14px;
    }
  }
`;

export const LeftFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 80px;
  margin-top: 87px;
  flex-wrap: wrap;
  font-family: mon-semi-bold;

  .left-footer__slogan {
    &--1 {
      font-size: 20px;
      color: ${color.textBlack};
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.1px;
    }

    &--2 {
      margin-top: 10px;
      font-size: 32px;
      color: ${color.red};
      line-height: 43px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  @media only screen and ${breakpoints.device.xl} {
    .left-footer__slogan {
      &--1 {
        font-size: 20px;
        line-height: 32px;
      }

      &--2 {
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
`;

export const RightFormWrapper = styled.div`
  padding: 152px 100px 0 100px;
  width: 100%;
  height: 100%;

  @media only screen and ${breakpoints?.device?.xl} {
    padding-right: 50px;
    padding-left: 50px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url(./imgs/loginImage.png);
      background-repeat: no-repeat;
      background-position: center;
      filter: grayscale(100%);
      opacity: 0.1;
    }
  }

  @media only screen and ${breakpoints?.device?.md} {
    padding-top: 120px;
  }

  .form-header {
    position: relative;
    height: 137px;
    z-index: 1;

    @media only screen and ${breakpoints?.device?.md} {
      height: 80px;
    }

    p {
      font-size: 28px;
      font-family: mon-semi-bold;
      letter-spacing: 0.12px;
      color: ${color.textBlack};
      text-align: center;

      @media only screen and ${breakpoints?.device?.sm} {
        font-size: 18px;
        height: auto;
        margin-bottom: 30px;
      }
    }
  }

  .form-content {
    .ant-form-item {
      margin-bottom: 40px;
    }

    @media only screen and ${breakpoints?.device?.md} {
      label {
        font-size: 18px;
      }

      .ant-checkbox-input {
        width: 18px !important;
        height: 18px !important;
      }
    }

    @media only screen and ${breakpoints?.device?.sm} {
      label {
        font-size: 14px;
      }

      .ant-checkbox-input {
        width: 14px !important;
        height: 14px !important;
      }

      .ant-form-item {
        margin-bottom: 20px;
      }

      span {
        font-size: 14px;
      }
    }
  }
`;

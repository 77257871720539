import React from 'react';
import { Button } from 'antd';
import { LANGUAGE } from 'config';
import { useTranslation } from 'react-i18next';
import vnFlag from 'assets/img/vietnam.png';
import usFlag from 'assets/img/united-states-of-america.png';

const { VI, EN } = LANGUAGE;

const LanguageBtn = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const changeLanguage = () => {
    const nextLanguage = language === VI ? EN : VI;
    i18n.changeLanguage(nextLanguage);
    localStorage.setItem('language', nextLanguage);
  };

  return (
    <Button
      onClick={changeLanguage}
      size='small'
      type='text'
      style={{ marginLeft: 15, marginTop: 5 }}>
      <img
        alt='flag'
        src={language === VI ? vnFlag : usFlag}
        style={{ width: 20, height: 20 }}
      />
    </Button>
  );
};

export default LanguageBtn;

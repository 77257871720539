import { useEffect } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  useSuccess,
} from 'app/hooks';
import {
  generateInviteCode,
  getInviteCode,
  updateInviteCode,
  resetCourseInviteCodeState,
} from 'features/course/courseSlice';
import { useParams } from 'react-router-dom';
import { InviteCodeModel } from '../types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';

export const useCourseInviteCode = () => {
  const getInviteCodeLoading = useAppSelector(
    state => state.course.getInviteCodeLoading,
  );
  const inviteCode = useAppSelector(state => state.course.inviteCode);
  const getInviteCodeFailed = useAppSelector(
    state => state.course.getInviteCodeFailed,
  );
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getInviteCode(id));
    }
  }, [dispatch, id]);

  useFailed(getInviteCodeFailed);

  return {
    getInviteCodeLoading,
    inviteCode,
  };
};

export const useCourseGenerateInviteCode = () => {
  const generateInviteCodeLoading = useAppSelector(
    state => state.course.generateInviteCodeLoading,
  );
  const generateInviteCodeFailed = useAppSelector(
    state => state.course.generateInviteCodeFailed,
  );

  const dispatch = useAppDispatch();

  const handleGenerateInviteCode = (model: InviteCodeModel) => {
    dispatch(generateInviteCode(model));
  };

  useFailed(generateInviteCodeFailed);

  return {
    handleGenerateInviteCode,
    generateInviteCodeLoading,
  };
};

export const useCourseUpdateInviteCode = (callback: () => void) => {
  const updateInviteCodeLoading = useAppSelector(
    state => state.course.updateInviteCodeLoading,
  );
  const updateInviteCodeSuccess = useAppSelector(
    state => state.course.updateInviteCodeSuccess,
  );
  const updateInviteCodeFailed = useAppSelector(
    state => state.course.updateInviteCodeFailed,
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation(LOCALES.MESSAGE);

  const handleUpdateInviteCode = (model: InviteCodeModel) => {
    dispatch(updateInviteCode(model));
  };

  useSuccess(
    updateInviteCodeSuccess,
    t<string>('INVITE_CODE_UPDATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(updateInviteCodeFailed);

  useEffect(() => {
    return () => {
      dispatch(resetCourseInviteCodeState());
    };
  }, [dispatch]);

  return {
    updateInviteCodeLoading,
    handleUpdateInviteCode,
  };
};

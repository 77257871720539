const vi = {
  createAccount: 'Tạo Tài Khoản',
  easyToManage: 'Quản lý trung tâm dễ dàng và hiệu quả',
  comprehensiveSolution: 'Giải pháp toàn diện',
  loginToAccount: 'Đăng nhập vào tài khoản',
  emailOrUsername: 'Email hoặc tên đăng nhập',
  emailOrUsernameIsRequired: 'Vui lòng nhập Email hoặc tên đăng nhập',
  invalidEmail: 'Email không hợp lệ',
  password: 'Mật khẩu',
  passwordIsRequired: 'Vui lòng nhập mật khẩu ',
  remember: 'Nhớ thông tin cho lần sau',
  forgotPassword: 'Quên mật khẩu?',
  login: 'Đăng Nhập',
  phoneNumber: 'Số điện thoại',
  phoneNumberIsRequired: 'Vui lòng nhập số điện thoại',
  email: 'Email',
};

export default vi;

import React from 'react';
import { FilterPanel } from 'components/Common/Page';
import { Row, Form, Input, Button, Space, Col } from 'antd';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { ASSET_QUERY_PARAMS } from 'features/asset/assetHooks';
import { useURLQueryParams, useFilter } from 'app/hooks';

const Filter = ({ loading }: { loading: boolean }) => {
  const { t } = useTranslation(LOCALES.ASSET);
  const [form] = Form.useForm();
  const { onReset, onFilter } = useFilter(form, ASSET_QUERY_PARAMS);
  const urlQueryParams = useURLQueryParams(Object.values(ASSET_QUERY_PARAMS));

  return (
    <FilterPanel>
      <Form
        initialValues={urlQueryParams}
        form={form}
        onFinish={onFilter}
        disabled={loading}>
        <Row
          gutter={24}
          style={{ width: '100%', flexWrap: 'nowrap' }}
          justify='space-between'>
          {' '}
          <Col span={6}>
            {' '}
            <FlatFormItem
              name={ASSET_QUERY_PARAMS.NAME}
              label={t('name')}
              style={{ marginBottom: 0 }}>
              <Input bordered={false} placeholder={t<string>('enterName')} />
            </FlatFormItem>
          </Col>
          <Col span={6}>
            <FlatFormItem
              name={ASSET_QUERY_PARAMS.TAGS}
              label={t('tags')}
              style={{ marginBottom: 0 }}>
              <Input bordered={false} placeholder={t<string>('enterTag')} />
            </FlatFormItem>
          </Col>
          <Col span={6}>
            <FlatFormItem
              name='search'
              label={t('keyword')}
              style={{ marginBottom: 0 }}>
              <Input bordered={false} placeholder={t<string>('enterKeyword')} />
            </FlatFormItem>
          </Col>
          <Space style={{ marginLeft: 20, marginRight: -12 }}>
            <Button disabled={loading} onClick={onReset}>
              Reset
            </Button>
            <Button
              loading={loading}
              htmlType='submit'
              style={{ marginLeft: 15 }}
              type='primary'>
              {t('search')}
            </Button>
          </Space>
        </Row>
      </Form>
    </FilterPanel>
  );
};

export default Filter;

import { Row, Form, Input, Button, Space, Col } from 'antd';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  STUDENT_MODAL_QUERY_PARAMS,
  useStudentsModalUrlQueryParams,
} from 'features/student/studentHooks';
import { useFilter } from 'app/hooks';

const { NAME, ENGLISH_NAME, PHONE_NUMBER, SEARCH } = STUDENT_MODAL_QUERY_PARAMS;

const Filter = ({ loading }: { loading: boolean }) => {
  const urlQueryParams = useStudentsModalUrlQueryParams();
  const { t } = useTranslation(LOCALES.STUDENT);
  const [form] = Form.useForm();
  const { onReset, onFilter } = useFilter(form, STUDENT_MODAL_QUERY_PARAMS);

  return (
    <Form
      initialValues={urlQueryParams}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout='horizontal'
      onFinish={onFilter}
      disabled={loading}>
      <Row
        style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
        gutter={64}>
        <Col span={19}>
          <Row gutter={48} style={{ width: '100%' }}>
            <Col span={12}>
              <FlatFormItem name={NAME} label={t('name')}>
                <Input
                  width={100}
                  bordered={false}
                  placeholder={t<string>('enterName')}
                />
              </FlatFormItem>
            </Col>

            <Col span={12}>
              <FlatFormItem name={ENGLISH_NAME} label={t('englishName')}>
                <Input
                  bordered={false}
                  placeholder={t<string>('enterEnglishName')}
                />
              </FlatFormItem>
            </Col>

            <Col span={12}>
              <FlatFormItem name={PHONE_NUMBER} label={t('phoneNumber')}>
                <Input
                  bordered={false}
                  placeholder={t<string>('enterPhoneNumber')}
                />
              </FlatFormItem>
            </Col>

            <Col span={12}>
              <FlatFormItem name={SEARCH} label={t('keyword')}>
                <Input
                  bordered={false}
                  placeholder={t<string>('enterKeyword')}
                />
              </FlatFormItem>
            </Col>
          </Row>
        </Col>

        <Col span={5} style={{ marginLeft: 'auto' }}>
          <Space
            style={{ marginLeft: 0, height: '100%', paddingBottom: 24 }}
            align='end'>
            <Button disabled={loading} onClick={onReset}>
              Reset
            </Button>
            <Button
              loading={loading}
              htmlType='submit'
              style={{ marginLeft: 15 }}
              type='primary'>
              {t('search')}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;

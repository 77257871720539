import React, { Fragment } from 'react';
import { PageContent, PageHeader } from 'components/Common/Page';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import WorkspaceForm from 'features/auth/Workspace/Form';

const UpdateWorkspace = () => {
  const { t } = useTranslation(LOCALES.WORKSPACE);

  return (
    <Fragment>
      <PageHeader title={t('updateWorkspace')} />
      <PageContent style={{ maxWidth: 900, padding: 32 }}>
        <WorkspaceForm />
      </PageContent>
    </Fragment>
  );
};

export default UpdateWorkspace;

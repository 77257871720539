import { Row, Col, Space, Image, Skeleton } from 'antd';
import { Book } from 'features/book/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import InfoItem from 'components/Common/InfoItem';
import { getDisplayingDate } from 'utils';

const Information = ({ book, loading }: { book?: Book; loading: boolean }) => {
  const { t } = useTranslation(LOCALES.BOOK);
  const photoSrc = typeof book?.photo !== 'string' ? '' : book?.photo;

  return (
    <Row>
      <Col span={6}>
        {loading ? (
          <Skeleton.Image active />
        ) : (
          <Image
            width={96}
            height={96}
            style={{ objectFit: 'contain' }}
            placeholder={true}
            src={photoSrc}
          />
        )}
      </Col>

      <Col span={9}>
        <Space direction='vertical'>
          <InfoItem label={t('name')} value={book?.name} loading={loading} />
          <InfoItem
            label={t('author')}
            value={book?.author}
            loading={loading}
          />
          <InfoItem
            label={t('readers')}
            value={book?.total_reader}
            loading={loading}
          />
        </Space>
      </Col>

      <Col span={9}>
        {' '}
        <Space direction='vertical'>
          <InfoItem
            label={t('updatedAt')}
            value={getDisplayingDate(book?.updated_at)}
            loading={loading}
          />
          <InfoItem
            label={t('updatedBy')}
            value={book?.updated_by?.name}
            loading={loading}
          />

          <InfoItem
            label={t('description')}
            value={book?.descriptions}
            loading={loading}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default Information;

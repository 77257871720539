import { Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { forwardRef } from 'react';

interface IFileUploaderProps {
  onUpload: (file: RcFile) => void;
}

type Ref = HTMLDivElement;

const DirectoryUploader = forwardRef<Ref, IFileUploaderProps>(
  ({ onUpload }, ref) => {
    const beforeUpload = (file: RcFile) => {
      onUpload(file);
      return false;
    };

    return (
      <Upload
        directory={true}
        showUploadList={false}
        style={{ display: 'none' }}
        beforeUpload={beforeUpload}>
        <div ref={ref}></div>
      </Upload>
    );
  },
);

export default DirectoryUploader;

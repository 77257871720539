import { NotifyContext } from 'app/hooks';
import { message } from 'antd';
import routes from 'router';
import { useRoutes } from 'react-router-dom';
import { useAuth } from 'features/auth/authHooks';
import { setAxiosToken, setTenant } from 'utils/requester';
import UploadManager from 'components/UploadManager';
import VideoModal from 'components/AssetViewers/VideoModal';
import PDFModal from 'components/AssetViewers/PDFModal';
import ExcelModal from 'components/AssetViewers/ExcelModal';
import ImageModal from 'components/AssetViewers/ImageModal';

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const element = useRoutes(routes);
  const { token, workspace } = useAuth();

  workspace && setTenant(workspace);
  token && setAxiosToken(token);

  return (
    <div className='app'>
      {contextHolder}
      <NotifyContext.Provider value={messageApi}>
        {element}
      </NotifyContext.Provider>
      <UploadManager />

      <VideoModal />
      <PDFModal />
      <ExcelModal />
      <ImageModal />
    </div>
  );
}

export default App;

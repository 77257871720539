const en = {
  books: 'Books',
  no: 'No',
  create: 'Create',
  cancel: 'Cancel',
  update: 'Update',
  delete: 'Delete',
  createdBy: 'Created By',
  createBook: 'Create Book',
  updateBook: 'Update Book',
  bookInformation: "Book Information",
  add: 'Add',

  name: 'Name',
  author: 'Author',
  readers: 'Readers',
  description: 'Description',
  logo: 'Logo',
  updatedAt: 'Updated at',
  updatedBY: 'Updated by',
  reader: 'Reader',
  document: 'Document',

  pleaseInputName: 'Please input book name!',
  pleaseInputAuthor: 'Please input author name!',
  enterName: 'Enter playlist name',
  enterAuthor: 'Enter author name',
  enterDescription: 'Enter description',
  inviteCode: 'Invitation Code',

  deleteWarning: 'Are you sure want to delete this Book?',
};

export default en;

import { useEffect } from 'react';
import { Form, Input, Row, Button } from 'antd';
import { LOCALES } from 'app/i18n';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { BaseBook } from 'features/book/types';
import { useCreateBook, useUpdateBook } from 'features/book/bookHooks';
import ImageUploader from 'components/Common/ImageUploader';
import FormModal from 'components/Styled/FormModal';

interface BookFormProps {
  isOpen: boolean;
  onClose: () => void;
  initValues: BaseBook;
  refreshAfterCreating: () => void;
}

const BookForm = (props: BookFormProps) => {
  const { isOpen, onClose, initValues, refreshAfterCreating } = props;
  const { t } = useTranslation(LOCALES.BOOK);
  const [form] = Form.useForm<BaseBook>();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, form]);

  const onCreateSuccess = () => {
    onCancel();
    refreshAfterCreating();
  };

  const { handleCreateBook, createBookLoading } = useCreateBook(
    onCreateSuccess,
  );

  const { handleUpdateBook, updateBookLoading } = useUpdateBook(onCancel);

  const isLoading = createBookLoading || updateBookLoading;

  const onFinish = (values: any) => {
    const bookModel = {
      ...values,
    };
    if (initValues.id) {
      handleUpdateBook({ ...bookModel, id: initValues.id });
    } else {
      handleCreateBook(bookModel);
    }
  };

  return (
    <FormModal
      width={700}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
      title={t(initValues.id ? 'updateBook' : 'createBook')}>
      <Form
        disabled={isLoading}
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        layout='vertical'>
        <Row justify='center' align='middle' style={{ marginTop: 20 }}>
          <Form.Item name='photo' style={{ marginBottom: 0 }}>
            <ImageUploader label={t<string>('logo')} />
          </Form.Item>
        </Row>

        <FlatFormItem
          label={t('name')}
          name='name'
          style={{ marginTop: 30 }}
          rules={[{ required: true, message: t<string>('pleaseInputName') }]}>
          <Input bordered={false} placeholder={t<string>('enterName')} />
        </FlatFormItem>

        <FlatFormItem
          label={t('author')}
          name='author'
          style={{ marginTop: 30 }}
          rules={[{ required: true, message: t<string>('pleaseInputAuthor') }]}>

          <Input bordered={false} placeholder={t<string>('enterAuthor')} />
        </FlatFormItem>

        <FlatFormItem label={t('description')} name='descriptions'>
          <Input bordered={false} placeholder={t<string>('enterDescription')} />
        </FlatFormItem>

        <Row justify='center' style={{ marginTop: 40, marginBottom: 10 }}>
          <Button
            disabled={isLoading}
            style={{ marginRight: 10 }}
            onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
          <Button
            loading={isLoading}
            htmlType='submit'
            style={{ marginLeft: 10 }}
            type='primary'>
            {initValues.id ? t('update') : t('create')}
          </Button>
        </Row>
      </Form>
    </FormModal>
  );
};

export default BookForm;

import { Row, Col, Skeleton, Space, Image } from 'antd';
import { Playlist } from '../types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import InfoItem from 'components/Common/InfoItem';
import { getDisplayingDate } from 'utils';

const Information = ({
  playlist,
  loading,
}: {
  playlist?: Playlist;
  loading: boolean;
}) => {
  const { t } = useTranslation(LOCALES.PLAYLIST);

  const photoSrc = typeof playlist?.photo !== 'string' ? '' : playlist?.photo;

  return (
    <Row style={{ paddingBottom: 20 }}>
      <Col span={6}>
        {loading ? (
          <Skeleton.Image active />
        ) : (
          <Image
            width={96}
            height={96}
            style={{ objectFit: 'contain' }}
            placeholder={true}
            src={photoSrc}
          />
        )}
      </Col>

      <Col span={9}>
        <Space direction='vertical'>
          <InfoItem
            label={t('name')}
            value={playlist?.name}
            loading={loading}
          />
          <InfoItem
            label={t('createdBy')}
            value={playlist?.created_by?.name}
            loading={loading}
          />
          <InfoItem
            label={t('descriptions')}
            value={playlist?.descriptions}
            loading={loading}
          />
        </Space>
      </Col>

      <Col span={9}>
        {' '}
        <Space direction='vertical'>
          <InfoItem
            label={t('updatedAt')}
            value={getDisplayingDate(playlist?.updated_at)}
            loading={loading}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default Information;

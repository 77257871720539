import { useEffect } from 'react';
import { Form, Input, Row, Button } from 'antd';
import { LOCALES } from 'app/i18n';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { BaseBranch } from './types';
import { useCreateBranch, useUpdateBranch } from './branchHooks';
import FormModal from 'components/Styled/FormModal';

interface BranchFormProps {
  isOpen: boolean;
  onClose: () => void;
  initValues: BaseBranch;
  refreshAfterCreating: () => void;
}

const BranchForm = (props: BranchFormProps) => {
  const { isOpen, onClose, initValues, refreshAfterCreating } = props;
  const { t } = useTranslation(LOCALES.BRANCH);
  const [form] = Form.useForm<BaseBranch>();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, form]);

  const onCreateSuccess = () => {
    onCancel();
    refreshAfterCreating();
  };

  const { handleCreateBranch, createBranchLoading } =
    useCreateBranch(onCreateSuccess);

  const { handleUpdateBranch, updateBranchLoading } = useUpdateBranch(onCancel);

  const isLoading = createBranchLoading || updateBranchLoading;

  const onFinish = (values: any) => {
    const formatLongLat = (longLat: string) => {
      return `POINT(${longLat.split(',').join(' ')})`;
    };

    const location = formatLongLat(values.location);
    const model = {
      ...values,
      location,
    };

    if (initValues.id) {
      handleUpdateBranch({ ...model, id: initValues.id });
    } else {
      handleCreateBranch(model);
    }
  };

  return (
    <FormModal
      width={700}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
      title={initValues.id ? t('updateBranch') : t('createBranch')}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        layout='vertical'>
        <FlatFormItem
          label={t('name')}
          name='name'
          style={{ marginTop: 30 }}
          rules={[{ required: true, message: t<string>('nameIsRequired') }]}>
          <Input bordered={false} />
        </FlatFormItem>

        <FlatFormItem
          label={t('address')}
          name='address'
          style={{ marginTop: 30 }}
          rules={[{ required: true, message: t<string>('addressIsRequired') }]}>
          <Input bordered={false} />
        </FlatFormItem>

        <FlatFormItem
          label={t('location') + ` - ${'(Latitude, Longitude)'}`}
          name='location'
          rules={[
            { required: true, message: t<string>('locationIsRequired') },
          ]}>
          <Input bordered={false} />
        </FlatFormItem>

        <Row justify='center' style={{ marginTop: 40, marginBottom: 10 }}>
          <Button
            disabled={isLoading}
            style={{ marginRight: 10 }}
            onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
          <Button
            loading={isLoading}
            htmlType='submit'
            style={{ marginLeft: 10 }}
            type='primary'>
            {initValues.id ? t('update') : t('create')}
          </Button>
        </Row>
      </Form>
    </FormModal>
  );
};

export default BranchForm;

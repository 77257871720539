import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './assets/css/scss/main.scss';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'app/i18n';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.Fragment>
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#da271c',
              colorLink: '#c1112f',
              colorLinkHover: '#da271c',
            },
          }}>
          <App />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import bookDetailsAPI from './bookDetailsAPI';
import {
  GetBookAssetsParams,
  BookAsset,
  DeleteBookAssetParams,
  AddBookAssetsPayload,
  BookDetailsState,
  GetBookReadersParams,
  BookReader,
  OrderBookAssetsModel,
} from './types';
import { resetStoreAction } from 'config';
import { ListResponse } from 'types';
import dbAPI from 'db';

export const getBook = createAppAsyncThunk(
  'book/getBook',
  async (id: number, { rejectWithValue }) => {
    try {
      const book = await bookDetailsAPI.getBook(id);
      return book;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

// BOOK ASSETS
export const getBookAssets = createAppAsyncThunk(
  'book/getBookAssets',
  async (
    params: GetBookAssetsParams,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const response: ListResponse<BookAsset> =
        await bookDetailsAPI.getBookAssets(params);

      const {
        bookDetails: { shouldReorderAssets },
      } = getState();
      if (shouldReorderAssets) {
        const ids = response.results.map(({ id }) => id);
        const orderModel: OrderBookAssetsModel = {
          ids,
          bookId: Number(params.id),
        };

        await dispatch(orderBookAssets(orderModel));
      }

      return await dbAPI.addCachedStatus(response);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteBookAsset = createAppAsyncThunk(
  'book/deleteBookAsset',
  async (params: DeleteBookAssetParams, { rejectWithValue }) => {
    try {
      const response = await bookDetailsAPI.deleteBookAsset(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const addBookAssets = createAppAsyncThunk(
  'book/addBookAssets',
  async (payload: AddBookAssetsPayload, { rejectWithValue }) => {
    try {
      const response = await bookDetailsAPI.addBookAssets(payload);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const orderBookAssets = createAppAsyncThunk(
  'book/orderBookAssets',
  async (model: OrderBookAssetsModel, { rejectWithValue, dispatch }) => {
    try {
      await bookDetailsAPI.orderBookAssets(model);
      return {};
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

// BOOK READERS
export const getBookReaders = createAppAsyncThunk(
  'book/getBookReaders',
  async (params: GetBookReadersParams, { rejectWithValue }) => {
    try {
      const response: ListResponse<BookReader> =
        await bookDetailsAPI.getBookReaders(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: BookDetailsState = {
  getBookLoading: false,
  book: undefined,
  getBookFailed: undefined,

  getBookAssetsLoading: false,
  bookAssets: [],
  getBookAssetsFailed: undefined,
  bookAssetsPaginationConfig: {},

  deleteBookAssetLoading: false,
  deleteBookAssetSuccess: undefined,
  deleteBookAssetFailed: undefined,

  addBookAssetsLoading: false,
  addBookAssetsSuccess: undefined,
  addBookAssetsFailed: undefined,

  orderBookAssetsLoading: false,
  orderBookAssetsSuccess: undefined,
  orderBookAssetsFailed: undefined,

  getBookReadersLoading: false,
  bookReaders: [],
  getBookReadersFailed: undefined,
  bookReadersPaginationConfig: {},

  shouldReorderAssets: false,
};

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    resetBookState() {
      return initialState;
    },

    setBookAssets(state, action) {
      state.bookAssets = action.payload;
    },
    setShouldReorderBookAssets(state, action) {
      state.shouldReorderAssets = action.payload;
    },
  },

  extraReducers(builder) {
    builder

      .addCase(getBook.pending, state => {
        state.getBookLoading = true;
        state.getBookFailed = undefined;
      })
      .addCase(getBook.fulfilled, (state, action) => {
        state.getBookLoading = false;
        state.book = action.payload;
      })
      .addCase(getBook.rejected, (state, action) => {
        state.getBookLoading = false;
        state.getBookFailed = action.payload;
      })

      // BOOK ASSETS
      .addCase(getBookAssets.pending, (state, action) => {
        state.getBookAssetsLoading = true;
        state.getBookAssetsFailed = undefined;

        const {
          meta: {
            arg: {
              params: { page, page_size },
            },
          },
        } = action;

        state.bookAssetsPaginationConfig.current = Number(page);
        state.bookAssetsPaginationConfig.pageSize = Number(page_size);
      })
      .addCase(getBookAssets.fulfilled, (state, { payload }) => {
        state.getBookAssetsLoading = false;
        state.bookAssets = payload.results;
        state.bookAssetsPaginationConfig.total = payload.count;
      })
      .addCase(getBookAssets.rejected, (state, action) => {
        state.getBookAssetsLoading = false;
        state.getBookAssetsFailed = action.payload;
      })

      .addCase(deleteBookAsset.pending, state => {
        state.deleteBookAssetLoading = true;
        state.deleteBookAssetFailed = undefined;
      })
      .addCase(deleteBookAsset.fulfilled, (state, action) => {
        state.deleteBookAssetLoading = false;
        state.deleteBookAssetSuccess = action.payload;
      })
      .addCase(deleteBookAsset.rejected, (state, action) => {
        state.deleteBookAssetLoading = false;
        state.deleteBookAssetFailed = action.payload;
      })

      .addCase(addBookAssets.pending, state => {
        state.addBookAssetsLoading = true;
        state.addBookAssetsFailed = undefined;
      })
      .addCase(addBookAssets.fulfilled, (state, action) => {
        state.addBookAssetsLoading = false;
        state.addBookAssetsSuccess = action.payload;
      })
      .addCase(addBookAssets.rejected, (state, action) => {
        state.addBookAssetsLoading = false;
        state.addBookAssetsFailed = action.payload;
      })

      .addCase(orderBookAssets.pending, state => {
        state.orderBookAssetsLoading = true;
        state.orderBookAssetsFailed = undefined;
      })
      .addCase(orderBookAssets.fulfilled, (state, action) => {
        state.orderBookAssetsLoading = false;
        state.orderBookAssetsSuccess = action.payload;
        state.shouldReorderAssets = false;
      })
      .addCase(orderBookAssets.rejected, (state, action) => {
        state.orderBookAssetsLoading = false;
        state.orderBookAssetsFailed = action.payload;
        state.shouldReorderAssets = false;
      })

      // BOOK READERS
      .addCase(getBookReaders.pending, (state, action) => {
        state.getBookReadersLoading = true;
        state.getBookReadersFailed = undefined;

        const {
          meta: {
            arg: {
              params: { page, page_size },
            },
          },
        } = action;

        state.bookReadersPaginationConfig.current = Number(page);
        state.bookReadersPaginationConfig.pageSize = Number(page_size);
      })
      .addCase(getBookReaders.fulfilled, (state, { payload }) => {
        state.getBookReadersLoading = false;
        state.bookReaders = payload.results;
        state.bookReadersPaginationConfig.total = payload.count;
      })
      .addCase(getBookReaders.rejected, (state, action) => {
        state.getBookReadersLoading = false;
        state.getBookReadersFailed = action.payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const { resetBookState, setBookAssets, setShouldReorderBookAssets } =
  bookSlice.actions;

export default bookSlice.reducer as Reducer<BookDetailsState>;

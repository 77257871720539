const vi = {
  no: 'STT',
  name: 'Tên',
  roles: 'Danh sách quyền',
  create: 'Tạo mới',
  cancel: 'Thoát',
  update: 'Cập nhật',
  changeName: 'Đổi tên',
  duplicate: 'Nhân bản',
  delete: 'Xoá',

  view: 'Xem',
  edit: 'Cập nhật',
  destroy: 'Xoá',
  viewUserprofile: 'Xem Profile',
  can_register_sms_workspace: 'Đăng ký WS SMS',
  invitationStaff: 'Mời NV',
  can_resend_email_invite_staff: 'Gửi lại thư mời',
  editStaffPermissions: 'Cập nhật quyền NV',
  editStaffRoles: 'Cập nhật Role NV',
  editRolePermissions: 'Cập nhật quyền Role',
  createInvitationCode: 'Tạo mã mời',
  resource: 'Resource',
  field: 'Field',
  deleteWarning: 'Bạn có chắc muốn xóa Role này không?',

  assets: 'Tài liệu',
  books: 'Sách',
  staff: 'Nhân viên',
  role: 'Phân quyền',
  student: 'Học sinh',
  workspace: 'Workspace',
  course: 'course',
  sharelistassets: 'Playlist',
  viewAll: 'Bạn có quyền truy cập tất cả object của resource này',
};

export default vi;

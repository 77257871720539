import { Outlet } from 'react-router-dom';

const RolePermissions = () => {
  return (
    <div
      style={{ padding: 24, width: 'calc(100% - 250px)', overflowX: 'auto' }}>
      <Outlet />
    </div>
  );
};

export default RolePermissions;

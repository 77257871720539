const en = {
  branches: 'Danh sách chi nhánh',

  no: 'STT',
  create: 'Tạo mới',
  cancel: 'Huỷ',
  update: 'Cập nhật',

  add: 'Thêm',
  name: 'Tên',

  updateBranch: 'Cập nhật chi nhánh',
  createBranch: 'Tạo chi nhánh',

  deleteWarning: 'Bạn có chắc muốn xóa chi nhánh này không?',
  delete: 'Xoá',
  keyword: 'Từ khoá',
  enterKeyword: 'Nhập từ khoá',
  search: 'Tìm kiếm',
  nameIsRequired: 'Vui lòng nhập tên chi nhánh',
  locationIsRequired: 'Vui lòng nhập vị trí',
  addressIsRequired: 'Vui lòng nhập địa chỉ',

  location: 'Vị trí',
  address: 'Địa chỉ',
};

export default en;

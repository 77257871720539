import { CLIENT_DATE_FORMAT } from 'config';
import dayjs from 'dayjs';
import type { RcFile } from 'antd/es/upload/interface';

export const getPhoneNumber = (phoneNumber?: string) => {
  let phoneNumberPrefix = '';
  let phone_number = '';

  if (phoneNumber) {
    phone_number = phoneNumber.slice(-9);
    phoneNumberPrefix = phoneNumber.slice(0, phoneNumber.length - 9);
  }

  return {
    phoneNumberPrefix,
    phone_number,
  };
};

export const getBirthDate = (birthDate?: string) => {
  if (!birthDate) {
    return dayjs();
  }

  return dayjs(birthDate, CLIENT_DATE_FORMAT);
};

const EXCLUDE_IMAGE_FIELD = ['logo', 'photo', 'picture', 'path_file', 'uid'];
const getFormData = (model: { [key: string]: string | RcFile }) => {
  const formData = new FormData();

  Object.entries(model).forEach(([key, value]) => {
    if (!EXCLUDE_IMAGE_FIELD.includes(key) && value) {
      formData.append(key, value);
    }
  });

  return formData;
};

export const getProfileFormData = (profile: any) => {
  return getFormData(profile);
};

export const getWorkspaceFormData = (workspace: any) => {
  const formData = getFormData(workspace);

  workspace.logo &&
    typeof workspace.logo !== 'string' &&
    formData.append('logo', workspace.logo);

  return formData;
};

export const multipartFormConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const updateSearchParam = (
  searchParams: URLSearchParams,
  { key, value }: { key: string; value: string },
) => {
  if (value) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }
};

export const getCourseFormData = (course: any) => {
  const formData = getFormData(course);

  course.photo &&
    typeof course.photo !== 'string' &&
    formData.append('photo', course.photo);

  return formData;
};

export const getAssetFormData = (asset: any) => {
  const formData = getFormData(asset);
  asset.path_file &&
    typeof asset.path_file !== 'string' &&
    formData.append('path_file', asset.path_file);

  return formData;
};

export const getPlaylistFormData = (playlist: any) => {
  const formData = getFormData(playlist);

  playlist.photo &&
    typeof playlist.photo !== 'string' &&
    formData.append('photo', playlist.photo);

  return formData;
};

export const getBookFormData = (book: any) => {
  const formData = getFormData(book);

  book.photo &&
    typeof book.photo !== 'string' &&
    formData.append('photo', book.photo);

  return formData;
};

export const getAlbumAssetFormData = (file: RcFile) => {
  const formData = getFormData({ image: file });

  return formData;
};

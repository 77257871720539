import { Row } from 'antd';
import { SmallFilterPanel } from 'components/Common/Page';
import SearchBox from 'components/Common/SearchBox';

const Filter = () => {
  return (
    <SmallFilterPanel>
      <Row justify='space-between'>
        <SearchBox style={{ marginBottom: 0 }} />
      </Row>
    </SmallFilterPanel>
  );
};

export default Filter;

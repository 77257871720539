import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import playlistAPI from './playlistAPI';
import {
  BasePlaylist,
  Playlist,
  PlaylistState,
  GetPlaylistsQueryParams,
  GetPlaylistAssetsParams,
  PlaylistAsset,
  DeletePlaylistAssetParams,
  AddPlaylistAssetsPayload,
  OrderPlaylistAssetsModel,
} from './types';
import { resetStoreAction } from 'config';
import { ListResponse } from 'types';
import dbAPI from 'db';

export const getPlaylists = createAppAsyncThunk(
  'playlist/getPlaylists',
  async (params: GetPlaylistsQueryParams, { rejectWithValue }) => {
    try {
      const response = await playlistAPI.getPlaylists(params);

      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createPlaylist = createAppAsyncThunk(
  'playlist/createPlaylist',
  async (playlist: BasePlaylist, { rejectWithValue }) => {
    try {
      const response = await playlistAPI.createPlaylist(playlist);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updatePlaylist = createAppAsyncThunk(
  'playlist/updatePlaylist',
  async (playlist: Playlist, { rejectWithValue }) => {
    try {
      const { data } = await playlistAPI.updatePlaylist(playlist);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deletePlaylist = createAppAsyncThunk(
  'playlist/deletePlaylist',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await playlistAPI.deletePlaylist(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getPlaylist = createAppAsyncThunk(
  'playlist/getPlaylist',
  async (id: number, { rejectWithValue }) => {
    try {
      const playlist = await playlistAPI.getPlaylist(id);
      return playlist;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getPlaylistInvitationCode = createAppAsyncThunk(
  'asset/getPlaylistInvitationCode',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await playlistAPI.getInvitationCode(id);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

// PLAYLIST ASSETS
export const getPlaylistAssets = createAppAsyncThunk(
  'playlist/getPlaylistAssets',
  async (
    params: GetPlaylistAssetsParams,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const response: ListResponse<PlaylistAsset> =
        await playlistAPI.getPlaylistAssets(params);

      const {
        playlist: { shouldReorderAssets },
      } = getState();
      if (shouldReorderAssets) {
        const ids = response.results.map(({ id }) => id);
        const orderModel: OrderPlaylistAssetsModel = {
          ids,
          playlistId: Number(params.id),
        };

        await dispatch(orderPlaylistAssets(orderModel));
      }

      const nextResponse = await dbAPI.addCachedStatus(response);
      return nextResponse;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deletePlaylistAsset = createAppAsyncThunk(
  'playlist/deletePlaylistAsset',
  async (params: DeletePlaylistAssetParams, { rejectWithValue }) => {
    try {
      const response = await playlistAPI.deletePlaylistAsset(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const addPlaylistAssets = createAppAsyncThunk(
  'playlist/addPlaylistAssets',
  async (payload: AddPlaylistAssetsPayload, { rejectWithValue }) => {
    try {
      const response = await playlistAPI.addPlaylistAssets(payload);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getRolePlaylists = createAppAsyncThunk(
  'playlist/getRolePlaylists',
  async (ids: string, { rejectWithValue }) => {
    try {
      const response = await playlistAPI.getRolePlaylists(ids);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const orderPlaylistAssets = createAppAsyncThunk(
  'playlist/orderPlaylistAssets',
  async (model: OrderPlaylistAssetsModel, { rejectWithValue }) => {
    try {
      await playlistAPI.orderPlaylistAssets(model);
      return {};
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: PlaylistState = {
  getPlaylistsLoading: false,
  playlists: [],
  getPlaylistsFailed: undefined,
  paginationConfig: {},

  createPlaylistLoading: false,
  createPlaylistSuccess: undefined,
  createPlaylistFailed: undefined,

  updatePlaylistLoading: false,
  updatePlaylistSuccess: undefined,
  updatePlaylistFailed: undefined,

  deletePlaylistLoading: false,
  deletePlaylistSuccess: undefined,
  deletePlaylistFailed: undefined,

  getPlaylistLoading: false,
  playlist: undefined,
  getPlaylistFailed: undefined,

  getPlaylistAssetsLoading: false,
  playlistAssets: [],
  getPlaylistAssetsFailed: undefined,
  playlistAssetsPaginationConfig: {},

  deletePlaylistAssetLoading: false,
  deletePlaylistAssetSuccess: undefined,
  deletePlaylistAssetFailed: undefined,

  addPlaylistAssetsLoading: false,
  addPlaylistAssetsSuccess: undefined,
  addPlaylistAssetsFailed: undefined,

  orderPlaylistAssetsLoading: false,
  orderPlaylistAssetsSuccess: undefined,
  orderPlaylistAssetsFailed: undefined,

  getPlaylistInvitationCodeLoading: false,
  invitationCode: undefined,
  getPlaylistInvitationCodeFailed: undefined,

  getRolePlaylistsLoading: false,
  rolePlaylists: [],
  getRolePlaylistsFailed: undefined,

  shouldReorderAssets: false,
};

export const playlistSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    resetPlaylistState() {
      return initialState;
    },

    resetRolePlaylists(state) {
      state.rolePlaylists = [];
    },

    setPlaylistAssets(state, action) {
      state.playlistAssets = action.payload;
    },

    setShouldReorderAssets(state, action) {
      state.shouldReorderAssets = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getPlaylists.pending, (state, action) => {
        const {
          meta: {
            arg: { page, page_size },
          },
        } = action;

        state.getPlaylistsLoading = true;
        state.paginationConfig.current = Number(page);
        state.paginationConfig.pageSize = Number(page_size);
      })
      .addCase(getPlaylists.fulfilled, (state, action) => {
        state.getPlaylistsLoading = false;
        state.playlists = action.payload.results;
        state.paginationConfig.total = action.payload.count;
      })
      .addCase(getPlaylists.rejected, (state, action) => {
        state.getPlaylistsLoading = false;
        state.getPlaylistsFailed = action.payload;
      })

      .addCase(createPlaylist.pending, state => {
        state.createPlaylistLoading = true;
        state.createPlaylistFailed = undefined;
      })
      .addCase(createPlaylist.fulfilled, (state, action) => {
        state.createPlaylistLoading = false;
        state.createPlaylistSuccess = action.payload;
      })
      .addCase(createPlaylist.rejected, (state, action) => {
        state.createPlaylistLoading = false;
        state.createPlaylistFailed = action.payload;
      })

      .addCase(updatePlaylist.pending, state => {
        state.updatePlaylistLoading = true;
        state.updatePlaylistFailed = undefined;
      })
      .addCase(updatePlaylist.fulfilled, (state, { payload }) => {
        state.updatePlaylistLoading = false;
        state.updatePlaylistSuccess = payload;
        state.playlists = state.playlists.map(playlist =>
          playlist.id !== payload.id ? playlist : payload,
        );
      })
      .addCase(updatePlaylist.rejected, (state, action) => {
        state.updatePlaylistLoading = false;
        state.updatePlaylistFailed = action.payload;
      })

      .addCase(deletePlaylist.pending, state => {
        state.deletePlaylistLoading = true;
        state.deletePlaylistFailed = undefined;
      })
      .addCase(deletePlaylist.fulfilled, (state, action) => {
        state.deletePlaylistLoading = false;
        state.deletePlaylistSuccess = action.payload;
      })
      .addCase(deletePlaylist.rejected, (state, action) => {
        state.deletePlaylistLoading = false;
        state.deletePlaylistFailed = action.payload;
      })

      .addCase(getPlaylist.pending, state => {
        state.getPlaylistLoading = true;
        state.getPlaylistFailed = undefined;
      })
      .addCase(getPlaylist.fulfilled, (state, action) => {
        state.getPlaylistLoading = false;
        state.playlist = action.payload;
      })
      .addCase(getPlaylist.rejected, (state, action) => {
        state.getPlaylistLoading = false;
        state.getPlaylistFailed = action.payload;
      })

      // PLAYLIST ASSETS
      .addCase(getPlaylistAssets.pending, (state, action) => {
        state.getPlaylistAssetsLoading = true;
        state.getPlaylistAssetsFailed = undefined;

        const {
          meta: {
            arg: {
              params: { page, page_size },
            },
          },
        } = action;

        state.getPlaylistsLoading = true;
        state.playlistAssetsPaginationConfig.current = Number(page);
        state.playlistAssetsPaginationConfig.pageSize = Number(page_size);
      })
      .addCase(getPlaylistAssets.fulfilled, (state, { payload }) => {
        state.getPlaylistAssetsLoading = false;
        state.playlistAssets = payload.results;
        state.playlistAssetsPaginationConfig.total = payload.count;
      })
      .addCase(getPlaylistAssets.rejected, (state, action) => {
        state.getPlaylistAssetsLoading = false;
        state.getPlaylistAssetsFailed = action.payload;
      })

      .addCase(deletePlaylistAsset.pending, state => {
        state.deletePlaylistAssetLoading = true;
        state.deletePlaylistAssetFailed = undefined;
      })
      .addCase(deletePlaylistAsset.fulfilled, (state, action) => {
        state.deletePlaylistAssetLoading = false;
        state.deletePlaylistAssetSuccess = action.payload;
      })
      .addCase(deletePlaylistAsset.rejected, (state, action) => {
        state.deletePlaylistAssetLoading = false;
        state.deletePlaylistAssetFailed = action.payload;
      })

      .addCase(addPlaylistAssets.pending, state => {
        state.addPlaylistAssetsLoading = true;
        state.addPlaylistAssetsFailed = undefined;
      })
      .addCase(addPlaylistAssets.fulfilled, (state, action) => {
        state.addPlaylistAssetsLoading = false;
        state.addPlaylistAssetsSuccess = action.payload;
      })
      .addCase(addPlaylistAssets.rejected, (state, action) => {
        state.addPlaylistAssetsLoading = false;
        state.addPlaylistAssetsFailed = action.payload;
      })

      .addCase(getPlaylistInvitationCode.pending, state => {
        state.getPlaylistInvitationCodeLoading = true;
        state.getPlaylistInvitationCodeFailed = undefined;
      })
      .addCase(getPlaylistInvitationCode.fulfilled, (state, action) => {
        state.getPlaylistInvitationCodeLoading = false;
        state.invitationCode = action.payload;
      })
      .addCase(getPlaylistInvitationCode.rejected, (state, action) => {
        state.getPlaylistInvitationCodeLoading = false;
        state.getPlaylistInvitationCodeFailed = action.payload;
      })

      .addCase(getRolePlaylists.pending, state => {
        state.getRolePlaylistsLoading = true;
      })
      .addCase(getRolePlaylists.fulfilled, (state, action) => {
        state.getRolePlaylistsLoading = false;
        state.rolePlaylists = action.payload.results;
      })
      .addCase(getRolePlaylists.rejected, (state, action) => {
        state.getRolePlaylistsLoading = false;
        state.getRolePlaylistsFailed = action.payload;
      })

      .addCase(orderPlaylistAssets.pending, state => {
        state.orderPlaylistAssetsLoading = true;
        state.orderPlaylistAssetsFailed = undefined;
      })
      .addCase(orderPlaylistAssets.fulfilled, (state, action) => {
        state.orderPlaylistAssetsLoading = false;
        state.orderPlaylistAssetsSuccess = action.payload;
        state.shouldReorderAssets = false;
      })
      .addCase(orderPlaylistAssets.rejected, (state, action) => {
        state.orderPlaylistAssetsLoading = false;
        state.orderPlaylistAssetsFailed = action.payload;
        state.shouldReorderAssets = false;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const {
  resetPlaylistState,
  resetRolePlaylists,
  setPlaylistAssets,
  setShouldReorderAssets,
} = playlistSlice.actions;

export default playlistSlice.reducer as Reducer<PlaylistState>;

import React from 'react';
import { FilterPanel } from 'components/Common/Page';
import { Row, Form, Input, Button, Space } from 'antd';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { STUDENT_QUERY_PARAMS } from 'features/student/studentHooks';
import { useFilter, useURLQueryParams } from 'app/hooks';

const Filter = ({ loading }: { loading: boolean }) => {
  const urlQueryParams = useURLQueryParams(Object.values(STUDENT_QUERY_PARAMS));
  const { t } = useTranslation(LOCALES.STUDENT);
  const [form] = Form.useForm();
  const { onReset, onFilter } = useFilter(form, STUDENT_QUERY_PARAMS);

  return (
    <FilterPanel>
      <Form
        initialValues={urlQueryParams}
        form={form}
        onFinish={onFilter}
        disabled={loading}>
        <Row
          style={{ width: '100%', flexWrap: 'nowrap' }}
          justify='space-between'>
          {' '}
          <Space size='large'>
            <FlatFormItem
              name={STUDENT_QUERY_PARAMS.NAME}
              label={t('name')}
              style={{ marginBottom: 0 }}>
              <Input bordered={false} placeholder={t<string>('enterName')} />
            </FlatFormItem>

            <FlatFormItem
              name={STUDENT_QUERY_PARAMS.ENGLISH_NAME}
              label={t('englishName')}
              style={{ marginBottom: 0 }}>
              <Input
                bordered={false}
                placeholder={t<string>('enterEnglishName')}
              />
            </FlatFormItem>

            <FlatFormItem
              name={STUDENT_QUERY_PARAMS.PHONE_NUMBER}
              label={t('phoneNumber')}
              style={{ marginBottom: 0 }}>
              <Input
                bordered={false}
                placeholder={t<string>('enterPhoneNumber')}
              />
            </FlatFormItem>

            <FlatFormItem
              name={STUDENT_QUERY_PARAMS.SEARCH}
              label={t('keyword')}
              style={{ marginBottom: 0 }}>
              <Input bordered={false} placeholder={t<string>('enterKeyword')} />
            </FlatFormItem>
          </Space>
          <Space style={{ marginLeft: 20 }}>
            <Button disabled={loading} onClick={onReset}>
              Reset
            </Button>
            <Button
              loading={loading}
              htmlType='submit'
              style={{ marginLeft: 15 }}
              type='primary'>
              {t('search')}
            </Button>
          </Space>
        </Row>
      </Form>
    </FilterPanel>
  );
};

export default Filter;

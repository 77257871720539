import React from 'react';
import { PageContent } from 'components/Common/Page';
import FlatFormItem from 'components/Common/FlatFormItem';
import { Form, Input, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useNavigate } from 'react-router-dom';
import PATHS from 'router/paths';
import { useChangePassword } from '../authHooks';

const ChangePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(LOCALES.PROFILE);
  const { t: validationT } = useTranslation(LOCALES.VALIDATION);
  const [form] = Form.useForm();
  const { handleChangePassword, changePasswordLoading } = useChangePassword(
    form,
  );

  return (
    <PageContent style={{ padding: 32 }}>
      <Form
        form={form}
        disabled={changePasswordLoading}
        onFinish={handleChangePassword}
        requiredMark={false}
        layout='vertical'
        style={{ width: 600, margin: '40px auto' }}>
        <FlatFormItem
          label={t('currentPassword')}
          name='old_password'
          rules={[
            {
              required: true,
              message: validationT<string>('pleaseInputPass'),
            },
          ]}>
          <Input.Password bordered={false} />
        </FlatFormItem>

        <FlatFormItem
          label={t('newPassword')}
          name='new_password'
          rules={[
            {
              required: true,
              message: validationT<string>('pleaseInputPass'),
            },
          ]}>
          <Input.Password bordered={false} />
        </FlatFormItem>

        <FlatFormItem
          label={t('confirmPassword')}
          name='new_password_confirm'
          dependencies={['new_password']}
          rules={[
            {
              required: true,
              message: validationT<string>('pleaseReEnterPass'),
            },

            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(validationT<string>('passNoSame')),
                );
              },
            }),
          ]}>
          <Input.Password bordered={false} />
        </FlatFormItem>

        <Row justify='center' style={{ marginTop: 120 }}>
          <Button
            disabled={changePasswordLoading}
            onClick={() => navigate(PATHS.account.root)}
            style={{ marginRight: 10 }}>
            {t('cancel')}
          </Button>
          <Button
            loading={changePasswordLoading}
            style={{ marginLeft: 10 }}
            htmlType='submit'
            type='primary'>
            {t('save')}
          </Button>
        </Row>
      </Form>
    </PageContent>
  );
};

export default ChangePassword;

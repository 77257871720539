const en = {
  workspaces: 'Workspaces',
  createWorkspace: 'Create Workspace',

  invitations: 'Invitations',
  no: 'STT',
  WorkspaceName: 'Workspace name',
  position: 'Position',
  inviteDate: 'Invitation date',
  actions: 'Actions',
  invitationAcceptedSuccessfully: 'Invitation Accepted Successfully',
  invitationAcceptedFailed: 'Invitation Accepted Failed',
  accept: 'Accept',

  profile: 'Profile',
  generalInformation: 'General Information',
  changePassword: 'Change password',

  name: 'Name',
  address: 'Address',
  email: 'Email',
  phoneNumber: 'Phone Number',
  workspace: 'Workspace',
  url: 'Website URL',
  cancel: 'Cancel',
  update: 'Update',
  create: 'Create',
  updateWorkspace: 'Update Workspace',

  acceptWarning: 'Are you sure to accept this invitation?'
};

export default en;

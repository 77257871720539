import React from 'react';
import {
  FileImageOutlined,
  FileOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  VideoCameraOutlined,
  FileWordOutlined,
} from '@ant-design/icons';
import color from 'config/color';

const IMAGE_EXTENSIONS = [
  'png',
  'jpeg',
  'svg',
  'image/png',
  'image/jpeg',
  'image/svg',
  'webp'
];
const PDF_EXTENSIONS = ['pdf', 'application/pdf'];
const EXCEL_EXTENSIONS = [
  'xlsx',
  'xls',
  'csv',
  'text/csv',
  'application/vnd.ms-excel',
];
const WORD_EXTENSION = ['doc', 'docx', 'application/msword'];

const VIDEO_EXTENSIONS = ['mp3', 'mp4', 'audio/mpeg', 'video/mpeg', 'video/mp4'];

const FileExtensionIcon = ({
  extension,
  size = 'middle',
  isCached = false,
}: {
  extension: string;
  size?: 'middle' | 'large';
  isCached?: boolean;
}) => {
  const style: any = {
    color: isCached ? color.secondary : color.primary,
  };

  if (size === 'large') {
    style.fontSize = 20;
  }

  if (IMAGE_EXTENSIONS.includes(extension)) {
    return <FileImageOutlined style={style} />;
  }

  if (EXCEL_EXTENSIONS.includes(extension)) {
    return <FileExcelOutlined style={style} />;
  }

  if (PDF_EXTENSIONS.includes(extension)) {
    return <FilePdfOutlined style={style} />;
  }

  if (VIDEO_EXTENSIONS.includes(extension)) {
    return <VideoCameraOutlined style={style} />;
  }

  if (WORD_EXTENSION.includes(extension)) {
    return <FileWordOutlined style={style} />;
  }

  return <FileOutlined />;
};

export default FileExtensionIcon;

import { Form, Input, Modal } from 'antd';
import { LOCALES } from 'app/i18n';
import { useInviteStaff } from 'features/staff/staffHooks';
import { useTranslation } from 'react-i18next';

const InviteStaffModal = ({
  isOpen,
  onClose,
  staffId,
}: {
  isOpen: boolean;
  onClose: () => void;
  staffId?: number;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(LOCALES.STAFF);

  const { handleInviteStaff, inviteStaffLoading } = useInviteStaff(() => {
    onClose();
    form.resetFields();
  });

  const handleSubmit = () => {
    form.validateFields().then(({ email }) => {
      if (email && staffId) handleInviteStaff({ id: staffId, email });
    });
  };

  return (
    <Modal
      onCancel={onClose}
      title={t('inviteStaff')}
      open={isOpen}
      okButtonProps={{
        onClick: () => {
          handleSubmit();
        },
        loading: inviteStaffLoading,
      }}
      okText={t('invite')}
      cancelButtonProps={{
        disabled: inviteStaffLoading,
      }}>
      <Form layout='vertical' form={form} requiredMark={false}>
        <Form.Item
          label={t('email')}
          name={'email'}
          rules={[{ required: true }, { type: 'email' }]}>
          <Input disabled={inviteStaffLoading} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InviteStaffModal;

import { useEffect, useMemo } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  useSuccess,
  useURLQueryParams,
  usePagination,
} from 'app/hooks';
import {
  register,
  login,
  setWorkspace,
  logout,
  getWorkspaces,
  getAccountProfile,
  changePassword,
  resetAuthState,
  updateAccountProfile,
  createWorkspace,
  updateWorkspace,
  getStaffInvitations,
  acceptStaffInvitation,
} from './authSlice';
import { setTenant, removeAxiosToken } from 'utils/requester';
import { useMatch, useNavigate } from 'react-router-dom';
import PATHS from 'router/paths';
import { getBirthDate, getPhoneNumber } from 'utils/formHelper';
import { resetStoreAction } from 'config';
import {
  LoginUser,
  LoginHook,
  RegisterUser,
  RegisterHook,
  AuthState,
  WorkspacesState,
  AccountProfileState,
  ChangePasswordHook,
  ChangePasswordPayload,
  UpdateAccountProfileHook,
  UpdateAccountProfilePayload,
  CreateWorkspaceHook,
  Workspace,
  UpdateWorkspaceHook,
  UseStaffInvitationsReturnType,
  UseAcceptStaffInvitationReturnType,
} from './types';
import { FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';

export const useResetAuthState = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetAuthState());
    };
  }, [dispatch]);
};

export const useLogin = (): LoginHook => {
  const navigate = useNavigate();
  const loginSuccess = useAppSelector(state => state.auth.loginSuccess);
  const loginFailed = useAppSelector(state => state.auth.loginFailed);
  const loginLoading = useAppSelector(state => state.auth.loginLoading);
  const dispatch = useAppDispatch();

  const handleLogin = (user: LoginUser) => {
    dispatch(login(user));
  };

  useSuccess(loginSuccess, '', () => navigate(PATHS.account.root));

  useFailed(loginFailed);
  useResetAuthState();
  return { loginSuccess, loginFailed, loginLoading, handleLogin };
};

export const useRegister = (): RegisterHook => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const navigate = useNavigate();

  const registerFailed = useAppSelector(state => state.auth.registerFailed);
  const registerSuccess = useAppSelector(state => state.auth.registerSuccess);
  const registerLoading = useAppSelector(state => state.auth.registerLoading);
  const dispatch = useAppDispatch();

  const handleRegister = (user: RegisterUser) => {
    dispatch(register(user));
  };

  useSuccess(registerSuccess, t<string>('USER__CREATE__SUCCESS'), () =>
    navigate(PATHS.auth.signIn),
  );

  useFailed(registerFailed);

  useResetAuthState();
  return { registerFailed, registerSuccess, registerLoading, handleRegister };
};

export const useAuth = (): AuthState => {
  const token = useAppSelector(state => state.auth.token);
  const workspace = useAppSelector(state => state.auth.workspace);
  const refreshToken = useAppSelector(state => state.auth.refreshToken);
  const email = useAppSelector(state => state.auth.email);

  return {
    token,
    workspace,
    refreshToken,
    email,
  };
};

export const useWorkspace = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSelectWorkSpace = (workspace: string) => {
    dispatch(setWorkspace(workspace));
    dispatch(resetStoreAction);
    setTenant(workspace);

    if (workspace) {
      navigate(PATHS.app.root);
    }
  };

  return onSelectWorkSpace;
};

export const useWorkspaces = (getStateOnly?: boolean): WorkspacesState => {
  const getWorkspacesLoading = useAppSelector(
    state => state.auth.getWorkspacesLoading,
  );
  const getWorkspacesFailed = useAppSelector(
    state => state.auth.getWorkspacesFailed,
  );
  const workspaces = useAppSelector(state => state.auth.workspaces);

  const dispatch = useAppDispatch();

  useFailed(getWorkspacesFailed);

  useEffect(() => {
    if (!getStateOnly) {
      dispatch(getWorkspaces());
    }
  }, [dispatch, getStateOnly]);

  useResetAuthState();

  return {
    getWorkspacesLoading,
    getWorkspacesFailed,
    workspaces,
  };
};

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return () => {
    dispatch(logout());
    dispatch(resetStoreAction);
    navigate(PATHS.auth.signIn);
    removeAxiosToken();
  };
};

const useSelectAccountProfileState = () => {
  const getAccountProfileLoading = useAppSelector(
    state => state.auth.getAccountProfileLoading,
  );
  const getAccountProfileFailed = useAppSelector(
    state => state.auth.getAccountProfileFailed,
  );
  const accountProfile = useAppSelector(state => state.auth.accountProfile);

  return {
    getAccountProfileLoading,
    getAccountProfileFailed,
    accountProfile,
  };
};

export const useAccountProfile = (): AccountProfileState => {
  const state = useSelectAccountProfileState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAccountProfile());
  }, [dispatch]);

  return state;
};

export const useInitProfileForm = (form: FormInstance) => {
  const { accountProfile, getAccountProfileLoading } =
    useSelectAccountProfileState();

  const initValue = useMemo(() => {
    return {
      ...accountProfile,
      ...getPhoneNumber(accountProfile?.phone_number),
      birthday: getBirthDate(accountProfile?.birthday),
    };
  }, [accountProfile]);

  useEffect(() => {
    form.setFieldsValue(initValue);
  }, [initValue, form]);

  return { initValue, getAccountProfileLoading };
};

export const useChangePassword = (form: FormInstance): ChangePasswordHook => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const changePasswordLoading = useAppSelector(
    state => state.auth.changePasswordLoading,
  );
  const changePasswordFailed = useAppSelector(
    state => state.auth.changePasswordFailed,
  );
  const changePasswordSuccess = useAppSelector(
    state => state.auth.changePasswordSuccess,
  );

  const dispatch = useAppDispatch();

  const handleChangePassword = (data: ChangePasswordPayload) => {
    dispatch(changePassword(data));
  };

  useSuccess(
    changePasswordSuccess,
    t<string>('PASSWORD_UPDATED_SUCCESSFULLY'),
    () => {
      form.resetFields();
    },
  );

  useFailed(changePasswordFailed);
  useResetAuthState();

  return {
    changePasswordLoading,
    changePasswordFailed,
    changePasswordSuccess,
    handleChangePassword,
  };
};

export const useUpdateAccountProfile = (): UpdateAccountProfileHook => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const updateAccountProfileLoading = useAppSelector(
    state => state.auth.updateAccountProfileLoading,
  );
  const updateAccountProfileFailed = useAppSelector(
    state => state.auth.updateAccountProfileFailed,
  );
  const updateAccountProfileSuccess = useAppSelector(
    state => state.auth.updateAccountProfileSuccess,
  );
  const dispatch = useAppDispatch();

  const handleUpdateAccountProfile = (
    accountProfile: UpdateAccountProfilePayload,
  ) => {
    dispatch(updateAccountProfile(accountProfile));
  };

  useSuccess(
    updateAccountProfileSuccess,
    t<string>('PROFILE_UPDATED_SUCCESSFULLY'),
  );

  useFailed(updateAccountProfileFailed);
  useResetAuthState();

  return {
    updateAccountProfileLoading,
    updateAccountProfileFailed,
    updateAccountProfileSuccess,
    handleUpdateAccountProfile,
  };
};

export const useCreateWorkspace = (): CreateWorkspaceHook => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const createWorkspaceLoading = useAppSelector(
    state => state.auth.createWorkspaceLoading,
  );
  const createWorkspaceFailed = useAppSelector(
    state => state.auth.createWorkspaceFailed,
  );
  const createWorkspaceSuccess = useAppSelector(
    state => state.auth.createWorkspaceSuccess,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCreateWorkspace = (workspace: Workspace) => {
    dispatch(createWorkspace(workspace));
  };

  useSuccess(
    createWorkspaceSuccess,
    t<string>('WORKSPACE_CREATED_SUCCESSFULLY'),
    () => navigate(PATHS.account.root),
  );

  useFailed(createWorkspaceFailed);
  useResetAuthState();

  return {
    createWorkspaceSuccess,
    createWorkspaceFailed,
    createWorkspaceLoading,
    handleCreateWorkspace,
  };
};

export const useCurrentWorkspace = () => {
  const currentWorkspace = useAppSelector(state => state.auth.currentWorkspace);
  const getWorkspacesLoading = useAppSelector(
    state => state.auth.getWorkspacesLoading,
  );
  return { currentWorkspace, getWorkspacesLoading };
};

export const useInitWorkspace = (form: FormInstance) => {
  const isUpdatingWorkspace = useMatch(PATHS.app.updateWorkspace);
  const { currentWorkspace, getWorkspacesLoading } = useCurrentWorkspace();

  const initValue = useMemo(() => {
    if (!isUpdatingWorkspace) {
      return {
        phoneNumberPrefix: '+84',
        id: undefined,
      };
    }

    return {
      ...currentWorkspace,
      ...getPhoneNumber(currentWorkspace?.phone_number),
    };
  }, [currentWorkspace, isUpdatingWorkspace]);

  useEffect(() => {
    form.setFieldsValue(initValue);
  }, [initValue, form]);

  return { initValue, getCurrentWorkspaceLoading: getWorkspacesLoading };
};

export const useUpdateWorkspace = (): UpdateWorkspaceHook => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const updateWorkspaceLoading = useAppSelector(
    state => state.auth.updateWorkspaceLoading,
  );
  const updateWorkspaceFailed = useAppSelector(
    state => state.auth.updateWorkspaceFailed,
  );
  const updateWorkspaceSuccess = useAppSelector(
    state => state.auth.updateWorkspaceSuccess,
  );
  const dispatch = useAppDispatch();

  const handleUpdateWorkspace = (workspace: Workspace) => {
    dispatch(updateWorkspace(workspace));
  };

  useSuccess(
    updateWorkspaceSuccess,
    t<string>('WORKSPACE_UPDATED_SUCCESSFULLY'),
  );

  useFailed(updateWorkspaceFailed);
  useResetAuthState();

  return {
    updateWorkspaceSuccess,
    updateWorkspaceFailed,
    updateWorkspaceLoading,
    handleUpdateWorkspace,
  };
};

export const useStaffInvitations = (): UseStaffInvitationsReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const getStaffInvitationsLoading = useAppSelector(
    state => state.auth.getStaffInvitationsLoading,
  );
  const staffInvitations = useAppSelector(state => state.auth.staffInvitations);
  const getStaffInvitationsFailed = useAppSelector(
    state => state.auth.getStaffInvitationsFailed,
  );
  const staffInvitationsPaginationConfig = useAppSelector(
    state => state.auth.staffInvitationsPaginationConfig,
  );
  const dispatch = useAppDispatch();

  const urlQueryParams = useURLQueryParams([]);
  const onPageChange = usePagination();

  useEffect(() => {
    dispatch(getStaffInvitations(urlQueryParams));
  }, [urlQueryParams, dispatch]);

  useFailed(getStaffInvitationsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  const refreshCurrentPage = () => {
    dispatch(getStaffInvitations(urlQueryParams));
  };

  const refreshAfterAccepting = () => {
    const shouldDecreasePage =
      staffInvitations.length === 1 && urlQueryParams.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(urlQueryParams.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  return {
    getStaffInvitationsLoading,
    staffInvitations,
    getStaffInvitationsFailed,
    staffInvitationsPaginationConfig: {
      ...staffInvitationsPaginationConfig,
      onChange: onPageChange,
    },
    refreshAfterAccepting,
  };
};

export const useAcceptStaffInvitation = (
  callback: () => void,
): UseAcceptStaffInvitationReturnType => {
  const { t } = useTranslation(LOCALES.WORKSPACE);

  const acceptStaffInvitationLoading = useAppSelector(
    state => state.auth.acceptStaffInvitationLoading,
  );
  const acceptStaffInvitationFailed = useAppSelector(
    state => state.auth.acceptStaffInvitationFailed,
  );
  const acceptStaffInvitationSuccess = useAppSelector(
    state => state.auth.acceptStaffInvitationSuccess,
  );

  const dispatch = useAppDispatch();

  const handleAcceptStaffInvitation = (id: number) => {
    dispatch(acceptStaffInvitation(id));
  };

  useSuccess(
    acceptStaffInvitationSuccess,
    t<string>('invitationAcceptedSuccessfully'),
    callback,
  );

  useFailed(acceptStaffInvitationFailed);
  useResetAuthState();

  return {
    acceptStaffInvitationLoading,
    acceptStaffInvitationFailed,
    acceptStaffInvitationSuccess,
    handleAcceptStaffInvitation,
  };
};

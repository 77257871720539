import { useState, useEffect } from 'react';
import { Table, Modal, Space, Image } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Course } from 'features/course/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useCoursesModal,
  useResetCourseState,
  COURSE_MODAL_QUERY_PARAMS,
} from 'features/course/courseHooks';
import FilterPanel from './Filter';
import { useTableSorting } from 'app/hooks';
import { TableRowSelection } from 'antd/es/table/interface';
import CourseStatus from 'components/Common/CourseStatus';

const CoursesModal = ({
  isOpen,
  onCancel,
  addingLoading,
  onSubmit,
  addingSuccess,
  initValues = [],
  isAcceptEmpty = false,
}: {
  isOpen: boolean;
  onCancel: () => void;
  addingLoading: boolean;
  onSubmit: (ids: number[]) => void;
  addingSuccess?: any;
  initValues?: number[];
  isAcceptEmpty?: boolean;
}) => {
  const { t } = useTranslation(LOCALES.COURSE);

  const { handleSorting, getColumnSorting } = useTableSorting(
    COURSE_MODAL_QUERY_PARAMS.ORDERING,
  );

  const { courses, getCoursesLoading, paginationConfig, goToFirstPage } =
    useCoursesModal(isOpen);
  useResetCourseState();

  const [selectedCourseIds, setSelectedCourseIds] = useState<any[]>([]);

  useEffect(() => {
    if (isOpen) {
      setSelectedCourseIds(initValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const columns: ColumnsType<Course> = [
    {
      title: t('name'),
      key: 'name',
      ...getColumnSorting('name'),

      render: record => {
        return (
          <Space>
            <Image
              placeholder={true}
              style={{ objectFit: 'contain' }}
              width={30}
              height={30}
              src={record.photo}
            />
            {record.name}
          </Space>
        );
      },
    },
    {
      title: t('status'),
      key: 'status',
      dataIndex: 'status',
      ...getColumnSorting('status'),
      render: status => <CourseStatus status={status} />,
    },
    {
      title: t('teacher'),
      key: 'teacher',
      dataIndex: 'teacher',
      ...getColumnSorting('teacher'),
    },

    {
      title: t('numberOfStudent'),
      key: 'total_student',
      dataIndex: 'total_student',
      ...getColumnSorting('total_student'),
    },
  ];

  const rowSelection: TableRowSelection<Course> = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedCourseIds(selectedRowKeys);
    },
  };

  useEffect(() => {
    if (addingSuccess) {
      goToFirstPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingSuccess]);

  return (
    <Modal
      open={isOpen}
      width={1100}
      onCancel={onCancel}
      onOk={() => onSubmit(selectedCourseIds)}
      cancelButtonProps={{ disabled: addingLoading }}
      okButtonProps={{
        loading: addingLoading,
        disabled: !isAcceptEmpty && !selectedCourseIds.length,
      }}
      okText={t('add')}
      cancelText={t('cancel')}>
      <FilterPanel loading={getCoursesLoading} />

      <Table
        rowSelection={{
          ...rowSelection,
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedCourseIds,
        }}
        loading={getCoursesLoading}
        dataSource={courses}
        columns={columns}
        pagination={paginationConfig}
        rowKey='id'
        onChange={handleSorting}
      />
    </Modal>
  );
};

export default CoursesModal;

import { Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { SUPPORTED_ALBUM_ASSET_FILE_TYPES } from 'config';
import { forwardRef } from 'react';

interface IFileUploaderProps {
  onUpload: (file: RcFile) => void;
  isAlbumUploader?: boolean;
}

type Ref = HTMLDivElement;

const FileUploader = forwardRef<Ref, IFileUploaderProps>((props, ref) => {
  const { onUpload, isAlbumUploader } = props;
  const beforeUpload = (file: RcFile) => {
    onUpload(file);
    return false;
  };

  return (
    <Upload
      accept={isAlbumUploader ? SUPPORTED_ALBUM_ASSET_FILE_TYPES : ''}
      beforeUpload={beforeUpload}
      showUploadList={false}
      multiple
      style={{ display: 'none' }}>
      <div ref={ref}></div>
    </Upload>
  );
});

export default FileUploader;

const PATHS = {
  // PUBLIC PATHS
  auth: {
    signIn: '/login',
    signUp: '/register',
  },

  // PRIVATE PATHS
  account: {
    root: '/account',
    createWorkspace: '/account/create-workspace',
    invitations: 'account/invitations',
    profile: {
      root: '/account/profile',
      changePassword: '/account/profile/update-password',
    },
    changePassword: '/account/change-password',
  },

  app: {
    root: '/',
    updateWorkspace: '/update-workspace',
    students: '/students',
    courses: {
      root: '/courses',
      details: {
        root: '/courses/:id/details',
        document: '/courses/:id/details/document',
        albums: '/courses/:id/details/albums',
        album: '/courses/:id/details/album/:albumId/details',
        qrCode: '/courses/:id/details/qr-code',
      },
    },

    albumForm: {
      create: '/courses/:id/album/create',
      edit: '/courses/:id/album/:albumId/edit',
    },

    books: {
      root: '/books',
      details: {
        root: '/books/:id/details',
        document: '/books/:id/details/document',
      },
    },

    playlist: {
      root: '/playlists',
      details: '/playlists/:id/details',
    },

    assets: '/assets',

    branches: {
      root: '/branches',
    },

    checkIns: {
      root: '/check-in',
    },

    staffs: {
      root: '/staffs',
      group: '/staffs/:roleId/',
    },

    roles: {
      root: '/roles',
      details: '/roles/:roleId',
      customizeStudent: '/roles/:roleId/student',
      customizeStaff: '/roles/:roleId/staff',
      customizeCourse: '/roles/:roleId/courses',
      customizeBook: '/roles/:roleId/book',
      customizeAsset: '/roles/:roleId/assets',
      customizePlaylist: '/roles/:roleId/sharelistassets',
    },
  },
};

export const getCustomizePath = (resourceKey: string) => {
  return `/roles/:roleId/${resourceKey}`;
};

export default PATHS;

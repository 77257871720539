const vi = {
  name: 'Họ và tên',
  birthDate: 'Ngày tháng năm sinh',
  email: 'Email',
  phone: 'Số điện thoại',
  address: 'Địa chỉ',
  save: 'Cập nhật',
  avatar: 'Ảnh đại diện',
  upload: 'upload ảnh',
  note:
    'Định dạng .jpg, .jpeg, .png dung lượng thấp hơn 300 KB với kích thước tối thiểu 300x300 px',
    resetPass: 'Thay đổi mật khẩu',
  joined: 'Cuộc thi đã tham gia',
  currentPassword: 'Mật khẩu hiện tại',
  newPassword: 'Mật khẩu mới',
  confirmPassword: 'Xác nhận lại mật khẩu',
  logOut: 'Đăng xuất',
  information: 'Thông tin tài khoản',
  changePass: 'Đổi mật khẩu',
  cancel: 'Thoát',
};

export default vi;

import React, { Fragment } from 'react';
import { PageContent, PageHeader } from 'components/Common/Page';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { Form, Row, Col, Input, Button, DatePicker } from 'antd';
import FlatFormItem from 'components/Common/FlatFormItem';
import ImageUploader from 'components/Common/ImageUploader';
import { useInitProfile, useUpdateProfile } from './profileHooks';
import { CLIENT_DATE_FORMAT } from 'config';
import PhoneFormItem from 'components/Common/PhoneFormItem';

const FormItem = Form.Item;

const Profile = () => {
  const { t } = useTranslation(LOCALES.PROFILE);
  const [form] = Form.useForm();
  const { initValue, getProfileLoading } = useInitProfile(form);
  const { handleUpdateProfile, updateProfileLoading } = useUpdateProfile();

  const onFinish = (values: any) => {
    handleUpdateProfile({
      ...values,
      dob: values.dob.format(CLIENT_DATE_FORMAT),
      avatar:
        values.avatar && typeof values.avatar !== 'string'
          ? values.avatar
          : undefined,
      phone_number: values.phoneNumberPrefix + values.phone_number,
      id: initValue.id,
    });
  };

  const isDisabled = getProfileLoading || updateProfileLoading;

  return (
    <Fragment>
      <PageHeader title={t('information')} />
      <PageContent style={{ maxWidth: 900, padding: 32 }}>
        <Form
          initialValues={initValue}
          disabled={isDisabled}
          style={{ height: '100%' }}
          form={form}
          layout='vertical'
          onFinish={onFinish}>
          <Row justify='center' align='middle' style={{ marginTop: 20 }}>
            <FormItem name='avatar' style={{ marginBottom: 0 }}>
              <ImageUploader label={t<string>('avatar')} />
            </FormItem>
          </Row>

          <Row gutter={64} style={{ marginTop: 40 }}>
            <Col span={12}>
              <FlatFormItem label={t('name')} name='name'>
                <Input bordered={false} />
              </FlatFormItem>
            </Col>

            <Col span={12}>
              <FlatFormItem name='email' label={t('email')}>
                <Input bordered={false} />
              </FlatFormItem>
            </Col>
          </Row>

          <Row gutter={64}>
            <Col span={12}>
              <PhoneFormItem />
            </Col>
            <Col span={12}>
              <FlatFormItem label={t('birthDate')} name='dob'>
                <DatePicker
                  bordered={false}
                  format={CLIENT_DATE_FORMAT}
                  style={{ width: '100%' }}
                />
              </FlatFormItem>
            </Col>{' '}
          </Row>

          <FlatFormItem label={t('address')} name='address'>
            <Input bordered={false} />
          </FlatFormItem>

          <Row justify='center' style={{ marginTop: 70 }}>
            <Button disabled={updateProfileLoading} style={{ marginRight: 10 }}>
              {t('cancel')}
            </Button>
            <Button
              loading={updateProfileLoading}
              style={{ marginLeft: 10 }}
              htmlType='submit'
              type='primary'>
              {t('save')}
            </Button>
          </Row>
        </Form>
      </PageContent>
    </Fragment>
  );
};

export default Profile;

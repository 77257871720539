import { Fragment } from 'react';
import { PageHeader } from 'components/Common/Page';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  usePlaylist,
  useResetPlaylistState,
} from 'features/playlist/playlistHooks';
import Information from './Information';
import PlayListAssets from './PlaylistAssets';

const Wrapper = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
  margin-bottom: 32px;
  padding-top: 16px;
  padding-bottom: 0;
`;

const ContentWrapper = styled(Wrapper)`
  padding-top: 24px;
  padding-bottom: 24px;
`;

const PlaylistDetails = () => {
  const { t } = useTranslation(LOCALES.PLAYLIST);
  const { playlist, getPlaylistLoading } = usePlaylist();
  useResetPlaylistState();

  return (
    <Fragment>
      <Wrapper>
        <PageHeader
          style={{ marginBottom: 20 }}
          title={t('playlistInformation')}></PageHeader>

        <Information playlist={playlist} loading={getPlaylistLoading} />
      </Wrapper>

      <ContentWrapper>
        <PlayListAssets />
      </ContentWrapper>
    </Fragment>
  );
};

export default PlaylistDetails;

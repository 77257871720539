import { LOCALES } from 'app/i18n';
import { useRoleAssets } from 'features/asset/assetHooks';
import { useTranslation } from 'react-i18next';
import { Button, Row, Skeleton, Space, Table } from 'antd';
import { DetailsPageTabs, PageHeader } from 'components/Common/Page';
import { Fragment, useState } from 'react';
import AssetsModal from 'components/AssetsModal';
import { PERMISSIONS_RESOURCES } from 'config/permissions';
import {
  useResourceObjects,
  useRoleDetails,
  useUpdateRole,
} from 'features/role/roleHooks';
import styled from 'styled-components';
import FieldTable from '../FieldTable';
import { getResourceObjectIds } from 'features/role/roleUtils';
import { FolderOutlined } from '@ant-design/icons';
import color from 'config/color';
import FileExtensionIcon from 'components/Common/FileExtensionIcon';
import { Asset } from 'features/asset/types';
import NoDataTitle from '../NoDataTitle';

const TabWrapper = styled(DetailsPageTabs)`
  margin-top: 15px;

  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }
`;

const { ASSET } = PERMISSIONS_RESOURCES;

const RoleAssets = () => {
  const { roleAssets, getRoleAssetsLoading } = useRoleAssets();
  const { getResourceObjectColumns, onSelectedObjectsChanged } =
    useResourceObjects();
  const { roleDetails, getRolesLoading, permissionJson } = useRoleDetails();
  const { handleUpdateRole, updateRoleLoading } = useUpdateRole();

  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
  const { t } = useTranslation(LOCALES.ASSET);

  const columns = getResourceObjectColumns(ASSET);
  columns[0] = {
    ...columns[0],
    render: (name: string, rc: Asset) => {
      return (
        <Fragment>
          {rc.folder ? (
            <FolderOutlined style={{ color: color.primary }} />
          ) : (
            <FileExtensionIcon extension={rc.extension || ''} />
          )}{' '}
          <span>{name} </span>
        </Fragment>
      );
    },
  };

  const selectedIds = getResourceObjectIds({
    resourceKey: ASSET,
    permissionJson,
  });
  const initSelectedIds = Object.keys(selectedIds).map(id => Number(id));

  const onSelectedAssetsChanged = (ids: number[]) => {
    onSelectedObjectsChanged(ASSET, ids);
    setIsAssetModalOpen(false);
  };

  const tableLoading = getRolesLoading || getRoleAssetsLoading;
  const noData = !tableLoading && !roleAssets.length;

  return (
    <div>
      <Row justify='space-between' align='middle'>
        <PageHeader
          style={{ marginBottom: 0 }}
          title={
            roleDetails ? `${roleDetails?.name} - ${t('assets')}` : ''
          }></PageHeader>
        <Space>
          {roleDetails ? (
            <Fragment>
              <Button
                loading={updateRoleLoading}
                type='primary'
                onClick={() => handleUpdateRole()}>
                {t('update')}
              </Button>
              <Button onClick={() => setIsAssetModalOpen(true)} type='primary'>
                {t('add')}
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              {[0, 1].map(index => (
                <Skeleton.Button active key={index}></Skeleton.Button>
              ))}
            </Fragment>
          )}
        </Space>
      </Row>

      <TabWrapper
        items={[
          {
            label: 'Objects',
            key: 'Objects',
            children: noData ? (
              <NoDataTitle />
            ) : (
              <Table
                loading={tableLoading}
                columns={columns}
                dataSource={roleAssets}
                rowKey='id'
              />
            ),
          },
          {
            label: 'Fields',
            key: 'Fields',
            children: <FieldTable resourceKey={ASSET} />,
          },
        ]}
      />

      <AssetsModal
        isOpen={isAssetModalOpen}
        onSubmit={ids => onSelectedAssetsChanged(ids)}
        onClose={() => setIsAssetModalOpen(false)}
        addingLoading={false}
        initValues={initSelectedIds}
        isAcceptEmpty
      />
    </div>
  );
};

export default RoleAssets;

import { useEffect } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
  useURLQueryParams,
} from 'app/hooks';
import {
  getCourseAssets,
  addCourseAssets,
  deleteCourseAsset,
  orderCourseAssets,
  setCourseAssets,
  setShouldReorderCourseAssets,
} from 'features/course/courseSlice';
import {
  CourseAsset,
  UseAddCourseAssetsReturnType,
  UseDeleteCourseAssetReturnType,
} from 'features/course/types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';

export const useCourseAssets = () => {
  const courseAssets = useAppSelector(state => state.course.courseAssets);
  const getCourseAssetsFailed = useAppSelector(
    state => state.course.getCourseAssetsFailed,
  );
  const getCourseAssetsLoading = useAppSelector(
    state => state.course.getCourseAssetsLoading,
  );
  const courseAssetsPaginationConfig = useAppSelector(
    state => state.course.courseAssetsPaginationConfig,
  );
  const dispatch = useAppDispatch();

  const params = useURLQueryParams([]);
  const onPageChange = usePagination();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getCourseAssets({ id, params }));
    }
  }, [params, dispatch, id]);

  useFailed(getCourseAssetsFailed);

  const refreshCurrentPage = () => {
    if (id) {
      dispatch(getCourseAssets({ id, params }));
    }
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage = courseAssets.length === 1 && params.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(params.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterAdding = () => {
    if (params.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    courseAssets,
    getCourseAssetsLoading,
    courseAssetsPaginationConfig: {
      ...courseAssetsPaginationConfig,
      onChange: onPageChange,
    },
    refreshAfterAdding,
    refreshAfterDeleting,
    refreshCurrentPage,
  };
};

export const useDeleteCourseAsset = (
  callback: () => void,
): UseDeleteCourseAssetReturnType => {
  const { id: courseId } = useParams();
  const { t } = useTranslation(LOCALES.MESSAGE);

  const deleteCourseAssetLoading = useAppSelector(
    state => state.course.deleteCourseAssetLoading,
  );
  const deleteCourseAssetFailed = useAppSelector(
    state => state.course.deleteCourseAssetFailed,
  );
  const deleteCourseAssetSuccess = useAppSelector(
    state => state.course.deleteCourseAssetSuccess,
  );

  const dispatch = useAppDispatch();

  const handleDeleteCourseAsset = (assetId: number) => {
    if (courseId) {
      dispatch(deleteCourseAsset({ courseId, assetId }));
    }
  };

  useSuccess(
    deleteCourseAssetSuccess,
    t<string>('ASSET_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteCourseAssetFailed);

  return {
    deleteCourseAssetLoading,
    deleteCourseAssetSuccess,
    deleteCourseAssetFailed,
    handleDeleteCourseAsset,
  };
};

export const useAddCourseAssets = (
  callback: () => void,
): UseAddCourseAssetsReturnType => {
  const { id: courseId } = useParams();
  const { t } = useTranslation(LOCALES.MESSAGE);
  const addCourseAssetsLoading = useAppSelector(
    state => state.course.addCourseAssetsLoading,
  );
  const addCourseAssetsFailed = useAppSelector(
    state => state.course.addCourseAssetsFailed,
  );
  const addCourseAssetsSuccess = useAppSelector(
    state => state.course.addCourseAssetsSuccess,
  );

  const dispatch = useAppDispatch();

  const handleAddCourseAssets = (assetIds: number[]) => {
    if (courseId) {
      dispatch(addCourseAssets({ courseId, assetIds }));
    }
  };

  useSuccess(
    addCourseAssetsSuccess,
    t<string>('ASSET_ADDED_SUCCESSFULLY'),
    callback,
  );

  useFailed(addCourseAssetsFailed);

  return {
    addCourseAssetsLoading,
    addCourseAssetsSuccess,
    addCourseAssetsFailed,
    handleAddCourseAssets,
  };
};

export const useOrderCourseAsset = (refreshPage: () => void) => {
  const { id: courseId } = useParams();
  const orderCourseAssetsFailed = useAppSelector(
    state => state.course.orderCourseAssetsFailed,
  );

  const dispatch = useAppDispatch();

  const handleOrderCourseAsset = (orderedData: CourseAsset[]) => {
    if (courseId) {
      const ids = orderedData.map(({ id }) => id);
      dispatch(orderCourseAssets({ courseId: Number(courseId), ids }));
      dispatch(setCourseAssets(orderedData));
    }
  };

  const updateShouldReorderCourseAssets = (shouldReorder: boolean) => {
    dispatch(setShouldReorderCourseAssets(shouldReorder));
  };

  useFailed(orderCourseAssetsFailed, '', refreshPage);

  return {
    handleOrderCourseAsset,
    updateShouldReorderCourseAssets,
  };
};

const vi = {
  create: 'Create',
  cancel: 'Cancel',
  update: 'Update',
  delete: 'Delete',
  add: 'Add',

  createClass: 'Create Class',
  updateClass: 'Update Class',

  Classes: 'Classes',
  classesList: 'Classes',
  no: 'No',
  name: 'Name',
  status: 'Status',
  numberOfStudent: 'Students',
  description: 'Description',

  class: 'Class',
  teacher: 'Teacher',
  ordering: 'Ordering',

  pleaseInputClassName: 'Please Input Class Name',
  search: 'Search',
  enterName: 'Enter name',
  enterTeacherName: 'Enter teacher name',
  enterOrderingField: 'Enter ordering field',
  keyword: 'Keyword',
  enterKeyword: 'Enter Keyword',

  preparing: 'Preparing',
  ended: 'Finished',
  started: 'Started',
  created: 'Created',
  logo: 'Logo',

  deleteWarning: 'Are you sure want to delete this Class?',

  student: 'Student',
  document: 'Document',

  courseInformation: 'Course Information',
  invitationCode: 'Invitation Code',
  download: 'Download',

  album: 'Album',
  createAlbum: 'Create album',
  albumName: 'Album Name',
  uploadPhotosOrVideos: 'Upload Photos or Videos',
  submit: 'Submit',
  dragAlbumTitle: 'Ready to add something?',
  dragAlbumDescription: 'Drag photos and videos here to get started.',

  albumNameIsRequired: 'Album name is required!',
  item: 'item',
  items: 'items',
  deleteAlbumWarning: 'Are you sure want to delete this Album?',

  updateAlbum: 'Update album',
  deleteAlbum: 'Delete album',

  deleteAlbumAssetWarning: 'Are you sure want to delete this file?',
  addToAlbum: 'Add To Album',
  qrCode: 'QR Code'
};

export default vi;

import { Form, Modal, Select } from 'antd';
import { useUpdateStaffRole } from 'features/staff/staffHooks';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useRefreshRoles, useRoleOptions } from 'features/role/roleHooks';
import { useEffect } from 'react';

const UpdatingStaffRoleForm = ({
  isOpen,
  initValue,
  onCancel,
  refreshAfterUpdateRole,
}: {
  isOpen: boolean;
  initValue: any;
  onCancel: () => void;
  refreshAfterUpdateRole: () => void;
}) => {
  const refreshRoles = useRefreshRoles();
  const { t } = useTranslation(LOCALES.STAFF);
  const { handleUpdateStaffRole, updateStaffRoleLoading } = useUpdateStaffRole(
    () => {
      refreshRoles();
      refreshAfterUpdateRole();
      onCancel();
    },
  );

  const roleOptions = useRoleOptions();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then(staff => {
      const model = {
        roleIds: [staff.role],
        staffId: initValue.id,
      };

      handleUpdateStaffRole(model);
    });
  };

  useEffect(() => {
    if (initValue) {
      form.setFieldsValue({
        role: Number(initValue.roles?.[0]?.id),
      });
    }
  }, [form, initValue]);

  return (
    <Modal
      open={isOpen}
      title={t('updateStaff')}
      okButtonProps={{
        onClick: handleSubmit,
        loading: updateStaffRoleLoading,
      }}
      cancelButtonProps={{
        disabled: updateStaffRoleLoading,
      }}
      cancelText={t('cancel')}
      onCancel={onCancel}
      okText={t('update')}>
      <Form
        form={form}
        layout='vertical'
        disabled={updateStaffRoleLoading}
        onFinish={handleSubmit}>
        <Form.Item label={t('role')} name='role'>
          <Select options={roleOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatingStaffRoleForm;

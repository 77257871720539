import { createSlice, Reducer, createAction } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import assetAPI from './assetAPI';
import {
  UploadingFileModel,
  Asset,
  AssetState,
  GetAssetsQueryParams,
  UploadingProgressModel,
  UploadingFile,
} from './types';
import {
  resetStoreAction,
  DEFAULT_PAGING,
  MAX_UPLOAD_REQUEST,
  ASSET_TYPES,
} from 'config';
import { ListResponse } from 'types';
import { findParentFolder, removeRedundantChildren } from 'utils/assets';
import { getErrorMessage } from 'app/hooks';
import dbAPI from 'db';
import { addCachedStatus } from 'features/course/courseSlice';

export const getAssets = createAppAsyncThunk(
  'asset/getAssets',
  async (params: GetAssetsQueryParams, { rejectWithValue }) => {
    try {
      const response: ListResponse<Asset> = await assetAPI.getAssets({
        ...params,
        isLoadMore: undefined,
      });
      const nextResponse = await dbAPI.addCachedStatus(response);

      return {
        ...nextResponse,
        parentId: params.parent,
      };
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getAssetOptions = createAppAsyncThunk(
  'asset/getAssetOptions',
  async (params: GetAssetsQueryParams, { rejectWithValue }) => {
    try {
      const response: ListResponse<Asset> = await assetAPI.getAssets({
        ...params,
        isLoadMore: undefined,
      });
      return { ...response, parentId: params.parent };
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const updateUploadingProgressAction = createAction<UploadingProgressModel>(
  'asset/updateUploadingProgress',
);

let numberOfUploadingFile = 0;

export const createAsset = createAppAsyncThunk(
  'asset/createAsset',
  async (
    asset: UploadingFileModel,
    { rejectWithValue, dispatch, getState },
  ) => {
    if (!asset.folder && numberOfUploadingFile === MAX_UPLOAD_REQUEST) {
    } else {
      numberOfUploadingFile += 1;
      const updateProgress = (
        uploadingProgressModel: UploadingProgressModel,
      ) => {
        dispatch(updateUploadingProgressAction(uploadingProgressModel));
      };

      try {
        const { data } = await assetAPI.createAsset(asset, updateProgress);
        return { ...data, uid: asset.uid };
      } catch (err: any) {
        return rejectWithValue({ error: err, asset });
      } finally {
        numberOfUploadingFile -= 1;
        setTimeout(() => {
          const {
            asset: { fileModelQueue },
          } = getState();
          if (fileModelQueue.length) {
            dispatch(createAsset(fileModelQueue[0]));
          }
        }, 100);
      }
    }
  },
);

export const updateAsset = createAppAsyncThunk(
  'asset/updateAsset',
  async (asset: Asset, { rejectWithValue }) => {
    try {
      const { data } = await assetAPI.updateAsset(asset);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteAsset = createAppAsyncThunk(
  'asset/deleteAsset',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await assetAPI.deleteAsset(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getAssetInvitationCode = createAppAsyncThunk(
  'asset/getAssetInvitationCode',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await assetAPI.getInvitationCode(id);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getRoleAssets = createAppAsyncThunk(
  'asset/getRoleAssets',
  async (ids: string, { rejectWithValue }) => {
    try {
      const response: ListResponse<Asset> = await assetAPI.getRoleAssets(ids);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const downloadFile = createAppAsyncThunk(
  'asset/downloadFile',
  async (
    {
      id,
      isCached,
      parentId,
      isAlbumAsset,
    }: {
      id: number;
      isCached: boolean;
      parentId?: number;
      isAlbumAsset?: boolean;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      if (isCached) {
        const response = await dbAPI.getById(id);
        return response?.file;
      } else {
        const { path_file } = await assetAPI.getPathFile(id);
        const response = await assetAPI.downloadFile(path_file);
        dbAPI.addCachedFile(id, response);
        if (isAlbumAsset) {
          dispatch(addCachedStatus(id));
        } else {
          dispatch(setFileCachedStatus({ id, parentId }));
        }

        return response;
      }
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: AssetState = {
  getAssetsLoading: false,
  assets: [],
  getAssetsFailed: undefined,
  paginationConfig: {},
  loadingAssetId: undefined,
  isLoadingMoreAssets: false,

  createAssetLoading: false,
  createAssetSuccess: undefined,
  createAssetFailed: undefined,

  updateAssetLoading: false,
  updateAssetSuccess: undefined,
  updateAssetFailed: undefined,

  deleteAssetLoading: false,
  deleteAssetSuccess: undefined,
  deleteAssetFailed: undefined,

  uploadingFiles: [],
  filesQueue: [],
  fileModelQueue: [],
  widgetStatus: 'hidden',

  getAssetInvitationCodeLoading: false,
  invitationCode: undefined,
  getAssetInvitationCodeFailed: undefined,

  getRoleAssetsLoading: false,
  roleAssets: [],
  getRoleAssetsFailed: undefined,

  getAssetOptionsLoading: false,
  assetOptions: [],
  getAssetOptionsFailed: undefined,
  assetOptionsPaginationConfig: {},
  loadingAssetOptionId: undefined,
  isLoadingMoreAssetOptions: false,

  downloadFileLoading: false,
  downloadedFile: undefined,
  downloadFileFailed: undefined,

  isVideoModalOpen: false,
  isPDFModalOpen: false,
  isImageModalOpen: false,
  isExcelModalOpen: false,
};

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    resetAssetState() {
      return initialState;
    },

    setWidgetStatus(state, action) {
      state.widgetStatus = action.payload;
    },

    resetRoleAssets(state) {
      state.roleAssets = [];
    },

    closeAssetViewer(state) {
      state.isVideoModalOpen = false;
      state.isImageModalOpen = false;
      state.isPDFModalOpen = false;
      state.isExcelModalOpen = false;
    },

    setFileCachedStatus(state, { payload: { id, parentId } }) {
      let assets = state.assets;

      if (parentId) {
        const parentFolder = findParentFolder(
          { children: state.assets },
          parentId,
        );

        assets = parentFolder.children;
      }

      assets.forEach(asset => {
        if (asset.id === id) {
          asset.isCached = true;
        }
      });
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getAssets.pending, (state, action) => {
        const { parent, isLoadMore, page, page_size } = action.meta?.arg ?? {};
        state.isLoadingMoreAssets = !!isLoadMore;

        if (parent) {
          state.loadingAssetId = parent;
        } else {
          state.getAssetsLoading = true;
          state.paginationConfig.current = Number(page);
          state.paginationConfig.pageSize = Number(page_size);
        }
      })

      .addCase(
        getAssets.fulfilled,
        (state, { payload: { count, parentId, results } }) => {
          let assets = removeRedundantChildren([...results]);

          state.getAssetsLoading = false;
          state.loadingAssetId = undefined;
          state.isLoadingMoreAssets = false;

          if (parentId) {
            const parentFolder = findParentFolder(
              { children: state.assets },
              parentId,
            );

            const childrenAssets = [...parentFolder.children];

            // Remove load more record
            if (childrenAssets.length) {
              childrenAssets.pop();
            }

            // Update load more record
            const nextChildrenAssets = [...childrenAssets, ...assets];
            if (nextChildrenAssets.length < count) {
              const nextPage = String(
                nextChildrenAssets.length /
                  Number(DEFAULT_PAGING.CHILD_ASSET_PAGE_SIZE) +
                  1,
              );

              nextChildrenAssets.push({
                title: 'Load more',
                isLoadMoreRecord: true,
                parent: parentId,
                nextPage,
                id: `${parentId + 'Load More'}`,
              });
            }

            parentFolder.children = nextChildrenAssets;
            parentFolder.expanded = true;
            parentFolder.paging = { totalRecords: count };
          } else {
            state.assets = assets;
            state.paginationConfig.total = count;
          }
        },
      )
      .addCase(getAssets.rejected, (state, action) => {
        state.getAssetsLoading = false;
        state.getAssetsFailed = action.payload;
      })

      .addCase(createAsset.pending, (state, { meta: { arg } }) => {
        const isUploadingFile = !arg.folder;

        if (isUploadingFile) {
          const uploadingFile: UploadingFile = {
            uid: arg.path_file?.uid,
            status: 'inprogress',
            progress: 0,
            name: arg.name,
            type: arg.path_file?.type || 'file',
          };

          if (numberOfUploadingFile < MAX_UPLOAD_REQUEST) {
            state.uploadingFiles.push(uploadingFile);
            state.filesQueue = state.filesQueue.filter(file => {
              return file.uid !== uploadingFile.uid;
            });
            state.fileModelQueue = state.fileModelQueue.filter(model => {
              return model.path_file?.uid !== arg.path_file?.uid;
            });
          } else {
            state.filesQueue.push({ ...uploadingFile, status: 'queue' });
            state.fileModelQueue.push(arg);
          }

          state.widgetStatus = 'active';
        }

        state.createAssetLoading = true;
        state.createAssetFailed = undefined;
      })

      .addCase(createAsset.fulfilled, (state, { payload: asset }) => {
        state.createAssetLoading = false;

        if (asset) {
          if (asset.parent) {
            const parentFolder = findParentFolder(
              { children: state.assets },
              asset.parent,
            );

            asset.children = undefined;

            const { children } = parentFolder;

            if (parentFolder.expanded) {
              parentFolder.children = children ? [asset, ...children] : [asset];
            } else {
              parentFolder.children = [];
            }

            state.createAssetSuccess = {
              ...asset,
              shouldReset: !parentFolder.expanded && !asset.parent,
            };
          } else {
            state.createAssetSuccess = asset;
          }

          if (!asset.folder) {
            state.uploadingFiles = state.uploadingFiles.map(file =>
              file.uid !== asset.uid ? file : { ...file, status: 'succeeded' },
            );
          }
        }
      })
      .addCase(createAsset.rejected, (state, action) => {
        const { asset, error }: any = action.payload;
        state.createAssetLoading = false;

        if (asset.folder) {
          state.createAssetFailed = error;
        }

        state.uploadingFiles = state.uploadingFiles.map(file =>
          file.uid !== asset.uid
            ? file
            : {
                ...file,
                status: 'failed',
                errorMessage: getErrorMessage(error),
              },
        );
      })

      .addCase(updateAsset.pending, state => {
        state.updateAssetLoading = true;
        state.updateAssetFailed = undefined;
      })
      .addCase(updateAsset.fulfilled, (state, { payload }) => {
        state.updateAssetLoading = false;
        state.updateAssetSuccess = payload;

        if (payload.parent) {
          const parentFolder = findParentFolder(
            { children: state.assets },
            payload.parent,
          );

          parentFolder.children = parentFolder.children.map((asset: Asset) =>
            asset.id !== payload.id ? asset : { ...asset, name: payload.name },
          );
        } else {
          state.assets = state.assets.map(asset =>
            asset.id !== payload.id ? asset : { ...asset, name: payload.name },
          );
        }
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.updateAssetLoading = false;
        state.updateAssetFailed = action.payload;
      })

      .addCase(deleteAsset.pending, state => {
        state.deleteAssetLoading = true;
        state.deleteAssetFailed = undefined;
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        state.deleteAssetLoading = false;
        state.deleteAssetSuccess = action.payload;
      })
      .addCase(deleteAsset.rejected, (state, action) => {
        state.deleteAssetLoading = false;
        state.deleteAssetFailed = action.payload;
      })

      .addCase(updateUploadingProgressAction, (state, { payload }) => {
        state.uploadingFiles = state.uploadingFiles.map(uploadingFile => {
          return uploadingFile.uid === payload.uid
            ? {
                ...uploadingFile,
                progress: payload.percentCompleted,
              }
            : uploadingFile;
        });
      })

      .addCase(getAssetInvitationCode.pending, state => {
        state.getAssetInvitationCodeLoading = true;
        state.getAssetInvitationCodeFailed = undefined;
      })
      .addCase(getAssetInvitationCode.fulfilled, (state, action) => {
        state.getAssetInvitationCodeLoading = false;
        state.invitationCode = action.payload;
      })
      .addCase(getAssetInvitationCode.rejected, (state, action) => {
        state.getAssetInvitationCodeLoading = false;
        state.getAssetInvitationCodeFailed = action.payload;
      })

      .addCase(getRoleAssets.pending, state => {
        state.getRoleAssetsLoading = true;
        state.getRoleAssetsFailed = undefined;
      })
      .addCase(getRoleAssets.fulfilled, (state, action) => {
        state.getRoleAssetsLoading = false;
        state.roleAssets = action.payload.results.map(asset => {
          return { ...asset, children: undefined };
        });
      })
      .addCase(getRoleAssets.rejected, (state, action) => {
        state.getRoleAssetsLoading = false;
        state.getRoleAssetsFailed = action.payload;
      })

      .addCase(getAssetOptions.pending, (state, action) => {
        const { parent, isLoadMore, page, page_size } = action.meta?.arg ?? {};
        state.isLoadingMoreAssetOptions = !!isLoadMore;

        if (parent) {
          state.loadingAssetOptionId = parent;
        } else {
          state.getAssetOptionsLoading = true;
          state.assetOptionsPaginationConfig.current = Number(page);
          state.assetOptionsPaginationConfig.pageSize = Number(page_size);
        }
      })

      .addCase(
        getAssetOptions.fulfilled,
        (state, { payload: { count, parentId, results } }) => {
          let assets = removeRedundantChildren([...results]);

          state.getAssetOptionsLoading = false;
          state.loadingAssetOptionId = undefined;
          state.isLoadingMoreAssetOptions = false;

          if (parentId) {
            const parentFolder = findParentFolder(
              { children: state.assetOptions },
              parentId,
            );

            const childrenAssets = [...parentFolder.children];

            // Remove load more record
            if (childrenAssets.length) {
              childrenAssets.pop();
            }

            // Update load more record
            const nextChildrenAssets = [...childrenAssets, ...assets];
            if (nextChildrenAssets.length < count) {
              const nextPage = String(
                nextChildrenAssets.length /
                  Number(DEFAULT_PAGING.CHILD_ASSET_PAGE_SIZE) +
                  1,
              );

              nextChildrenAssets.push({
                title: 'Load more',
                isLoadMoreRecord: true,
                parent: parentId,
                nextPage,
                id: `${parentId + 'Load More'}`,
              });
            }

            parentFolder.children = nextChildrenAssets;
            parentFolder.expanded = true;
            parentFolder.paging = { totalRecords: count };
          } else {
            state.assetOptions = assets;
            state.assetOptionsPaginationConfig.total = count;
          }
        },
      )
      .addCase(getAssetOptions.rejected, (state, action) => {
        state.getAssetOptionsLoading = false;
        state.getAssetOptionsFailed = action.payload;
      })

      .addCase(downloadFile.pending, state => {
        state.downloadFileLoading = true;
        state.downloadFileFailed = undefined;
      })
      .addCase(downloadFile.fulfilled, (state, { payload: downloadedFile }) => {
        state.downloadFileLoading = false;
        state.downloadedFile = downloadedFile;

        const fileType = downloadedFile.data.type;

        if (fileType === ASSET_TYPES.MP4 || fileType === ASSET_TYPES.MP3) {
          state.isVideoModalOpen = true;
        }

        if (fileType === ASSET_TYPES.PDF) {
          state.isPDFModalOpen = true;
        }

        if (fileType === ASSET_TYPES.PDF) {
          state.isPDFModalOpen = true;
        }

        if (fileType === ASSET_TYPES.EXCEL || fileType === ASSET_TYPES.CSV) {
          state.isExcelModalOpen = true;
        }

        if (
          fileType === ASSET_TYPES.PNG ||
          fileType === ASSET_TYPES.JPEG ||
          fileType === ASSET_TYPES.WEBP
        ) {
          state.isImageModalOpen = true;
        }
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.downloadFileLoading = false;
        state.downloadFileFailed = action.payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const {
  resetAssetState,
  setWidgetStatus,
  resetRoleAssets,
  closeAssetViewer,
  setFileCachedStatus,
} = assetSlice.actions;

export default assetSlice.reducer as Reducer<AssetState>;

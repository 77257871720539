import { useEffect, useMemo } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  UsePlaylistsReturnType,
  UseCreatePlaylistReturnType,
  UseUpdatePlaylistReturnType,
  UseDeletePlaylistReturnType,
  Playlist,
  BasePlaylist,
  GetPlaylistState,
  UseGetPlaylistInvitationCodeReturnType,
} from 'features/playlist/types';

import {
  getPlaylists,
  createPlaylist,
  updatePlaylist,
  deletePlaylist,
  resetPlaylistState,
  getPlaylist,
  getPlaylistInvitationCode,
  getRolePlaylists,
  resetRolePlaylists,
} from 'features/playlist/playlistSlice';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGING } from 'config';
import { useRoleResourceIds } from 'features/role/roleHooks';
import { PERMISSIONS_RESOURCES } from 'config/permissions';

export * from './playlistAssetHooks';

export const PLAYLIST_QUERY_PARAMS = {
  SEARCH: 'search',
};

export const PLAYLIST_MODAL_QUERY_PARAMS = {
  SEARCH: 'playlistSearch',
  PAGE: 'playlistPage',
  PAGE_SIZE: 'playlistPageSize',
  ORDERING: 'playlistOrdering',
};

const { SEARCH, PAGE, PAGE_SIZE, ORDERING } = PLAYLIST_MODAL_QUERY_PARAMS;

const REAL_KEY = {
  [SEARCH]: PLAYLIST_QUERY_PARAMS.SEARCH,
  [PAGE]: 'page',
  [PAGE_SIZE]: 'page_size',
  [ORDERING]: 'ordering',
};

export const usePlaylistsModalUrlQueryParams = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get(SEARCH) ?? undefined;
  const page = searchParams.get(PAGE) ?? DEFAULT_PAGING.PAGE;
  const pageSize = searchParams.get(PAGE_SIZE) ?? DEFAULT_PAGING.PAGE_SIZE;

  const urlQueryParams = useMemo(() => {
    return [ORDERING].reduce(
      (currentParams, paramKey) => ({
        ...currentParams,
        [REAL_KEY[paramKey]]: searchParams.get(paramKey),
      }),
      { search, page, page_size: pageSize },
    );
  }, [search, page, pageSize, searchParams]);

  return urlQueryParams;
};

const usePlayListState = () => {
  const getPlaylistsLoading = useAppSelector(
    state => state.playlist.getPlaylistsLoading,
  );
  const playlists = useAppSelector(state => state.playlist.playlists);
  const getPlaylistsFailed = useAppSelector(
    state => state.playlist.getPlaylistsFailed,
  );
  const paginationConfig = useAppSelector(
    state => state.playlist.paginationConfig,
  );

  return {
    getPlaylistsLoading,
    playlists,
    getPlaylistsFailed,
    paginationConfig,
  };
};

export const usePlaylistsModal = (isOpen: boolean) => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const state = usePlayListState();

  const dispatch = useAppDispatch();

  const urlQueryParams = usePlaylistsModalUrlQueryParams();
  const onPageChange = usePagination(
    PLAYLIST_MODAL_QUERY_PARAMS.PAGE,
    PLAYLIST_MODAL_QUERY_PARAMS.PAGE_SIZE,
  );

  const goToFirstPage = () => {
    onPageChange(1);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getPlaylists(urlQueryParams));
    }
  }, [urlQueryParams, dispatch, isOpen]);

  useFailed(state.getPlaylistsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    ...state,
    paginationConfig: {
      ...state.paginationConfig,
      onChange: onPageChange,
    },
    goToFirstPage,
  };
};

export const usePlaylists = (): UsePlaylistsReturnType => {
  const state = usePlayListState();
  const dispatch = useAppDispatch();

  const urlQueryParams = useURLQueryParams(
    Object.values(PLAYLIST_QUERY_PARAMS),
  );
  const onPageChange = usePagination();

  useEffect(() => {
    dispatch(getPlaylists(urlQueryParams));
  }, [urlQueryParams, dispatch]);

  useFailed(state.getPlaylistsFailed);

  const refreshCurrentPage = () => {
    dispatch(getPlaylists(urlQueryParams));
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      state.playlists.length === 1 && urlQueryParams.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(urlQueryParams.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterCreating = () => {
    if (urlQueryParams.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    ...state,
    paginationConfig: {
      ...state.paginationConfig,
      onChange: onPageChange,
    },
    refreshAfterCreating,
    refreshAfterDeleting,
  };
};

export const useCreatePlaylist = (
  callback: () => void,
): UseCreatePlaylistReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const createPlaylistLoading = useAppSelector(
    state => state.playlist.createPlaylistLoading,
  );
  const createPlaylistFailed = useAppSelector(
    state => state.playlist.createPlaylistFailed,
  );
  const createPlaylistSuccess = useAppSelector(
    state => state.playlist.createPlaylistSuccess,
  );

  const dispatch = useAppDispatch();

  const handleCreatePlaylist = (playlist: BasePlaylist) => {
    dispatch(createPlaylist(playlist));
  };

  useSuccess(
    createPlaylistSuccess,
    t<string>('PLAYLIST_CREATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(createPlaylistFailed);

  return {
    createPlaylistLoading,
    createPlaylistFailed,
    createPlaylistSuccess,
    handleCreatePlaylist,
  };
};

export const useUpdatePlaylist = (
  callback: () => void,
): UseUpdatePlaylistReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const updatePlaylistLoading = useAppSelector(
    state => state.playlist.updatePlaylistLoading,
  );
  const updatePlaylistFailed = useAppSelector(
    state => state.playlist.updatePlaylistFailed,
  );
  const updatePlaylistSuccess = useAppSelector(
    state => state.playlist.updatePlaylistSuccess,
  );

  const dispatch = useAppDispatch();

  const handleUpdatePlaylist = (playlist: Playlist) => {
    dispatch(updatePlaylist(playlist));
  };

  useSuccess(
    updatePlaylistSuccess,
    t<string>('PLAYLIST_UPDATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(updatePlaylistFailed);

  return {
    updatePlaylistLoading,
    updatePlaylistFailed,
    updatePlaylistSuccess,
    handleUpdatePlaylist,
  };
};

export const useDeletePlaylist = (
  callback: () => void,
): UseDeletePlaylistReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const deletePlaylistLoading = useAppSelector(
    state => state.playlist.deletePlaylistLoading,
  );
  const deletePlaylistFailed = useAppSelector(
    state => state.playlist.deletePlaylistFailed,
  );
  const deletePlaylistSuccess = useAppSelector(
    state => state.playlist.deletePlaylistSuccess,
  );

  const dispatch = useAppDispatch();

  const handleDeletePlaylist = (id: number) => {
    dispatch(deletePlaylist(id));
  };

  useSuccess(
    deletePlaylistSuccess,
    t<string>('PLAYLIST_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deletePlaylistFailed);

  return {
    deletePlaylistLoading,
    deletePlaylistFailed,
    deletePlaylistSuccess,
    handleDeletePlaylist,
  };
};

export const useResetPlaylistState = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetPlaylistState());
    };
  }, [dispatch]);
};

export const usePlaylist = (): GetPlaylistState => {
  const { id } = useParams();
  const getPlaylistLoading = useAppSelector(
    state => state.playlist.getPlaylistLoading,
  );
  const playlist = useAppSelector(state => state.playlist.playlist);
  const getPlaylistFailed = useAppSelector(
    state => state.playlist.getPlaylistFailed,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getPlaylist(Number(id)));
    }
  }, [dispatch, id]);

  useFailed(getPlaylistFailed);

  return {
    getPlaylistLoading,
    playlist,
    getPlaylistFailed,
  };
};

export const useGetPlaylistInvitationCode =
  (): UseGetPlaylistInvitationCodeReturnType => {
    const getPlaylistInvitationCodeLoading = useAppSelector(
      state => state.playlist.getPlaylistInvitationCodeLoading,
    );
    const getPlaylistInvitationCodeFailed = useAppSelector(
      state => state.playlist.getPlaylistInvitationCodeFailed,
    );
    const invitationCode = useAppSelector(
      state => state.playlist.invitationCode,
    );
    const dispatch = useAppDispatch();

    const handleGetPlaylistInvitationCode = (id: number) => {
      dispatch(getPlaylistInvitationCode(id));
    };

    useFailed(getPlaylistInvitationCodeFailed);

    return {
      getPlaylistInvitationCodeLoading,
      getPlaylistInvitationCodeFailed,
      invitationCode,
      handleGetPlaylistInvitationCode,
    };
  };

export const useRolePlaylists = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const getRolePlaylistsLoading = useAppSelector(
    state => state.playlist.getRolePlaylistsLoading,
  );
  const rolePlaylists = useAppSelector(state => state.playlist.rolePlaylists);
  const getRolePlaylistsFailed = useAppSelector(
    state => state.playlist.getRolePlaylistsFailed,
  );
  const dispatch = useAppDispatch();

  const { ids } = useRoleResourceIds(PERMISSIONS_RESOURCES.PLAYLIST);

  useEffect(() => {
    if (ids) {
      dispatch(getRolePlaylists(ids));
    } else {
      dispatch(resetRolePlaylists());
    }
  }, [dispatch, ids]);

  const refetchRolePlaylists = (ids: number[]) => {
    if (ids.length) {
      dispatch(getRolePlaylists(ids.join(',')));
    } else {
      dispatch(resetRolePlaylists());
    }
  };

  useFailed(getRolePlaylistsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    getRolePlaylistsLoading,
    rolePlaylists,
    getRolePlaylistsFailed,
    refetchRolePlaylists,
  };
};

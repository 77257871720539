import React, { Fragment, useState } from 'react';
import { HasFilterPageContent, PageHeader } from 'components/Common/Page';
import { Button, Table, Dropdown, Popconfirm as PopConfirm } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Student } from 'features/student/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useDeleteStudent,
  useStudents,
  useResetStudentState,
} from 'features/student/studentHooks';
import { getNo } from 'utils';
import StudentForm from 'features/student/StudentForm';
import FilterPanel from 'features/student/Students/Filter';
import type { MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { getBirthDate, getPhoneNumber } from 'utils/formHelper';
import { useTableSorting } from 'app/hooks';

const initValues = {
  phoneNumberPrefix: '+84',
  name: undefined,
  id: undefined,
  english_name: undefined,
  phone_number: undefined,
  dob: undefined,
};

const Students = () => {
  const { t } = useTranslation(LOCALES.STUDENT);
  const {
    students,
    getStudentsLoading,
    paginationConfig,
    refreshAfterCreating,
    refreshAfterDeleting,
  } = useStudents();
  useResetStudentState();

  const { handleSorting, getColumnSorting } = useTableSorting();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [initialStudent, setInitialStudent] = useState<any>(initValues);

  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);
  const { handleDeleteStudent, deleteStudentLoading } = useDeleteStudent(() => {
    setOpenedPopConfirm(-1);
    refreshAfterDeleting();
  });

  const onOpenCreateForm = () => {
    setIsFormOpen(true);
    setInitialStudent(initValues);
  };

  const onOpenEditForm = (student: Student) => {
    setIsFormOpen(true);
    setInitialStudent({
      ...student,
      ...getPhoneNumber(student.phone_number),
      dob: getBirthDate(student.dob),
    });
  };

  const onCloseForm = () => {
    setIsFormOpen(false);
    setInitialStudent(initValues);
  };

  const columns: ColumnsType<Student> = [
    {
      title: t('no'),
      key: 'no',
      render: (_, rc, index) => getNo(paginationConfig, index),
      width: 60,
      align: 'right',
    },
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      ...getColumnSorting('name'),
    },
    {
      title: t('englishName'),
      key: 'english_name',
      dataIndex: 'english_name',
      ...getColumnSorting('english_name'),
    },
    {
      title: t('phoneNumber'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      ...getColumnSorting('phone_number'),
    },
    {
      title: t('dateOfBirth'),
      key: 'dob',
      dataIndex: 'dob',
      ...getColumnSorting('dob'),
    },
    {
      title: '',
      width: 50,
      render: (student: Student) => {
        const items: MenuProps['items'] = [
          {
            label: (
              <p
                className='styled-table__action-item'
                onClick={() => onOpenEditForm(student)}>
                {t('update')}
              </p>
            ),
            key: 'Edit',
          },
          { type: 'divider' },
          {
            label: (
              <p
                className='styled-table__action-item color-error'
                onClick={() => setOpenedPopConfirm(student.id)}>
                {t('delete')}
              </p>
            ),
            key: 'Delete',
          },
        ];
        return (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            overlayStyle={{ width: 150 }}
            overlayClassName='styled-table__actions'
            placement='bottomRight'>
            <PopConfirm
              placement='topRight'
              title={t('deleteWarning')}
              onConfirm={() => handleDeleteStudent(student.id)}
              okText={t('delete')}
              cancelText={t('cancel')}
              okButtonProps={{ loading: deleteStudentLoading }}
              cancelButtonProps={{ disabled: deleteStudentLoading }}
              open={openedPopConfirm === student.id}
              onCancel={() => setOpenedPopConfirm(-1)}>
              <Button size='small' type='text'>
                <EllipsisOutlined />
              </Button>
            </PopConfirm>
          </Dropdown>
        );
      },
      key: 'action',
    },
  ];

  return (
    <Fragment>
      <FilterPanel loading={getStudentsLoading} />

      <HasFilterPageContent>
        <PageHeader title={t('students')}>
          <Button type='primary' onClick={onOpenCreateForm}>
            {t('create')}
          </Button>
        </PageHeader>

        <Table
          loading={getStudentsLoading}
          dataSource={students}
          columns={columns}
          pagination={paginationConfig}
          rowKey='id'
          onChange={handleSorting}
        />
      </HasFilterPageContent>

      <StudentForm
        isOpen={isFormOpen}
        onClose={onCloseForm}
        refreshAfterCreating={refreshAfterCreating}
        initValues={initialStudent}
      />
    </Fragment>
  );
};

export default Students;

import requester from 'utils/requester';
import { StudentAPI } from './types';

const studentAPI: StudentAPI = {
  getStudents(params) {
    return requester.get('student/', params);
  },

  createStudent(student) {
    return requester.post('student/', student);
  },

  updateStudent(student) {
    return requester.put(`student/${student.id}/`, student);
  },

  deleteStudent(id) {
    return requester.delete(`student/${id}/`);
  },

  getRoleStudents(ids) {
    return requester.get('student/', { ids, page_size: 9999 });
  },
};

export default studentAPI;

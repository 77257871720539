import React from 'react';
import { Layout } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'features/auth/authHooks';
import PATHS from 'router/paths';
import { setAxiosToken } from 'utils/requester';
import Header from './Header';
import styled from 'styled-components';

const { Content } = Layout;

const ContainerWrapper = styled.div`
  height: calc(100vh - 105px);
  max-width: 84%;
  margin: 0 auto;
`;

const AccountLayout = () => {
  const { token } = useAuth();

  if (!token) {
    return <Navigate to={PATHS.auth.signIn} />;
  } else {
    setAxiosToken(token);
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Content
        style={{
          padding: '32px 32px 0',
          background: '#f0f2f5',
          height: 'calc(100vh - 65px)',
        }}>
        <ContainerWrapper>
          <Outlet />
        </ContainerWrapper>
      </Content>
    </Layout>
  );
};

export default AccountLayout;

import {
  useContext,
  useEffect,
  createContext,
  useRef,
  useState,
  useMemo,
} from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { LOCALES } from './i18n';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  DEFAULT_PAGING,
  SORT_TYPE,
  URL_SORT_KEY,
  SORT_DESC_PREFIX,
} from 'config';
import { SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { getSortOrder } from 'utils';
import { UseFilter } from 'types';
import { updateSearchParam } from 'utils/formHelper';

export const NotifyContext = createContext<any>({});

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type ReactToResult = <T extends { message?: string }>(
  result: T,
  customMess?: string | React.MutableRefObject<string>,
  callback?: (success?: T) => any,
) => any;

export const getErrorMessage = (error: any) => {
  return error.response?.data?.errors?.[0];
};

export const useSuccess: ReactToResult = (success, customMess, callback) => {
  const messageApi = useContext(NotifyContext);
  const isMounted = useRef<any>(null);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      if (success) {
        if (customMess) {
          const message =
            typeof customMess !== 'string' ? customMess.current : customMess;
          messageApi.open({
            type: 'success',
            content: message,
          });
        }

        callback?.(success);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);
};

export const useFailed: ReactToResult = (error, customMess, callback) => {
  const messageApi = useContext(NotifyContext);
  const { t } = useTranslation(LOCALES.MESSAGE);
  const isMounted = useRef<any>(null);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      if (error) {
        const permissionError = (error as any).response?.data?.detail;

        messageApi.open({
          type: 'error',
          content:
            permissionError ||
            customMess ||
            t(getErrorMessage(error)) ||
            t('DEFAULT_ERROR_MESSAGE'),
        });
        callback?.(error);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export const useDebounceCallback = (callback: Function, timeout = 200) => {
  const timeoutRef = useRef<any>(null);

  const handleCallback = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(callback, timeout);
  };

  return handleCallback;
};

export const useChangeWithDebounce = (
  callback: (value: any) => void,
  initValue: any,
  delay = 1000,
) => {
  const [tmpValue, setTmpValue] = useState(initValue);
  const timeout = useRef<any>(null);

  const clear = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  const onChange = (event: any) => {
    const value = event.target?.value ?? event;

    clear();
    setTmpValue(value);
    timeout.current = setTimeout(() => {
      callback(value);
    }, delay);
  };

  useEffect(() => {
    return clear;
  }, []);

  return { value: tmpValue, onChange, setTmpValue };
};

export const useURLQueryParams = (params: string[]) => {
  const prevExtraParams = useRef<any>(null);

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search') ?? undefined;
  const page = searchParams.get('page') ?? DEFAULT_PAGING.PAGE;
  const pageSize = searchParams.get('pageSize') ?? DEFAULT_PAGING.PAGE_SIZE;
  const ordering = searchParams.get('ordering') ?? undefined;

  const extraParams = useMemo(() => {
    return (params || []).reduce(
      (currentParams, paramKey) => ({
        ...currentParams,
        [paramKey]: searchParams.get(paramKey),
      }),
      {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const isExtraParamsChanged =
    prevExtraParams.current !== JSON.stringify(extraParams);

  if (isExtraParamsChanged) {
    prevExtraParams.current = JSON.stringify(extraParams);
  }

  const urlQueryParams = useMemo(() => {
    return {
      ordering,
      search,
      page,
      page_size: pageSize,
      ...extraParams,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, pageSize, prevExtraParams.current, ordering]);

  return urlQueryParams;
};

export const useSearch = (searchKey?: string) => {
  const urlKey = searchKey || 'search';
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get(urlKey);
  const location = useLocation();

  const { value, onChange, setTmpValue } = useChangeWithDebounce(
    (search: string) => {
      setSearchParams(search && { [urlKey]: search });
    },
    search,
  );

  useEffect(() => {
    if (search !== value) {
      setTmpValue(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return { searchValue: value, onSearch: onChange };
};

export const usePagination = (pageKey = 'page', pageSizeKey = 'pageSize') => {
  const [, setSearchParams] = useSearchParams();
  const onPageChange = (page: number, pageSize?: number) => {
    setSearchParams(searchParams => {
      searchParams.set(pageKey, page + '');
      searchParams.set(
        pageSizeKey,
        (pageSize ?? DEFAULT_PAGING.PAGE_SIZE) + '',
      );
      return searchParams;
    });
  };

  return onPageChange;
};

export const useSortValue = (sortKey = URL_SORT_KEY) => {
  const [searchParams] = useSearchParams();

  let sortedInfo: SorterResult<any> = {};
  const sortedValue = searchParams.get(sortKey);

  if (sortedValue) {
    const isSortDesc = sortedValue[0] === SORT_DESC_PREFIX;
    const columnKey = sortedValue.slice(isSortDesc ? 1 : 0);

    sortedInfo = {
      columnKey,
      order: isSortDesc ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    };
  }

  return sortedInfo;
};

export const useTableSorting = (sortKey = URL_SORT_KEY) => {
  const sortedInfo = useSortValue(sortKey);
  const [, setSearchParams] = useSearchParams();

  const onSortChange = (sorter: {
    columnKey?: string;
    order?: string;
    column?: any;
  }): void => {
    const { columnKey, order, column } = sorter;

    if (!column) {
      setSearchParams(params => {
        params.delete(sortKey);
        return params;
      });
    } else {
      const prefix = order === SORT_TYPE.DESC ? SORT_DESC_PREFIX : '';
      const sortValue = prefix + columnKey;

      setSearchParams(params => {
        params.set(sortKey, sortValue);
        return params;
      });
    }
  };

  const handleSorting = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
    extra: any,
  ) => {
    if (extra.action === 'sort') {
      onSortChange(sorter as any);
    }
  };

  const getColumnSorting = (columnKey: string) => {
    return {
      sorter: {},
      sortOrder: getSortOrder(sortedInfo, columnKey),
    };
  };

  return { handleSorting, getColumnSorting };
};

export const useFilter: UseFilter = (formInstant, paramKeys) => {
  const [, setSearchParams] = useSearchParams();

  const onFilter = (values: any) => {
    setSearchParams(searchParams => {
      Object.values(paramKeys).forEach(param => {
        updateSearchParam(searchParams, { key: param, value: values[param] });
      });

      searchParams.set('page', '1');

      return searchParams;
    });
  };

  const onReset = () => {
    setSearchParams(searchParams => {
      Object.values(paramKeys).forEach(param => {
        searchParams.delete(param);
      });

      searchParams.set('page', '1');

      return searchParams;
    });

    setTimeout(() => {
      formInstant.resetFields();
    }, 200);
  };

  return {
    onFilter,
    onReset,
  };
};

import { Fragment } from 'react';
import {
  PageHeader,
  DetailsPageInfoWrapper,
  DetailsPageContentWrapper,
  DetailsPageTabs,
} from 'components/Common/Page';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useResetBookState } from 'features/book/bookHooks';
import type { TabsProps } from 'antd';
import Information from './Information';
import { NavLink, Outlet, useMatch, useParams } from 'react-router-dom';
import { getClassName } from 'utils/style';
import PATHS from 'router/paths';
import { getIdPath } from 'utils';
import { useBook } from 'features/bookDetails/bookDetailsHooks';

const TABS = {
  STUDENT: 'STUDENT',
  DOCUMENT: 'DOCUMENT',
};

const BookDetails = () => {
  const { t } = useTranslation(LOCALES.BOOK);

  const { book, getBookLoading } = useBook();
  const { id } = useParams();
  const isDocumentPage = useMatch(PATHS.app.books.details.document);
  useResetBookState();

  const items: TabsProps['items'] = [
    {
      label: (
        <NavLink
          to={getIdPath(PATHS.app.books.details.root, id)}
          className={getClassName}>
          {t('reader')}
        </NavLink>
      ),
      key: TABS.STUDENT,
    },
    {
      label: (
        <NavLink
          to={getIdPath(PATHS.app.books.details.document, id)}
          className={getClassName}>
          {t('document')}
        </NavLink>
      ),
      key: TABS.DOCUMENT,
    },
  ];

  return (
    <Fragment>
      <DetailsPageInfoWrapper>
        <PageHeader style={{ marginBottom: 20 }} title={t('bookInformation')} />

        <Information book={book} loading={getBookLoading} />

        <Row>
          <DetailsPageTabs
            style={{ marginBottom: 0 }}
            defaultActiveKey={isDocumentPage ? TABS.DOCUMENT : TABS.STUDENT}
            items={items}
          />
        </Row>
      </DetailsPageInfoWrapper>

      <DetailsPageContentWrapper>
        <Outlet />
      </DetailsPageContentWrapper>
    </Fragment>
  );
};

export default BookDetails;

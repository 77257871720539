import requester from 'utils/requester';
import { CheckInAPI } from './types';

const branchAPI: CheckInAPI = {
  getCheckIns(params) {
    return requester.get('check-in/', params);
  },

  createCheckIn(branch) {
    return requester.post('check-in/', branch);
  },

  updateCheckIn(branch) {
    return requester.put(`check-in/${branch.id}/`, branch);
  },

  deleteCheckIn(id) {
    return requester.delete(`check-in/${id}/`);
  },
};

export default branchAPI;

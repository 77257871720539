import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import profileAPI from './profileAPI';
import { Profile, ProfileState } from './types';

// Thunks
export const getProfile = createAppAsyncThunk(
  'profile/getProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response: any = await profileAPI.getProfile();
      return response?.[0];
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateProfile = createAppAsyncThunk(
  'profile/updateProfile',
  async (profile: Profile, { rejectWithValue }) => {
    try {
      const response: any = await profileAPI.updateProfile(profile);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: ProfileState = {
  getProfileLoading: false,
  profile: undefined,
  getProfileFailed: undefined,

  updateProfileLoading: false,
  updateProfileSuccess: undefined,
  updateProfileFailed: undefined,

  username: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfileState(state) {
      state.updateProfileSuccess = undefined;
      state.updateProfileFailed = undefined;
      state.getProfileFailed = undefined;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getProfile.pending, state => {
        state.getProfileLoading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.getProfileLoading = false;
        state.profile = action.payload;
        state.username = action.payload.name || '';
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.getProfileLoading = false;
        state.getProfileFailed = action.payload;
      })

      .addCase(updateProfile.pending, state => {
        state.updateProfileLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.updateProfileLoading = false;
        state.updateProfileSuccess = action.payload;
        state.profile = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.updateProfileLoading = false;
        state.updateProfileFailed = action.payload;
      });
  },
});

export const { resetProfileState } = profileSlice.actions;

export default profileSlice.reducer as Reducer<ProfileState>;

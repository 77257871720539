import requester from 'utils/requester';
import { BaseStaff, StaffAPI } from './types';

const staffAPI: StaffAPI = {
  getAllStaffs() {
    return requester.get('staff/', {
      page: 1,
      page_size: 9999,
    });
  },

  getStaffs(params) {
    return requester.get('staff/', params);
  },

  createStaff(staff: BaseStaff) {
    return requester.post('staff/', staff);
  },

  updateStaff(staff: BaseStaff) {
    return requester.put(`staff/${staff.id}/`, staff);
  },

  deleteStaff(id) {
    return requester.delete(`staff/${id}/`);
  },

  invite(staff) {
    return requester.post(`staff/${staff.id}/invite/`, { email: staff.email });
  },

  reInvite(staffId) {
    return requester.get(`staff/${staffId}/resend-email/`);
  },

  getRoleStaffs(ids) {
    return requester.get('staff/', { ids, page_size: 9999 });
  },

  updateStaffRole({ staffId, roleIds }) {
    return requester.put(`/staff/${staffId}/roles/`, { roles: roleIds });
  },
};

export default staffAPI;

import { useEffect, useMemo } from 'react';
import {
  UseStudentsReturnType,
  BaseStudent,
  UseCreateStudentReturnType,
  UseUpdateStudentReturnType,
  UseDeleteStudentReturnType,
  Student,
  UseStudentsModalReturnType,
} from './types';

import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  getStudents,
  createStudent,
  updateStudent,
  deleteStudent,
  resetStudentState,
  getRoleStudents,
  resetRoleStudents,
} from './studentSlice';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGING } from 'config';
import { useRoleResourceIds } from 'features/role/roleHooks';
import { PERMISSIONS_RESOURCES } from 'config/permissions';

export const STUDENT_QUERY_PARAMS = {
  NAME: 'name',
  ENGLISH_NAME: 'english_name',
  PHONE_NUMBER: 'phone_number',
  SEARCH: 'search',
};

export const STUDENT_MODAL_QUERY_PARAMS = {
  NAME: 'studentName',
  ENGLISH_NAME: 'studentEnglishName',
  PHONE_NUMBER: 'studentPhoneNumber',
  SEARCH: 'studentSearch',
  PAGE: 'studentPage',
  PAGE_SIZE: 'studentPageSize',
  ORDERING: 'studentOrdering',
};

const { NAME, ENGLISH_NAME, PHONE_NUMBER, SEARCH, PAGE, PAGE_SIZE, ORDERING } =
  STUDENT_MODAL_QUERY_PARAMS;

const REAL_KEY = {
  [NAME]: STUDENT_QUERY_PARAMS.NAME,
  [ENGLISH_NAME]: STUDENT_QUERY_PARAMS.ENGLISH_NAME,
  [PHONE_NUMBER]: STUDENT_QUERY_PARAMS.PHONE_NUMBER,
  [SEARCH]: STUDENT_QUERY_PARAMS.SEARCH,
  [PAGE]: 'page',
  [PAGE_SIZE]: 'page_size',
  [ORDERING]: 'ordering',
};

export const useStudentsModalUrlQueryParams = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get(SEARCH) ?? undefined;
  const page = searchParams.get(PAGE) ?? DEFAULT_PAGING.PAGE;
  const pageSize = searchParams.get(PAGE_SIZE) ?? DEFAULT_PAGING.PAGE_SIZE;
  const { id: courseId } = useParams();

  const urlQueryParams = useMemo(() => {
    return [NAME, ENGLISH_NAME, PHONE_NUMBER, ORDERING].reduce(
      (currentParams, paramKey) => ({
        ...currentParams,
        [REAL_KEY[paramKey]]: searchParams.get(paramKey),
      }),
      { search, page, page_size: pageSize, not_in_course: courseId },
    );
  }, [search, page, pageSize, searchParams, courseId]);

  return urlQueryParams;
};

export const useStudentsModal = (
  isOpen: boolean,
): UseStudentsModalReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getStudentsLoading = useAppSelector(
    state => state.student.getStudentsLoading,
  );
  const getStudentsFailed = useAppSelector(
    state => state.student.getStudentsFailed,
  );
  const students = useAppSelector(state => state.student.students);
  const paginationConfig = useAppSelector(
    state => state.student.paginationConfig,
  );

  const dispatch = useAppDispatch();

  const urlQueryParams = useStudentsModalUrlQueryParams();
  const onPageChange = usePagination(
    STUDENT_MODAL_QUERY_PARAMS.PAGE,
    STUDENT_MODAL_QUERY_PARAMS.PAGE_SIZE,
  );

  const goToFirstPage = () => {
    onPageChange(1);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getStudents(urlQueryParams));
    }
  }, [urlQueryParams, dispatch, isOpen]);

  useFailed(getStudentsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    students,
    getStudentsLoading,
    getStudentsFailed,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    goToFirstPage,
  };
};

export const useStudents = (): UseStudentsReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getStudentsLoading = useAppSelector(
    state => state.student.getStudentsLoading,
  );
  const getStudentsFailed = useAppSelector(
    state => state.student.getStudentsFailed,
  );
  const students = useAppSelector(state => state.student.students);
  const paginationConfig = useAppSelector(
    state => state.student.paginationConfig,
  );

  const dispatch = useAppDispatch();

  const urlQueryParams = useURLQueryParams(Object.values(STUDENT_QUERY_PARAMS));
  const onPageChange = usePagination();

  useEffect(() => {
    dispatch(getStudents(urlQueryParams));
  }, [urlQueryParams, dispatch]);

  useFailed(getStudentsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  const refreshCurrentPage = () => {
    dispatch(getStudents(urlQueryParams));
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      students.length === 1 && urlQueryParams.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(urlQueryParams.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterCreating = () => {
    if (urlQueryParams.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    getStudentsLoading,
    getStudentsFailed,
    students,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    refreshAfterCreating,
    refreshAfterDeleting,
  };
};

export const useCreateStudent = (
  callback: () => void,
): UseCreateStudentReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const createStudentLoading = useAppSelector(
    state => state.student.createStudentLoading,
  );
  const createStudentSuccess = useAppSelector(
    state => state.student.createStudentSuccess,
  );
  const createStudentFailed = useAppSelector(
    state => state.student.createStudentFailed,
  );

  const dispatch = useAppDispatch();

  const handleCreateStudent = (student: BaseStudent) => {
    dispatch(createStudent(student));
  };

  useSuccess(
    createStudentSuccess,
    t<string>('STUDENT_CREATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(createStudentFailed);

  return {
    createStudentLoading,
    createStudentSuccess,
    createStudentFailed,
    handleCreateStudent,
  };
};

export const useUpdateStudent = (
  callback: () => void,
): UseUpdateStudentReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const updateStudentLoading = useAppSelector(
    state => state.student.updateStudentLoading,
  );
  const updateStudentSuccess = useAppSelector(
    state => state.student.updateStudentSuccess,
  );
  const updateStudentFailed = useAppSelector(
    state => state.student.updateStudentFailed,
  );

  const dispatch = useAppDispatch();

  const handleUpdateStudent = (student: Student) => {
    dispatch(updateStudent(student));
  };

  useSuccess(
    updateStudentSuccess,
    t<string>('STUDENT_UPDATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(updateStudentFailed);

  return {
    updateStudentLoading,
    updateStudentSuccess,
    updateStudentFailed,
    handleUpdateStudent,
  };
};

export const useDeleteStudent = (
  callback: () => void,
): UseDeleteStudentReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const deleteStudentLoading = useAppSelector(
    state => state.student.deleteStudentLoading,
  );
  const deleteStudentSuccess = useAppSelector(
    state => state.student.deleteStudentSuccess,
  );
  const deleteStudentFailed = useAppSelector(
    state => state.student.deleteStudentFailed,
  );

  const dispatch = useAppDispatch();

  const handleDeleteStudent = (id: number) => {
    dispatch(deleteStudent(id));
  };

  useSuccess(
    deleteStudentSuccess,
    t<string>('STUDENT_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteStudentFailed);

  return {
    deleteStudentLoading,
    deleteStudentSuccess,
    deleteStudentFailed,
    handleDeleteStudent,
  };
};

export const useResetStudentState = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetStudentState());
    };
  }, [dispatch]);
};

export const useRoleStudents = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const roleStudents = useAppSelector(state => state.student.roleStudents);
  const getRoleStudentsLoading = useAppSelector(
    state => state.student.getRoleStudentsLoading,
  );
  const getRoleStudentsFailed = useAppSelector(
    state => state.student.getRoleStudentsFailed,
  );

  const dispatch = useAppDispatch();

  const { ids: studentIds } = useRoleResourceIds(PERMISSIONS_RESOURCES.STUDENT);

  useEffect(() => {
    if (studentIds) {
      dispatch(getRoleStudents(studentIds));
    } else {
      dispatch(resetRoleStudents());
    }
  }, [studentIds, dispatch]);

  const refetchRoleStudents = (ids: number[]) => {
    if (ids.length) {
      dispatch(getRoleStudents(ids.join(',')));
    } else {
      dispatch(resetRoleStudents());
    }
  };

  useFailed(getRoleStudentsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    roleStudents,
    getRoleStudentsLoading,
    getRoleStudentsFailed,
    refetchRoleStudents,
  };
};

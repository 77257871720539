import React from 'react';
import { Form, Input, Row, Col, Button } from 'antd';
import FlatFormItem from 'components/Common/FlatFormItem';
import PhoneFormItem from 'components/Common/PhoneFormItem';
import ImageUploader from 'components/Common/ImageUploader';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useInitWorkspace,
  useCreateWorkspace,
  useUpdateWorkspace,
} from 'features/auth/authHooks';
import { Workspace, WorkspaceFormModel } from 'features/auth/types';
import { useNavigate } from 'react-router-dom';
import PATHS from 'router/paths';

const FormItem = Form.Item;

const WorkspaceForm = () => {
  const [form] = Form.useForm();
  const { handleCreateWorkspace, createWorkspaceLoading } =
    useCreateWorkspace();

  const { handleUpdateWorkspace, updateWorkspaceLoading } =
    useUpdateWorkspace();

  const navigate = useNavigate();
  const { t } = useTranslation(LOCALES.WORKSPACE);
  const { initValue, getCurrentWorkspaceLoading } = useInitWorkspace(form);

  const loading = createWorkspaceLoading || updateWorkspaceLoading;
  const disabledForm = loading || getCurrentWorkspaceLoading;

  const onFinish = (values: WorkspaceFormModel) => {
    const phoneNumber = values.phoneNumberPrefix + values.phone_number;
    const workspace: Workspace = {
      ...values,
      id: initValue.id,
      phone_number: phoneNumber,
    };

    if (initValue?.id) {
      handleUpdateWorkspace(workspace);
    } else {
      handleCreateWorkspace(workspace);
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initValue}
      disabled={disabledForm}>
      <Row justify='center' align='middle' style={{ marginTop: 20 }}>
        <FormItem name='logo' style={{ marginBottom: 0 }}>
          <ImageUploader label={t<string>('logo')} />
        </FormItem>
      </Row>

      <Row gutter={64} style={{ marginTop: 40 }}>
        <Col span={12}>
          <FlatFormItem label={t('name')} name='name'>
            <Input bordered={false} />
          </FlatFormItem>
        </Col>

        <Col span={12}>
          <FlatFormItem label={t('address')} name='address'>
            <Input bordered={false} />
          </FlatFormItem>
        </Col>

        <Col span={12}>
          <FlatFormItem label={t('email')} name='email'>
            <Input bordered={false} />
          </FlatFormItem>
        </Col>

        <Col span={12}>
          <PhoneFormItem />
        </Col>

        <Col span={12}>
          <FlatFormItem label={t('workspace')} name='workspace'>
            <Input disabled={!!initValue?.id} bordered={false} />
          </FlatFormItem>
        </Col>

        <Col span={12}>
          <FlatFormItem label={t('url')} name='website_url'>
            <Input bordered={false} />
          </FlatFormItem>
        </Col>
      </Row>

      <Row justify='center' style={{ marginTop: 70 }}>
        <Button
          disabled={loading}
          style={{ marginRight: 10 }}
          onClick={() => navigate(PATHS.account.root)}>
          {t('cancel')}
        </Button>
        <Button
          loading={loading}
          style={{ marginLeft: 10 }}
          htmlType='submit'
          type='primary'>
          {initValue.id ? t('update') : t('create')}
        </Button>
      </Row>
    </Form>
  );
};

export default WorkspaceForm;

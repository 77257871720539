const en = {
  createNewAccount: 'Create New Account',
  fastAndEasy: 'Fast and easy',
  email: 'Email',
  emailPlaceholder: 'Enter Email',
  password: 'Password',
  confirmPassword: 'Confirm password',
  passwordPlaceholder: 'Enter password',
  phoneNumber: 'Phone number',
  phoneNumberPlaceholder: 'Enter phone number',
  register: 'Register',
  haveAnAccount: 'You have an account',
  loginHere: 'Login here',
  registerFailed: 'Account Registered Failed.',
  registerSuccessfully: 'Account Registered Successfully.',
};

export default en;

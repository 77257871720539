import {
  BookOutlined,
  UsergroupAddOutlined,
  FolderOpenOutlined,
  ReadOutlined,
  UserSwitchOutlined,
  ApartmentOutlined,
  CheckOutlined,
  BranchesOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import PATHS from 'router/paths';
import { getClassName } from 'utils/style';
import Logo from 'svgs/Logo';

const { Sider } = Layout;

const SiderWrapper = styled(Sider)`
  height: calc(100vh - 64px);

  .ant-menu-light {
    border-inline-end: none !important;
  }

  .ant-menu-item:has(.active-link) {
    color: #da271c;
    background-color: #fff1ed;

    &::after {
      position: absolute;
      inset-block: 0;
      inset-inline-end: 0;
      border-inline-end: 0px solid #da271c;
      content: '';
    }
  }

  .ant-menu-item:has(.not-active-link) {
    color: rgba(0, 0, 0, 0.88);

    &::after {
      border-width: 0;
      border-bottom-color: transparent;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const AppSider = ({ collapsed }: { collapsed: boolean }) => {
  const { t } = useTranslation(LOCALES.LAYOUT);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <SiderWrapper
      width={260}
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ backgroundColor: colorBgContainer }}>
      <Menu
        style={{ marginTop: 20, marginBottom: 'auto' }}
        mode='inline'
        items={[
          {
            key: 'path_student',
            icon: <UsergroupAddOutlined />,
            label: (
              <NavLink
                className={getClassName}
                to={PATHS.app.students}
                style={{ fontWeight: 500 }}>
                {t('path_student')}
              </NavLink>
            ),
          },
          {
            key: 'path_courses',
            icon: <BookOutlined />,

            label: (
              <NavLink
                className={getClassName}
                to={PATHS.app.courses.root}
                style={{ fontWeight: 500 }}>
                {t('path_courses')}
              </NavLink>
            ),
          },
          {
            key: 'path_book',
            icon: <ReadOutlined />,

            label: (
              <NavLink
                className={getClassName}
                to={PATHS.app.books.root}
                style={{ fontWeight: 500 }}>
                {t('path_book')}
              </NavLink>
            ),
          },
          {
            key: 'files',
            icon: <FolderOpenOutlined />,
            label: t('files'),

            children: [
              {
                key: 'path_playlist',
                label: (
                  <NavLink
                    className={getClassName}
                    to={PATHS.app.playlist.root}>
                    {t('path_playlist')}
                  </NavLink>
                ),
              },
              {
                key: 'path_assets',
                label: (
                  <NavLink className={getClassName} to={PATHS.app.assets}>
                    {t('path_assets')}
                  </NavLink>
                ),
              },
            ],
          },

          {
            key: 'path_branch',
            icon: <BranchesOutlined />,
            label: (
              <NavLink
                className={getClassName}
                to={PATHS.app.branches.root}
                style={{ fontWeight: 500 }}>
                {t('path_branch')}
              </NavLink>
            ),
          },
          {
            key: 'path_check_in',
            icon: <CheckOutlined />,
            label: (
              <NavLink
                className={getClassName}
                to={PATHS.app.checkIns.root}
                style={{ fontWeight: 500 }}>
                {t('path_check_in')}
              </NavLink>
            ),
          },

          {
            key: 'path_staff',
            icon: <UserSwitchOutlined />,
            label: (
              <NavLink
                className={getClassName}
                to={PATHS.app.staffs.root}
                style={{ fontWeight: 500 }}>
                {t('path_staff')}
              </NavLink>
            ),
          },

          {
            key: 'path_role',
            icon: <ApartmentOutlined />,
            label: (
              <NavLink
                className={getClassName}
                to={PATHS.app.roles.root}
                style={{ fontWeight: 500 }}>
                {t('path_role')}
              </NavLink>
            ),
          },
        ]}
      />

      <div
        style={{
          paddingBottom: 20,
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Logo
          style={{
            width: collapsed ? '65px' : '100px',
          }}
        />
      </div>
    </SiderWrapper>
  );
};

export default AppSider;

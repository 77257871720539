import { useState } from 'react';
import { Table, Button, Popconfirm as PopConfirm, Tag } from 'antd';
import { Fragment } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  usePlaylistAssets,
  useDeletePlaylistAsset,
  useAddPlaylistAssets,
  useOrderPlaylistAsset,
} from 'features/playlist/playlistHooks';
import { DeleteOutlined } from '@ant-design/icons';
import Filter from './Filter';
import { PlaylistAsset } from 'features/playlist/types';
import { useTableSorting } from 'app/hooks';
import AssetsModal from 'components/AssetsModal';
import { CLIENT_DATE_2_FORMAT, CLIENT_DATE_FORMAT } from 'config';
import dayjs from 'dayjs';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import MoveableRow from 'components/Common/MoveableRow';
import FileExtensionIcon from 'components/Common/FileExtensionIcon';
import { usePlayFile } from 'features/asset/assetHooks';
import { useSearchParams } from 'react-router-dom';

const PlaylistAssets = () => {
  const { t } = useTranslation(LOCALES.ASSET);
  const { handleSorting, getColumnSorting } = useTableSorting();
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
  const { getPlayFileAction, downloadFileLoading, getFileStyle } =
    usePlayFile();

  const [searchParams] = useSearchParams();
  const hasSearch = searchParams.get('search');

  const {
    playlistAssets,
    getPlaylistAssetsLoading,
    refreshAfterAdding,
    refreshAfterDeleting,
    refreshCurrentPage,
  } = usePlaylistAssets();

  const { handleOrderPlaylistAsset, updateShouldReorderAssets } =
    useOrderPlaylistAsset(refreshCurrentPage);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = playlistAssets.findIndex(
        (i: any) => i.id === active.id,
      );
      const overIndex = playlistAssets.findIndex((i: any) => i.id === over?.id);
      const orderedData = arrayMove(playlistAssets, activeIndex, overIndex);
      handleOrderPlaylistAsset(orderedData);
    }
  };

  const { handleAddPlaylistAssets, addPlaylistAssetsLoading } =
    useAddPlaylistAssets(() => {
      setIsAssetModalOpen(false);
      refreshAfterAdding();
    });

  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);
  const { handleDeletePlaylistAsset, deletePlaylistAssetLoading } =
    useDeletePlaylistAsset(() => {
      refreshAfterDeleting();
      setOpenedPopConfirm(-1);
    });

  const sorter = hasSearch ? {} : getColumnSorting('name');

  let columns: ColumnsType<PlaylistAsset> = [
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      ...sorter,
      render: (_, rc) => {
        return (
          <div style={getFileStyle(rc)} onClick={getPlayFileAction(rc)}>
            <FileExtensionIcon isCached={rc.isCached} extension={rc.extension || ''} />
            <span style={{ marginLeft: 8 }}>{rc.name} </span>
          </div>
        );
      },
    },
    {
      title: t('tags'),
      key: 'tags',
      dataIndex: 'tags',
      render: (tags: string) => {
        return tags?.split(',').map(tag => (
          <Tag style={{ marginTop: 5 }} key={tag}>
            {tag}
          </Tag>
        ));
      },
    },

    {
      title: t('createdAt'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at: string) => {
        return dayjs(created_at, CLIENT_DATE_2_FORMAT).format(
          CLIENT_DATE_FORMAT,
        );
      },
    },

    {
      title: '',
      key: 'actions',
      width: 70,
      render: playlistAsset => (
        <PopConfirm
          placement='topRight'
          title={t('deleteWarning')}
          onConfirm={() => {
            handleDeletePlaylistAsset(playlistAsset.id);
          }}
          okText={t('delete')}
          cancelText={t('cancel')}
          okButtonProps={{ loading: deletePlaylistAssetLoading }}
          cancelButtonProps={{ disabled: deletePlaylistAssetLoading }}
          open={openedPopConfirm === playlistAsset.id}
          onCancel={() => setOpenedPopConfirm(-1)}>
          <Button
            onClick={() => setOpenedPopConfirm(playlistAsset.id)}
            type='text'
            icon={<DeleteOutlined style={{ color: '#f81d22' }} />}></Button>
        </PopConfirm>
      ),
    },
  ];

  if (!hasSearch) {
    columns = [{ key: 'sort' }, ...columns];
  }

  const tableLoading = getPlaylistAssetsLoading || downloadFileLoading;

  return (
    <Fragment>
      <Filter openAssetsModal={() => setIsAssetModalOpen(true)} />
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={playlistAssets.map((i: any) => i.id)}
          strategy={verticalListSortingStrategy}>
          <Table
            onChange={(...value) => {
              handleSorting(...value);
              updateShouldReorderAssets(true);
            }}
            rowKey='id'
            loading={tableLoading}
            columns={columns}
            dataSource={playlistAssets}
            pagination={false}
            components={
              hasSearch
                ? undefined
                : {
                    body: {
                      row: MoveableRow,
                    },
                  }
            }
          />
        </SortableContext>
      </DndContext>

      <AssetsModal
        onSubmit={handleAddPlaylistAssets}
        addingLoading={addPlaylistAssetsLoading}
        isOpen={isAssetModalOpen}
        onClose={() => setIsAssetModalOpen(false)}
      />
    </Fragment>
  );
};

export default PlaylistAssets;

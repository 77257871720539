import { Form } from 'antd';
import color from 'config/color';
import styled from 'styled-components';

export default styled(Form.Item)`
  .ant-form-item-control-input-content {
    border: none !important;
    border-bottom: 1px solid #e2e2ea !important;
    border-radius: 0;
    padding-left: 0;
  }

  .ant-input-affix-wrapper {
    padding-left: 0;
  }

  label {
    color: ${color.grey3} !important;
  }

  &.ant-form-item-has-error {
    .ant-form-item-control-input-content {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-input-group-addon,
  .ant-select-selector {
    padding-left: 0;
  }

  .ant-input-group-addon {
    border: none !important;
    background: #fff !important;
  }
`;

import { IMAGE_EXTENSIONS, VIDEO_EXTENSIONS } from 'config';
import { UploadingAlbumAsset } from 'features/course/types';

const DisplayedTempAlbumAsset = ({ asset }: { asset: UploadingAlbumAsset }) => {
  const { type, base64 } = asset;
  const isImage = IMAGE_EXTENSIONS.includes(type);
  const isVideo = VIDEO_EXTENSIONS.includes(type);
  if (isImage) {
    return (
      <img
        className='album-file__image'
        alt='placeholder'
        src={base64 ?? 'https://wiki.dave.eu/images/4/47/Placeholder.png'}
      />
    );
  }

  if (isVideo) {
    return <video className='album-file__image' src={base64 ?? ''} />;
  }

  return <div className='album-file__image'></div>;
};

export default DisplayedTempAlbumAsset;

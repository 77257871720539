import { Fragment, useState } from 'react';
import { HasFilterPageContent, PageHeader } from 'components/Common/Page';
import {
  Button,
  Table,
  Dropdown,
  Popconfirm as PopConfirm,
  Skeleton,
  Tag,
  Avatar,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { BaseCheckIn, CheckIn } from 'features/checkIn/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useDeleteCheckIn,
  useCheckIns,
  useResetCheckInState,
} from 'features/checkIn/checkInHooks';
import CheckInForm from 'features/checkIn/CheckInForm';
import FilterPanel from 'features/checkIn/CheckIns/Filter';
import type { MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useTableSorting } from 'app/hooks';
import { useAllStaffs } from 'features/staff/staffHooks';
import { useAllBranches } from 'features/branch/branchHooks';
import dayjs from 'dayjs';

const initValues: BaseCheckIn = {
  type: 'check_in',
  notes: '',
  branch: null,
  staff: null,
};

const CheckIns = () => {
  const { t } = useTranslation(LOCALES.CHECK_IN);
  const {
    checkIns,
    getCheckInsLoading,
    paginationConfig,
    refreshAfterCreating,
    refreshAfterDeleting,
  } = useCheckIns();
  useResetCheckInState();

  const { allStaffs, getAllStaffsLoading, allStaffsDict } = useAllStaffs();
  const { allBranches, getAllBranchesLoading, allBranchesDict } =
    useAllBranches();

  const { handleSorting, getColumnSorting } = useTableSorting();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [initialCheckIn, setInitialCheckIn] = useState<BaseCheckIn>(initValues);

  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);
  const { handleDeleteCheckIn, deleteCheckInLoading } = useDeleteCheckIn(() => {
    setOpenedPopConfirm(-1);
    refreshAfterDeleting();
  });

  const onOpenCreateForm = () => {
    setIsFormOpen(true);
    setInitialCheckIn(initValues);
  };

  const onOpenEditForm = (checkIn: CheckIn) => {
    setIsFormOpen(true);

    setInitialCheckIn({
      staff: checkIn.staff,
      branch: checkIn.branch,
      type: checkIn.type,
      id: checkIn.id,
      notes: checkIn.notes,
    });
  };

  const onCloseForm = () => {
    setIsFormOpen(false);
    setInitialCheckIn(initValues);
  };

  const columns: ColumnsType<CheckIn> = [
    {
      title: t('photo'),
      key: 'photo',
      dataIndex: 'photo',
      ...getColumnSorting('photo'),
      render: photo => {
        if (getAllStaffsLoading) {
          return <Skeleton.Avatar size='small' />;
        }

        return <Avatar size={50} src={photo} />;
      },
    },

    {
      title: t('staff'),
      key: 'staff',
      dataIndex: 'staff',
      ...getColumnSorting('staff'),
      render: staff => {
        if (getAllStaffsLoading) {
          return <Skeleton.Input size='small' />;
        }
        return allStaffsDict[staff]?.full_name ?? '';
      },
    },
    {
      title: t('branch'),
      key: 'branch',
      dataIndex: 'branch',
      ...getColumnSorting('branch'),
      render: branch => {
        if (getAllBranchesLoading) {
          return <Skeleton.Input size='small' />;
        }
        return allBranchesDict[branch]?.name ?? '';
      },
    },

    {
      title: t('type'),
      key: 'type',
      dataIndex: 'type',
      ...getColumnSorting('type'),
      render: type => {
        if (type === 'check_in') {
          return <Tag color='processing'>{t(type)}</Tag>;
        }

        return <Tag color='success'>{t(type)}</Tag>;
      },
    },

    {
      title: t('time'),
      key: 'check_in',
      dataIndex: 'check_in',
      ...getColumnSorting('check_in'),
      render: checkInTime => dayjs(checkInTime).format('DD/MM/YYYY HH:mm'),
    },

    {
      title: t('notes'),
      key: 'notes',
      dataIndex: 'notes',
      ...getColumnSorting('notes'),
    },

    {
      title: '',
      width: 50,
      render: (checkIn: CheckIn) => {
        const items: MenuProps['items'] = [
          {
            label: (
              <p
                className='styled-table__action-item'
                onClick={() => onOpenEditForm(checkIn)}>
                {t('update')}
              </p>
            ),
            key: 'Edit',
          },
          { type: 'divider' },
          {
            label: (
              <p
                className='styled-table__action-item color-error'
                onClick={() => setOpenedPopConfirm(checkIn.id)}>
                {t('delete')}
              </p>
            ),
            key: 'Delete',
          },
        ];
        return (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            overlayStyle={{ width: 150 }}
            overlayClassName='styled-table__actions'
            placement='bottomRight'>
            <PopConfirm
              placement='topRight'
              title={t('deleteWarning')}
              onConfirm={() => handleDeleteCheckIn(checkIn.id)}
              okText={t('delete')}
              cancelText={t('cancel')}
              okButtonProps={{ loading: deleteCheckInLoading }}
              cancelButtonProps={{ disabled: deleteCheckInLoading }}
              open={openedPopConfirm === checkIn.id}
              onCancel={() => setOpenedPopConfirm(-1)}>
              <Button size='small' type='text'>
                <EllipsisOutlined />
              </Button>
            </PopConfirm>
          </Dropdown>
        );
      },
      key: 'action',
    },
  ];

  return (
    <Fragment>
      <FilterPanel loading={getCheckInsLoading} />

      <HasFilterPageContent>
        <PageHeader title={t('checkIns')}>
          <Button type='primary' onClick={onOpenCreateForm}>
            {t('create')}
          </Button>
        </PageHeader>

        <Table
          loading={getCheckInsLoading}
          dataSource={checkIns}
          columns={columns}
          pagination={paginationConfig}
          rowKey='id'
          onChange={handleSorting}
        />
      </HasFilterPageContent>

      <CheckInForm
        isOpen={isFormOpen}
        onClose={onCloseForm}
        refreshAfterCreating={refreshAfterCreating}
        initValues={initialCheckIn}
        staffs={allStaffs}
        branches={allBranches}
      />
    </Fragment>
  );
};

export default CheckIns;

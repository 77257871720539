import React from 'react';
import { Input, Form, Row, Button, Typography } from 'antd';
import Logo from 'svgs/Logo';
import { PhoneIcon, UserIcon } from 'svgs/Profiles';
import FlatFormItem from 'components/Common/FlatFormItem';
import { SignUpWrapper, SignUpFormWrapper } from './Styled';
import { Link } from 'react-router-dom';
import PATHS from 'router/paths';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useRegister } from '../authHooks';

const Register = () => {
  const { t } = useTranslation(LOCALES.REGISTER);
  const { t: validationT } = useTranslation(LOCALES.VALIDATION);
  const { handleRegister, registerLoading } = useRegister();

  return (
    <SignUpWrapper>
      <SignUpFormWrapper>
        <Form
          onFinish={handleRegister}
          disabled={registerLoading}
          hideRequiredMark
          autoComplete='off'
          scrollToFirstError
          layout='vertical'>
          <Row
            style={{
              width: '100%',
              justifyContent: 'space-between',
              paddingBottom: 40,
            }}>
            <div>
              <Logo />
            </div>
            <div>
              <Typography.Title className='register-title' level={4}>
                {t('createNewAccount')}
              </Typography.Title>
              <Typography.Text className='register-sub-title'>
                {t('fastAndEasy')}
              </Typography.Text>
            </div>
          </Row>

          <FlatFormItem
            label={t('email')}
            name='email'
            rules={[
              {
                required: true,
                message: validationT<string>('pleaseInputEmail'),
              },
            ]}>
            <Input prefix={<UserIcon />} bordered={false} />
          </FlatFormItem>

          <FlatFormItem
            label={t('password')}
            name='password'
            rules={[
              {
                required: true,
                message: validationT<string>('pleaseInputPass'),
              },
            ]}>
            <Input.Password bordered={false} />
          </FlatFormItem>

          <FlatFormItem
            label={t('confirmPassword')}
            name='confirm_password'
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: validationT<string>('pleaseReEnterPass'),
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(validationT<string>('passNoSame')),
                  );
                },
              }),
            ]}>
            <Input.Password bordered={false} />
          </FlatFormItem>

          <FlatFormItem
            label={t('phoneNumber')}
            name='phone_number'
            rules={[
              {
                required: true,
                message: validationT<string>('pleaseInputPhoneNumber'),
              },
            ]}>
            <Input prefix={<PhoneIcon />} bordered={false} />
          </FlatFormItem>

          <Row justify='center' style={{ marginTop: 60 }}>
            <Button
              loading={registerLoading}
              style={{ width: '100%' }}
              size='large'
              type='primary'
              htmlType='submit'>
              {t('register')}
            </Button>
          </Row>
        </Form>

        <Row style={{ marginTop: 20 }} justify='center'>
          <span style={{ marginRight: 10, fontWeight: 600 }}>
            {t('haveAnAccount')}
          </span>{' '}
          <Link style={{ fontWeight: 'bold' }} to={PATHS.auth.signIn}>
            {t('loginHere')}
          </Link>
        </Row>
      </SignUpFormWrapper>
    </SignUpWrapper>
  );
};

export default Register;

import { useEffect } from 'react';
import { Form, Input, Row, Button, Select } from 'antd';
import { LOCALES } from 'app/i18n';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { BaseCourse } from './types';
import { useCreateCourse, useUpdateCourse } from './courseHooks';
import ImageUploader from 'components/Common/ImageUploader';
import { COURSE_STATUS } from 'config';
import FormModal from 'components/Styled/FormModal';
const { Option } = Select;

interface CourseFormProps {
  isOpen: boolean;
  onClose: () => void;
  initValues: BaseCourse;
  refreshAfterCreating: () => void;
}

const CourseForm = (props: CourseFormProps) => {
  const { isOpen, onClose, initValues, refreshAfterCreating } = props;
  const { t } = useTranslation(LOCALES.COURSE);
  const [form] = Form.useForm<BaseCourse>();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, form]);

  const onCreateSuccess = () => {
    onCancel();
    refreshAfterCreating();
  };

  const { handleCreateCourse, createCourseLoading } = useCreateCourse(
    onCreateSuccess,
  );

  const { handleUpdateCourse, updateCourseLoading } = useUpdateCourse(onCancel);

  const isLoading = createCourseLoading || updateCourseLoading;

  const onFinish = (values: any) => {
    const courseModel = {
      ...values,
    };
    if (initValues.id) {
      handleUpdateCourse({ ...courseModel, id: initValues.id });
    } else {
      handleCreateCourse(courseModel);
    }
  };

  return (
    <FormModal
      width={700}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
      title={t(initValues.id ? 'updateClass' : 'createClass')}>
      <Form
        disabled={isLoading}
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        layout='vertical'>
        <Row justify='center' align='middle' style={{ marginTop: 20 }}>
          <Form.Item name='photo' style={{ marginBottom: 0 }}>
            <ImageUploader label={t<string>('logo')} />
          </Form.Item>
        </Row>

        <FlatFormItem
          label={t('name')}
          name='name'
          style={{ marginTop: 30 }}
          rules={[
            { required: true, message: t<string>('pleaseInputCourseName') },
          ]}>
          <Input bordered={false} />
        </FlatFormItem>

        <FlatFormItem label={t('teacher')} name='teacher'>
          <Input bordered={false} />
        </FlatFormItem>

        <FlatFormItem label={t('status')} name='status'>
          <Select bordered={false} className='flat-select'>
            {COURSE_STATUS.map(status => (
              <Option key={status} value={status}>
                {t(status)}
              </Option>
            ))}
          </Select>
        </FlatFormItem>

        <FlatFormItem label={t('descriptions')} name='descriptions'>
          <Input bordered={false} />
        </FlatFormItem>

        <Row justify='center' style={{ marginTop: 40, marginBottom: 10 }}>
          <Button
            disabled={isLoading}
            style={{ marginRight: 10 }}
            onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
          <Button
            loading={isLoading}
            htmlType='submit'
            style={{ marginLeft: 10 }}
            type='primary'>
            {initValues.id ? t('update') : t('create')}
          </Button>
        </Row>
      </Form>
    </FormModal>
  );
};

export default CourseForm;

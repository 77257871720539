import { FilterPanel } from 'components/Common/Page';
import { Row, Form, Input, Button, Space, Select } from 'antd';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { CHECK_IN_QUERY_PARAMS } from 'features/checkIn/checkInHooks';
import { useFilter, useURLQueryParams } from 'app/hooks';
import { useAllBranches } from 'features/branch/branchHooks';
import { useAllStaffs } from 'features/staff/staffHooks';
import { useMemo } from 'react';

const filterOption = (
  input: string,
  option?: { label: string; value: string },
) => {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
};

const Filter = ({ loading }: { loading: boolean }) => {
  const urlQueryParams: any = useURLQueryParams(
    Object.values(CHECK_IN_QUERY_PARAMS),
  );

  const { t } = useTranslation(LOCALES.CHECK_IN);
  const [form] = Form.useForm();
  const { onReset, onFilter } = useFilter(form, CHECK_IN_QUERY_PARAMS);

  const { allBranches, getAllBranchesLoading } = useAllBranches();
  const { allStaffs, getAllStaffsLoading } = useAllStaffs();

  const branchOptions = useMemo(() => {
    return allBranches.map((branch: any) => ({
      label: branch.name,
      value: branch.id,
    }));
  }, [allBranches]);

  const staffOptions = useMemo(() => {
    return allStaffs.map((staff: any) => ({
      label: staff.full_name,
      value: staff.id,
    }));
  }, [allStaffs]);

  return (
    <FilterPanel>
      <Form
        initialValues={{
          ...urlQueryParams,
          branch__id: urlQueryParams.branch__id
            ? Number(urlQueryParams.branch__id)
            : undefined,
          staff__id: urlQueryParams.staff__id
            ? Number(urlQueryParams.staff__id)
            : undefined,
        }}
        form={form}
        onFinish={onFilter}
        disabled={loading}>
        <Row
          style={{ width: '100%', flexWrap: 'nowrap', gap: 50 }}
          justify='space-between'>
          {' '}
          <FlatFormItem
            name={CHECK_IN_QUERY_PARAMS.SEARCH}
            label={t('keyword')}
            style={{ marginBottom: 0, flex: '1 1 0' }}>
            <Input bordered={false} placeholder={t<string>('enterKeyword')} />
          </FlatFormItem>
          <FlatFormItem
            name={CHECK_IN_QUERY_PARAMS.BRANCH_ID}
            label={t('branch')}
            style={{ marginBottom: 0, flex: '1 1 0' }}>
            <Select
              filterOption={filterOption}
              showSearch
              bordered={false}
              loading={getAllBranchesLoading}
              options={branchOptions}
              placeholder={t('selectBranch')}
            />
          </FlatFormItem>
          <FlatFormItem
            name={CHECK_IN_QUERY_PARAMS.STAFF_ID}
            label={t('staff')}
            style={{ marginBottom: 0, flex: '1 1 0' }}>
            <Select
              disabled={getAllStaffsLoading}
              filterOption={filterOption}
              showSearch
              bordered={false}
              loading={getAllStaffsLoading}
              options={staffOptions}
              placeholder={t('selectStaff')}
            />
          </FlatFormItem>
          <Space style={{ marginLeft: 20 }}>
            <Button disabled={loading} onClick={onReset}>
              Reset
            </Button>
            <Button
              loading={loading}
              htmlType='submit'
              style={{ marginLeft: 15 }}
              type='primary'>
              {t('search')}
            </Button>
          </Space>
        </Row>
      </Form>
    </FilterPanel>
  );
};

export default Filter;

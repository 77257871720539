import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import vi from './locales/vi';
import { LANGUAGE } from 'config';

i18n.use(initReactI18next).init({
  resources: {
    en,
    vi,
  },
  lng: localStorage.getItem('language') || LANGUAGE.VI,
  fallbackLng: 'en',
});

export default i18n;

export const LOCALES = {
  LOGIN: 'login',
  VALIDATION: 'validation',
  REGISTER: 'register',
  LAYOUT: 'layout',
  PROFILE: 'profile',
  MESSAGE: 'message',
  WORKSPACE: 'workspace',
  STUDENT: 'student',
  COURSE: 'course',
  ASSET: 'asset',
  PLAYLIST: 'playlist',
  BOOK: 'book',
  STAFF: 'staff',
  ROLE: 'role',
  BRANCH: 'branch',
  CHECK_IN: 'checkIn',
};

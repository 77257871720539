import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: calc(100vh - 64px);
  left: 0;
  bottom: 0;
  background: #e4e6eb;
  display: flex;

  .album-form-left {
    height: 100vh;
    flex-shrink: 0;
    top: 0;
    width: 400px;
    margin-top: -64px;
    background: #fff;
    z-index: 1000;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    &__header {
      height: 60px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      padding: 16px;
    }

    &__title {
      margin: 20px 16px 12px 16px;
      font-size: 24px;
      line-height: 24px;
    }

    &__footer {
      height: 68px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      margin-top: auto;
      border-top: 1px solid #ced0d4;
    }
  }

  .album-form {
    margin-top: 20px;
    padding: 0 16px;

    &__upload-btn,
    &__submit-btn,
    &__delete-btn {
      font-weight: 600;
      width: 100%;
    }

    &__upload-btn {
      margin-top: 30px;
    }

    &__delete-btn {
    }
  }

  .album-form-right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-upload-wrapper {
      width: 100%;
      height: 100%;
    }

    .ant-upload {
      border: none !important;
      cursor: unset;
      background: transparent;
      padding-top: 0px;
      display: block;
      overflow-y: auto;
    }

    .ant-upload-drag-container {
      width: 100%;
      height: 100%;
      display: block;
    }

    &__uploader--has-placeholder {
      .ant-upload-drag-container {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }

  .upload-album-placeholder {
    width: 352px;
    height: 238px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      width: 112px;
      height: 112px;
      margin-bottom: 20px;
    }

    &__title {
      color: rgb(101, 103, 107);
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin: 6px 0;
    }

    &__description {
      font-size: 17px;
      line-height: 20px;
      color: rgb(101, 103, 107);
    }
  }
`;

export const AlbumAssetsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  grid-row-gap: 25px;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const AlbumAssetWrapper = styled.div`
  width: 300px;
  height: 315px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  & .album-file {
    &__image-box {
      height: 265px;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__info {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 10px;
    }

    &__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
`;

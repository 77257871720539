const en = {
  name: 'Full name',
  birthDate: 'Date of birth',
  email: 'Email',
  phone: 'Phone number',
  address: 'Address',
  save: 'Update',
  avatar: 'Avatar',
  upload: 'Upload',
  note:
    'File size of .jpg, .jpeg, .png is smaller than 300 KB with minimum size 300x300 px',
  resetPass: 'Change password',
  joined: 'Joined contests',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',

  logOut: 'Logout',
  information: 'Profile',
  changePass: 'Change password',
  cancel: 'Cancel',
};

export default en;

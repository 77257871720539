import {
  useCourseAlbum,
  useDeleteAlbumAsset,
} from 'features/course/courseHooks/courseAlbumHooks';
import { AlbumAsset, DeleteAlbumAssetModel } from 'features/course/types';
import { Button, Skeleton, Popconfirm as PopConfirm, Spin } from 'antd';
import styled from 'styled-components';
import {
  PlusOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Link, generatePath, useParams } from 'react-router-dom';
import PATHS from 'router/paths';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useState } from 'react';
import { usePlayFile } from 'features/asset/assetHooks';

const AlbumAssetGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  gap: 10px;
  grid-row-gap: 25px;
`;

const AlbumAssetWrapper = styled.div`
  width: 185px;
  cursor: pointer;
  position: relative;

  .delete-album-file-btn {
    display: none;
  }

  &:hover {
    .delete-album-file-btn {
      display: block;
    }
  }
`;

const AlbumAssetThumbnailWrapper = styled.div`
  width: 185px;
  height: 185px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AlbumDetailsWrapper = styled.div`
  .album-details-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .album-details__name {
    font-size: 16px;
    font-weight: 700;
    margin-left: 20px;
  }

  .album-details__add-to-album-btn {
    font-size: 20px;
    font-weight: 700;
    color: rgb(101, 103, 107);
  }

  .album-details__items {
    font-size: 13px;
    font-weight: 400;
    color: rgb(101, 103, 107);
  }
`;

const AddToAlbumBtn = () => {
  const { id, albumId } = useParams();
  return (
    <Link
      to={generatePath(PATHS.app.albumForm.edit, {
        id,
        albumId,
      })}>
      <AlbumAssetWrapper>
        <AlbumAssetThumbnailWrapper>
          <PlusOutlined
            style={{
              color: 'rgb(101, 103, 107)',
              fontSize: 20,
              fontWeight: 'bold',
              marginRight: 5,
            }}
          />
          <span className='album-details__add-to-album-btn'>Add to album</span>
        </AlbumAssetThumbnailWrapper>
      </AlbumAssetWrapper>
    </Link>
  );
};

const AlbumAssetItem = ({
  asset,
  handleDeleteAlbumAsset,
  deleteAlbumAssetLoading,
  getPlayFileAction,
}: {
  asset: AlbumAsset;
  handleDeleteAlbumAsset: (model: DeleteAlbumAssetModel) => void;
  deleteAlbumAssetLoading: boolean;
  getPlayFileAction: any;
}) => {
  const { id, albumId } = useParams();
  const onDeleteAlbumAsset = () => {
    if (id && albumId) {
      handleDeleteAlbumAsset({ assetId: asset.id, courseId: id, albumId });
    }
  };
  const { t } = useTranslation(LOCALES.COURSE);
  const [deletingAlbumAssetId, setDeletingAlbumAssetId] = useState(-1);

  return (
    <AlbumAssetWrapper>
      <AlbumAssetThumbnailWrapper>
        <img
          onClick={getPlayFileAction(
            {
              ...asset.asset,
            },
            true,
          )}
          src={
            asset.asset.photo && typeof asset.asset.photo === 'string'
              ? asset.asset.photo
              : 'https://wiki.dave.eu/images/4/47/Placeholder.png'
          }
          alt='album'
        />
      </AlbumAssetThumbnailWrapper>

      <PopConfirm
        placement='topRight'
        title={t('deleteAlbumAssetWarning')}
        onConfirm={onDeleteAlbumAsset}
        okText={t('delete')}
        cancelText={t('cancel')}
        okButtonProps={{ loading: deleteAlbumAssetLoading }}
        cancelButtonProps={{ disabled: deleteAlbumAssetLoading }}
        open={deletingAlbumAssetId === asset.id}
        onCancel={() => setDeletingAlbumAssetId(-1)}>
        <Button
          onClick={() => setDeletingAlbumAssetId(asset.id)}
          icon={<DeleteOutlined />}
          shape='circle'
          className='delete-album-file-btn'></Button>
      </PopConfirm>
    </AlbumAssetWrapper>
  );
};

const Details = () => {
  const { album, getAlbumLoading } = useCourseAlbum();
  const { id } = useParams();
  const { t } = useTranslation(LOCALES.COURSE);
  const { handleDeleteAlbumAsset, deleteAlbumAssetLoading } =
    useDeleteAlbumAsset();
  const { getPlayFileAction } = usePlayFile();

  const { downloadFileLoading } = usePlayFile();

  return (
    <Spin spinning={downloadFileLoading}>
      <AlbumDetailsWrapper>
        <div className='album-details-info'>
          <Link to={generatePath(PATHS.app.courses.details.albums, { id })}>
            <Button
              type='primary'
              shape='circle'
              icon={<ArrowLeftOutlined />}
            />
          </Link>

          {album && (
            <p className='album-details__name'>
              {album?.name}{' '}
              <span className='album-details__items'>
                {`(${album?.assets?.length} ${
                  album?.assets?.length && album?.assets?.length > 1
                    ? t('items')
                    : t('item')
                })`}
              </span>
            </p>
          )}
        </div>

        <AlbumAssetGrid>
          {getAlbumLoading ? (
            [1, 2, 3].map(index => (
              <AlbumAssetWrapper key={index}>
                <Skeleton.Image style={{ width: 185, height: 185 }} active />
              </AlbumAssetWrapper>
            ))
          ) : (
            <>
              <AddToAlbumBtn />
              {album?.assets.map((asset, index) => (
                <AlbumAssetItem
                  key={index}
                  asset={asset}
                  handleDeleteAlbumAsset={handleDeleteAlbumAsset}
                  deleteAlbumAssetLoading={deleteAlbumAssetLoading}
                  getPlayFileAction={getPlayFileAction}
                />
              ))}
            </>
          )}
        </AlbumAssetGrid>
      </AlbumDetailsWrapper>
    </Spin>
  );
};

export default Details;

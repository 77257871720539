import { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { Asset } from 'features/asset/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';

const FormItem = Form.Item;

interface EditingFileFormProps {
  handleUpdateAsset: (asset: Asset) => void;
  updateAssetLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  editingFile?: Asset;
}

const EditingFileForm = (props: EditingFileFormProps) => {
  const { t } = useTranslation(LOCALES.ASSET);

  const {
    handleUpdateAsset,
    updateAssetLoading,
    isOpen,
    onClose,
    editingFile,
  } = props;
  const [form] = Form.useForm<Asset>();

  const getInitValue = (asset?: Asset): any => {
    return (
      asset && {
        ...asset,
        tags: asset.tags ? asset.tags.split(',') : [],
      }
    );
  };

  useEffect(() => {
    form.resetFields();

    if (editingFile) {
      form.setFieldsValue(getInitValue(editingFile));
    }
  }, [isOpen, editingFile, form]);

  const onOk = () => {
    onClose();
  };

  const onSubmit = () => {
    if (!editingFile) {
      return;
    }

    form.validateFields().then(values => {
      const asset = {
        ...values,
        sharing: !!values.sharing,
        id: editingFile.id,
      };
      handleUpdateAsset(asset);
    });
  };

  return (
    <Modal
      okButtonProps={{ onClick: onSubmit, loading: updateAssetLoading }}
      cancelButtonProps={{ disabled: updateAssetLoading }}
      title={t('updateFile')}
      open={isOpen}
      onOk={onOk}
      cancelText={t('cancel')}
      onCancel={onClose}
      okText={t('update')}
      className='file-form-modal'>
      <Form
        disabled={updateAssetLoading}
        form={form}
        layout='vertical'
        style={{ marginTop: 30 }}
        initialValues={getInitValue(editingFile)}>
        <FormItem label={t('name')} name='name' rules={[{ required: true }]}>
          <Input />
        </FormItem>

        <FormItem label={t('tags')} name='tags'>
          <Select mode='tags' />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default EditingFileForm;

import React from 'react';
import { Space, Input, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

interface FolderFormProps {
  value: string;
  onChange: (value: string) => void;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const FolderForm = (props: FolderFormProps) => {
  const { value, onChange, loading, onSubmit, onCancel } = props;

  return (
    <div style={{ display: 'flex' }}>
      <Space align='center'>
        <Input
          disabled={loading}
          size='small'
          value={value}
          onChange={e => onChange(e.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              value && onSubmit();
            }
          }}
        />
        <Button
          disabled={loading}
          type='primary'
          danger
          size='small'
          shape='circle'
          icon={<CloseOutlined />}
          onClick={onCancel}
        />
        <Button
          loading={loading}
          type='primary'
          size='small'
          shape='circle'
          icon={<CheckOutlined />}
          onClick={onSubmit}
          disabled={!value}
        />
      </Space>
    </div>
  );
};

export default FolderForm;

import color from 'config/color';
import styled from 'styled-components';

export const SignUpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${color.red};
`;

export const SignUpFormWrapper = styled.div`
  width: 645px;
  border-radius: 50px;
  background-color: #fff;
  padding: 50px;
  min-height: 700px;

  .register-title {
    font-size: 20px;
    font-family: mon-semi-bold;
    text-transform: uppercase;
    margin: 0 !important;
  }

  .register-sub-title {
    font-size: 20px !important;
    line-height: 30px;
    font-family: mon-regular;
    margin: 0 !important;
  }
`;

import { Button, Menu, Skeleton } from 'antd';
import { LOCALES } from 'app/i18n';
import { PageHeader } from 'components/Common/Page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useCreateRole, useRoles } from 'features/role/roleHooks';
import {
  generatePath,
  NavLink,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getClassName } from 'utils/style';
import PATHS from 'router/paths';
import { PlusSquareOutlined } from '@ant-design/icons';
import color from 'config/color';
import RoleForm from './RolePermissions/RoleForm';
import { OWNER_ROLE_NAME } from 'config';

const Wrapper = styled.div`
  width: 250px;
  padding: 24px;
  border-right: 1px solid rgba(5, 5, 5, 0.06);

  .ant-menu-light {
    border-inline-end: none !important;
  }

  .ant-menu-item:has(.active-link) {
    color: #da271c;
    background-color: #fff1ed;

    &::after {
      position: absolute;
      inset-block: 0;
      inset-inline-end: 0;
      border-inline-end: 0px solid #da271c;
      content: '';
    }
  }

  .ant-menu-item:has(.not-active-link) {
    color: rgba(0, 0, 0, 0.88);

    &::after {
      border-width: 0;
      border-bottom-color: transparent;
    }
  }
`;

const RoleMenu = () => {
  const { t } = useTranslation(LOCALES.ROLE);
  const fetchPermissionAlso = true;
  const { roles, getRolesLoading } = useRoles(fetchPermissionAlso);
  const navigate = useNavigate();
  const { roleId } = useParams();

  useEffect(() => {
    if (!roleId && roles.length) {
      navigate(generatePath(PATHS.app.roles.details, { roleId: roles[0].id }));
    }
  }, [roleId, roles, navigate]);

  const [showRoleForm, setShowRoleForm] = useState(false);
  const { createRoleLoading, handleCreateRole } = useCreateRole(() => {
    setShowRoleForm(false);
  });

  return (
    <Wrapper>
      <PageHeader title={t('roles')}>
        <Button
          type='text'
          onClick={() => {
            setShowRoleForm(true);
          }}
          icon={
            <PlusSquareOutlined
              style={{ color: color.primary, fontSize: 16, cursor: 'pointer' }}
            />
          }></Button>
      </PageHeader>

      {getRolesLoading || false ? (
        <div style={{ paddingTop: 5 }}>
          <Skeleton.Input active />
          <Skeleton.Input active style={{ marginTop: 10 }} />
          <Skeleton.Input active style={{ marginTop: 10 }} />
        </div>
      ) : (
        <div>
          {showRoleForm && (
            <div style={{ marginTop: -15, marginBottom: 10 }}>
              <RoleForm
                initValue=''
                onSubmit={handleCreateRole}
                loading={createRoleLoading}
                onCancel={() => setShowRoleForm(false)}
              />
            </div>
          )}

          <Menu
            items={roles.map((role, index) => ({
              key: index,
              disabled: role.name === OWNER_ROLE_NAME,
              label:
                role.name === OWNER_ROLE_NAME ? (
                  role.name
                ) : (
                  <NavLink
                    className={getClassName}
                    to={generatePath(PATHS.app.roles.details, {
                      roleId: role.id,
                    })}
                    style={{ fontWeight: 500 }}>
                    {role.name}
                  </NavLink>
                ),
            }))}></Menu>
        </div>
      )}
    </Wrapper>
  );
};

export default RoleMenu;

import React, { Fragment, useState } from 'react';
import { HasFilterPageContent, PageHeader } from 'components/Common/Page';
import {
  Button,
  Table,
  Dropdown,
  Popconfirm as PopConfirm,
  Space,
  Image,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Course } from 'features/course/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useDeleteCourse,
  useCourses,
  useResetCourseState,
} from 'features/course/courseHooks';
import { getNo } from 'utils';
import CourseForm from 'features/course/CourseForm';
import FilterPanel from 'features/course/Courses/Filter';
import type { MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { COURSE_STATUS } from 'config';
import { Link } from 'react-router-dom';
import { useTableSorting } from 'app/hooks';
import CourseStatus from 'components/Common/CourseStatus';

const initValues = {
  name: undefined,
  id: undefined,
  teacher: undefined,
  status: COURSE_STATUS[0],
  descriptions: undefined,
};

const Courses = () => {
  const { t } = useTranslation(LOCALES.COURSE);

  const { handleSorting, getColumnSorting } = useTableSorting();

  const {
    courses,
    getCoursesLoading,
    paginationConfig,
    refreshAfterCreating,
    refreshAfterDeleting,
  } = useCourses();
  useResetCourseState();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [initialCourse, setInitialCourse] = useState<any>(initValues);

  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);
  const { handleDeleteCourse, deleteCourseLoading } = useDeleteCourse(() => {
    setOpenedPopConfirm(-1);
    refreshAfterDeleting();
  });

  const onOpenCreateForm = () => {
    setIsFormOpen(true);
    setInitialCourse(initValues);
  };

  const onOpenEditForm = (course: Course) => {
    setIsFormOpen(true);
    setInitialCourse({
      ...course,
    });
  };

  const onCloseForm = () => {
    setIsFormOpen(false);
    setInitialCourse(initValues);
  };

  const columns: ColumnsType<Course> = [
    {
      title: t('no'),
      key: 'no',
      render: (_, rc, index) => getNo(paginationConfig, index),
      width: 60,
      align: 'right',
    },
    {
      title: t('name'),
      key: 'name',
      ...getColumnSorting('name'),

      render: record => {
        return (
          <Space>
            <Image
              placeholder={true}
              style={{ objectFit: 'contain' }}
              width={30}
              height={30}
              src={record.photo}
            />
            <Link to={`/courses/${record.id}/details`}>{record.name}</Link>
          </Space>
        );
      },
    },
    {
      title: t('status'),
      key: 'status',
      dataIndex: 'status',
      ...getColumnSorting('status'),
      render: status => <CourseStatus status={status} />,
    },
    {
      title: t('teacher'),
      key: 'teacher',
      dataIndex: 'teacher',
      ...getColumnSorting('teacher'),
    },

    {
      title: t('numberOfStudent'),
      key: 'total_student',
      dataIndex: 'total_student',
      ...getColumnSorting('total_student'),
    },

    { title: t('description'), key: 'description', dataIndex: 'descriptions' },
    {
      title: '',
      width: 50,
      render: (course: Course) => {
        const items: MenuProps['items'] = [
          {
            label: (
              <p
                className='styled-table__action-item'
                onClick={() => onOpenEditForm(course)}>
                {t('update')}
              </p>
            ),
            key: 'Edit',
          },
          { type: 'divider' },
          {
            label: (
              <p
                className='styled-table__action-item color-error'
                onClick={() => setOpenedPopConfirm(course.id)}>
                {t('delete')}
              </p>
            ),
            key: 'Delete',
          },
        ];
        return (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            overlayStyle={{ width: 150 }}
            overlayClassName='styled-table__actions'
            placement='bottomRight'>
            <PopConfirm
              placement='topRight'
              title={t('deleteWarning')}
              onConfirm={() => handleDeleteCourse(course.id)}
              okText={t('delete')}
              cancelText={t('cancel')}
              okButtonProps={{ loading: deleteCourseLoading }}
              cancelButtonProps={{ disabled: deleteCourseLoading }}
              open={openedPopConfirm === course.id}
              onCancel={() => setOpenedPopConfirm(-1)}>
              <Button size='small' type='text'>
                <EllipsisOutlined />
              </Button>
            </PopConfirm>
          </Dropdown>
        );
      },
      key: 'action',
    },
  ];

  return (
    <Fragment>
      <FilterPanel loading={getCoursesLoading} />

      <HasFilterPageContent>
        <PageHeader title={t('classesList')}>
          <Button type='primary' onClick={onOpenCreateForm}>
            {t('create')}
          </Button>
        </PageHeader>

        <Table
          loading={getCoursesLoading}
          dataSource={courses}
          columns={columns}
          pagination={paginationConfig}
          rowKey='id'
          onChange={handleSorting}
        />
      </HasFilterPageContent>

      <CourseForm
        isOpen={isFormOpen}
        onClose={onCloseForm}
        refreshAfterCreating={refreshAfterCreating}
        initValues={initialCourse}
      />
    </Fragment>
  );
};

export default Courses;

import React from 'react';
import FlatFormItem from 'components/Common/FlatFormItem';
import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';

const { Option } = Select;

const PhoneFormItem = ({
  required = false,
  disabled = false,
}: {
  required?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation(LOCALES.LAYOUT);
  const { t: validationT } = useTranslation(LOCALES.VALIDATION);
  return (
    <FlatFormItem
      name='phone_number'
      label={t('phoneNumber')}
      rules={[
        {
          pattern: new RegExp('^\\d{9}$'),
          message: validationT<string>('invalidPhoneNumber'),
        },
        required
          ? {
              required: true,
              message: validationT<string>('pleaseInputPhoneNumber'),
            }
          : {},
      ]}>
      <Input
        disabled={disabled}
        bordered={false}
        addonBefore={
          <FlatFormItem name='phoneNumberPrefix' noStyle>
            <Select style={{ width: 70 }} bordered={false} disabled={disabled}>
              <Option value='+84'>+84</Option>
              <Option value='+1'>+1</Option>
            </Select>
          </FlatFormItem>
        }
        style={{ width: '100%' }}
      />
    </FlatFormItem>
  );
};

export default PhoneFormItem;

import { Table, Image, Space, Typography } from 'antd';
import { Fragment } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { getNo } from 'utils';
import { useBookReaders } from 'features/bookDetails/bookDetailsHooks';
import Filter from './Filter';
import { BookReader } from 'features/bookDetails/types';
import { useTableSorting } from 'app/hooks';

const BookReaders = () => {
  const { t } = useTranslation(LOCALES.ASSET);
  const { handleSorting, getColumnSorting } = useTableSorting();

  const {
    bookReaders,
    getBookReadersLoading,
    bookReadersPaginationConfig,
  } = useBookReaders();

  const columns: ColumnsType<BookReader> = [
    {
      title: t('no'),
      key: 'no',
      render: (_, rc, index) => getNo(bookReadersPaginationConfig, index),
      width: 60,
      align: 'right',
    },
    {
      title: t('name'),
      key: 'name',
      ...getColumnSorting('name'),

      render: record => {
        return (
          <Space>
            <Image
              placeholder={true}
              style={{ objectFit: 'contain' }}
              width={30}
              height={30}
              src={record.avatar}
            />
            <Typography.Text>{record.name}</Typography.Text>
          </Space>
        );
      },
    },
    {
      title: t('email'),
      key: 'email',
      dataIndex: 'email',
      ...getColumnSorting('email'),
    },
  ];

  return (
    <Fragment>
      <Filter />

      <Table
        onChange={handleSorting}
        rowKey='id'
        loading={getBookReadersLoading}
        columns={columns}
        dataSource={bookReaders}
        pagination={bookReadersPaginationConfig}
      />
    </Fragment>
  );
};

export default BookReaders;

import { useState } from 'react';
import { Layout, Row, Button, Skeleton } from 'antd';
import Logo from 'svgs/Logo';
import styled from 'styled-components';
import Account from './Account';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { StyledDropdown } from 'components/Styled/Dropdown';
import {
  useWorkspace,
  useWorkspaces,
  useAuth,
  useCurrentWorkspace,
} from 'features/auth/authHooks';
import PATHS from 'router/paths';
import { useMatch, useNavigate } from 'react-router-dom';
import logoPlaceholder from 'assets/img/logo-placeholder.jpeg';

const { Header } = Layout;

const Wrapper = styled(Header)`
  padding: 0 24px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 4px 4px #00000014;
  z-index: 10;

  .ant-skeleton {
    display: flex !important;
    align-items: center;
    justify-content: center;

    .ant-skeleton-button {
      width: 100px;
      height: 47px;
    }
  }
`;

const LogoWrapper = styled(Row)`
  height: 64px;
  z-index: 100;
  transition: all 0.2s;
`;

const LayoutHeader = (props: any) => {
  const navigate = useNavigate();
  const { workspace: currentWorkspaceName } = useAuth();
  const { currentWorkspace, getWorkspacesLoading } = useCurrentWorkspace();
  const { isWorkspacesPage, setCollapsed, collapsed } = props;
  const [displayLogo, setDisplayLogo] = useState(true);
  const onSelectWorkSpace = useWorkspace();
  const { workspaces } = useWorkspaces();

  const isCreatingAlbumPage = useMatch(PATHS.app.albumForm.create);
  const isUpdatingAlbumPage = useMatch(PATHS.app.albumForm.edit);
  const isAlbumForm = isCreatingAlbumPage || isUpdatingAlbumPage;

  const goToWorkspaces = () => {
    onSelectWorkSpace('');
    navigate(PATHS.account.root);
  };

  const workspaceOptions = workspaces.map(({ workspace }) => ({
    label: (
      <p
        className={`workspace-option ${
          workspace === currentWorkspaceName && 'workspace-option--active'
        }`}
        onClick={() => onSelectWorkSpace(workspace)}>
        {workspace}
      </p>
    ),
    key: workspace,
  }));

  return (
    <Wrapper>
      {isWorkspacesPage ? (
        <Logo style={{ width: '100px' }} />
      ) : (
        <LogoWrapper
          align='middle'
          justify='center'
          style={{
            minWidth: collapsed ? 56 : 176,
            marginRight: collapsed ? 0 : 55,
          }}>
          <Button
            style={{ marginRight: collapsed ? 0 : 20 }}
            type='text'
            onClick={() => {
              setCollapsed(!collapsed);
              setTimeout(
                () => {
                  setDisplayLogo(!displayLogo);
                },
                displayLogo ? 0 : 200,
              );
            }}
            icon={
              collapsed ? (
                <MenuUnfoldOutlined
                  style={{ fontWeight: 'bold', fontSize: 16 }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ fontWeight: 'bold', fontSize: 16 }}
                />
              )
            }
          />
          {displayLogo ? (
            getWorkspacesLoading ? (
              <Skeleton.Button active />
            ) : (
              <img
                src={(currentWorkspace?.logo as string) || logoPlaceholder}
                alt='workspace logo'
                style={{
                  width: 100,
                  height: 47,
                  objectFit: 'contain',
                  border: 'none',
                }}
              />
            )
          ) : null}
        </LogoWrapper>
      )}
      <Row
        style={{ width: '100%', paddingLeft: 30 }}
        justify='space-between'
        align='middle'>
        {!isAlbumForm && currentWorkspaceName ? (
          <StyledDropdown
            title='Organization'
            items={workspaceOptions}
            text={currentWorkspaceName}
            onClickTitle={goToWorkspaces}
          />
        ) : (
          <div></div>
        )}

        <Account />
      </Row>
    </Wrapper>
  );
};

export default LayoutHeader;

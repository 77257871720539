import { Fragment } from 'react';
import { Modal, Skeleton, Image, Typography } from 'antd';
import { LOCALES } from 'app/i18n';
import { QRCode } from 'types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';

const CodeWrapper = styled.div`
  width: 100%;
  height: 400px;
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  .title-skeleton {
    margin-bottom: 10px;

    .ant-skeleton-content {
      display: flex;
      justify-content: center;
    }

    .ant-skeleton-title {
      width: 100px !important;
      height: 28px;
    }
  }

  .ant-skeleton-image,
  .ant-image-img {
    width: 300px !important;
    height: 300px !important;
  }
`;

const getImageBase64 = (base64?: string) => {
  return `data:image/png;base64,${base64}`;
};

const getDownloadLink = (base64?: string) => {
  return `data:application/octet-stream;base64,${base64}`;
};

const QRCodeModal = ({
  isOpen,
  onClose,
  isLoading,
  qrCode,
  title,
}: {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  qrCode?: QRCode;
  title: string;
}) => {
  const { t } = useTranslation(LOCALES.LAYOUT);

  const downloadElement = (
    <Typography.Link
      download={`${qrCode?.code}.png`}
      style={{ marginTop: 10, fontSize: 20, lineHeight: '30px' }}
      href={getDownloadLink(qrCode?.image_base64)}>
      <span>
        <DownloadOutlined />
      </span>{' '}
      {t('download')}
    </Typography.Link>
  );
  return (
    <Modal footer={false} title={title} open={isOpen} onCancel={onClose}>
      <CodeWrapper>
        {isLoading ? (
          <Fragment>
            <Skeleton className='title-skeleton' paragraph={false} active />
            <Skeleton.Image active />

            {downloadElement}
          </Fragment>
        ) : (
          <Fragment>
            <Typography.Title
              level={4}
              style={{
                marginTop: 0,
                marginBottom: 10,
                textAlign: 'center',
              }}>
              {qrCode?.code}
            </Typography.Title>
            <Image preview={false} src={getImageBase64(qrCode?.image_base64)} />

            {downloadElement}
          </Fragment>
        )}
      </CodeWrapper>
    </Modal>
  );
};

export default QRCodeModal;

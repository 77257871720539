import { useState, useEffect } from 'react';
import { Table, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Student } from 'features/student/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useStudentsModal,
  useResetStudentState,
  STUDENT_MODAL_QUERY_PARAMS,
} from 'features/student/studentHooks';
import { getNo } from 'utils';
import FilterPanel from './Filter';
import { useTableSorting } from 'app/hooks';
import { TableRowSelection } from 'antd/es/table/interface';

const StudentsModal = ({
  isOpen,
  onCancel,
  addingLoading,
  onSubmit,
  addingSuccess,
  initValues = [],
  isAcceptEmpty = false,
}: {
  isOpen: boolean;
  onCancel: () => void;
  addingLoading: boolean;
  onSubmit: (ids: number[]) => void;
  addingSuccess?: any;
  initValues?: number[];
  isAcceptEmpty?: boolean;
}) => {
  const { t } = useTranslation(LOCALES.STUDENT);

  const { handleSorting, getColumnSorting } = useTableSorting(
    STUDENT_MODAL_QUERY_PARAMS.ORDERING,
  );

  const { students, getStudentsLoading, paginationConfig, goToFirstPage } =
    useStudentsModal(isOpen);
  useResetStudentState();

  const [selectedStudentIds, setSelectedStudentIds] = useState<any[]>([]);

  useEffect(() => {
    if (isOpen) {
      setSelectedStudentIds(initValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const columns: ColumnsType<Student> = [
    {
      title: t('no'),
      key: 'no',
      render: (_, rc, index) => getNo(paginationConfig, index),
      width: 60,
      align: 'right',
    },
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      ...getColumnSorting('name'),
    },
    {
      title: t('englishName'),
      key: 'english_name',
      dataIndex: 'english_name',
      ...getColumnSorting('english_name'),
    },
    {
      title: t('phoneNumber'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      ...getColumnSorting('phone_number'),
    },
    {
      title: t('dateOfBirth'),
      key: 'dob',
      dataIndex: 'dob',
      ...getColumnSorting('dob'),
    },
  ];

  const rowSelection: TableRowSelection<Student> = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedStudentIds(selectedRowKeys);
    },

    getCheckboxProps: asset => ({
      disabled: asset.disabled,
      className: asset.disabled ? 'disabled-selection-row' : '',
    }),
  };

  useEffect(() => {
    if (addingSuccess) {
      goToFirstPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingSuccess]);

  return (
    <Modal
      open={isOpen}
      width={1100}
      onCancel={onCancel}
      onOk={() => onSubmit(selectedStudentIds)}
      cancelButtonProps={{ disabled: addingLoading }}
      okButtonProps={{
        loading: addingLoading,
        disabled: !isAcceptEmpty && !selectedStudentIds.length,
      }}
      okText={t('add')}
      cancelText={t('cancel')}>
      <FilterPanel loading={getStudentsLoading} />

      <Table
        rowSelection={{
          ...rowSelection,
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedStudentIds,
        }}
        loading={getStudentsLoading}
        dataSource={students}
        columns={columns}
        pagination={paginationConfig}
        rowKey='id'
        onChange={handleSorting}
      />
    </Modal>
  );
};

export default StudentsModal;

import { Row, Button } from 'antd';
import { LOCALES } from 'app/i18n';
import { SmallFilterPanel } from 'components/Common/Page';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/Common/SearchBox';

const Filter = ({ openAssetsModal }: { openAssetsModal: () => void }) => {
  const { t } = useTranslation(LOCALES.ASSET);

  return (
    <SmallFilterPanel>
      <Row justify='space-between'>
        <SearchBox style={{ marginBottom: 0 }} />

        <Button
          onClick={openAssetsModal}
          type='primary'
          style={{ marginLeft: 50 }}>
          {t('addAsset')}
        </Button>
      </Row>
    </SmallFilterPanel>
  );
};

export default Filter;

const en = {
  no: 'No',
  create: 'Create',
  cancel: 'Cancel',
  update: 'Update',
  createAssets: 'Create assets',
  updateAssets: 'Update assets',
  add: 'Add',
  addAsset: 'Add Asset',
  assets: 'Assets',
  move: 'Move',
  ok: 'Ok',

  tags: 'Tags',
  name: 'Name',
  deleteWarning: 'Are you sure want to delete this asset?',
  delete: 'Delete',

  search: 'Search',
  enterName: 'Enter Name',
  enterTag: 'Enter Tag',
  keyword: 'Keyword',
  enterKeyword: 'Enter Keyword',

  files: 'Files',
  createFile: 'Create File',
  folder: 'Folder',
  createFolder: 'Create Folder',
  loadMore: 'Load more...',
  lastModified: 'Last Modified',
  invitationCode: 'Invitation Code',
  createdAt: 'Created at',
  public: 'Public',

  updateFolder: 'Update folder',
  updateFile: 'Update file',
  uploadFolder: 'Upload folder',
  uploadFile: 'Upload file',
  deleteFile: 'Delete file',
  deleteFolder: 'Delete folder',
  upload: 'Upload',
  createSubfolder: 'Create Subfolder',

  fileCode: 'File Code',
  uploadManager: 'Upload manager',
};

export default en;

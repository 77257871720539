import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import checkInAPI from './checkInAPI';
import {
  BaseCheckIn,
  CheckIn,
  CheckInState,
  GetCheckInsQueryParams,
} from './types';
import { resetStoreAction } from 'config';
import { ListResponse } from 'types';

export const getCheckIns = createAppAsyncThunk(
  'checkIn/getCheckIns',
  async (params: GetCheckInsQueryParams, { rejectWithValue }) => {
    try {
      const response: ListResponse<CheckIn> = await checkInAPI.getCheckIns(
        params,
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createCheckIn = createAppAsyncThunk(
  'checkIn/createCheckIn',
  async (checkIn: BaseCheckIn, { rejectWithValue }) => {
    try {
      const response = await checkInAPI.createCheckIn(checkIn);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateCheckIn = createAppAsyncThunk(
  'checkIn/updateCheckIn',
  async (checkIn: CheckIn, { rejectWithValue }) => {
    try {
      const response = await checkInAPI.updateCheckIn(checkIn);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteCheckIn = createAppAsyncThunk(
  'checkIn/deleteCheckIn',
  async (id: number, { rejectWithValue }) => {
    try {
      await checkInAPI.deleteCheckIn(id);

      return id;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: CheckInState = {
  getCheckInsLoading: false,
  checkIns: [],
  getCheckInsFailed: undefined,
  paginationConfig: {},

  createCheckInLoading: false,
  createCheckInSuccess: undefined,
  createCheckInFailed: undefined,

  updateCheckInLoading: false,
  updateCheckInSuccess: undefined,
  updateCheckInFailed: undefined,

  deleteCheckInLoading: false,
  deleteCheckInSuccess: undefined,
  deleteCheckInFailed: undefined,
};

export const checkInSlice = createSlice({
  name: 'checkIn',
  initialState,
  reducers: {
    resetCheckInState() {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getCheckIns.pending, (state, action) => {
        const {
          meta: {
            arg: { page, page_size },
          },
        } = action;

        state.getCheckInsLoading = true;
        state.paginationConfig.current = Number(page);
        state.paginationConfig.pageSize = Number(page_size);
      })
      .addCase(getCheckIns.fulfilled, (state, action) => {
        state.getCheckInsLoading = false;
        state.checkIns = action.payload.results;
        state.paginationConfig.total = action.payload.count;
      })
      .addCase(getCheckIns.rejected, (state, action) => {
        state.getCheckInsLoading = false;
        state.getCheckInsFailed = action.payload;
      })

      .addCase(createCheckIn.pending, state => {
        state.createCheckInLoading = true;
        state.createCheckInFailed = undefined;
      })
      .addCase(createCheckIn.fulfilled, (state, action) => {
        state.createCheckInLoading = false;
        state.createCheckInSuccess = action.payload;
      })
      .addCase(createCheckIn.rejected, (state, action) => {
        state.createCheckInLoading = false;
        state.createCheckInFailed = action.payload;
      })

      .addCase(updateCheckIn.pending, state => {
        state.updateCheckInLoading = true;
        state.updateCheckInFailed = undefined;
      })
      .addCase(updateCheckIn.fulfilled, (state, { payload }) => {
        state.updateCheckInLoading = false;
        state.updateCheckInSuccess = payload;

        state.checkIns = state.checkIns.map(checkIn =>
          checkIn.id !== payload.id ? checkIn : payload,
        );
      })
      .addCase(updateCheckIn.rejected, (state, action) => {
        state.updateCheckInLoading = false;
        state.updateCheckInFailed = action.payload;
      })

      .addCase(deleteCheckIn.pending, state => {
        state.deleteCheckInLoading = true;
        state.deleteCheckInFailed = undefined;
      })
      .addCase(deleteCheckIn.fulfilled, (state, action) => {
        state.deleteCheckInLoading = false;
        state.deleteCheckInSuccess = action.payload;
      })
      .addCase(deleteCheckIn.rejected, (state, action) => {
        state.deleteCheckInLoading = false;
        state.deleteCheckInFailed = action.payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const { resetCheckInState } = checkInSlice.actions;

export default checkInSlice.reducer as Reducer<CheckInState>;

import { useEffect, useMemo } from 'react';
import { Form, Input, Row, Button, Select } from 'antd';
import { LOCALES } from 'app/i18n';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { BaseCheckIn } from './types';
import { useCreateCheckIn, useUpdateCheckIn } from './checkInHooks';
import FormModal from 'components/Styled/FormModal';
import { CHECK_IN_TYPE_OPTIONS } from 'config';
import { Staff } from 'features/staff/types';
import { Branch } from 'features/branch/types';

interface CheckInFormProps {
  isOpen: boolean;
  onClose: () => void;
  initValues: BaseCheckIn;
  refreshAfterCreating: () => void;
  staffs: Staff[];
  branches: Branch[];
}

const filterOption = (
  input: string,
  option?: { label: string; value: number },
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const CheckInForm = (props: CheckInFormProps) => {
  const {
    isOpen,
    onClose,
    initValues,
    refreshAfterCreating,
    staffs,
    branches,
  } = props;
  const { t } = useTranslation(LOCALES.CHECK_IN);
  const [form] = Form.useForm<BaseCheckIn>();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, form]);

  const onCreateSuccess = () => {
    onCancel();
    refreshAfterCreating();
  };

  const { handleCreateCheckIn, createCheckInLoading } =
    useCreateCheckIn(onCreateSuccess);

  const { handleUpdateCheckIn, updateCheckInLoading } =
    useUpdateCheckIn(onCancel);

  const staffOptions = useMemo(() => {
    return staffs.map(staff => ({ value: staff.id, label: staff.full_name }));
  }, [staffs]);

  const branchesOptions = useMemo(() => {
    return branches.map(branch => ({ value: branch.id, label: branch.name }));
  }, [branches]);

  const isLoading = createCheckInLoading || updateCheckInLoading;

  const onFinish = (values: any) => {
    if (initValues.id) {
      handleUpdateCheckIn({ ...values, id: initValues.id });
    } else {
      handleCreateCheckIn(values);
    }
  };

  return (
    <FormModal
      width={700}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
      title={initValues.id ? t('updateCheckIn') : t('createCheckIn')}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        layout='vertical'>
        <FlatFormItem
          style={{ marginTop: 30 }}
          label={t('staff')}
          name='staff'
          rules={[{ required: true, message: t<string>('nameIsRequired') }]}>
          <Select
            showSearch
            filterOption={filterOption}
            bordered={false}
            className='flat-select'
            options={staffOptions}
          />
        </FlatFormItem>

        <FlatFormItem
          label={t('branch')}
          name='branch'
          rules={[{ required: true, message: t<string>('branchIsRequired') }]}>
          <Select
            showSearch
            bordered={false}
            className='flat-select'
            filterOption={filterOption}
            options={branchesOptions}></Select>
        </FlatFormItem>

        <FlatFormItem
          label={t('type')}
          name='type'
          rules={[{ required: true, message: t<string>('typeIsRequired') }]}>
          <Select bordered={false} className='flat-select'>
            {CHECK_IN_TYPE_OPTIONS.map(type => (
              <Select.Option key={type.value} value={type.value}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </FlatFormItem>

        <FlatFormItem label={t('notes')} name='notes' style={{ marginTop: 30 }}>
          <Input bordered={false} />
        </FlatFormItem>

        <Row justify='center' style={{ marginTop: 40, marginBottom: 10 }}>
          <Button
            disabled={isLoading}
            style={{ marginRight: 10 }}
            onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
          <Button
            loading={isLoading}
            htmlType='submit'
            style={{ marginLeft: 10 }}
            type='primary'>
            {initValues.id ? t('update') : t('create')}
          </Button>
        </Row>
      </Form>
    </FormModal>
  );
};

export default CheckInForm;

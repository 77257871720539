import { Image } from 'antd';
import { useAssetViewer, useAssetViewerUrl } from 'features/asset/assetHooks';

const ImageModal = () => {
  const { isImageModalOpen, onCloseAssetViewer, downloadedFile } =
    useAssetViewer();
  const url = useAssetViewerUrl(isImageModalOpen, downloadedFile);

  if (!isImageModalOpen) {
    return <div style={{ width: 0, height: 0 }}></div>;
  }

  return (
    <Image
      width={0}
      height={0}
      src={url}
      preview={{
        visible: isImageModalOpen,
        onVisibleChange: () => onCloseAssetViewer(),
      }}
    />
  );
};

export default ImageModal;

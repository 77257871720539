import { Typography, Row, Progress, Space, Button } from 'antd';
import { useUploadManager } from 'features/asset/assetHooks';
import styled from 'styled-components';
import {
  DownCircleOutlined,
  CloseOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import FileExtensionIcon from 'components/Common/FileExtensionIcon';

const Wrapper = styled.div`
  background-color: #fff;
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 1001;
  overflow: visible;
  opacity: 0;
  visibility: hidden;
  display: block;
  transform: translate3d(0, 24px, 0);
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  border-radius: 12px 12px 0 0;
  width: 365px;

  &.active {
    bottom: 0px;
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const UploadHeader = styled.div`
  border-radius: 12px 12px 0 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 12px;
  margin-left: 0;
`;

const UploadContent = styled.div`
  padding: 12px 0;
  max-height: 500px;
  overflow: auto;
`;

const UploadItem = styled(Row)`
  width: 100%;
  height: 44px;
  padding: 0 12px;

  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

const UploadManager = () => {
  const { uploadingFiles, filesQueue, widgetStatus, setWidgetStatus } =
    useUploadManager();
  const { t } = useTranslation(LOCALES.ASSET);
  const { t: tMessage } = useTranslation(LOCALES.MESSAGE);

  return (
    <Wrapper className={widgetStatus !== 'hidden' ? 'active' : ''}>
      <UploadHeader>
        <Typography.Title style={{ marginBottom: 0 }} level={5}>
          {t('uploadManager')}
        </Typography.Title>

        <Space style={{ marginLeft: 'auto' }}>
          {widgetStatus === 'active' ? (
            <Button
              onClick={() => setWidgetStatus('collapsed')}
              type='text'
              icon={<DownCircleOutlined style={{ fontSize: 20 }} />}></Button>
          ) : (
            <Button
              onClick={() => setWidgetStatus('active')}
              type='text'
              icon={<UpCircleOutlined style={{ fontSize: 20 }} />}></Button>
          )}

          <Button
            onClick={() => setWidgetStatus('hidden')}
            type='text'
            icon={<CloseOutlined style={{ fontSize: 20 }} />}></Button>
        </Space>
      </UploadHeader>
      <UploadContent
        style={{ display: widgetStatus === 'active' ? 'block' : 'none' }}>
        {[...uploadingFiles, ...filesQueue].map(file => (
          <UploadItem align='middle' justify='space-between' key={file.uid}>
            <FileExtensionIcon extension={file.type} size='large' />

            <div style={{ width: '70%' }}>
              <Typography.Text
                style={{
                  margin: 0,
                  color:
                    file.status === 'failed'
                      ? '#ff4d4f'
                      : file.status === 'queue'
                      ? 'rgba(0,0,0,.25)'
                      : 'inherit',
                }}
                ellipsis>
                {' '}
                {file.name}
              </Typography.Text>

              {file.errorMessage && (
                <Typography.Text
                  style={{
                    margin: 0,
                    display: 'block',
                    color: '#ff4d4f',
                    fontSize: 12,
                  }}>
                  {tMessage(file.errorMessage)}
                </Typography.Text>
              )}
            </div>

            <Progress
              size={25}
              showInfo
              type='circle'
              percent={file.progress}
              status={
                file.status === 'failed'
                  ? 'exception'
                  : file.status === 'succeeded'
                  ? 'success'
                  : 'active'
              }
            />
          </UploadItem>
        ))}
      </UploadContent>
    </Wrapper>
  );
};

export default UploadManager;

import { useWorkspace } from 'features/auth/authHooks';
import { Card } from 'antd';

const { Meta } = Card;

const WorkspaceCard = ({ workspace, name, email, logo }: any) => {
  const onSelectWorkspace = useWorkspace();

  return (
    <Card
      hoverable
      onClick={() => onSelectWorkspace(workspace)}
      style={{ width: 200, height: 257 }}>
      <div style={{ width: 150, height: 150, marginBottom: 10 }}>
        <img
          src={logo}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          alt='logo'
        />
      </div>
      <Meta title={name} description={email} />
    </Card>
  );
};

export default WorkspaceCard;

const en = {
  checkIns: 'Danh sách check in',
  branch: 'Chi nhánh',
  staff: 'Nhân viên',
  type: 'Loại',
  notes: 'Ghi chú',
  time: 'Thời gian',
  check_in: 'Check in',
  check_out: 'Check out',

  no: 'STT',
  create: 'Tạo mới',
  cancel: 'Huỷ',
  update: 'Cập nhật',

  add: 'Thêm',
  name: 'Tên',

  updateCheckIn: 'Cập nhật check in',
  createCheckIn: 'Tạo check in',

  deleteWarning: 'Bạn có chắc muốn xóa check in này không?',
  delete: 'Xoá',
  keyword: 'Từ khoá',
  enterKeyword: 'Nhập từ khoá',
  search: 'Tìm kiếm',
  nameIsRequired: 'Vui lòng nhập tên check in',
  locationIsRequired: 'Vui lòng nhập vị trí',
  addressIsRequired: 'Vui lòng nhập địa chỉ',

  location: 'Vị trí',
  address: 'Địa chỉ',
  photo: 'Ảnh',
  selectBranch: 'Chọn chi nhánh',
  selectStaff: 'Chọn nhân viên',
};

export default en;

import { useState, useEffect } from 'react';
import { Space, Input, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useCreateStaff, useUpdateStaff } from 'features/staff/staffHooks';
import { Staff } from 'features/staff/types';
import { useParams } from 'react-router-dom';
import { NEW_RECORD_ID } from 'config';

interface StaffFormProps {
  initValue: Staff;
  onCancel: () => void;
  refresh: () => void;
}

const StaffForm = (props: StaffFormProps) => {
  const { initValue, onCancel, refresh } = props;
  const [name, setName] = useState(initValue?.full_name);
  const { handleCreateStaff, createStaffLoading } = useCreateStaff(refresh);
  const { handleUpdateStaff, updateStaffLoading } = useUpdateStaff(onCancel);

  const { roleId } = useParams();

  useEffect(() => {
    setName(initValue?.full_name);
  }, [initValue]);

  const onSubmit = () => {
    const { id } = initValue;
    if (id && id !== NEW_RECORD_ID) {
      handleUpdateStaff({ id, full_name: name });
    } else {
      handleCreateStaff({ full_name: name, roles: [roleId ?? ''] });
    }
  };

  const isLoading = createStaffLoading || updateStaffLoading;

  return (
    <div style={{ display: 'flex' }}>
      <Space align='center'>
        <Input
          disabled={isLoading}
          size='small'
          value={name}
          onChange={e => setName(e.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              name && onSubmit();
            }
          }}
        />
        <Button
          disabled={isLoading}
          type='primary'
          danger
          size='small'
          shape='circle'
          icon={<CloseOutlined />}
          onClick={onCancel}
        />
        <Button
          loading={isLoading}
          type='primary'
          size='small'
          shape='circle'
          icon={<CheckOutlined />}
          onClick={onSubmit}
          disabled={!name}
        />
      </Space>
    </div>
  );
};

export default StaffForm;

import { Button, Form, Input, Skeleton, Popconfirm as PopConfirm } from 'antd';
import {
  ArrowLeftOutlined,
  FileImageOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import PATHS from 'router/paths';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { RcFile } from 'antd/es/upload';
import { AlbumAssetWrapper, AlbumAssetsGrid, Wrapper } from './Styled';
import FileUploader from 'components/Uploader/FileUploader';
import { useEffect, useRef, useState } from 'react';
import {
  useAddAlbumAssetBase64,
  useCourseAlbum,
  useDeleteAlbum,
  useDeleteAlbumAsset,
  useUpdateAlbum,
  useUploadAlbumAsset,
} from 'features/course/courseHooks/courseAlbumHooks';
import {
  AlbumAsset as AlbumAssetType,
  CreateAlbumModel,
  DeleteAlbumAssetModel,
} from 'features/course/types';
import AlbumAssetsModal from './AlbumAssetsModal';
import { getBase64 } from 'utils/assets';

export const AlbumAsset = ({
  asset,
  handleDeleteAlbumAsset,
  deleteAlbumAssetLoading,
}: {
  handleDeleteAlbumAsset: (model: DeleteAlbumAssetModel) => void;
  deleteAlbumAssetLoading: boolean;
  asset: AlbumAssetType;
}) => {
  const { id, albumId } = useParams();

  const onDeleteAlbumAsset = () => {
    if (id && albumId) {
      handleDeleteAlbumAsset({
        courseId: id,
        albumId,
        assetId: asset.id,
      });
    }
  };

  const [isPopConfirmOpen, setIsPopConfirmOpen] = useState(-1);

  const { t } = useTranslation(LOCALES.COURSE);

  return (
    <AlbumAssetWrapper className='album-file'>
      <PopConfirm
        placement='topRight'
        title={t('deleteAlbumAssetWarning')}
        onConfirm={onDeleteAlbumAsset}
        okText={t('delete')}
        cancelText={t('cancel')}
        okButtonProps={{ loading: deleteAlbumAssetLoading }}
        cancelButtonProps={{ disabled: deleteAlbumAssetLoading }}
        open={isPopConfirmOpen === asset.id}
        onCancel={() => setIsPopConfirmOpen(-1)}>
        <Button
          style={{ zIndex: 1000 }}
          onClick={() => setIsPopConfirmOpen(asset.id)}
          shape='circle'
          icon={<DeleteOutlined />}
          className='delete-album-file-btn'
        />
      </PopConfirm>
      <div className='album-file__image-box'>
        <img
          className='album-file__image'
          alt='placeholder'
          src='https://wiki.dave.eu/images/4/47/Placeholder.png'
        />
      </div>

      <div className='album-file__info'>
        <p className='album-file__name'>{asset.name}</p>
      </div>
    </AlbumAssetWrapper>
  );
};

const EditAlbumForm = () => {
  const { id, albumId } = useParams();
  const { album, getAlbumLoading } = useCourseAlbum(true);
  const { t } = useTranslation(LOCALES.COURSE);
  const [form] = Form.useForm();
  const { uploadAlbumAssetLoading, handleUploadAlbumAsset, albumFilesQueue } =
    useUploadAlbumAsset();

  const { updateAlbumLoading, handleUpdateAlbum } = useUpdateAlbum();
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);

  const fileUploadRef = useRef<any>(null);
  const addAlbumAssetBase64 = useAddAlbumAssetBase64();

  const onUpload = (file: RcFile) => {
    setIsAssetModalOpen(true);
    handleUploadAlbumAsset({ uid: file.uid, file });
    getBase64(file, base64 => {
      addAlbumAssetBase64({ uid: file.uid, base64 });
    });
  };

  const triggerUpload = () => {
    fileUploadRef.current?.click();
  };

  const handleSubmit = () => {
    if (id && albumId) {
      form.validateFields().then((album: CreateAlbumModel) => {
        handleUpdateAlbum({ updatedAlbum: album, courseId: id, albumId });
      });
    }
  };

  const [isFormChanged, setIsFormChanged] = useState(false);

  const checkFormValue = () => {
    const name = form.getFieldValue('name');
    const descriptions = form.getFieldValue('descriptions');
    const isChanged =
      name !== album?.name || descriptions !== album?.descriptions;

    if (isChanged && !isFormChanged) {
      setIsFormChanged(true);
    }

    if (!isChanged && isFormChanged) {
      setIsFormChanged(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: album?.name ?? '',
      descriptions: album?.descriptions,
    });

    checkFormValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, album]);

  const { handleDeleteAlbumAsset, deleteAlbumAssetLoading } =
    useDeleteAlbumAsset();

  const [isPopConfirmOpen, setIsPopConfirmOpen] = useState(false);
  const navigate = useNavigate();

  const goToAlbumsPage = () => {
    navigate(generatePath(PATHS.app.courses.details.albums, { id }));
  };
  const { handleDeleteAlbum, deleteAlbumLoading } =
    useDeleteAlbum(goToAlbumsPage);

  const onConfirmDeleteAlbum = () => {
    if (id && albumId) {
      handleDeleteAlbum({ courseId: id, albumId });
    }
  };

  return (
    <Wrapper>
      <div className='album-form-left'>
        <div className='album-form-left__header'>
          <Link
            to={generatePath(PATHS.app.courses.details.albums, {
              id,
            })}>
            <Button
              type='primary'
              shape='circle'
              icon={<ArrowLeftOutlined />}
              size={'large'}
            />
          </Link>
        </div>

        <h3 className='album-form-left__title'>{t('updateAlbum')}</h3>

        <Form
          onChange={checkFormValue}
          className='album-form'
          layout='vertical'
          form={form}
          disabled={updateAlbumLoading}>
          <Form.Item
            shouldUpdate={true}
            name='name'
            label={t('albumName')}
            required
            rules={[
              { required: true, message: t<string>('albumNameIsRequired') },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            shouldUpdate={true}
            name='descriptions'
            label={t('description')}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button
              icon={<FileImageOutlined />}
              disabled={uploadAlbumAssetLoading || updateAlbumLoading}
              onClick={() => triggerUpload()}
              size='large'
              className='album-form__upload-btn'>
              {t('uploadPhotosOrVideos')}
            </Button>

            <FileUploader ref={fileUploadRef} onUpload={onUpload} />
          </Form.Item>

          <Form.Item>
            <PopConfirm
              placement='top'
              title={t('deleteAlbumWarning')}
              onConfirm={onConfirmDeleteAlbum}
              okText={t('delete')}
              cancelText={t('cancel')}
              okButtonProps={{ loading: deleteAlbumLoading }}
              cancelButtonProps={{ disabled: deleteAlbumLoading }}
              open={isPopConfirmOpen}
              onCancel={() => setIsPopConfirmOpen(false)}>
              <Button
                icon={<DeleteOutlined />}
                disabled={uploadAlbumAssetLoading || updateAlbumLoading}
                onClick={() => setIsPopConfirmOpen(true)}
                size='large'
                className='album-form__delete-btn'>
                {t('deleteAlbum')}
              </Button>
            </PopConfirm>
          </Form.Item>
        </Form>

        <div className='album-form-left__footer'>
          <Button
            onClick={handleSubmit}
            disabled={
              uploadAlbumAssetLoading ||
              !isFormChanged ||
              !!albumFilesQueue.length
            }
            loading={updateAlbumLoading}
            type='primary'
            size='large'
            className='album-form__submit-btn'>
            {t('submit')}
          </Button>
        </div>
      </div>

      <div className='album-form-right'>
        <AlbumAssetsGrid>
          {getAlbumLoading
            ? [1, 2, 3].map(index => (
                <AlbumAssetWrapper key={index}>
                  <Skeleton.Image style={{ width: 300, height: 300 }} active />
                </AlbumAssetWrapper>
              ))
            : album?.assets.map((asset, index) => (
                <AlbumAsset
                  key={index}
                  asset={asset}
                  handleDeleteAlbumAsset={handleDeleteAlbumAsset}
                  deleteAlbumAssetLoading={deleteAlbumAssetLoading}
                />
              ))}
        </AlbumAssetsGrid>
      </div>

      <AlbumAssetsModal
        isOpen={isAssetModalOpen}
        triggerUpload={triggerUpload}
        onClose={() => setIsAssetModalOpen(false)}
      />
    </Wrapper>
  );
};

export default EditAlbumForm;

import { useEffect, useMemo, useState } from 'react';
import {
  UploadingFileModel,
  UseCreateAssetReturnType,
  UseUpdateAssetReturnType,
  UseDeleteAssetReturnType,
  Asset,
  WidgetStatus,
  UseUploadManagerReturnType,
  UseGetAssetInvitationCodeReturnType,
} from './types';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  getAssets,
  createAsset,
  updateAsset,
  deleteAsset,
  resetAssetState,
  setWidgetStatus,
  getAssetInvitationCode,
  getRoleAssets,
  resetRoleAssets,
  getAssetOptions,
  downloadFile,
  closeAssetViewer,
} from './assetSlice';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';
import { DEFAULT_PAGING } from 'config';
import { findParentFolder } from 'utils/assets';
import { useSearchParams, useMatch } from 'react-router-dom';
import PATHS from 'router/paths';
import { useRoleResourceIds } from 'features/role/roleHooks';
import { PERMISSIONS_RESOURCES } from 'config/permissions';
import { isPlayableFile } from 'utils';
import color from 'config/color';
import { read, utils } from 'xlsx';

export const ASSET_QUERY_PARAMS = {
  NAME: 'name',
  TAGS: 'tags',
  SEARCH: 'search',
};

export const ASSET_MODAL_QUERY_PARAMS = {
  NAME: 'assetName',
  TAGS: 'assetTags',
  SEARCH: 'assetSearch',
  PAGE: 'assetPage',
  PAGE_SIZE: 'assetPageSize',
  ORDERING: 'assetOrdering',
};

const { NAME, TAGS, SEARCH, PAGE, PAGE_SIZE, ORDERING } =
  ASSET_MODAL_QUERY_PARAMS;

const ASSET_REAL_KEY = {
  [NAME]: ASSET_QUERY_PARAMS.NAME,
  [TAGS]: ASSET_QUERY_PARAMS.TAGS,
  [SEARCH]: ASSET_QUERY_PARAMS.SEARCH,
  [PAGE]: 'page',
  [PAGE_SIZE]: 'page_size',
  [ORDERING]: 'ordering',
};

const useExcludedAssetsParams = () => {
  const matchCourseDocumentPath = useMatch(PATHS.app.courses.details.document);
  const matchBookPath = useMatch(PATHS.app.books.details.document);
  const matchPlaylistPath = useMatch(PATHS.app.playlist.details);

  return useMemo(() => {
    return {
      not_in_course_materials:
        matchCourseDocumentPath && matchCourseDocumentPath.params.id,
      not_in_book_materials: matchBookPath && matchBookPath.params.id,
      not_in_playlist: matchPlaylistPath && matchPlaylistPath.params.id,
    };
  }, [matchCourseDocumentPath, matchBookPath, matchPlaylistPath]);
};

export const useAssetsModalUrlQueryParams = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get(SEARCH) ?? undefined;
  const page = searchParams.get(PAGE) ?? DEFAULT_PAGING.PAGE;
  const pageSize = searchParams.get(PAGE_SIZE) ?? DEFAULT_PAGING.PAGE_SIZE;

  const excludedParams = useExcludedAssetsParams();

  const urlQueryParams = useMemo(() => {
    return [NAME, TAGS, ORDERING].reduce(
      (currentParams, paramKey) => ({
        ...currentParams,
        [ASSET_REAL_KEY[paramKey]]: searchParams.get(paramKey),
      }),
      {
        search,
        page,
        page_size: pageSize,
        ...excludedParams,
      },
    );
  }, [search, page, pageSize, searchParams, excludedParams]);
  return urlQueryParams;
};

export const useAssetsModal = (isOpen: boolean) => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const excludedParams = useExcludedAssetsParams();
  const getAssetOptionsLoading = useAppSelector(
    state => state.asset.getAssetOptionsLoading,
  );
  const assetOptions = useAppSelector(state => state.asset.assetOptions);
  const getAssetOptionsFailed = useAppSelector(
    state => state.asset.getAssetOptionsFailed,
  );
  const assetOptionsPaginationConfig = useAppSelector(
    state => state.asset.assetOptionsPaginationConfig,
  );
  const loadingAssetOptionId = useAppSelector(
    state => state.asset.loadingAssetOptionId,
  );
  const isLoadingMoreAssetOptions = useAppSelector(
    state => state.asset.isLoadingMoreAssetOptions,
  );
  const dispatch = useAppDispatch();

  const urlQueryParams = useAssetsModalUrlQueryParams();
  const onPageChange = usePagination(
    ASSET_MODAL_QUERY_PARAMS.PAGE,
    ASSET_MODAL_QUERY_PARAMS.PAGE_SIZE,
  );

  const goToFirstPage = () => {
    onPageChange(1);
  };
  const getChildrenAssets = ({
    parent,
    page = '1',
    isLoadMore = false,
  }: {
    parent: number;
    page?: string;
    isLoadMore?: boolean;
  }) => {
    dispatch(
      getAssetOptions({
        isLoadMore,
        page,
        page_size: DEFAULT_PAGING.CHILD_ASSET_PAGE_SIZE,
        parent,
        ...excludedParams,
      }),
    );
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getAssetOptions(urlQueryParams));
    }
  }, [urlQueryParams, dispatch, isOpen]);

  useFailed(getAssetOptionsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    getAssetOptionsLoading,
    assetOptions,
    getAssetOptionsFailed,
    loadingAssetOptionId,
    isLoadingMoreAssetOptions,
    assetOptionsPaginationConfig: {
      ...assetOptionsPaginationConfig,
      onChange: onPageChange,
    },
    goToFirstPage,
    getChildrenAssets,
    urlQueryParams,
  };
};

export const useAssets = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getAssetsLoading = useAppSelector(
    state => state.asset.getAssetsLoading,
  );
  const assets = useAppSelector(state => state.asset.assets);
  const getAssetsFailed = useAppSelector(state => state.asset.getAssetsFailed);
  const paginationConfig = useAppSelector(
    state => state.asset.paginationConfig,
  );
  const loadingAssetId = useAppSelector(state => state.asset.loadingAssetId);
  const isLoadingMoreAssets = useAppSelector(
    state => state.asset.isLoadingMoreAssets,
  );

  const dispatch = useAppDispatch();

  const urlQueryParams = useURLQueryParams(Object.values(ASSET_QUERY_PARAMS));
  const onPageChange = usePagination();

  useEffect(() => {
    dispatch(getAssets(urlQueryParams));
  }, [urlQueryParams, dispatch]);

  useFailed(getAssetsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  const refreshCurrentPage = () => {
    dispatch(getAssets(urlQueryParams));
  };

  const getChildrenAssets = ({
    parent,
    page = '1',
    isLoadMore = false,
  }: {
    parent: number;
    page?: string;
    isLoadMore?: boolean;
  }) => {
    dispatch(
      getAssets({
        page_size: DEFAULT_PAGING.CHILD_ASSET_PAGE_SIZE,
        parent,
        page,
        isLoadMore,
      }),
    );
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      assets.length === 1 && urlQueryParams.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(urlQueryParams.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterCreating = () => {
    if (urlQueryParams.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    getAssetsLoading,
    assets,
    getAssetsFailed,
    loadingAssetId,
    isLoadingMoreAssets,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    refreshAfterCreating,
    refreshAfterDeleting,
    getChildrenAssets,
    urlQueryParams,
    refreshCurrentPage,
  };
};

export const useCreateAsset = (
  onReset: () => void,
  refresh: () => void,
): UseCreateAssetReturnType => {
  const createAssetSuccess = useAppSelector(
    state => state.asset.createAssetSuccess,
  );
  const createAssetLoading = useAppSelector(
    state => state.asset.createAssetLoading,
  );
  const createAssetFailed = useAppSelector(
    state => state.asset.createAssetFailed,
  );

  const dispatch = useAppDispatch();

  const handleCreateAsset = (asset: UploadingFileModel) => {
    dispatch(createAsset(asset));
  };

  useSuccess(createAssetSuccess, '', onReset);

  useEffect(() => {
    if (createAssetSuccess) {
      if (!createAssetSuccess.parent || createAssetSuccess.shouldReset) {
        refresh();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAssetSuccess]);

  useFailed(createAssetFailed);

  return {
    createAssetLoading,
    createAssetFailed,
    createAssetSuccess,

    handleCreateAsset,
  };
};

export const useUpdateAsset = (
  callback: () => void,
): UseUpdateAssetReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const updateAssetSuccess = useAppSelector(
    state => state.asset.updateAssetSuccess,
  );
  const updateAssetLoading = useAppSelector(
    state => state.asset.updateAssetLoading,
  );
  const updateAssetFailed = useAppSelector(
    state => state.asset.updateAssetFailed,
  );

  const dispatch = useAppDispatch();

  const handleUpdateAsset = (asset: Asset) => {
    dispatch(updateAsset(asset));
  };

  useSuccess(
    updateAssetSuccess,
    t<string>('ASSET_UPDATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(updateAssetFailed);

  return {
    updateAssetLoading,
    updateAssetFailed,
    updateAssetSuccess,

    handleUpdateAsset,
  };
};

export const useDeleteAsset = (
  callback: () => void,
): UseDeleteAssetReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const deleteAssetSuccess = useAppSelector(
    state => state.asset.deleteAssetSuccess,
  );
  const deleteAssetLoading = useAppSelector(
    state => state.asset.deleteAssetLoading,
  );
  const deleteAssetFailed = useAppSelector(
    state => state.asset.deleteAssetFailed,
  );

  const dispatch = useAppDispatch();

  const handleDeleteAsset = (id: number) => {
    dispatch(deleteAsset(id));
  };

  useSuccess(
    deleteAssetSuccess,
    t<string>('ASSET_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteAssetFailed);

  return {
    deleteAssetSuccess,
    deleteAssetFailed,
    deleteAssetLoading,
    handleDeleteAsset,
  };
};

export const useResetAssetState = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetAssetState());
    };
  }, [dispatch]);
};

export const useAssetsDataSource = (
  assets: Asset[],
  parentIdOfCreatingAsset: number,
) => {
  const dataSource = useMemo(() => {
    let data = JSON.parse(JSON.stringify(assets));

    if (parentIdOfCreatingAsset) {
      const parentFolder = findParentFolder(
        { children: data },
        parentIdOfCreatingAsset,
      );

      parentFolder.children = [
        {
          id: 'Draft Sub Folder',
          name: '',
          parent: parentFolder.id,
          isSubfolderFormRecord: true,
        },
        ...(parentFolder.children ?? []),
      ];
    }

    return data;
  }, [assets, parentIdOfCreatingAsset]);

  return dataSource;
};

export const useUploadManager = (): UseUploadManagerReturnType => {
  const uploadingFiles = useAppSelector(state => state.asset.uploadingFiles);
  const widgetStatus = useAppSelector(state => state.asset.widgetStatus);
  const filesQueue = useAppSelector(state => state.asset.filesQueue);
  const fileModelQueue = useAppSelector(state => state.asset.fileModelQueue);

  const dispatch = useAppDispatch();
  const updateWidgetStatus = (status: WidgetStatus) => {
    dispatch(setWidgetStatus(status));
  };

  return {
    uploadingFiles,
    widgetStatus,
    filesQueue,
    fileModelQueue,
    setWidgetStatus: updateWidgetStatus,
  };
};

export const useGetAssetInvitationCode =
  (): UseGetAssetInvitationCodeReturnType => {
    const getAssetInvitationCodeLoading = useAppSelector(
      state => state.asset.getAssetInvitationCodeLoading,
    );
    const getAssetInvitationCodeFailed = useAppSelector(
      state => state.asset.getAssetInvitationCodeFailed,
    );
    const invitationCode = useAppSelector(state => state.asset.invitationCode);
    const dispatch = useAppDispatch();

    const handleGetAssetInvitationCode = (id: number) => {
      dispatch(getAssetInvitationCode(id));
    };

    useFailed(getAssetInvitationCodeFailed);

    return {
      getAssetInvitationCodeLoading,
      getAssetInvitationCodeFailed,
      invitationCode,
      handleGetAssetInvitationCode,
    };
  };

export const useRoleAssets = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const roleAssets = useAppSelector(state => state.asset.roleAssets);
  const getRoleAssetsFailed = useAppSelector(
    state => state.asset.getRoleAssetsFailed,
  );
  const getRoleAssetsLoading = useAppSelector(
    state => state.asset.getRoleAssetsLoading,
  );
  const dispatch = useAppDispatch();

  const { ids } = useRoleResourceIds(PERMISSIONS_RESOURCES.ASSET);

  useEffect(() => {
    if (ids) {
      dispatch(getRoleAssets(ids));
    } else {
      dispatch(resetRoleAssets());
    }
  }, [dispatch, ids]);

  const refetchRoleAssets = (ids: number[]) => {
    if (ids.length) {
      dispatch(getRoleAssets(ids.join(',')));
    } else {
      dispatch(resetRoleAssets());
    }
  };

  useFailed(getRoleAssetsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    roleAssets,
    getRoleAssetsFailed,
    getRoleAssetsLoading,
    refetchRoleAssets,
  };
};

export const usePlayFile = () => {
  const downloadFileLoading = useAppSelector(
    state => state.asset.downloadFileLoading,
  );
  const downloadedFile = useAppSelector(state => state.asset.downloadedFile);
  const downloadFileFailed = useAppSelector(
    state => state.asset.downloadFileFailed,
  );

  const dispatch = useAppDispatch();

  const handlePlayFile = (params: {
    id: number;
    isCached: boolean;
    parentId?: number;
    isAlbumAsset?: boolean;
  }) => {
    dispatch(downloadFile(params));
  };

  useFailed(downloadFileFailed);

  const getFileStyle = (file: Asset) => {
    return isPlayableFile(file)
      ? file.isCached
        ? { cursor: 'pointer', color: color.secondary }
        : {
            cursor: 'pointer',
            color: color.primary,
          }
      : {};
  };

  const getPlayFileAction = (file: Asset, isAlbumAsset?: boolean) => {
    return isPlayableFile(file)
      ? () =>
          handlePlayFile({
            id: file.id,
            isCached: file.isCached || false,
            parentId: file.parent,
            isAlbumAsset,
          })
      : () => {};
  };

  return {
    downloadFileLoading,
    downloadedFile,
    downloadFileFailed,
    handlePlayFile,
    getFileStyle,
    getPlayFileAction,
  };
};

export const useAssetViewer = () => {
  const isImageModalOpen = useAppSelector(
    state => state.asset.isImageModalOpen,
  );
  const isVideoModalOpen = useAppSelector(
    state => state.asset.isVideoModalOpen,
  );
  const isPDFModalOpen = useAppSelector(state => state.asset.isPDFModalOpen);
  const downloadedFile = useAppSelector(state => state.asset.downloadedFile);
  const isExcelModalOpen = useAppSelector(
    state => state.asset.isExcelModalOpen,
  );

  const dispatch = useAppDispatch();

  const onCloseAssetViewer = () => {
    dispatch(closeAssetViewer());
  };

  return {
    isImageModalOpen,
    isVideoModalOpen,
    isPDFModalOpen,
    downloadedFile,
    isExcelModalOpen,
    onCloseAssetViewer,
  };
};

export const useAssetViewerUrl = (isOpen: boolean, file: any) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (isOpen && file) {
      const { data, headers } = file;
      const url = URL.createObjectURL(
        new Blob([data], { type: headers['content-type'] }),
      );

      setUrl(url);

      return () => {
        URL.revokeObjectURL(url);
        setUrl('');
      };
    }
  }, [isOpen, file]);

  return url;
};

export const useExcelViewer = (isOpen: boolean, file: any) => {
  const [workBook, setWorkBook] = useState<any>();
  const [worksheet, setWorksheet] = useState<any>([]);

  const onWorksheetChange = (index: number) => {
    setWorksheet(workBook.Sheets[workBook.SheetNames[index]]);
  };

  useEffect(() => {
    if (isOpen && file) {
      const init = async () => {
        const { data, headers } = file;
        const blob = new Blob([data], { type: headers['content-type'] });
        const buffer = await blob.arrayBuffer();
        const wb = read(buffer);
        const ws = wb.Sheets[wb.SheetNames[0]];
        setWorkBook(wb);
        setWorksheet(ws);
      };

      init();
    }
  }, [isOpen, file]);

  const excelData = useMemo(() => {
    return worksheet
      ? utils
          .sheet_to_json<any>(worksheet)
          .map((item, index) => ({ ...item, tableKey: index }))
      : [];
  }, [worksheet]);

  const columns = useMemo(() => {
    if (!excelData?.[0]) return [];
    const data: any[] = [];

    Object.keys(excelData[0]).forEach(title => {
      if (title !== 'tableKey') {
        data.push({ title, dataIndex: title, key: title });
      }
    });

    return data;
  }, [excelData]);

  return {
    columns,
    dataSource: excelData,
    onWorksheetChange,
    sheetNames: workBook?.SheetNames || [],
  };
};

const en = {
  BRANCH_CREATED_SUCCESSFULLY: 'Branch created successfully',
  BRANCH_UPDATED_SUCCESSFULLY: 'Branch updated successfully',
  BRANCH_DELETED_SUCCESSFULLY: 'Branch deleted successfully',

  CHECK_IN_CREATED_SUCCESSFULLY: 'Check in created successfully',
  CHECK_IN_UPDATED_SUCCESSFULLY: 'Check in updated successfully',
  CHECK_IN_DELETED_SUCCESSFULLY: 'Check in deleted successfully',

  INVITE_CODE_UPDATED_SUCCESSFULLY: 'Code update successfully',

  ALBUM_CREATED_SUCCESSFULLY: 'Album created successfully',
  ALBUM_UPDATED_SUCCESSFULLY: 'Album updated successfully',
  ALBUM_DELETED_SUCCESSFULLY: 'Album deleted successfully',

  STUDENT_CREATED_SUCCESSFULLY: 'Student created successfully',
  STUDENT_UPDATED_SUCCESSFULLY: 'Student updated successfully',
  STUDENT_DELETED_SUCCESSFULLY: 'Student deleted successfully',
  STUDENT_ADDED_SUCCESSFULLY: 'Student added successfully',

  COURSE_CREATED_SUCCESSFULLY: 'Course created successfully',
  COURSE_UPDATED_SUCCESSFULLY: 'Course updated successfully',
  COURSE_DELETED_SUCCESSFULLY: 'Course deleted successfully',

  ASSET_CREATED_SUCCESSFULLY: 'Asset created successfully',
  ASSET_UPDATED_SUCCESSFULLY: 'Asset updated successfully',
  ASSET_DELETED_SUCCESSFULLY: 'Asset deleted successfully',
  ASSET_ADDED_SUCCESSFULLY: 'Asset added successfully',

  PLAYLIST_CREATED_SUCCESSFULLY: 'Playlist created successfully',
  PLAYLIST_UPDATED_SUCCESSFULLY: 'Playlist updated successfully',
  PLAYLIST_DELETED_SUCCESSFULLY: 'Playlist deleted successfully',

  BOOK_CREATED_SUCCESSFULLY: 'Book created successfully',
  BOOK_UPDATED_SUCCESSFULLY: 'Book updated successfully',
  BOOK_DELETED_SUCCESSFULLY: 'Book deleted successfully',

  STAFF_CREATED_SUCCESSFULLY: 'Staff created successfully',
  STAFF_UPDATED_SUCCESSFULLY: 'Staff updated successfully',
  STAFF_DELETED_SUCCESSFULLY: 'Staff deleted successfully',
  STAFF_INVITATION_ACCEPTED_SUCCESSFULLY:
    'Staff invitation accepted successfully',
  STAFF_INVITED_SUCCESSFULLY: 'Staff invited successfully',

  ROLE_CREATED_SUCCESSFULLY: 'Role created successfully',
  ROLE_UPDATED_SUCCESSFULLY: 'Role updated successfully',
  ROLE_DELETED_SUCCESSFULLY: 'Role deleted successfully',
  ROLE_DUPLICATED_SUCCESSFULLY: 'Role copied successfully.',

  USER__ACCOUNT__INCORRECT: 'Username or password is incorrect.',
  USER__PHONE_NUMBER__REQUIRED: 'Please enter phone number.',
  USER__PHONE_NUMBER__INVALID: 'Phone number is not exist.',
  USER__PHONE_NUMBER__INCORRECT: 'Phone number is not verified yet.',
  USER__VERIFY_KEY__SENDING: 'Code sent successfully.',
  USER__CREATE__SUCCESS: 'User registered successfully',
  USER__PASSWORD__INVALID: 'Password does not match.',
  USER__EMAIL__INVALID: 'Please enter a correct Email.',
  USER__ACCOUNT__DEACTIVATE: 'The account that were disabled.',
  USER__UPDATE__SUCCESS: 'Updated successfully',
  USER__VERIFY_KEY__EXPIRED: 'Expired OTP! Please try again.',
  USER__VERIFY_KEY__INCORRECT: 'Incorrect OTP! Please try again.',
  USER__AVATAR__REQUIRED: 'Please upload avatar.',
  BOOK__CREATE__SUCCESS: 'Book added successfully.',
  BOOK__UPDATE__SUCCESS: 'Book edited successfully.',
  USER__PASSWORD__EMPTY: 'Please do not leave password blank.',
  USER__PASSWORD__REQUIRED: 'Please enter password.',
  USER__PHONE_NUMBER__UNIQUE: 'Phone number existed.',
  USER__PHONE_NUMBER__EMPTY: 'Please do not leave phone number blank.',
  USER__EMAIL__REQUIRED: 'Please enter Email.',
  USER__EMAIL__LENGTH: 'Email does not exceed 52 characters.',
  USER__EMAIL__UNIQUE: 'Email existed.',
  USER__EMAIL__EMPTY: 'Please do not leave password blank.',
  USER__BIRTHDAY__INVALID_FORMAT: 'Incorrect date format.',
  USER__OLD_PASSWORD__EMPTY: 'Please do not leave password blank.',
  USER__OLD_PASSWORD__REQUIRED: 'Please enter password.',
  USER__NEW_PASSWORD__EMPTY: 'Please do not leave new password blank.',
  USER__NEW_PASSWORD__REQUIRED: 'Please enter new password.',
  USER__NEW_PASSWORD_CONFIRM__EMPTY: 'Please do not leave new password blank.',
  USER__NEW_PASSWORD_CONFIRM__REQUIRED: 'Please enter new password.',
  USER__SUID__INVALID: 'Invalid sub-user.',
  USER__FIELD__UNIQUE: 'User existed.',
  ASSETS__RESOURCE_TYPE__EMPTY: 'Please do not leave file type blank.',
  ASSETS__RESOURCE_TYPE__REQUIRED: 'Please enter file type.',
  ASSETS__RESOURCE_TYPE__INVALID: 'Invalid file type.',
  ASSETS__NAME__EMPTY: 'Please do not leave file name blank.',
  ASSETS__NAME__REQUIRED: 'Please enter file name.',
  ASSETS__TAGS__EMPTY: 'Please do not leave file tag blank.',
  ASSETS__TAGS__REQUIRED: 'Please enter tag name.',
  BOOK__NAME__EMPTY: 'Please do not leave book name blank.',
  BOOK__NAME__REQUIRED: 'Please enter book name.',
  BOOK__ASSETS_ID__INVALID: 'Incorrect file ID.',
  BOOK__USER_PROFILE_ID__INVALID: 'Incorrect profile ID.',
  COURSE__NAME__EMPTY: 'Please do not leave course name blank.',
  COURSE__NAME__REQUIRED: 'Please enter course name.',
  COURSE__NAME__UNIQUE: 'Course name existed.',
  COURSE__ASSETS_ID__INVALID: 'Invalid course ID.',
  COURSE__STUDENT_ID__INVALID: 'Invalid student ID.',
  WORKSPACE__NAME__EMPTY: 'Please do not leave workspace name blank.',
  WORKSPACE__NAME__REQUIRED: 'Please enter workspace name.',
  WORKSPACE__NAME__UNIQUE: 'Workspace name existed.',
  WORKSPACE__WORKSPACE__UNIQUE: 'Workspace existed.',
  WORKSPACE__WORKSPACE__EMPTY: 'Please do not leave workspace blank.',
  WORKSPACE__WORKSPACE__REQUIRED: 'Please enter workspace.',
  WORKSPACE__CREATE__FAILED: 'Workspace added failed.',
  USER_PROFILE__DOB__INVALID_FORMAT: 'Incorrect date of birth format.',
  STUDENT__DOB__INVALID_FORMAT: 'Incorrect date of birth format.',
  INVITATION_CODE__BOOK_ID__INVALID: 'Incorrect book code.',
  INVITATION_CODE__COURSE_ID__INVALID: 'Incorrect course code.',
  INVITATION_CODE__DELETE__SUCCESS: 'Deleted successfully.',
  INVITATION_CODE__CREATE__SUCCESS: 'Created successfully.',

  PROFILE_UPDATED_SUCCESSFULLY: 'Profile Updated Successfully.',
  WORKSPACE__DELETE__SUCCESS: 'Deleted successfully.',
  WORKSPACE_UPDATED_SUCCESSFULLY: 'Workspace updated successfully.',
  WORKSPACE_CREATED_SUCCESSFULLY: 'Workspace created successfully.',
  COURSE__ADD_STUDENT__SUCCESS: 'Added successfully.',
  BOOK__DELETE__SUCCESS: 'Deleted successfully.',
  BOOK__INVITE_USER__SUCCESS: 'Added successfully.',
  BOOK__ADD_ASSETS__SUCCESS: 'Added successfully.',
  USER_CREATE_SUCCESS: 'Email updated successfully.',
  VERIFY_KEY__INPUT__INVALID: 'Incorrect OTP.',
  INVITATION_CODE__CODE__EXISTS: 'OTP is used.',
  ASSETS__ADD_INVITATION_CODE__SUCCESS: 'Added successfully.',
  USER__VERIFY_KEY__INVALID: 'Incorrect OTP.',
  ASSETS__INVITATION_CODE__INVALID: 'Incorrect code.',
  ASSETS__INVITATION_CODE__VERIFIED: 'Verified successfully.',
  USERPROFILE__DOB__FORMAT_INVALID: 'Invalid date of birth.',
  STAFF__CREATE__SUCCESS: 'New staff added successfully.',
  STAFF__DELETE__SUCCESS: 'Staff deleted successfully.',
  ROLE__DELETE__FAILED: 'You are not entitled to delete this permission.',
  STAFF__INVITATION__SUCCESS: 'Invitation sent successfully.',
  ROLE__UPDATE__SUCCESS: 'Permission updated successfully',
  STAFF_INVITATION__INVITED__BEFORE: 'Staff invited.',
  STAFF__UPDATE__SUCCESS: 'Staff updated successfully.',
  SHARE_LIST_ASSETS__CREATE__SUCCESS: 'Created successfully.',
  SHARE_ASSETS__UPDATE__SUCCESS: 'Playlist updated successfully.',
  ASSETS__CODE__VERIFIED: 'Verified successfully.',
  PLAYLIST__DELETE__SUCCESS: 'Playlist deleted successfully.',
  PLAYLIST__UPDATE__SUCCESS: 'Playlist updated successfully.',
  WORKSPACE__CREATE__NOT_SUPPORT_ON_BETA_VERSION:
    'This feature is limited at beta version.',
  BOOK__CREATE_ORDER_ASSETS__SUCCESS: 'Changed order successfully.',
  BOOK__UPDATE_ORDER_ASSETS__SUCCESS: 'Changed order successfully.',
  COURSE__CREATE_ORDER_ASSETS__SUCCESS: 'Changed order successfully.',
  COURSE__UPDATE_ORDER_ASSETS__SUCCESS: 'Changed order successfully.',
  COURSES__ADD_STUDENT__SUCCESS: 'Added successfully.',
  COURSES__DELETE_STUDENT__SUCCESS: 'Deleted successfully.',
  PLAYLIST__DELETE_ASSETS__SUCCESS: 'Deleted successfully.',
  PLAYLIST__ADD_ASSETS__SUCCESS: 'Added successfully.',
  COURSES_LESSON__DELETE__SUCCESS: 'Lesson deleted successfully.',
  COURSES_LESSON_EXERCISE__ADD__SUCCESS: 'Exercise added successfully.',
  COURSES_LESSON_EXERCISE__DELETE__SUCCESS: 'Exercise deleted successfully.',
  COURSES_LESSON__UPDATE__SUCCESS: 'Lesson updated successfully.',
  COURSES_LESSON_EXERCISE__UPDATE__SUCCESS: 'Exercise updated successfully.',
  ASSETS__NAME__UNIQUE: 'Please enter another name.',
  COURSES_LESSON__ADD__SUCCESS: 'Lesson added successfully.',
  COURSES_LESSON__NAME__UNIQUE: 'Please enter another lesson name.',
  EXERCISES__UPDATE__SUCCESS: 'Exercise updated successfully.',
  COURSES_LESSON_DOCUMENT__ADD__SUCCESS: 'File added successfully.',
  COURSES_LESSON_DOCUMENT__DELETE__SUCCESS: 'File deleted successfully.',
  COURSES_LESSON_DOCUMENT__UPDATE__SUCCESS: 'File updated successfully.',
  ANSWER_EXERCISES__UPDATE__SUCCESS: 'Marked Successfully.',
  COURSES_LESSON__LEARNING_CONTENT__EMPTY:
    'Please do not leave lesson content blank.',
  ATTENDANCE__UPDATE__SUCCESS: 'Attendance updated successfully.',
  COURSE_LESSONS__MULTI_CREATE__SUCCESS: 'Lesson added successfully.',
  ROLE__UPDATE_PERMISSIONS__SUCCESS: 'Permission updated successfully.',

  CANT_DELETE_ROLE_HAS_STAFF: 'Delete role failed',
  PLAYLIST__CREATE__SUCCESS: 'Create Playlist successfully.',
  BOOK__NAME__UNIQUE: 'Please enter another book name.',
  ROLE__NAME__UNIQUE: 'Please enter another role name.',
  STAFF__ROLE__CAN_NOT_DELETE: "Workspace's owner cannot be deleted",
  STAFF_INVITATION__EMAIL__INVITED_BEFORE: 'This email has been invited',
  PASSWORD_UPDATED_SUCCESSFULLY: 'Password updated successfully',
  DEFAULT_ERROR_MESSAGE: 'Something went wrong!',

  RESEND__EMAIL__SUCCESS: 'Email resent successfully',
};

export default en;

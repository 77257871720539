import React, { Fragment, useState } from 'react';
import { PageContent, PageHeader } from 'components/Common/Page';
import {
  Button,
  Table,
  Dropdown,
  Popconfirm as PopConfirm,
  Space,
  Image,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Book } from 'features/book/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useDeleteBook,
  useBooks,
  useResetBookState,
  useGetBookInvitationCode,
} from 'features/book/bookHooks';
import { getNo } from 'utils';
import BookForm from 'features/book/Books/BookForm';
import type { MenuProps } from 'antd';
import { EllipsisOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTableSorting } from 'app/hooks';
import InvitationCodeModal from 'components/Common/InvitationCodeModal';
import Filter from './Filter';

const initValues = {
  name: undefined,
  id: undefined,
  descriptions: undefined,
};

const Books = () => {
  const { t } = useTranslation(LOCALES.BOOK);
  const { handleSorting, getColumnSorting } = useTableSorting();

  const {
    books,
    getBooksLoading,
    paginationConfig,
    refreshAfterCreating,
    refreshAfterDeleting,
  } = useBooks();
  useResetBookState();

  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const {
    handleGetBookInvitationCode,
    getBookInvitationCodeLoading,
    invitationCode,
  } = useGetBookInvitationCode();

  const onGetInvitationCode = (id: number) => {
    setIsQRCodeModalOpen(true);
    handleGetBookInvitationCode({ book_id: id });
  };

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [initialBook, setInitialBook] = useState<any>(initValues);

  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);
  const { handleDeleteBook, deleteBookLoading } = useDeleteBook(() => {
    setOpenedPopConfirm(-1);
    refreshAfterDeleting();
  });

  const onOpenCreateForm = () => {
    setIsFormOpen(true);
    setInitialBook(initValues);
  };

  const onOpenEditForm = (book: Book) => {
    setIsFormOpen(true);
    setInitialBook({
      ...book,
    });
  };

  const onCloseForm = () => {
    setIsFormOpen(false);
    setInitialBook(initValues);
  };

  const columns: ColumnsType<Book> = [
    {
      title: t('no'),
      key: 'no',
      render: (_, rc, index) => getNo(paginationConfig, index),
      width: 60,
      align: 'right',
    },
    {
      title: t('name'),
      key: 'name',
      ...getColumnSorting('name'),

      render: record => {
        return (
          <Space>
            <Image
              placeholder={true}
              style={{ objectFit: 'contain' }}
              width={30}
              height={30}
              src={record.photo}
            />
            <Link to={`/books/${record.id}/details`}>{record.name}</Link>
          </Space>
        );
      },
    },
    { title: t('author'), key: 'author', dataIndex: 'author' },
    {
      title: t('readers'),
      key: 'total_reader',
      dataIndex: 'total_reader',
      align: 'right',
      width: 150,
      ...getColumnSorting('total_reader'),
    },
    { title: t('description'), key: 'description', dataIndex: 'descriptions' },
    {
      title: t('inviteCode'),
      key: 'inviteCode',
      width: 120,
      render: ({ id }) => (
        <Button
          onClick={() => onGetInvitationCode(id)}
          type='text'
          icon={<QrcodeOutlined />}></Button>
      ),
      align: 'center',
    },
    {
      title: '',
      width: 50,
      render: (book: Book) => {
        const items: MenuProps['items'] = [
          {
            label: (
              <p
                className='styled-table__action-item'
                onClick={() => onOpenEditForm(book)}>
                {t('update')}
              </p>
            ),
            key: 'Edit',
          },
          { type: 'divider' },
          {
            label: (
              <p
                className='styled-table__action-item color-error'
                onClick={() => setOpenedPopConfirm(book.id)}>
                {t('delete')}
              </p>
            ),
            key: 'Delete',
          },
        ];
        return (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            overlayStyle={{ width: 150 }}
            overlayClassName='styled-table__actions'
            placement='bottomRight'>
            <PopConfirm
              placement='topRight'
              title={t('deleteWarning')}
              onConfirm={() => handleDeleteBook(book.id)}
              okText={t('delete')}
              cancelText={t('cancel')}
              okButtonProps={{ loading: deleteBookLoading }}
              cancelButtonProps={{ disabled: deleteBookLoading }}
              open={openedPopConfirm === book.id}
              onCancel={() => setOpenedPopConfirm(-1)}>
              <Button size='small' type='text'>
                <EllipsisOutlined />
              </Button>
            </PopConfirm>
          </Dropdown>
        );
      },
      key: 'action',
    },
  ];

  return (
    <PageContent>
      <Fragment>
        <PageHeader title={t('books')}>
          <Space align='center' size={60}>
            <Filter />
            <Button type='primary' onClick={onOpenCreateForm}>
              {t('create')}
            </Button>
          </Space>
        </PageHeader>

        <Table
          loading={getBooksLoading}
          dataSource={books}
          columns={columns}
          pagination={paginationConfig}
          rowKey='id'
          onChange={handleSorting}
        />
        <BookForm
          isOpen={isFormOpen}
          onClose={onCloseForm}
          refreshAfterCreating={refreshAfterCreating}
          initValues={initialBook}
        />
        <InvitationCodeModal
          invitationCode={invitationCode}
          isOpen={isQRCodeModalOpen}
          onClose={() => setIsQRCodeModalOpen(false)}
          isLoading={getBookInvitationCodeLoading}
        />
      </Fragment>
    </PageContent>
  );
};

export default Books;

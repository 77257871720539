import { useState } from 'react';
import { Table, Button, Popconfirm as PopConfirm } from 'antd';
import { Fragment } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Student } from 'features/student/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { getNo } from 'utils';
import {
  useAddCourseStudents,
  useCourseStudents,
  useDeleteCourseStudent,
  useCourseInvitation,
} from 'features/course/courseHooks';
import { QrcodeOutlined, DeleteOutlined } from '@ant-design/icons';
import Filter from './Filter';
import { useParams } from 'react-router-dom';
import StudentsModal from 'components/StudentsModal';
import InvitationCodeModal from 'components/Common/InvitationCodeModal';
import { useTableSorting } from 'app/hooks';

const CourseStudents = () => {
  const { t } = useTranslation(LOCALES.STUDENT);
  const { id: courseId } = useParams();
  const { handleSorting, getColumnSorting } = useTableSorting();

  const {
    courseStudents,
    getCourseStudentsLoading,
    courseStudentPaginationConfig,
    refreshAfterDeleting,
    refreshAfterAdding,
  } = useCourseStudents();

  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);
  const {
    handleDeleteCourseStudent,
    deleteCourseStudentLoading,
  } = useDeleteCourseStudent(() => {
    setOpenedPopConfirm(-1);
    refreshAfterDeleting();
  });

  const [isStudentsModalOpen, setIsStudentsModalOpen] = useState(false);

  const {
    handleAddCourseStudents,
    addCourseStudentsLoading,
    addCourseStudentsSuccess,
  } = useAddCourseStudents(() => {
    setIsStudentsModalOpen(false);
    refreshAfterAdding();
  });

  const [isInvitationCodeModalOpen, setIsInvitationCodeModalOpen] = useState(
    false,
  );

  const {
    handleGetCourseInvitation,
    getCourseInvitationLoading,
    invitationCode,
  } = useCourseInvitation();

  const handleSubmit = (selectedStudentIds: number[]) => {
    handleAddCourseStudents({ courseId, studentIds: selectedStudentIds });
  };

  const columns: ColumnsType<Student> = [
    {
      title: t('no'),
      key: 'no',
      render: (_, rc, index) => getNo(courseStudentPaginationConfig, index),
      width: 60,
      align: 'right',
    },
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      ...getColumnSorting('name'),
    },

    {
      title: t('englishName'),
      key: 'english_name',
      dataIndex: 'english_name',
      ...getColumnSorting('english_name'),
    },
    {
      title: t('phoneNumber'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      ...getColumnSorting('phone_number'),
    },
    {
      title: t('learnedHours'),
      key: 'total_learning_time',
      dataIndex: 'total_learning_time',
      align: 'right',
      ...getColumnSorting('total_learning_time'),
    },
    {
      title: t('inviteCode'),
      key: 'inviteCode',
      width: 120,
      render: ({ id }) => (
        <Button
          onClick={() => {
            handleGetCourseInvitation({
              student_id: id,
              course_id: Number(courseId),
            });
            setIsInvitationCodeModalOpen(true);
          }}
          type='text'
          icon={<QrcodeOutlined />}></Button>
      ),
      align: 'center',
    },
    {
      title: '',
      key: 'actions',
      width: 70,
      render: courseStudent => (
        <PopConfirm
          placement='topRight'
          title={t('deleteWarning')}
          onConfirm={() =>
            handleDeleteCourseStudent({
              courseId,
              studentId: courseStudent.id,
            })
          }
          okText={t('delete')}
          cancelText={t('cancel')}
          okButtonProps={{ loading: deleteCourseStudentLoading }}
          cancelButtonProps={{ disabled: deleteCourseStudentLoading }}
          open={openedPopConfirm === courseStudent.id}
          onCancel={() => setOpenedPopConfirm(-1)}>
          <Button
            onClick={() => setOpenedPopConfirm(courseStudent.id)}
            type='text'
            icon={<DeleteOutlined style={{ color: '#f81d22' }} />}></Button>
        </PopConfirm>
      ),
    },
  ];

  return (
    <Fragment>
      <Filter openStudentsModal={() => setIsStudentsModalOpen(true)} />

      <Table
        rowKey='id'
        loading={getCourseStudentsLoading}
        columns={columns}
        dataSource={courseStudents}
        pagination={courseStudentPaginationConfig}
        onChange={handleSorting}
      />

      <StudentsModal
        isOpen={isStudentsModalOpen}
        onCancel={() => setIsStudentsModalOpen(false)}
        onSubmit={handleSubmit}
        addingLoading={addCourseStudentsLoading}
        addingSuccess={addCourseStudentsSuccess}
      />

      <InvitationCodeModal
        invitationCode={invitationCode}
        isLoading={getCourseInvitationLoading}
        isOpen={isInvitationCodeModalOpen}
        onClose={() => setIsInvitationCodeModalOpen(false)}
      />
    </Fragment>
  );
};

export default CourseStudents;

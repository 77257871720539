const en = {
  no: 'No',
  create: 'Create',
  cancel: 'Cancel',
  update: 'Update',
  delete: 'Delete',
  createdBy: 'Created By',
  add: 'Add',

  createPlaylist: 'Create Playlist',
  updatePlaylist: 'Update Playlist',

  playlists: 'Playlists',
  playlistCode: 'Playlist code',
  playlistName: 'Playlist name',
  deleteWarning: 'Are you sure you want to delete this playlist?',
  photo: 'Image',
  auth: 'Author',
  descriptions: 'Description',
  name: 'Name',
  author: 'Author',
  description: 'Description',
  image: 'Cover image',
  chapterList: "Book's files",
  enterName: 'Enter playlist name',
  enterAuthor: 'Enter author name',
  enterDescription: 'Enter description',
  importChapterList: "Import book's files",
  inviteCode: 'Invitation Code',
  updatedAt: 'Updated at',
  createdAt: 'Created at',

  keyword: 'Keyword',
  enterKeyword: 'Enter Keyword',

  pleaseInputPlaylistName: 'Please input playlist name!',

  playlistInformation: 'Playlist Information',
};

export default en;

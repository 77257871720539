import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { RcFile, UploadChangeParam } from 'antd/es/upload/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { useState } from 'react';
import requester from 'utils/requester';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { getNonTenantEndpoint } from 'utils';
import styled from 'styled-components';

interface ImageUploaderProps {
  label?: string;
  onChange?: Function;
  size?: string;
  value?: string;
}

const StyledUpload = styled(Upload)`
  .ant-upload {
    width: 152px !important;
    height: 152px !important;
    overflow: hidden;
  }
`;

export default function ImageUploader({
  label,
  onChange,
  value,
}: ImageUploaderProps) {
  const { t } = useTranslation(LOCALES.PROFILE);
  const [isLoading, setIsLoading] = useState(false);

  const uploadImage = async (options: RcCustomRequestOptions) => {
    const { onSuccess, onError, file } = options;

    const formData = new FormData();
    formData.append('avatar', file);

    try {
      const response = await requester.post(
        getNonTenantEndpoint('auth/upload/avatar/'),
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        },
      );
      onSuccess?.(response);
    } catch (err: any) {
      onError?.(err);
    }
  };

  const beforeUpload = async (file: RcFile) => {
    const isImage =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg+xml';

    if (!isImage) {
      message.error(t('acceptImageOnly'));
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('imageMustSmallerThan2MB'));
    }

    return isImage && isLt2M;
  };

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
    }

    if (info.file.status === 'done') {
      const imageUrl = info.file.response.avatar;
      setIsLoading(false);
      onChange?.(imageUrl);
    }
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{label}</div>
    </div>
  );

  return (
    <StyledUpload
      customRequest={uploadImage}
      listType='picture-card'
      showUploadList={false}
      onChange={handleChange}
      beforeUpload={beforeUpload}
      style={{ height: '100%', width: '100%' }}>
      {value ? (
        <img
          src={value}
          alt={label}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      ) : (
        uploadButton
      )}
    </StyledUpload>
  );
}

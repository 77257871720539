import axios from 'axios';
import memoizedRefreshToken from './refreshToken';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

const instants = axios.create({
  baseURL: process.env.REACT_APP_HAS_TENANT_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export function setAxiosToken(token: string) {
  instants.defaults.headers.common.Authorization = 'Bearer ' + token;
}

export function removeAxiosToken() {
  instants.defaults.headers.common.Authorization = '';
}

export function setTenant(workspace: string) {
  instants.defaults.headers.common['TENANT'] = workspace;
}

const responseBody = (res: any) => res.data;
const getResponse = (res: any) => res;

const requester = {
  get: (url: string, params?: any, config = {}) =>
    instants
      .get(url, {
        params,
        ...config,
      })
      .then(responseBody),

  post: (url: string, data: any, config = {}) =>
    instants.post(url, data, config).then(responseBody),
  put: (url: string, data: any, config = {}) =>
    instants.put(url, data, config).then(responseBody),
  delete: (url: string, data?: any) =>
    instants.delete(url, { data }).then(responseBody),

  getFull: (url: string, params: any, config = {}) =>
    instants.get(url, { params, ...config }).then(getResponse),
};

export const setupInstantsInterceptor = (store: ToolkitStore) => {
  instants.interceptors.response.use(
    response => response,
    async (error: any) => {
      const config = error?.config;

      const isAuthError =
        error?.response?.status === 401 || error?.response?.status === 403;

      if (isAuthError && !config?.sent) {
        config.sent = true;

        const result = await memoizedRefreshToken(store);

        if (result?.accessToken) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${result.accessToken}`,
          };
          setAxiosToken(result.accessToken);
          return axios(config);
        }

        return new Promise(() => {});
      }

      return Promise.reject(error);
    },
  );
};

export default requester;

const downloadFileInstants = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fileDownloader = {
  async downloadFile(path: string) {
    return await downloadFileInstants.get(path, { responseType: 'blob' });
  },
};

import message from './message/en';
import validation from './validation/en';
import layout from './layout/en';
import login from './login/en';
import register from './register/en';
import profile from './profile/en';
import workspace from './workspace/en';
import student from './student/en';
import course from './course/en';
import asset from './asset/en';
import playlist from './playlist/en';
import book from './book/en';
import staff from './staff/en';
import role from './role/en';
import branch from './branch/en';
import checkIn from './checkIn/en';

const en = {
  message,
  validation,
  layout,
  login,
  register,
  profile,
  workspace,
  student,
  course,
  asset,
  playlist,
  book,
  staff,
  role,
  branch,
  checkIn,
};

export default en;

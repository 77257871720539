import { RcFile } from 'antd/es/upload';
import { Asset } from 'features/asset/types';

export const findParentFolder = (assets: any, folderId: number) => {
  let stack: any[] = [];

  const depthFirstSearch = (data: any, parentId: number): any => {
    const folders = (data.children ?? []).filter(
      ({ folder: isFolder }: any) => isFolder,
    );
    const result = folders.find((folder: any) => folder.id === parentId);

    if (result) {
      return result;
    } else {
      stack = [...stack, ...folders];
      const nextFolder = stack.pop();
      return depthFirstSearch(nextFolder, folderId);
    }
  };

  const parentFolder = depthFirstSearch(assets, folderId);

  return parentFolder;
};

export const preventDefault = (e: Event) => {
  e.stopPropagation();
  e.preventDefault();
};

export const replaceWithOutChildren = (asset: Asset, updatedAsset: Asset) => {
  return asset.id === updatedAsset.id
    ? { ...updatedAsset, children: asset.children }
    : asset;
};

export const removeRedundantChildren = (data: Asset[]) => {
  return data.map(item => {
    return item.has_children ? item : { ...item, children: undefined };
  });
};

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

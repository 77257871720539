import { useEffect, useMemo } from 'react';
import {
  UseBranchesReturnType,
  BaseBranch,
  UseCreateBranchReturnType,
  UseUpdateBranchReturnType,
  UseDeleteBranchReturnType,
  Branch,
  IBranchesDict,
} from './types';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  getBranches,
  createBranch,
  updateBranch,
  deleteBranch,
  resetBranchState,
  getAllBranches,
} from './branchSlice';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';

export const BRANCH_QUERY_PARAMS = {
  SEARCH: 'search',
};

export const useBranches = (): UseBranchesReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const branches = useAppSelector(state => state.branch.branches);
  const getBranchesLoading = useAppSelector(
    state => state.branch.getBranchesLoading,
  );
  const getBranchesFailed = useAppSelector(
    state => state.branch.getBranchesFailed,
  );
  const paginationConfig = useAppSelector(
    state => state.branch.paginationConfig,
  );

  const dispatch = useAppDispatch();

  const urlQueryParams = useURLQueryParams(Object.values(BRANCH_QUERY_PARAMS));
  const onPageChange = usePagination();

  useEffect(() => {
    dispatch(getBranches(urlQueryParams));
  }, [urlQueryParams, dispatch]);

  useFailed(getBranchesFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  const refreshCurrentPage = () => {
    dispatch(getBranches(urlQueryParams));
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      branches.length === 1 && urlQueryParams.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(urlQueryParams.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterCreating = () => {
    if (urlQueryParams.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    branches,
    getBranchesLoading,
    getBranchesFailed,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    refreshAfterCreating,
    refreshAfterDeleting,
  };
};

export const useCreateBranch = (
  callback: () => void,
): UseCreateBranchReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const createBranchSuccess = useAppSelector(
    state => state.branch.createBranchSuccess,
  );
  const createBranchFailed = useAppSelector(
    state => state.branch.createBranchFailed,
  );
  const createBranchLoading = useAppSelector(
    state => state.branch.createBranchLoading,
  );

  const dispatch = useAppDispatch();

  const handleCreateBranch = (student: BaseBranch) => {
    dispatch(createBranch(student));
  };

  useSuccess(
    createBranchSuccess,
    t<string>('BRANCH_CREATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(createBranchFailed);

  return {
    createBranchSuccess,
    createBranchFailed,
    createBranchLoading,
    handleCreateBranch,
  };
};

export const useUpdateBranch = (
  callback: () => void,
): UseUpdateBranchReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const updateBranchSuccess = useAppSelector(
    state => state.branch.updateBranchSuccess,
  );
  const updateBranchFailed = useAppSelector(
    state => state.branch.updateBranchFailed,
  );
  const updateBranchLoading = useAppSelector(
    state => state.branch.updateBranchLoading,
  );

  const dispatch = useAppDispatch();

  const handleUpdateBranch = (student: Branch) => {
    dispatch(updateBranch(student));
  };

  useSuccess(
    updateBranchSuccess,
    t<string>('BRANCH_UPDATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(updateBranchFailed);

  return {
    updateBranchSuccess,
    updateBranchFailed,
    updateBranchLoading,
    handleUpdateBranch,
  };
};

export const useDeleteBranch = (
  callback: () => void,
): UseDeleteBranchReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const deleteBranchSuccess = useAppSelector(
    state => state.branch.deleteBranchSuccess,
  );
  const deleteBranchFailed = useAppSelector(
    state => state.branch.deleteBranchFailed,
  );
  const deleteBranchLoading = useAppSelector(
    state => state.branch.deleteBranchLoading,
  );

  const dispatch = useAppDispatch();

  const handleDeleteBranch = (id: number) => {
    dispatch(deleteBranch(id));
  };

  useSuccess(
    deleteBranchSuccess,
    t<string>('BRANCH_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteBranchFailed);

  return {
    deleteBranchSuccess,
    deleteBranchFailed,
    deleteBranchLoading,
    handleDeleteBranch,
  };
};

export const useResetBranchState = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetBranchState());
    };
  }, [dispatch]);
};

export const useAllBranches = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const allBranches = useAppSelector(state => state.branch.allBranches);
  const getAllBranchesLoading = useAppSelector(
    state => state.branch.getAllBranchesLoading,
  );
  const getAllBranchesFailed = useAppSelector(
    state => state.branch.getAllBranchesFailed,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllBranches());
  }, [dispatch]);

  useFailed(getAllBranchesFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  const allBranchesDict = useMemo(() => {
    return allBranches.reduce((acc: IBranchesDict, branch: Branch) => {
      acc[branch.id] = branch;
      return acc;
    }, {});
  }, [allBranches]);

  return {
    allBranches,
    getAllBranchesLoading,
    allBranchesDict,
  };
};

import requester from 'utils/requester';
import { RoleAPI } from './types';

const roleAPI: RoleAPI = {
  getRoles: () => requester.get('role/'),
  getPredefinedPermissions: () => requester.get('predefinedpermissionsv2/'),
  updateRole: ({ id, ...rest }) => requester.put(`role/${id}/`, { ...rest }),
  createRole: role => requester.post(`role/`, role),
  copyRole: roleId => requester.post(`role/${roleId}/copy/`, null),
  deleteRole: roleId => requester.delete(`role/${roleId}/`),
};

export default roleAPI;

import { useState, useEffect } from 'react';
import { Table, Modal, Tag, Typography, Row } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Staff } from 'features/staff/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useStaffsModal,
  useResetStaffState,
  STAFF_MODAL_QUERY_PARAMS,
} from 'features/staff/staffHooks';
import { getDisplayingDate } from 'utils';
import { useTableSorting } from 'app/hooks';
import { TableRowSelection } from 'antd/es/table/interface';
import { SERVER_DATE_FORMAT } from 'config';
import SearchBox from 'components/Common/SearchBox';

const StaffsModal = ({
  isOpen,
  onCancel,
  addingLoading,
  onSubmit,
  addingSuccess,
  initValues = [],
  isAcceptEmpty = false,
}: {
  isOpen: boolean;
  onCancel: () => void;
  addingLoading: boolean;
  onSubmit: (ids: number[]) => void;
  addingSuccess?: any;
  initValues?: number[];
  isAcceptEmpty?: boolean;
}) => {
  const { t } = useTranslation(LOCALES.STAFF);

  const { handleSorting, getColumnSorting } = useTableSorting(
    STAFF_MODAL_QUERY_PARAMS.ORDERING,
  );

  const { staffs, getStaffsLoading, paginationConfig, goToFirstPage } =
    useStaffsModal(isOpen);
  useResetStaffState();

  const [selectedStaffIds, setSelectedStaffIds] = useState<any[]>([]);

  useEffect(() => {
    if (isOpen) {
      setSelectedStaffIds(initValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const columns: ColumnsType<Staff> = [
    {
      title: t('name'),
      key: 'full_name',
      ...getColumnSorting('full_name'),

      render: (_, staff) => {
        return staff.full_name;
      },
    },
    {
      title: t('email'),
      key: 'email',
      dataIndex: ['invite', 'email'],
    },
    {
      title: t('invitedAt'),
      key: 'invited_at',
      dataIndex: ['invite', 'invited_at'],
      render: date =>
        date ? getDisplayingDate(date, SERVER_DATE_FORMAT) : null,
    },

    {
      title: t('status'),
      key: 'accept_status',
      dataIndex: ['invite', 'accept_status'],
      render: (accept_status, _, index) =>
        accept_status === true ? (
          <Tag color='success'>{t('linked')}</Tag>
        ) : (
          <Tag color='warning'>{t('unlinked')}</Tag>
        ),
    },
  ];

  const rowSelection: TableRowSelection<Staff> = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedStaffIds(selectedRowKeys);
    },

    getCheckboxProps: asset => ({
      disabled: asset.disabled,
      className: asset.disabled ? 'disabled-selection-row' : '',
    }),
  };

  useEffect(() => {
    if (addingSuccess) {
      goToFirstPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingSuccess]);

  return (
    <Modal
      open={isOpen}
      width={1100}
      onCancel={onCancel}
      onOk={() => onSubmit(selectedStaffIds)}
      cancelButtonProps={{ disabled: addingLoading }}
      okButtonProps={{
        loading: addingLoading,
        disabled: !isAcceptEmpty && !selectedStaffIds.length,
      }}
      okText={t('add')}
      cancelText={t('cancel')}>
      <Row justify='space-between'>
        <Typography.Title level={4}>{t('staffs')}</Typography.Title>{' '}
        <SearchBox searchKey={STAFF_MODAL_QUERY_PARAMS.SEARCH}></SearchBox>
      </Row>

      <Table
        rowSelection={{
          ...rowSelection,
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedStaffIds,
        }}
        loading={getStaffsLoading}
        dataSource={staffs}
        columns={columns}
        pagination={paginationConfig}
        rowKey='id'
        onChange={handleSorting}
      />
    </Modal>
  );
};

export default StaffsModal;

import message from './message/vi';
import validation from './validation/vi';
import layout from './layout/vi';
import login from './login/vi';
import register from './register/vi';
import profile from './profile/vi';
import workspace from './workspace/vi';
import student from './student/vi';
import course from './course/vi';
import asset from './asset/vi';
import playlist from './playlist/vi';
import book from './book/vi';
import staff from './staff/vi';
import role from './role/vi';
import branch from './branch/vi';
import checkIn from './checkIn/vi';

const vi = {
  message,
  validation,
  layout,
  login,
  register,
  profile,
  workspace,
  student,
  course,
  asset,
  playlist,
  book,
  staff,
  role,
  branch,
  checkIn
};

export default vi;

const en = {
  no: 'No',
  create: 'Create',
  cancel: 'Cancel',
  update: 'Update',
  staffs: 'Staffs',
  add: 'Add',
  invite: 'Invite',
  inviteStaff: 'Invite Staff',

  name: 'Name',
  email: 'Email',
  invitedAt: 'Invited at',
  status: 'Status',
  unlinked: 'Unlinked',
  linked: 'Linked',
  deleteWarning: 'Are you sure want to delete this staff?',
  delete: 'Delete',

  staffGroups: 'Staff Groups',

  nameIsRequired: 'Please Input Staff Name',
  updateStaff: 'Update staff',
  role: 'Role',
  updateRole: 'Update role'
};

export default en;

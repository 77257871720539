import { useState, useEffect } from 'react';
import { Space, Input, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

interface RoleFormProps {
  initValue: string;
  onCancel: () => void;
  loading: boolean;
  onSubmit: (name: string) => void;
}

const RoleForm = (props: RoleFormProps) => {
  const { initValue, onCancel, loading, onSubmit } = props;
  const [name, setName] = useState(initValue);

  useEffect(() => {
    setName(initValue);
  }, [initValue]);

  const handleSubmit = () => {
    onSubmit(name);
  };

  const isLoading = loading;

  return (
    <div style={{ display: 'flex' }}>
      <Space align='center'>
        <Input
          disabled={isLoading}
          size='small'
          value={name}
          onChange={e => setName(e.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              name && handleSubmit();
            }
          }}
        />
        <Button
          disabled={isLoading}
          type='primary'
          danger
          size='small'
          shape='circle'
          icon={<CloseOutlined />}
          onClick={onCancel}
        />
        <Button
          loading={isLoading}
          type='primary'
          size='small'
          shape='circle'
          icon={<CheckOutlined />}
          onClick={handleSubmit}
          disabled={!name}
        />
      </Space>
    </div>
  );
};

export default RoleForm;

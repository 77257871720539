import { createAction, createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk, getErrorMessage } from 'app/hooks';
import courseAPI from './courseAPI';
import {
  BaseCourse,
  Course,
  CourseState,
  GetCourseStudentParams,
  GetCoursesQueryParams,
  DeleteCourseStudentParams,
  AddCourseStudentsPayload,
  GetCourseAssetsParams,
  CourseAsset,
  DeleteCourseAssetParams,
  AddCourseAssetsPayload,
  OrderCourseAssetsModel,
  UploadingAlbumAsset,
  UploadingAlbumAssetModel,
  CreateAlbumModel,
  Album,
  DeleteAlbumModel,
  DeleteAlbumAssetModel,
  UpdateAlbumModel,
  AddAlbumAssetsModel,
  InviteCodeModel,
} from './types';

import { MAX_UPLOAD_REQUEST, resetStoreAction } from 'config';
import { InvitationCodeParam, ListResponse } from 'types';
import { Student } from 'features/student/types';
import dbAPI from 'db';
import { UploadingProgressModel } from 'features/asset/types';
import { addCachedStatusToAlbumAsset } from 'utils';

export const getCourses = createAppAsyncThunk(
  'course/getCourses',
  async (params: GetCoursesQueryParams, { rejectWithValue }) => {
    try {
      const response = await courseAPI.getCourses(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createCourse = createAppAsyncThunk(
  'course/createCourse',
  async (course: BaseCourse, { rejectWithValue }) => {
    try {
      const response = await courseAPI.createCourse(course);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateCourse = createAppAsyncThunk(
  'course/updateCourse',
  async (course: Course, { rejectWithValue }) => {
    try {
      const { data } = await courseAPI.updateCourse(course);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteCourse = createAppAsyncThunk(
  'course/deleteCourse',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await courseAPI.deleteCourse(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getCourse = createAppAsyncThunk(
  'course/getCourse',
  async (id: number, { rejectWithValue }) => {
    try {
      const course = await courseAPI.getCourse(id);
      return course;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

// COURSE STUDENTS
export const getCourseStudents = createAppAsyncThunk(
  'course/getCourseStudents',
  async (params: GetCourseStudentParams, { rejectWithValue }) => {
    try {
      const response: ListResponse<Student> = await courseAPI.getCourseStudents(
        params,
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteCourseStudent = createAppAsyncThunk(
  'course/deleteCourseStudent',
  async (params: DeleteCourseStudentParams, { rejectWithValue }) => {
    try {
      const response = await courseAPI.deleteCourseStudent(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const addCourseStudents = createAppAsyncThunk(
  'course/addCourseStudents',
  async (payload: AddCourseStudentsPayload, { rejectWithValue }) => {
    try {
      const response = await courseAPI.addCourseStudents(payload);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getCourseInvitation = createAppAsyncThunk(
  'course/getCourseInvitation',
  async (model: InvitationCodeParam, { rejectWithValue }) => {
    try {
      const { data } = await courseAPI.getCourseInvitation(model);

      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

// COURSE ASSETS
export const getCourseAssets = createAppAsyncThunk(
  'course/getCourseAssets',
  async (
    params: GetCourseAssetsParams,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const response: ListResponse<CourseAsset> =
        await courseAPI.getCourseAssets(params);

      const {
        course: { shouldReorderAssets },
      } = getState();
      if (shouldReorderAssets) {
        const ids = response.results.map(({ id }) => id);
        const orderModel: OrderCourseAssetsModel = {
          ids,
          courseId: Number(params.id),
        };

        await dispatch(orderCourseAssets(orderModel));
      }

      return await dbAPI.addCachedStatus(response);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteCourseAsset = createAppAsyncThunk(
  'course/deleteCourseAsset',
  async (params: DeleteCourseAssetParams, { rejectWithValue }) => {
    try {
      const response = await courseAPI.deleteCourseAsset(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const addCourseAssets = createAppAsyncThunk(
  'course/addCourseAssets',
  async (payload: AddCourseAssetsPayload, { rejectWithValue }) => {
    try {
      const response = await courseAPI.addCourseAssets(payload);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getRoleCourses = createAppAsyncThunk(
  'course/getRoleCourses',
  async (ids: string, { rejectWithValue }) => {
    try {
      const { results }: ListResponse<Course> = await courseAPI.getRoleCourses(
        ids,
      );

      return results;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const orderCourseAssets = createAppAsyncThunk(
  'course/orderCourseAssets',
  async (model: OrderCourseAssetsModel, { rejectWithValue }) => {
    try {
      await courseAPI.orderCourseAssets(model);
      return {};
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

// COURSE ALBUMS
export const getCourseAlbums = createAppAsyncThunk(
  'course/getCourseAlbums',
  async (courseId: string, { rejectWithValue }) => {
    try {
      const { results } = await courseAPI.getCourseAlbums(courseId);
      return dbAPI.addCachedStatusToAlbums(results);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

let numberOfUploadingAlbumAsset = 0;

const updateUploadingAlbumAssetProgress = createAction<UploadingProgressModel>(
  'course/updateUploadingAlbumAssetProgress',
);

export const uploadAlbumAsset = createAppAsyncThunk(
  'course/uploadAlbumAsset',
  async (
    albumFile: UploadingAlbumAssetModel,
    { rejectWithValue, dispatch, getState },
  ) => {
    if (numberOfUploadingAlbumAsset !== MAX_UPLOAD_REQUEST) {
      numberOfUploadingAlbumAsset += 1;

      const updateProgress = (
        uploadingProgressModel: UploadingProgressModel,
      ) => {
        dispatch(updateUploadingAlbumAssetProgress(uploadingProgressModel));
      };

      try {
        const { name } = await courseAPI.uploadAlbumAsset(
          albumFile,
          updateProgress,
        );
        return { path: name, uid: albumFile.uid };
      } catch (err: any) {
        return rejectWithValue({ error: err, albumFile });
      } finally {
        numberOfUploadingAlbumAsset -= 1;
        setTimeout(() => {
          const {
            course: { albumFileModelQueue },
          } = getState();
          if (albumFileModelQueue.length) {
            dispatch(uploadAlbumAsset(albumFileModelQueue[0]));
          }
        }, 100);
      }
    }
  },
);

export const createAlbum = createAppAsyncThunk(
  'course/createAlbum',
  async (
    { courseId, model }: { model: CreateAlbumModel; courseId: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await courseAPI.createAlbum(courseId, model);

      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateAlbum = createAppAsyncThunk(
  'course/updateAlbum',
  async (model: UpdateAlbumModel, { rejectWithValue }) => {
    try {
      const response = await courseAPI.updateAlbum(model);

      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteAlbum = createAppAsyncThunk(
  'course/deleteAlbum',
  async (model: DeleteAlbumModel, { rejectWithValue }) => {
    try {
      await courseAPI.deleteAlbum(model);
      return model.albumId;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const selectAlbum = createAction<Album>('course/selectAlbum');

export const getAlbum = createAppAsyncThunk(
  'course/getAlbum',
  async (
    {
      courseId,
      albumId,
    }: { courseId: string | number; albumId: string | number },
    { rejectWithValue },
  ) => {
    try {
      const album = await courseAPI.getAlbum(courseId, albumId);

      return dbAPI.addCachedStatusToAlbum(album);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteAlbumAsset = createAppAsyncThunk(
  'course/deleteAlbumAsset',
  async (model: DeleteAlbumAssetModel, { rejectWithValue }) => {
    try {
      await courseAPI.deleteAlbumAsset(model);
      return model;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const addAlbumAssets = createAppAsyncThunk(
  'course/addAlbumAssets',
  async (model: AddAlbumAssetsModel, { rejectWithValue, dispatch }) => {
    try {
      await courseAPI.addAlbumAssets(model);
      dispatch(getAlbum({ courseId: model.courseId, albumId: model.albumId }));
      return {};
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getInviteCode = createAppAsyncThunk(
  'course/getInviteCode',
  async (courseId: string, { rejectWithValue }) => {
    try {
      const code = await courseAPI.getInviteCode(courseId);

      return code;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const generateInviteCode = createAppAsyncThunk(
  'course/generateInviteCode',
  async (model: InviteCodeModel, { rejectWithValue }) => {
    try {
      const code = await courseAPI.generateInviteCode(model);

      return code;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateInviteCode = createAppAsyncThunk(
  'course/updateInviteCode',
  async (model: InviteCodeModel, { rejectWithValue }) => {
    try {
      const code = await courseAPI.updateInviteCode(model);

      return code;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: CourseState = {
  getCoursesLoading: false,
  courses: [],
  getCoursesFailed: undefined,
  paginationConfig: {},

  createCourseLoading: false,
  createCourseSuccess: undefined,
  createCourseFailed: undefined,

  updateCourseLoading: false,
  updateCourseSuccess: undefined,
  updateCourseFailed: undefined,

  deleteCourseLoading: false,
  deleteCourseSuccess: undefined,
  deleteCourseFailed: undefined,

  getCourseLoading: false,
  course: undefined,
  getCourseFailed: undefined,

  getCourseStudentsLoading: false,
  courseStudents: [],
  getCourseStudentsFailed: undefined,
  courseStudentPaginationConfig: {},

  deleteCourseStudentLoading: false,
  deleteCourseStudentSuccess: undefined,
  deleteCourseStudentFailed: undefined,

  addCourseStudentsLoading: false,
  addCourseStudentsSuccess: undefined,
  addCourseStudentsFailed: undefined,

  getCourseInvitationLoading: false,
  invitationCode: undefined,
  getCourseInvitationFailed: undefined,

  getCourseAssetsLoading: false,
  courseAssets: [],
  getCourseAssetsFailed: undefined,
  courseAssetsPaginationConfig: {},

  deleteCourseAssetLoading: false,
  deleteCourseAssetSuccess: undefined,
  deleteCourseAssetFailed: undefined,

  addCourseAssetsLoading: false,
  addCourseAssetsSuccess: undefined,
  addCourseAssetsFailed: undefined,

  getRoleCoursesLoading: false,
  roleCourses: [],
  getRoleCoursesFailed: undefined,

  orderCourseAssetsLoading: false,
  orderCourseAssetsSuccess: undefined,
  orderCourseAssetsFailed: undefined,

  shouldReorderAssets: false,

  getCourseAlbumsLoading: false,
  getCourseAlbumsFailed: undefined,
  courseAlbums: [],

  uploadAlbumAssetLoading: false,
  uploadAlbumAssetFailed: undefined,

  uploadingAlbumAssets: [],
  albumFilesQueue: [],
  albumFileModelQueue: [],

  createAlbumLoading: false,
  createAlbumSuccess: undefined,
  createAlbumFailed: undefined,

  updateAlbumLoading: false,
  updateAlbumSuccess: undefined,
  updateAlbumFailed: undefined,

  deleteAlbumLoading: false,
  deleteAlbumSuccess: undefined,
  deleteAlbumFailed: undefined,

  getAlbumLoading: false,
  album: undefined,
  getAlbumFailed: undefined,

  deleteAlbumAssetLoading: false,
  deleteAlbumAssetSuccess: undefined,
  deleteAlbumAssetFailed: undefined,

  addAlbumAssetsLoading: false,
  addAlbumAssetsSuccess: undefined,
  addAlbumAssetsFailed: undefined,

  inviteCode: undefined,
  getInviteCodeFailed: undefined,
  getInviteCodeLoading: true,

  generateInviteCodeLoading: false,
  generateInviteCodeSuccess: undefined,
  generateInviteCodeFailed: undefined,

  updateInviteCodeLoading: false,
  updateInviteCodeFailed: undefined,
  updateInviteCodeSuccess: undefined,
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    resetCourseState() {
      return initialState;
    },

    resetCourseInviteCodeState(state) {
      state.updateInviteCodeSuccess = undefined;
      state.generateInviteCodeSuccess = undefined;
      state.updateInviteCodeFailed = undefined;
      state.generateInviteCodeFailed = undefined;
    },

    resetRoleCourses(state) {
      state.roleCourses = [];
    },

    setCourseAssets(state, action) {
      state.courseAssets = action.payload;
    },

    setShouldReorderCourseAssets(state, action) {
      state.shouldReorderAssets = action.payload;
    },

    selectAlbum(state, action) {
      state.album = action.payload;
    },

    resetUploadingAlbumAssets(state) {
      state.uploadingAlbumAssets = [];
      state.albumFilesQueue = [];
    },

    removeUploadingAlbumAssets(state, { payload }) {
      state.uploadingAlbumAssets = state.uploadingAlbumAssets.filter(
        ({ uid }) => uid !== payload,
      );
    },

    addAlbumAssetBase64(state, { payload }) {
      const asset = state.uploadingAlbumAssets.find(
        ({ uid }) => uid === payload.uid,
      );

      if (asset) {
        asset.base64 = payload.base64;
      }
    },

    addCachedStatus(state, { payload: assetId }) {
      if (state.album) {
        state.album = addCachedStatusToAlbumAsset(state.album, assetId);
      }

      if (state.courseAlbums.length) {
        state.courseAlbums = state.courseAlbums.map(album => {
          return addCachedStatusToAlbumAsset(album, assetId);
        });
      }
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getCourses.pending, (state, action) => {
        const {
          meta: {
            arg: { page, page_size },
          },
        } = action;

        state.getCoursesLoading = true;
        state.paginationConfig.current = Number(page);
        state.paginationConfig.pageSize = Number(page_size);
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.getCoursesLoading = false;
        state.courses = action.payload.results;
        state.paginationConfig.total = action.payload.count;
      })
      .addCase(getCourses.rejected, (state, action) => {
        state.getCoursesLoading = false;
        state.getCoursesFailed = action.payload;
      })

      .addCase(createCourse.pending, state => {
        state.createCourseLoading = true;
        state.createCourseFailed = undefined;
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        state.createCourseLoading = false;
        state.createCourseSuccess = action.payload;
      })
      .addCase(createCourse.rejected, (state, action) => {
        state.createCourseLoading = false;
        state.createCourseFailed = action.payload;
      })

      .addCase(updateCourse.pending, state => {
        state.updateCourseLoading = true;
        state.updateCourseFailed = undefined;
      })
      .addCase(updateCourse.fulfilled, (state, { payload }) => {
        state.updateCourseLoading = false;
        state.updateCourseSuccess = payload;
        state.courses = state.courses.map(course =>
          course.id !== payload.id ? course : payload,
        );
      })
      .addCase(updateCourse.rejected, (state, action) => {
        state.updateCourseLoading = false;
        state.updateCourseFailed = action.payload;
      })

      .addCase(deleteCourse.pending, state => {
        state.deleteCourseLoading = true;
        state.deleteCourseFailed = undefined;
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.deleteCourseLoading = false;
        state.deleteCourseSuccess = action.payload;
      })
      .addCase(deleteCourse.rejected, (state, action) => {
        state.deleteCourseLoading = false;
        state.deleteCourseFailed = action.payload;
      })

      .addCase(getCourse.pending, state => {
        state.getCourseLoading = true;
        state.getCourseFailed = undefined;
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.getCourseLoading = false;
        state.course = action.payload;
      })
      .addCase(getCourse.rejected, (state, action) => {
        state.getCourseLoading = false;
        state.getCourseFailed = action.payload;
      })

      .addCase(getCourseStudents.pending, (state, action) => {
        state.getCourseStudentsLoading = true;
        state.getCourseStudentsFailed = undefined;

        const {
          meta: {
            arg: {
              params: { page, page_size },
            },
          },
        } = action;

        state.getCoursesLoading = true;
        state.courseStudentPaginationConfig.current = Number(page);
        state.courseStudentPaginationConfig.pageSize = Number(page_size);
      })
      .addCase(getCourseStudents.fulfilled, (state, { payload }) => {
        state.getCourseStudentsLoading = false;
        state.courseStudents = payload.results;
        state.courseStudentPaginationConfig.total = payload.count;
      })
      .addCase(getCourseStudents.rejected, (state, action) => {
        state.getCourseStudentsLoading = false;
        state.getCourseStudentsFailed = action.payload;
      })

      .addCase(deleteCourseStudent.pending, state => {
        state.deleteCourseStudentLoading = true;
        state.deleteCourseStudentFailed = undefined;
      })
      .addCase(deleteCourseStudent.fulfilled, (state, action) => {
        state.deleteCourseStudentLoading = false;
        state.deleteCourseStudentSuccess = action.payload;
      })
      .addCase(deleteCourseStudent.rejected, (state, action) => {
        state.deleteCourseStudentLoading = false;
        state.deleteCourseStudentFailed = action.payload;
      })

      .addCase(addCourseStudents.pending, state => {
        state.addCourseStudentsLoading = true;
        state.addCourseStudentsFailed = undefined;
      })
      .addCase(addCourseStudents.fulfilled, (state, action) => {
        state.addCourseStudentsLoading = false;
        state.addCourseStudentsSuccess = action.payload;
      })
      .addCase(addCourseStudents.rejected, (state, action) => {
        state.addCourseStudentsLoading = false;
        state.addCourseStudentsFailed = action.payload;
      })

      .addCase(getCourseInvitation.pending, state => {
        state.getCourseInvitationLoading = true;
        state.getCourseInvitationFailed = undefined;
        state.invitationCode = undefined;
      })
      .addCase(getCourseInvitation.fulfilled, (state, action) => {
        state.getCourseInvitationLoading = false;
        state.invitationCode = action.payload;
      })
      .addCase(getCourseInvitation.rejected, (state, action) => {
        state.getCourseInvitationLoading = false;
        state.getCourseInvitationFailed = action.payload;
      })

      // COURSE ASSETS
      .addCase(getCourseAssets.pending, (state, action) => {
        state.getCourseAssetsLoading = true;
        state.getCourseAssetsFailed = undefined;

        const {
          meta: {
            arg: {
              params: { page, page_size },
            },
          },
        } = action;

        state.getCoursesLoading = true;
        state.courseAssetsPaginationConfig.current = Number(page);
        state.courseAssetsPaginationConfig.pageSize = Number(page_size);
      })
      .addCase(getCourseAssets.fulfilled, (state, { payload }) => {
        state.getCourseAssetsLoading = false;
        state.courseAssets = payload.results;
        state.courseAssetsPaginationConfig.total = payload.count;
        state.shouldReorderAssets = false;
      })
      .addCase(getCourseAssets.rejected, (state, action) => {
        state.getCourseAssetsLoading = false;
        state.getCourseAssetsFailed = action.payload;
        state.shouldReorderAssets = false;
      })

      .addCase(deleteCourseAsset.pending, state => {
        state.deleteCourseAssetLoading = true;
        state.deleteCourseAssetFailed = undefined;
      })
      .addCase(deleteCourseAsset.fulfilled, (state, action) => {
        state.deleteCourseAssetLoading = false;
        state.deleteCourseAssetSuccess = action.payload;
      })
      .addCase(deleteCourseAsset.rejected, (state, action) => {
        state.deleteCourseAssetLoading = false;
        state.deleteCourseAssetFailed = action.payload;
      })

      .addCase(addCourseAssets.pending, state => {
        state.addCourseAssetsLoading = true;
        state.addCourseAssetsFailed = undefined;
      })
      .addCase(addCourseAssets.fulfilled, (state, action) => {
        state.addCourseAssetsLoading = false;
        state.addCourseAssetsSuccess = action.payload;
      })
      .addCase(addCourseAssets.rejected, (state, action) => {
        state.addCourseAssetsLoading = false;
        state.addCourseAssetsFailed = action.payload;
      })

      .addCase(getRoleCourses.pending, state => {
        state.getRoleCoursesLoading = true;
      })
      .addCase(getRoleCourses.fulfilled, (state, action) => {
        state.getRoleCoursesLoading = false;
        state.roleCourses = action.payload;
      })
      .addCase(getRoleCourses.rejected, (state, action) => {
        state.getRoleCoursesLoading = false;
        state.getRoleCoursesFailed = action.payload;
      })

      .addCase(orderCourseAssets.pending, state => {
        state.orderCourseAssetsLoading = true;
        state.orderCourseAssetsFailed = undefined;
      })
      .addCase(orderCourseAssets.fulfilled, (state, action) => {
        state.orderCourseAssetsLoading = false;
        state.orderCourseAssetsSuccess = action.payload;
      })
      .addCase(orderCourseAssets.rejected, (state, action) => {
        state.orderCourseAssetsLoading = false;
        state.orderCourseAssetsFailed = action.payload;
      })

      // COURSE ALBUMS
      .addCase(getCourseAlbums.pending, state => {
        state.getCourseAlbumsLoading = true;
        state.getCourseAlbumsFailed = undefined;
      })
      .addCase(getCourseAlbums.fulfilled, (state, action) => {
        state.getCourseAlbumsLoading = false;
        state.courseAlbums = action.payload;
      })
      .addCase(getCourseAlbums.rejected, (state, action) => {
        state.getCourseAlbumsLoading = false;
        state.getCourseAlbumsFailed = action.payload;
      })

      .addCase(uploadAlbumAsset.pending, (state, { meta: { arg } }) => {
        const uploadingAlbumAsset: UploadingAlbumAsset = {
          uid: arg.uid,
          status: 'inprogress',
          progress: 0,
          name: arg.file.name,
          type: arg.file?.type || 'file',
        };

        if (numberOfUploadingAlbumAsset < MAX_UPLOAD_REQUEST) {
          state.uploadingAlbumAssets.push(uploadingAlbumAsset);
          state.albumFilesQueue = state.albumFilesQueue.filter(file => {
            return file.uid !== uploadingAlbumAsset.uid;
          });
          state.albumFileModelQueue = state.albumFileModelQueue.filter(
            model => {
              return model.uid !== arg.uid;
            },
          );
        } else {
          state.albumFilesQueue.push({
            ...uploadingAlbumAsset,
            status: 'queue',
          });
          state.albumFileModelQueue.push(arg);
        }

        state.uploadAlbumAssetLoading = true;
        state.uploadAlbumAssetFailed = undefined;
      })

      .addCase(
        uploadAlbumAsset.fulfilled,
        (state, { payload: uploadedAlbumAsset }) => {
          if (uploadedAlbumAsset) {
            state.uploadingAlbumAssets = state.uploadingAlbumAssets.map(file =>
              file.uid !== uploadedAlbumAsset.uid
                ? file
                : {
                    ...file,
                    status: 'succeeded',
                    path: uploadedAlbumAsset.path,
                  },
            );
          }

          const uploadingFile = state.uploadingAlbumAssets.find(
            ({ status }) => status === 'inprogress',
          );
          const isUploadedAll = !uploadingFile && !state.albumFilesQueue.length;
          if (isUploadedAll) {
            state.uploadAlbumAssetLoading = false;
          }
        },
      )
      .addCase(uploadAlbumAsset.rejected, (state, action) => {
        const { albumFile, error }: any = action.payload;

        state.uploadingAlbumAssets = state.uploadingAlbumAssets.map(file =>
          file.uid !== albumFile.uid
            ? file
            : {
                ...file,
                status: 'failed',
                errorMessage: getErrorMessage(error),
              },
        );

        const uploadingFile = state.uploadingAlbumAssets.find(
          ({ status }) => status === 'inprogress',
        );
        const isUploadedAll = !uploadingFile && !state.albumFilesQueue.length;
        if (isUploadedAll) {
          state.uploadAlbumAssetLoading = false;
        }
      })

      .addCase(createAlbum.pending, state => {
        state.createAlbumLoading = true;
        state.createAlbumSuccess = undefined;
        state.createAlbumFailed = undefined;
      })
      .addCase(createAlbum.fulfilled, (state, { payload }) => {
        state.createAlbumLoading = false;
        state.createAlbumSuccess = payload;
      })
      .addCase(createAlbum.rejected, (state, { payload }) => {
        state.createAlbumLoading = false;
        state.createAlbumFailed = payload;
      })

      .addCase(deleteAlbum.pending, state => {
        state.deleteAlbumLoading = true;
        state.deleteAlbumSuccess = undefined;
        state.deleteAlbumFailed = undefined;
      })
      .addCase(deleteAlbum.fulfilled, (state, { payload: deletedAlbumId }) => {
        state.deleteAlbumLoading = false;
        state.deleteAlbumSuccess = deletedAlbumId;
        state.courseAlbums = state.courseAlbums.filter(
          ({ id }) => id !== Number(deletedAlbumId),
        );
      })
      .addCase(deleteAlbum.rejected, (state, { payload }) => {
        state.deleteAlbumLoading = false;
        state.deleteAlbumFailed = payload;
      })

      .addCase(getAlbum.pending, state => {
        state.getAlbumLoading = true;
        state.album = undefined;
        state.getAlbumFailed = undefined;
      })
      .addCase(getAlbum.fulfilled, (state, { payload }) => {
        state.getAlbumLoading = false;
        state.album = payload;
      })
      .addCase(getAlbum.rejected, (state, { payload }) => {
        state.getAlbumLoading = false;
        state.getAlbumFailed = payload;
      })

      .addCase(deleteAlbumAsset.pending, state => {
        state.deleteAlbumAssetLoading = true;
        state.deleteAlbumAssetFailed = undefined;
      })
      .addCase(deleteAlbumAsset.fulfilled, (state, { payload }) => {
        state.deleteAlbumAssetLoading = false;
        state.deleteAlbumAssetSuccess = payload;

        const albumInList = state.courseAlbums.find(
          ({ id }) => id === payload.albumId,
        );

        if (albumInList) {
          albumInList.assets = albumInList.assets.filter(
            ({ id }) => id !== payload.assetId,
          );
        }

        if (state.album) {
          state.album.assets = state.album.assets.filter(
            ({ id }) => id !== payload.assetId,
          );
        }
      })
      .addCase(deleteAlbumAsset.rejected, (state, action) => {
        state.deleteAlbumAssetLoading = false;
        state.deleteAlbumAssetFailed = action.payload;
      })

      .addCase(updateAlbum.pending, state => {
        state.updateAlbumLoading = true;
        state.updateAlbumFailed = undefined;
      })
      .addCase(updateAlbum.fulfilled, (state, { payload }) => {
        state.updateAlbumLoading = false;
        state.album = payload.data;
        state.updateAlbumSuccess = payload.data;
      })
      .addCase(updateAlbum.rejected, (state, { payload }) => {
        state.updateAlbumLoading = false;
        state.updateAlbumFailed = payload;
      })

      .addCase(addAlbumAssets.pending, state => {
        state.addAlbumAssetsLoading = true;
        state.addAlbumAssetsFailed = undefined;
      })
      .addCase(addAlbumAssets.fulfilled, (state, { payload }) => {
        state.addAlbumAssetsLoading = false;
        state.addAlbumAssetsSuccess = payload;
      })
      .addCase(addAlbumAssets.rejected, (state, { payload }) => {
        state.addAlbumAssetsLoading = false;
        state.addAlbumAssetsFailed = payload;
      })

      .addCase(updateUploadingAlbumAssetProgress, (state, { payload }) => {
        state.uploadingAlbumAssets = state.uploadingAlbumAssets.map(asset => {
          return asset.uid === payload.uid
            ? {
                ...asset,
                progress: payload.percentCompleted,
              }
            : asset;
        });
      })

      .addCase(getInviteCode.pending, state => {
        state.getInviteCodeLoading = true;
        state.getInviteCodeFailed = undefined;
      })
      .addCase(getInviteCode.fulfilled, (state, { payload }) => {
        state.getInviteCodeLoading = false;
        state.inviteCode = payload;
      })
      .addCase(getInviteCode.rejected, (state, { payload }) => {
        state.getInviteCodeLoading = false;
        state.getInviteCodeFailed = payload;
      })

      .addCase(generateInviteCode.pending, state => {
        state.generateInviteCodeLoading = true;
        state.generateInviteCodeFailed = undefined;
      })
      .addCase(generateInviteCode.fulfilled, (state, { payload }) => {
        state.generateInviteCodeLoading = false;
        state.generateInviteCodeSuccess = payload;
        state.inviteCode = payload.data;
      })
      .addCase(generateInviteCode.rejected, (state, { payload }) => {
        state.generateInviteCodeLoading = false;
        state.generateInviteCodeFailed = payload;
      })

      .addCase(updateInviteCode.pending, state => {
        state.updateInviteCodeLoading = true;
        state.updateInviteCodeFailed = undefined;
      })
      .addCase(updateInviteCode.fulfilled, (state, { payload }) => {
        state.updateInviteCodeLoading = false;
        state.updateInviteCodeSuccess = payload;
      })
      .addCase(updateInviteCode.rejected, (state, { payload }) => {
        state.updateInviteCodeLoading = false;
        state.updateInviteCodeFailed = payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const {
  resetCourseState,
  resetRoleCourses,
  setCourseAssets,
  setShouldReorderCourseAssets,
  resetUploadingAlbumAssets,
  removeUploadingAlbumAssets,
  addAlbumAssetBase64,
  addCachedStatus,
  resetCourseInviteCodeState
} = courseSlice.actions;

export default courseSlice.reducer as Reducer<CourseState>;

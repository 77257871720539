import { useState, Fragment, useEffect } from 'react';
import { Table, Tag, Modal } from 'antd';
import {
  ASSET_MODAL_QUERY_PARAMS,
  useAssetsModal,
  useResetAssetState,
} from 'features/asset/assetHooks';
import { FolderOutlined, LoadingOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { Asset } from 'features/asset/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useTableSorting } from 'app/hooks';
import Filter from './Filter';
import { TableRowSelection } from 'antd/es/table/interface';
import { getDisplayingDate } from 'utils';
import FileExtensionIcon from 'components/Common/FileExtensionIcon';
import color from 'config/color';

const AssetsModal = ({
  isOpen,
  onClose,
  onSubmit,
  addingLoading = false,
  initValues = [],
  isAcceptEmpty = false,
  movingAsset,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (assetIds: number[]) => void;
  addingLoading?: boolean;
  initValues?: number[];
  isAcceptEmpty?: boolean;
  movingAsset?: Asset;
}) => {
  const { t } = useTranslation(LOCALES.ASSET);

  const {
    getAssetOptionsLoading,
    assetOptions,
    loadingAssetOptionId,
    isLoadingMoreAssetOptions,
    assetOptionsPaginationConfig,
    getChildrenAssets,
    urlQueryParams,
  } = useAssetsModal(isOpen);

  useResetAssetState();

  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  const { handleSorting, getColumnSorting } = useTableSorting(
    ASSET_MODAL_QUERY_PARAMS.ORDERING,
  );

  const [selectedAssetIds, setSelectedAssetIds] = useState<any[]>([]);

  const rowSelection: TableRowSelection<Asset> = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedAssetIds(selectedRowKeys);
    },
    getCheckboxProps: asset => {
      const disabled = movingAsset
        ? !asset.folder ||
          asset.id === movingAsset.parent ||
          asset.id === movingAsset.id
        : asset.disabled;
      return {
        disabled,
        className: disabled ? 'disabled-selection-row' : '',
      };
    },
    type: movingAsset ? 'radio' : 'checkbox',
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedAssetIds,
    checkStrictly: isAcceptEmpty ? true : false,
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedAssetIds([]);
    } else {
      setSelectedAssetIds(initValues);
    }
    setExpandedRowKeys([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQueryParams, isOpen]);

  let columns: ColumnsType<Asset> = [
    {
      title: t('name'),
      key: 'name',
      ...getColumnSorting('name'),
      width: 600,
      render: (_, rc) => {
        if (rc.isLoadMoreRecord) {
          return (
            <span
              onClick={() => {
                rc.parent &&
                  getChildrenAssets({
                    parent: rc.parent,
                    page: rc.nextPage,
                    isLoadMore: true,
                  });
              }}
              style={{
                cursor: loadingAssetOptionId ? 'not-allow' : 'pointer',
                color: '#1677ff',
              }}>
              {t('loadMore')}{' '}
              {loadingAssetOptionId === rc.parent && <LoadingOutlined />}
            </span>
          );
        }

        return (
          <Fragment>
            {rc.folder ? (
              <FolderOutlined style={{ color: color.primary }} />
            ) : (
              <FileExtensionIcon extension={rc.extension || ''} />
            )}{' '}
            <span>
              {rc.name}{' '}
              {loadingAssetOptionId === rc.id && !isLoadingMoreAssetOptions && (
                <LoadingOutlined />
              )}
            </span>
          </Fragment>
        );
      },
    },
    {
      title: t('tags'),
      key: 'tags',
      dataIndex: 'tags',
      width: 300,
      render: (tags: string) => {
        return tags
          ? tags.split(',').map(tag => (
              <Tag style={{ marginTop: 5 }} key={tag}>
                {tag}
              </Tag>
            ))
          : '';
      },
      ...getColumnSorting('tags'),
    },

    {
      title: t('lastModified'),
      key: 'updated_at',
      dataIndex: 'updated_at',
      ...getColumnSorting('updated_at'),
      width: 200,
      render: (date, rc) => {
        return !rc.isLoadMoreRecord && getDisplayingDate(date);
      },
    },
  ];

  const handleSubmit = () => {
    const ids = selectedAssetIds.filter(id => typeof id === 'number');
    onSubmit(ids);
  };

  return (
    <Modal
      width={1100}
      title={t('assets')}
      open={isOpen}
      onCancel={onClose}
      onOk={() => handleSubmit()}
      cancelButtonProps={{ disabled: addingLoading }}
      okButtonProps={{
        loading: addingLoading,
        disabled: !isAcceptEmpty && !selectedAssetIds.length,
      }}
      okText={t('ok')}
      cancelText={t('cancel')}>
      <div style={{ marginTop: 20 }}>
        <Filter loading={getAssetOptionsLoading} />
      </div>

      <Table
        loading={getAssetOptionsLoading}
        columns={columns}
        dataSource={assetOptions}
        rowKey={rc => rc.id}
        pagination={assetOptionsPaginationConfig}
        expandable={{
          onExpand: (isExpanded, asset) => {
            if (isExpanded && !asset.expanded && asset.id !== movingAsset?.id) {
              getChildrenAssets({ parent: asset.id });
            }
          },
          expandedRowKeys: expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
        onChange={handleSorting}
        rowSelection={rowSelection}
      />
    </Modal>
  );
};

export default AssetsModal;

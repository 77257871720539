const en = {
  create: 'Tạo mới',
  cancel: 'Huỷ',
  update: 'Cập nhật',
  delete: 'Xoá',
  add: 'Thêm',

  createClass: 'Tạo mới lớp học',
  updateClass: 'Cập nhật lớp học',

  Classes: 'Lớp học',
  classesList: 'Danh sách lớp học',
  no: 'STT',
  name: 'Tên',
  status: 'Trạng thái',
  numberOfStudent: 'Số học sinh',
  description: 'Mô tả',

  class: 'Lớp học',
  teacher: 'Giáo viên',
  ordering: 'Sắp xếp theo',

  pleaseInputClassName: 'Vui lòng nhập tên lớp học',
  search: 'Tìm kiếm',
  enterName: 'Nhập tên lớp học',
  enterTeacherName: 'Nhập tên giáo viên',
  enterOrderingField: '',
  keyword: 'Từ khoá',
  enterKeyword: 'Nhập từ khoá',

  preparing: 'Đang chuẩn bị',
  ended: 'Đã kết thúc',
  started: 'Đã bắt đầu',

  logo: 'Logo',
  created: 'Ngày tạo',

  deleteWarning: 'Bạn có chắc muốn xóa lớp học này không?',

  student: 'Học sinh',
  document: 'Tài liệu',

  courseInformation: 'Thông tin lớp học',
  invitationCode: 'Mã mời',
  download: 'Tải xuống',

  album: 'Album',
  createAlbum: 'Tạo album',
  albumName: 'Tên album',
  uploadPhotosOrVideos: 'Tải Ảnh hoặc Videos',
  submit: 'Lưu',
  dragAlbumTitle: 'Ready to add something?',
  dragAlbumDescription: 'Drag photos and videos here to get started.',

  albumNameIsRequired: 'Vui lòng nhập album name!',
  item: 'item',
  items: 'items',
  deleteAlbumWarning: 'Bạn có chắc muốn xóa album này không?',

  updateAlbum: 'Cập nhật album',
  deleteAlbum: 'Xoá album',

  deleteAlbumAssetWarning: 'Bạn có chắc muốn xóa file này không?',
  addToAlbum: 'Thêm vào Album',
  qrCode: 'QR Code'
};

export default en;

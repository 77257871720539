import { Table } from 'antd';
import { useResourceFields } from 'features/role/roleHooks';

const FieldTable = ({ resourceKey }: { resourceKey: string }) => {
  const { columns, dataSource } = useResourceFields(resourceKey);

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowKey='id'
      pagination={false}
    />
  );
};

export default FieldTable;

const vi = {
  no: 'STT',
  create: 'Tạo mới',
  cancel: 'Huỷ',
  update: 'Cập nhật',
  delete: 'Xoá',
  createdBy: 'Người tạo',
  add: 'Thêm',

  createPlaylist: 'Tạo mới Playlist',
  updatePlaylist: 'Cập nhật Playlist',

  playlists: 'Danh sách Playlist',
  playlistCode: 'Xem share code',
  playlistName: 'Tên playlist',
  deleteWarning: 'Bạn có chắc chắn muốn xóa playlist này không?',
  photo: 'Hình ảnh',
  auth: 'Người tạo',
  descriptions: 'Mô tả',
  name: 'Tên',
  author: 'Tác giả',
  description: 'Mô tả',
  playlistCover: 'Ảnh bìa',
  chapterList: 'Danh sách file của sách',
  enterName: 'Nhập tên Playlist',
  enterAuthor: 'Nhập tên tác giả',
  enterDescription: 'Nhập mô tả',
  importChapterList: 'Nhập danh sách file của sách',
  inviteCode: 'Mã mời',
  updatedAt: 'Cập nhật',
  createdAt: 'Ngày tạo',

  keyword: 'Từ khoá',
  enterKeyword: 'Nhập từ khoá',

  pleaseInputPlaylistName: 'Vui lòng nhập tên playlist!',
  playlistInformation: 'Thông tin Playlist',
};

export default vi;

import { useAssetViewer, useAssetViewerUrl } from 'features/asset/assetHooks';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

const VideoModal = () => {
  const { isVideoModalOpen, downloadedFile, onCloseAssetViewer } =
    useAssetViewer();
  const url = useAssetViewerUrl(isVideoModalOpen, downloadedFile);

  return (
    <ModalVideo
      channel='custom'
      isOpen={isVideoModalOpen}
      url={url}
      onClose={() => onCloseAssetViewer()}
    />
  );
};

export default VideoModal;

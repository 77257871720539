import React, { Fragment, useState } from 'react';
import { HasFilterPageContent, PageHeader } from 'components/Common/Page';
import {
  Button,
  Table,
  Dropdown,
  Popconfirm as PopConfirm,
  Space,
  Image,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Playlist } from 'features/playlist/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useDeletePlaylist,
  useGetPlaylistInvitationCode,
  usePlaylists,
  useResetPlaylistState,
} from 'features/playlist/playlistHooks';
import PlaylistForm from 'features/playlist/Playlists/PlaylistForm';
import type { MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTableSorting } from 'app/hooks';
import { QrcodeOutlined } from '@ant-design/icons';
import QRCodeModal from 'components/Common/QRCodeModal';
import SearchBox from 'components/Common/SearchBox';

const initValues = {
  name: undefined,
  id: undefined,
  descriptions: undefined,
};

const Playlists = () => {
  const { t } = useTranslation(LOCALES.PLAYLIST);
  const { handleSorting, getColumnSorting } = useTableSorting();

  const {
    playlists,
    getPlaylistsLoading,
    paginationConfig,
    refreshAfterCreating,
    refreshAfterDeleting,
  } = usePlaylists();

  useResetPlaylistState();

  const {
    invitationCode,
    handleGetPlaylistInvitationCode,
    getPlaylistInvitationCodeLoading,
  } = useGetPlaylistInvitationCode();
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

  const onGetInvitationCode = (id: number) => {
    setIsQRCodeModalOpen(true);
    handleGetPlaylistInvitationCode(id);
  };

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [initialPlaylist, setInitialPlaylist] = useState<any>(initValues);

  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);
  const { handleDeletePlaylist, deletePlaylistLoading } = useDeletePlaylist(
    () => {
      setOpenedPopConfirm(-1);
      refreshAfterDeleting();
    },
  );

  const onOpenCreateForm = () => {
    setIsFormOpen(true);
    setInitialPlaylist(initValues);
  };

  const onOpenEditForm = (playlist: Playlist) => {
    setIsFormOpen(true);
    setInitialPlaylist({
      ...playlist,
    });
  };

  const onCloseForm = () => {
    setIsFormOpen(false);
    setInitialPlaylist(initValues);
  };

  const columns: ColumnsType<Playlist> = [
    {
      title: t('name'),
      key: 'name',
      ...getColumnSorting('name'),

      render: record => {
        return (
          <Space>
            <Image
              placeholder={true}
              style={{ objectFit: 'contain' }}
              width={30}
              height={30}
              src={record.photo}
            />
            <Link to={`/playlists/${record.id}/details`}>{record.name}</Link>
          </Space>
        );
      },
    },

    { title: t('description'), key: 'description', dataIndex: 'descriptions' },
    {
      title: t('inviteCode'),
      key: 'inviteCode',
      width: 120,
      render: ({ id }) => (
        <Button
          onClick={() => onGetInvitationCode(id)}
          type='text'
          icon={<QrcodeOutlined />}></Button>
      ),
      align: 'center',
    },
    {
      title: t('createdBy'),
      key: 'createdBy',
      width: 120,
      render: ({ created_by }) => created_by?.name,
    },

    {
      title: '',
      width: 50,
      render: (playlist: Playlist) => {
        const items: MenuProps['items'] = [
          {
            label: (
              <p
                className='styled-table__action-item'
                onClick={() => onOpenEditForm(playlist)}>
                {t('update')}
              </p>
            ),
            key: 'Edit',
          },
          { type: 'divider' },
          {
            label: (
              <p
                className='styled-table__action-item color-error'
                onClick={() => setOpenedPopConfirm(playlist.id)}>
                {t('delete')}
              </p>
            ),
            key: 'Delete',
          },
        ];
        return (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            overlayStyle={{ width: 150 }}
            overlayClassName='styled-table__actions'
            placement='bottomRight'>
            <PopConfirm
              placement='topRight'
              title={t('deleteWarning')}
              onConfirm={() => handleDeletePlaylist(playlist.id)}
              okText={t('delete')}
              cancelText={t('cancel')}
              okButtonProps={{ loading: deletePlaylistLoading }}
              cancelButtonProps={{ disabled: deletePlaylistLoading }}
              open={openedPopConfirm === playlist.id}
              onCancel={() => setOpenedPopConfirm(-1)}>
              <Button size='small' type='text'>
                <EllipsisOutlined />
              </Button>
            </PopConfirm>
          </Dropdown>
        );
      },
      key: 'action',
    },
  ];

  return (
    <Fragment>
      <HasFilterPageContent>
        <PageHeader title={t('playlists')}>
          <Space>
            <SearchBox style={{ marginBottom: 0, marginRight: 50 }} />
            <Button type='primary' onClick={onOpenCreateForm}>
              {t('create')}
            </Button>
          </Space>
        </PageHeader>

        <Table
          loading={getPlaylistsLoading}
          dataSource={playlists}
          columns={columns}
          pagination={paginationConfig}
          rowKey='id'
          onChange={handleSorting}
        />
      </HasFilterPageContent>

      <PlaylistForm
        isOpen={isFormOpen}
        onClose={onCloseForm}
        refreshAfterCreating={refreshAfterCreating}
        initValues={initialPlaylist}
      />

      <QRCodeModal
        qrCode={invitationCode}
        isOpen={isQRCodeModalOpen}
        onClose={() => setIsQRCodeModalOpen(false)}
        title={t('playlistCode')}
        isLoading={getPlaylistInvitationCodeLoading}
      />
    </Fragment>
  );
};

export default Playlists;

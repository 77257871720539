import { useCallback, useEffect, useMemo } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  useSuccess,
} from 'app/hooks';
import { useTranslation } from 'react-i18next';
import {
  updateRole,
  getRoleDetails,
  toggleSelectPermission,
  selectAllResourcesPermission,
  deselectAllResourcesPermission,
  onSelectedObjectChanged,
  toggleSelectObject,
  toggleSelectField,
  toggleSelectAllField,
  setPermissionIds,
  createRole,
  copyRole,
  deleteRole,
} from './roleSlice';
import { RoleTableDataItem, UseGetRoles } from './types';
import { getRoles } from './roleSlice';
import { LOCALES } from 'app/i18n';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from 'antd';
import PATHS, { getCustomizePath } from 'router/paths';
import {
  getPermissionsHasObjectConfig,
  isAllFieldChecked,
  isAllResourceHasThePermission,
  getResourceObjectIds,
} from './roleUtils';
import { SettingOutlined } from '@ant-design/icons';
import { HAS_CUSTOM_PERMISSION, OWNER_ROLE_NAME } from 'config';

export const useRoles: UseGetRoles = fetchPermissionAlso => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getRolesLoading = useAppSelector(state => state.role.getRolesLoading);
  const roles = useAppSelector(state => state.role.roles);
  const getRolesFailed = useAppSelector(state => state.role.getRolesFailed);
  const getStaffsLoading = useAppSelector(
    state => state.staff.getStaffsLoading,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRoles(fetchPermissionAlso || false));
  }, [dispatch, fetchPermissionAlso]);

  useFailed(getRolesFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    getRolesLoading,
    roles,
    getRolesFailed,
    getStaffsLoading,
  };
};

export const useRefreshRoles = () => {
  const dispatch = useAppDispatch();
  const refreshRoles = () => {
    dispatch(getRoles(false));
  };

  return refreshRoles;
};

export const useCheckOwnerRole = () => {
  const roles = useAppSelector(state => state.role.roles);
  const { roleId } = useParams();

  const currentRole = roles.find(role => role.id === Number(roleId));
  return currentRole?.name === OWNER_ROLE_NAME;
};

export const useRoleOptions = () => {
  const roles = useAppSelector(state => state.role.roles);
  return roles.map(role => ({
    value: role.id,
    label: role.name,
  }));
};

export const useRoleDetails = () => {
  const { roleId } = useParams();
  const getRolesLoading = useAppSelector(state => state.role.getRolesLoading);
  const predefinedPermissions = useAppSelector(
    state => state.role.predefinedPermissions,
  );
  const roles = useAppSelector(state => state.role.roles);
  const permissionJson = useAppSelector(state => state.role.permissionJson);
  const roleDetails = useAppSelector(state => state.role.roleDetails);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const nextRoleDetails = roles.find(({ id }) => id === Number(roleId));
    if (roleId && nextRoleDetails?.id !== roleDetails?.id) {
      dispatch(getRoleDetails(roleId));
    }
  }, [dispatch, roleId, roleDetails, roles]);

  return {
    getRolesLoading,
    predefinedPermissions,
    roles,
    permissionJson,
    roleDetails,
  };
};

export const useResourceObjects = () => {
  const { t } = useTranslation(LOCALES.ROLE);
  const predefinedPermissions = useAppSelector(
    state => state.role.predefinedPermissions,
  );
  const permissionJson = useAppSelector(state => state.role.permissionJson);
  const getRolesLoading = useAppSelector(state => state.role.getRolesLoading);
  const roles = useAppSelector(state => state.role.roles);

  const dispatch = useAppDispatch();

  const getUpdatedResourceObjects = (
    resourceKey: string,
    nextIds: number[],
  ) => {
    const currentIds = getResourceObjectIds({ permissionJson, resourceKey });
    const addedIds = nextIds.filter(id => !currentIds[id]);
    const removedIds = Object.keys(currentIds)
      .filter(id => !nextIds.includes(Number(id)))
      .map(id => Number(id));

    return {
      addedIds,
      removedIds,
    };
  };

  const onSelectedObjectsChanged = (resourceKey: string, nextIds: number[]) => {
    const { addedIds, removedIds } = getUpdatedResourceObjects(
      resourceKey,
      nextIds,
    );
    dispatch(onSelectedObjectChanged({ resourceKey, addedIds, removedIds }));
  };

  const onToggleSelectObject = ({
    resourceKey,
    permissionKey,
    objectId,
  }: {
    resourceKey: string;
    permissionKey: string;
    objectId: number;
  }) => {
    dispatch(toggleSelectObject({ resourceKey, permissionKey, objectId }));
  };

  const getResourceObjectColumns = (resourceKey: string) => {
    if (!permissionJson || !predefinedPermissions) {
      return [];
    }

    const permissionHasObjectConfig = getPermissionsHasObjectConfig(
      resourceKey,
      predefinedPermissions,
    );

    return permissionHasObjectConfig.reduce(
      (columns: any[], permissionKey: string) => {
        const ids = permissionJson[resourceKey]?.[permissionKey]?.ids;
        const currentIds = getResourceObjectIds({
          permissionJson,
          resourceKey,
        });

        const idsArray = Object.keys(currentIds).map(id => Number(id));
        const hasObjects = idsArray.length;
        const isChecked =
          ids && (ids === true || ids.length === idsArray.length);

        return [
          ...columns,
          {
            title: (
              <div>
                <div>{t(permissionKey)}</div>

                {hasObjects ? (
                  <Checkbox
                    checked={isChecked}
                    onClick={() => {
                      dispatch(
                        setPermissionIds({
                          resourceKey,
                          permissionKey,
                          ids: isChecked ? [] : idsArray,
                        }),
                      );
                    }}
                  />
                ) : null}
              </div>
            ),
            align: 'center',
            render: (_: any, rc: any) => {
              const config = permissionJson[resourceKey]?.[permissionKey]?.ids;
              const isChecked = config === true || config.includes(rc.id);

              return (
                <Checkbox
                  checked={isChecked}
                  onClick={() => {
                    onToggleSelectObject({
                      resourceKey,
                      permissionKey,
                      objectId: rc.id,
                    });
                  }}
                />
              );
            },
          },
        ];
      },
      [
        {
          title: t('name'),
          key: 'name',
          dataIndex: 'name',
          render: (name: string, rc: any) => {
            return name ?? rc.full_name;
          },
        },
      ],
    );
  };

  return {
    getRolesLoading,
    predefinedPermissions,
    roles,
    permissionJson,
    getResourceObjectColumns,
    onSelectedObjectsChanged,
  };
};

export const useRoleTable = () => {
  const { roleId } = useParams();
  const { t } = useTranslation(LOCALES.ROLE);
  const predefinedPermissions = useAppSelector(
    state => state.role.predefinedPermissions,
  );
  const permissionJson = useAppSelector(state => state.role.permissionJson);

  const dispatch = useAppDispatch();

  const dataSource: RoleTableDataItem[] = useMemo(() => {
    if (!predefinedPermissions || !permissionJson) {
      return [];
    }

    return Object.entries(predefinedPermissions).map(
      ([resourceKey, resourceValue]) => {
        const permissions = Object.keys(resourceValue.permissions).reduce(
          (currentPermissions, permissionKey) => {
            return {
              ...currentPermissions,
              [permissionKey]: permissionJson[resourceKey]?.[permissionKey],
            };
          },
          {},
        );

        return { resourceKey, permissions };
      },
    );
  }, [predefinedPermissions, permissionJson]);

  const columns: any[] = useMemo(() => {
    if (!predefinedPermissions) {
      return [];
    }

    const result: any[] = Object.values(predefinedPermissions).reduce(
      (currentColumns, resource) => {
        const { permissions } = resource;

        const shouldBeAddedColumns = Object.keys(permissions).reduce(
          (addedColumns: any[], permissionKey) => {
            const hasColumnAlready = currentColumns.find(
              ({ key }) => key === permissionKey,
            );

            if (hasColumnAlready) {
              return addedColumns;
            }

            //TODO
            const isChecked = isAllResourceHasThePermission(
              dataSource,
              permissionKey,
            );

            const newColumn = {
              key: permissionKey,
              title: (
                <div>
                  <div>{t(permissionKey)}</div>
                  <Checkbox
                    checked={isChecked}
                    onClick={() => {
                      if (isChecked) {
                        dispatch(deselectAllResourcesPermission(permissionKey));
                      } else {
                        dispatch(selectAllResourcesPermission(permissionKey));
                      }
                    }}
                  />
                </div>
              ),
              align: 'center',
              render: ({ permissions, resourceKey }: RoleTableDataItem) => {
                if (!permissions.hasOwnProperty(permissionKey)) {
                  return null;
                }

                return (
                  <Checkbox
                    checked={!!permissions[permissionKey]}
                    onClick={() => {
                      dispatch(
                        toggleSelectPermission({
                          resourceKey,
                          permissionKey,
                        }),
                      );
                    }}
                  />
                );
              },
            };

            return [...addedColumns, newColumn];
          },
          [],
        );

        return [...currentColumns, ...shouldBeAddedColumns];
      },
      [
        {
          title: '',
          key: 'setting',
          render: (_: any, rc: any) => {
            return HAS_CUSTOM_PERMISSION[rc.resourceKey] ? (
              <Link
                style={{ marginLeft: 10 }}
                to={generatePath(getCustomizePath(rc.resourceKey), {
                  roleId,
                })}>
                <SettingOutlined />
              </Link>
            ) : null;
          },
        },
        {
          title: t('resource'),
          key: 'resourceKey',
          dataIndex: 'resourceKey',
          render: (resourceKey: string) => t(resourceKey),
        },
      ],
    );

    return result;
  }, [predefinedPermissions, t, dataSource, roleId, dispatch]);

  return {
    columns,
    dataSource,
  };
};

export const useUpdateRole = (onSuccess?: Function) => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const updateRoleLoading = useAppSelector(
    state => state.role.updateRoleLoading,
  );
  const updateRoleSuccess = useAppSelector(
    state => state.role.updateRoleSuccess,
  );
  const updateRoleFailed = useAppSelector(state => state.role.updateRoleFailed);

  const dispatch = useAppDispatch();

  const handleUpdateRole = (name?: string) => {
    dispatch(updateRole({ name }));
  };

  useSuccess(updateRoleSuccess, t<string>('ROLE_UPDATED_SUCCESSFULLY'), () =>
    onSuccess?.(),
  );

  useFailed(updateRoleFailed);

  return {
    updateRoleLoading,
    updateRoleSuccess,
    updateRoleFailed,

    handleUpdateRole,
  };
};

export const useCreateRole = (callback: Function) => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const navigate = useNavigate();

  const createRoleLoading = useAppSelector(
    state => state.role.createRoleLoading,
  );
  const createRoleSuccess = useAppSelector(
    state => state.role.createRoleSuccess,
  );
  const createRoleFailed = useAppSelector(state => state.role.createRoleFailed);

  const dispatch = useAppDispatch();

  const handleCreateRole = (name: string) => {
    dispatch(createRole({ name }));
  };

  useSuccess(
    createRoleSuccess,
    t<string>('ROLE_CREATED_SUCCESSFULLY'),
    (role: any) => {
      callback();
      navigate(generatePath(PATHS.app.roles.details, { roleId: role.id }));
    },
  );

  useFailed(createRoleFailed);

  return {
    createRoleLoading,
    createRoleFailed,
    createRoleSuccess,
    handleCreateRole,
  };
};

export const useCopyRole = () => {
  const { roleId } = useParams();
  const { t } = useTranslation(LOCALES.MESSAGE);
  const navigate = useNavigate();

  const copyRoleLoading = useAppSelector(state => state.role.copyRoleLoading);
  const copyRoleSuccess = useAppSelector(state => state.role.copyRoleSuccess);
  const copyRoleFailed = useAppSelector(state => state.role.copyRoleFailed);

  const dispatch = useAppDispatch();

  const handleCopyRole = () => {
    if (roleId) {
      dispatch(copyRole(Number(roleId)));
    }
  };

  useSuccess(
    copyRoleSuccess,
    t<string>('ROLE_DUPLICATED_SUCCESSFULLY'),
    (role: any) =>
      navigate(generatePath(PATHS.app.roles.details, { roleId: role.id })),
  );

  useFailed(copyRoleFailed);

  return { copyRoleLoading, copyRoleSuccess, copyRoleFailed, handleCopyRole };
};

export const useDeleteRole = (callback: Function) => {
  const { roleId } = useParams();
  const { t } = useTranslation(LOCALES.MESSAGE);
  const navigate = useNavigate();

  const deleteRoleSuccess = useAppSelector(
    state => state.role.deleteRoleSuccess,
  );
  const deleteRoleFailed = useAppSelector(state => state.role.deleteRoleFailed);
  const deleteRoleLoading = useAppSelector(
    state => state.role.deleteRoleLoading,
  );
  const roles = useAppSelector(state => state.role.roles);
  const dispatch = useAppDispatch();

  const handleDeleteRole = () => {
    if (roleId && roles.length > 1) {
      dispatch(deleteRole(Number(roleId)));
    }
  };

  useSuccess(deleteRoleSuccess, t<string>('ROLE_DELETED_SUCCESSFULLY'), () => {
    callback();
    navigate(generatePath(PATHS.app.roles.details, { roleId: roles[0]?.id }));
  });

  useFailed(deleteRoleFailed);

  return {
    deleteRoleSuccess,
    deleteRoleLoading,
    deleteRoleFailed,
    handleDeleteRole,
  };
};

export const useRoleResourceIds = (resourceKey: string) => {
  const predefinedPermissions = useAppSelector(
    state => state.role.predefinedPermissions,
  );

  // state
  const roleDetails = useAppSelector(state => state.role.roleDetails);
  const permissionJsonState = useAppSelector(
    state => state.role.permissionJson,
  );

  if (!roleDetails || !predefinedPermissions) {
    return { ids: '' };
  }

  const permissionJson = permissionJsonState || roleDetails.permissions_json;

  const resourceConfig = predefinedPermissions[resourceKey];
  const resourcePermission = permissionJson[resourceKey];

  if (!resourcePermission) {
    return { ids: '' };
  }

  const ids: any = {};

  Object.entries(resourcePermission).forEach(
    ([permissionKey, permissionConfig]) => {
      if (permissionConfig.ids !== true) {
        const isPermissionHasObjectConfig =
          resourceConfig.permissions[permissionKey].need_obj_perm === true;

        if (isPermissionHasObjectConfig) {
          permissionConfig.ids.forEach((id: number) => {
            ids[id] = true;
          });
        }
      }
    },
  );

  return {
    ids: Object.keys(ids).join(',') || '',
  };
};

export const useResourceFields = (resourceKey: string) => {
  const { t } = useTranslation(LOCALES.ROLE);
  const dispatch = useAppDispatch();

  // state
  const roleDetails = useAppSelector(state => state.role.roleDetails);
  const permissionJson = useAppSelector(state => state.role.permissionJson);
  const predefinedPermissions = useAppSelector(
    state => state.role.predefinedPermissions,
  );

  const onToggleSelectField = useCallback(
    ({
      resourceKey,
      permissionKey,
      fieldId,
    }: {
      resourceKey: string;
      permissionKey: string;
      fieldId: string;
    }) => {
      dispatch(toggleSelectField({ resourceKey, permissionKey, fieldId }));
    },
    [dispatch],
  );

  const columns = useMemo(() => {
    if (!roleDetails || !predefinedPermissions || !permissionJson) {
      return [];
    }

    const { permissions } = predefinedPermissions[resourceKey];
    const columns = Object.entries(permissions).reduce(
      (currentColumns: any[], [permissionKey, permissionConfig]) => {
        if (permissionConfig.field_check) {
          const field = permissionJson[resourceKey]?.[permissionKey]?.fields;

          const isChecked =
            !field ||
            isAllFieldChecked({
              permissionJson,
              resourceKey,
              permissionKey,
              predefinedPermissions,
            });

          return [
            ...currentColumns,
            {
              title: (
                <div>
                  <div>{t(permissionKey)}</div>

                  <Checkbox
                    checked={isChecked}
                    onClick={() => {
                      dispatch(
                        toggleSelectAllField({
                          resourceKey,
                          permissionKey,
                          isAllFieldChecked: isChecked,
                        }),
                      );
                    }}
                  />
                </div>
              ),
              key: permissionKey,
              align: 'center',
              render: (_: any, rc: any) => {
                const fields =
                  permissionJson[resourceKey]?.[permissionKey]?.fields;

                const isChecked =
                  !fields ||
                  (fields && (fields === true || fields.includes(rc.id)));

                return (
                  <Checkbox
                    checked={isChecked}
                    onClick={() => {
                      onToggleSelectField({
                        resourceKey,
                        permissionKey,
                        fieldId: rc.id,
                      });
                    }}
                  />
                );
              },
            },
          ];
        }

        return currentColumns;
      },
      [{ title: t('field'), key: 'field', dataIndex: 'field' }],
    );

    return columns;
  }, [
    roleDetails,
    predefinedPermissions,
    permissionJson,
    resourceKey,
    t,
    onToggleSelectField,
    dispatch,
  ]);

  const dataSource = useMemo(() => {
    if (!roleDetails || !predefinedPermissions || !permissionJson) {
      return [];
    }

    const { available_fields } = predefinedPermissions[resourceKey];

    return available_fields.map(fieldId => {
      return {
        id: fieldId,
        field: fieldId,
      };
    });
  }, [predefinedPermissions, permissionJson, resourceKey, roleDetails]);

  return {
    columns,
    dataSource,
  };
};

import { useState } from 'react';
import { PageHeader } from 'components/Common/Page';
import {
  Button,
  Table,
  Dropdown,
  Popconfirm as PopConfirm,
  Tag,
  Space,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Staff } from 'features/staff/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useDeleteStaff,
  useStaffs,
  useResetStaffState,
  useReInviteStaff,
} from 'features/staff/staffHooks';
import { getDisplayingDate } from 'utils';
import type { MenuProps } from 'antd';
import {
  EllipsisOutlined,
  MailOutlined,
  RedoOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useTableSorting } from 'app/hooks';
import { NEW_RECORD_ID, SERVER_DATE_FORMAT } from 'config';
import SearchBox from 'components/Common/SearchBox';
import CreatingStaffForm from './StaffForm';
import color from 'config/color';
import InviteStaffModal from './InviteStaffModal';
import UpdatingStaffRoleForm from './UpdatingStaffRoleForm';

const initStaff = { id: -2, full_name: 'abc' };

const Staffs = () => {
  const { t } = useTranslation(LOCALES.STAFF);
  const {
    staffs,
    getStaffsLoading,
    paginationConfig,
    refreshAfterDeleting,
    refreshAfterCreating,
  } = useStaffs();
  useResetStaffState();

  const { handleSorting, getColumnSorting } = useTableSorting();
  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);

  const onDeleteSuccess = () => {
    setOpenedPopConfirm(-1);
    refreshAfterDeleting();
  };

  const { handleDeleteStaff, deleteStaffLoading } =
    useDeleteStaff(onDeleteSuccess);

  const onReset = () => {
    setInitValue(initStaff);
  };

  const [initValue, setInitValue] = useState<Staff>(initStaff);

  const [updatingRoleStaff, setUpdatingRoleStaff] = useState<Staff>(initStaff);
  const [isUpdatingRoleFormOpen, setIsUpdatingRoleFormOpen] = useState(false);

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [invitingStaffId, setInvitingStaffId] = useState<number | undefined>();

  const { reInviteStaffLoadingIds, handleReInviteStaff } = useReInviteStaff();

  const columns: ColumnsType<Staff> = [
    {
      title: t('name'),
      key: 'full_name',
      ...getColumnSorting('full_name'),

      render: (_, staff) => {
        if (staff.id === initValue.id) {
          return (
            <CreatingStaffForm
              initValue={initValue}
              onCancel={onReset}
              refresh={() => {
                onReset();
                refreshAfterCreating();
              }}
            />
          );
        }

        return staff.full_name;
      },
    },
    {
      title: t('email'),
      key: 'email',
      dataIndex: ['invite', 'email'],
    },
    {
      title: t('invitedAt'),
      key: 'invited_at',
      dataIndex: ['invite', 'invited_at'],
      render: date =>
        date ? getDisplayingDate(date, SERVER_DATE_FORMAT) : null,
    },
    {
      title: t('invite'),
      key: 'invite',
      align: 'center',
      render: (_, staff) => {
        const { accept_status, email } = staff.invite || {};

        if (!email) {
          return (
            <Button
              onClick={() => {
                setInvitingStaffId(staff.id);
                setIsInviteModalOpen(true);
              }}
              type='text'
              icon={<MailOutlined style={{ color: color.primary }} />}
            />
          );
        }

        if (!accept_status) {
          return (
            <Button
              loading={reInviteStaffLoadingIds[staff.id]}
              onClick={() => {
                handleReInviteStaff(staff.id + '');
              }}
              type='text'
              icon={
                reInviteStaffLoadingIds[staff.id] ? (
                  <LoadingOutlined />
                ) : (
                  <RedoOutlined style={{ color: color.primary }} />
                )
              }
            />
          );
        }

        return null;
      },
    },
    {
      title: t('status'),
      key: 'accept_status',
      dataIndex: ['invite', 'accept_status'],
      align: 'center',
      render: status =>
        status === true ? (
          <Tag color='success'>{t('linked')}</Tag>
        ) : (
          <Tag color='warning'>{t('unlinked')}</Tag>
        ),
    },

    {
      title: '',
      width: 50,
      render: (_, staff) => {
        const items: MenuProps['items'] = [
          {
            label: (
              <p
                className='styled-table__action-item'
                onClick={() => {
                  setInitValue(staff);
                }}>
                {t('update')}
              </p>
            ),
            key: 'Edit',
          },
          {
            label: (
              <p
                className='styled-table__action-item'
                onClick={() => {
                  setUpdatingRoleStaff(staff);
                  setIsUpdatingRoleFormOpen(true);
                }}>
                {t('updateRole')}
              </p>
            ),
            key: 'Update Role',
          },
          { type: 'divider' },
          {
            label: (
              <p
                className='styled-table__action-item color-error'
                onClick={() => setOpenedPopConfirm(staff.id)}>
                {t('delete')}
              </p>
            ),
            key: 'Delete',
          },
        ];
        return (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            overlayStyle={{ width: 150 }}
            overlayClassName='styled-table__actions'
            placement='bottomRight'>
            <PopConfirm
              placement='topRight'
              title={t('deleteWarning')}
              onConfirm={() => handleDeleteStaff(staff.id)}
              okText={t('delete')}
              cancelText={t('cancel')}
              okButtonProps={{ loading: deleteStaffLoading }}
              cancelButtonProps={{ disabled: deleteStaffLoading }}
              open={openedPopConfirm === staff.id}
              onCancel={() => setOpenedPopConfirm(-1)}>
              <Button size='small' type='text'>
                <EllipsisOutlined />
              </Button>
            </PopConfirm>
          </Dropdown>
        );
      },
      key: 'action',
    },
  ];

  return (
    <div style={{ padding: 24, width: 'calc(100% - 250px)' }}>
      <PageHeader title={t('staffs')}>
        <Space size={30}>
          {initValue.id === NEW_RECORD_ID ? (
            <CreatingStaffForm
              initValue={initValue}
              onCancel={onReset}
              refresh={() => {
                onReset();
                refreshAfterCreating();
              }}
            />
          ) : (
            <Button
              type='primary'
              onClick={() => {
                setInitValue({ id: NEW_RECORD_ID, full_name: '' });
              }}>
              {t('create')}
            </Button>
          )}

          <SearchBox style={{ marginBottom: 0 }} />
        </Space>
      </PageHeader>

      <Table
        loading={getStaffsLoading}
        dataSource={staffs}
        columns={columns}
        pagination={paginationConfig}
        rowKey='id'
        onChange={handleSorting}
      />

      <InviteStaffModal
        isOpen={isInviteModalOpen}
        onClose={() => {
          setIsInviteModalOpen(false);
          setInvitingStaffId(undefined);
        }}
        staffId={invitingStaffId}
      />

      <UpdatingStaffRoleForm
        initValue={updatingRoleStaff}
        isOpen={isUpdatingRoleFormOpen}
        onCancel={() => {
          setIsUpdatingRoleFormOpen(false);
          setUpdatingRoleStaff(initStaff);
        }}
        refreshAfterUpdateRole={refreshAfterCreating}
      />
    </div>
  );
};

export default Staffs;

import { useState } from 'react';
import { Table, Popconfirm, Button } from 'antd';
import { PageHeader } from 'components/Common/Page';
import {
  useAcceptStaffInvitation,
  useStaffInvitations,
  useResetAuthState,
} from 'features/auth/authHooks';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { getDisplayingDate, getNo } from 'utils';
import type { ColumnsType } from 'antd/es/table';
import { StaffInvitation } from '../types';
import { useTableSorting } from 'app/hooks';
import { SERVER_DATE_FORMAT } from 'config';
import { CheckSquareOutlined } from '@ant-design/icons';
import color from 'config/color';

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  height: calc(100vh - 64px);
  padding: 0 30px;
`;

const StaffInvitations = () => {
  const {
    getStaffInvitationsLoading,
    staffInvitations,
    staffInvitationsPaginationConfig,
    refreshAfterAccepting,
  } = useStaffInvitations();
  const { t } = useTranslation(LOCALES.WORKSPACE);
  const { getColumnSorting, handleSorting } = useTableSorting();

  useResetAuthState();
  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);

  const { handleAcceptStaffInvitation, acceptStaffInvitationLoading } =
    useAcceptStaffInvitation(() => {
      setOpenedPopConfirm(-1);
      refreshAfterAccepting();
    });

  const invitationColumns: ColumnsType<StaffInvitation> = [
    {
      title: t('no'),
      key: 'no',
      width: 60,
      align: 'right',
      render: (_, rc, index) => getNo(staffInvitationsPaginationConfig, index),
    },
    {
      title: t('WorkspaceName'),
      key: 'workspace',
      dataIndex: ['workspace', 'name'],
      ...getColumnSorting('workspace'),
    },
    {
      title: t('position'),
    },
    {
      ...getColumnSorting('invited_at'),
      title: t('inviteDate'),
      key: 'invited_at',
      dataIndex: 'invited_at',
      render: (date: string) => getDisplayingDate(date, SERVER_DATE_FORMAT),
    },
    {
      title: '',
      width: 50,
      render: staffInvitation => (
        <Popconfirm
          placement='topRight'
          title={t('acceptWarning')}
          onConfirm={() => {
            handleAcceptStaffInvitation(staffInvitation.id);
          }}
          okText={t('accept')}
          cancelText={t('cancel')}
          okButtonProps={{ loading: acceptStaffInvitationLoading }}
          cancelButtonProps={{ disabled: acceptStaffInvitationLoading }}
          open={openedPopConfirm === staffInvitation.id}
          onCancel={() => setOpenedPopConfirm(-1)}>
          <Button
            onClick={() => setOpenedPopConfirm(staffInvitation.id)}
            type='text'
            icon={
              <CheckSquareOutlined style={{ color: color.primary }} />
            }></Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Wrapper>
      <PageHeader title={t('invitations')}></PageHeader>

      <Table
        loading={getStaffInvitationsLoading}
        rowKey='id'
        dataSource={staffInvitations}
        pagination={staffInvitationsPaginationConfig}
        columns={invitationColumns}
        onChange={handleSorting}
      />
    </Wrapper>
  );
};

export default StaffInvitations;

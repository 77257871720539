import { LOCALES } from 'app/i18n';
import { useRoleStaffs } from 'features/staff/staffHooks';
import { useTranslation } from 'react-i18next';
import { Button, Row, Skeleton, Space, Table } from 'antd';
import { DetailsPageTabs, PageHeader } from 'components/Common/Page';
import { Fragment, useState } from 'react';
import StaffsModal from 'components/StaffsModal';
import { PERMISSIONS_RESOURCES } from 'config/permissions';
import {
  useResourceObjects,
  useRoleDetails,
  useUpdateRole,
} from 'features/role/roleHooks';
import styled from 'styled-components';
import FieldTable from '../FieldTable';
import { getResourceObjectIds } from 'features/role/roleUtils';
import NoDataTitle from '../NoDataTitle';

const TabWrapper = styled(DetailsPageTabs)`
  margin-top: 15px;

  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }
`;

const { STAFF } = PERMISSIONS_RESOURCES;

const RoleStaffs = () => {
  const { roleStaffs, getRoleStaffsLoading } = useRoleStaffs();
  const { getResourceObjectColumns, onSelectedObjectsChanged } =
    useResourceObjects();
  const { roleDetails, getRolesLoading, permissionJson } = useRoleDetails();
  const { handleUpdateRole, updateRoleLoading } = useUpdateRole();

  const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);
  const { t } = useTranslation(LOCALES.STAFF);

  const columns = getResourceObjectColumns(STAFF);

  const selectedIds = getResourceObjectIds({
    resourceKey: STAFF,
    permissionJson,
  });
  const initSelectedIds = Object.keys(selectedIds).map(id => Number(id));

  const onSelectedStaffsChanged = (ids: number[]) => {
    onSelectedObjectsChanged(STAFF, ids);
    setIsStaffModalOpen(false);
  };

  const tableLoading = getRolesLoading || getRoleStaffsLoading;
  const noData = !tableLoading && !roleStaffs.length;

  return (
    <div>
      <Row justify='space-between' align='middle'>
        <PageHeader
          style={{ marginBottom: 0 }}
          title={
            roleDetails ? `${roleDetails?.name} - ${t('staffs')}` : ''
          }></PageHeader>
        <Space>
          {roleDetails ? (
            <Fragment>
              <Button
                loading={updateRoleLoading}
                type='primary'
                onClick={() => handleUpdateRole()}>
                {t('update')}
              </Button>
              <Button onClick={() => setIsStaffModalOpen(true)} type='primary'>
                {t('add')}
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              {[0, 1].map(index => (
                <Skeleton.Button active key={index}></Skeleton.Button>
              ))}
            </Fragment>
          )}
        </Space>
      </Row>

      <TabWrapper
        items={[
          {
            label: 'Objects',
            key: 'Objects',
            children: noData ? (
              <NoDataTitle />
            ) : (
              <Table
                loading={tableLoading}
                columns={columns}
                dataSource={roleStaffs}
                rowKey='id'
              />
            ),
          },
          {
            label: 'Fields',
            key: 'Fields',
            children: <FieldTable resourceKey={STAFF} />,
          },
        ]}
      />

      <StaffsModal
        isOpen={isStaffModalOpen}
        onSubmit={ids => onSelectedStaffsChanged(ids)}
        onCancel={() => setIsStaffModalOpen(false)}
        addingLoading={false}
        initValues={initSelectedIds}
        isAcceptEmpty
      />
    </div>
  );
};

export default RoleStaffs;

import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import authAPI from './authAPI';
import { setAxiosToken, removeAxiosToken } from 'utils/requester';
import {
  RegisterUser,
  LoginUser,
  AuthStateType,
  ChangePasswordPayload,
  UpdateAccountProfilePayload,
  Workspace,
} from './types';

import { resetStoreAction } from 'config';
import { BaseURLQueryParams } from 'types';

const initialState: AuthStateType = {
  token: undefined,
  workspace: undefined,
  refreshToken: undefined,
  email: undefined,

  loginLoading: false,
  loginFailed: undefined,
  loginSuccess: undefined,

  registerLoading: false,
  registerSuccess: undefined,
  registerFailed: undefined,

  getWorkspacesLoading: false,
  getWorkspacesFailed: undefined,
  workspaces: [],

  createWorkspaceLoading: false,
  createWorkspaceFailed: undefined,
  createWorkspaceSuccess: undefined,

  updateWorkspaceLoading: false,
  updateWorkspaceFailed: undefined,
  updateWorkspaceSuccess: undefined,

  accountProfile: undefined,
  getAccountProfileLoading: false,
  getAccountProfileFailed: undefined,

  changePasswordLoading: false,
  changePasswordSuccess: undefined,
  changePasswordFailed: undefined,

  updateAccountProfileLoading: false,
  updateAccountProfileSuccess: undefined,
  updateAccountProfileFailed: undefined,

  currentWorkspace: undefined,

  getStaffInvitationsLoading: false,
  getStaffInvitationsFailed: undefined,
  staffInvitations: [],
  staffInvitationsPaginationConfig: {},

  acceptStaffInvitationLoading: false,
  acceptStaffInvitationSuccess: undefined,
  acceptStaffInvitationFailed: undefined,
};

// Thunks
export const register = createAppAsyncThunk(
  'auth/register',
  async (user: RegisterUser, { rejectWithValue }) => {
    try {
      const response = await authAPI.register(user);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const login = createAppAsyncThunk(
  'auth/login',
  async (user: LoginUser, { rejectWithValue }) => {
    try {
      const response: any = await authAPI.login(user);
      setAxiosToken(response.access_token);
      const workspaceResponse: any = await authAPI.getWorkspaces();
      const workspaces = workspaceResponse.results;

      return { ...response, workspaces, email: user.email };
    } catch (err: any) {
      removeAxiosToken();
      return rejectWithValue(err);
    }
  },
);

export const getWorkspaces = createAppAsyncThunk(
  'auth/getWorkspaces',
  async (_, { rejectWithValue }) => {
    try {
      const response: any = await authAPI.getWorkspaces();
      return response.results;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getAccountProfile = createAppAsyncThunk(
  'auth/getAccountProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response: any = await authAPI.getAccountProfile();
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateAccountProfile = createAppAsyncThunk(
  'auth/updateAccountProfile',
  async (accountProfile: UpdateAccountProfilePayload, { rejectWithValue }) => {
    try {
      const response: any = await authAPI.updateAccountProfile(accountProfile);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const changePassword = createAppAsyncThunk(
  'auth/changePassword',
  async (data: ChangePasswordPayload, { rejectWithValue }) => {
    try {
      const response: any = await authAPI.changePassword(data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createWorkspace = createAppAsyncThunk(
  'auth/createWorkspace',
  async (workspace: Workspace, { rejectWithValue }) => {
    try {
      const response = await authAPI.createWorkspace(workspace);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateWorkspace = createAppAsyncThunk(
  'auth/updateWorkspace',
  async (workspace: Workspace, { rejectWithValue }) => {
    try {
      const response: any = await authAPI.updateWorkspace(workspace);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getStaffInvitations = createAppAsyncThunk(
  'auth/getStaffInvitations',
  async (params: BaseURLQueryParams, { rejectWithValue }) => {
    try {
      const response = await authAPI.getStaffInvitations(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const acceptStaffInvitation = createAppAsyncThunk(
  'auth/acceptStaffInvitation',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await authAPI.acceptStaffInvitation(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState(state) {
      state.registerSuccess = undefined;
      state.registerFailed = undefined;
      state.loginFailed = undefined;
      state.loginSuccess = undefined;
      state.createWorkspaceFailed = undefined;
      state.createWorkspaceSuccess = undefined;
      state.changePasswordSuccess = undefined;
      state.changePasswordFailed = undefined;
      state.updateAccountProfileSuccess = undefined;
      state.updateAccountProfileFailed = undefined;
      state.createWorkspaceFailed = undefined;
      state.createWorkspaceSuccess = undefined;
      state.updateWorkspaceFailed = undefined;
      state.updateWorkspaceSuccess = undefined;

      state.getStaffInvitationsLoading = false;
      state.getStaffInvitationsFailed = undefined;
      state.staffInvitations = [];
      state.staffInvitationsPaginationConfig = {};

      state.acceptStaffInvitationLoading = false;
      state.acceptStaffInvitationSuccess = undefined;
      state.acceptStaffInvitationFailed = undefined;
    },

    setWorkspace: (state, { payload }) => {
      state.workspace = payload;
      state.currentWorkspace = state.workspaces.find(
        ({ workspace }) => workspace === state.workspace,
      );
    },

    logout() {
      return initialState;
    },

    updateToken(state, { payload: token }) {
      state.token = token;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(register.pending, state => {
        state.registerLoading = true;
        state.registerFailed = null;
        state.registerSuccess = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.registerLoading = false;
        state.registerSuccess = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.registerLoading = false;
        state.registerFailed = action.payload;
      })

      .addCase(login.pending, state => {
        state.loginLoading = true;
        state.loginFailed = null;
        state.token = undefined;
        state.loginSuccess = null;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loginLoading = false;
        state.token = payload.access_token;
        state.refreshToken = payload.refresh_token;
        state.loginSuccess = payload;
        state.workspaces = payload.workspaces;
        state.email = payload.email;

        if (state.workspaces.length === 1) {
          state.workspace = state.workspaces[0].workspace;
        }
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loginLoading = false;
        state.loginFailed = payload;
      })

      .addCase(getWorkspaces.pending, state => {
        state.getWorkspacesLoading = true;
        state.getWorkspacesFailed = null;
        state.workspaces = [];
      })
      .addCase(getWorkspaces.fulfilled, (state, { payload }) => {
        state.getWorkspacesLoading = false;
        state.workspaces = payload;

        if (state.workspace) {
          state.currentWorkspace = state.workspaces.find(
            ({ workspace }) => workspace === state.workspace,
          );
        }
      })
      .addCase(getWorkspaces.rejected, (state, { payload }) => {
        state.getWorkspacesLoading = false;
        state.getWorkspacesFailed = payload;
      })

      .addCase(getAccountProfile.pending, state => {
        state.getAccountProfileLoading = true;
        state.getAccountProfileFailed = null;
      })
      .addCase(getAccountProfile.fulfilled, (state, { payload }) => {
        state.getAccountProfileLoading = false;
        state.accountProfile = payload;
      })
      .addCase(getAccountProfile.rejected, (state, { payload }) => {
        state.getAccountProfileLoading = false;
        state.getAccountProfileFailed = payload;
      })

      .addCase(changePassword.pending, state => {
        state.changePasswordLoading = true;
        state.changePasswordFailed = null;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.changePasswordLoading = false;
        state.changePasswordSuccess = payload;
      })
      .addCase(changePassword.rejected, (state, { payload }) => {
        state.changePasswordLoading = false;
        state.changePasswordFailed = payload;
      })

      .addCase(updateAccountProfile.pending, state => {
        state.updateAccountProfileLoading = true;
        state.updateAccountProfileFailed = null;
      })
      .addCase(updateAccountProfile.fulfilled, (state, { payload }) => {
        state.updateAccountProfileLoading = false;
        state.updateAccountProfileSuccess = payload;
      })
      .addCase(updateAccountProfile.rejected, (state, { payload }) => {
        state.updateAccountProfileLoading = false;
        state.updateAccountProfileFailed = payload;
      })

      .addCase(createWorkspace.pending, state => {
        state.createWorkspaceLoading = true;
        state.createWorkspaceFailed = null;
      })
      .addCase(createWorkspace.fulfilled, (state, { payload }) => {
        state.createWorkspaceLoading = false;
        state.createWorkspaceSuccess = payload;
      })
      .addCase(createWorkspace.rejected, (state, { payload }) => {
        state.createWorkspaceLoading = false;
        state.createWorkspaceFailed = payload;
      })

      .addCase(updateWorkspace.pending, state => {
        state.updateWorkspaceLoading = true;
        state.updateWorkspaceFailed = null;
      })
      .addCase(updateWorkspace.fulfilled, (state, { payload }) => {
        state.updateWorkspaceLoading = false;
        state.updateWorkspaceSuccess = payload;
        state.currentWorkspace = payload;
      })
      .addCase(updateWorkspace.rejected, (state, { payload }) => {
        state.updateWorkspaceLoading = false;
        state.updateWorkspaceFailed = payload;
      })

      .addCase(getStaffInvitations.pending, (state, action) => {
        const {
          meta: {
            arg: { page, page_size },
          },
        } = action;

        state.getStaffInvitationsLoading = true;
        state.staffInvitationsPaginationConfig.current = Number(page);
        state.staffInvitationsPaginationConfig.pageSize = Number(page_size);
      })
      .addCase(getStaffInvitations.fulfilled, (state, action) => {
        state.getStaffInvitationsLoading = false;
        state.staffInvitations = action.payload.results;
        state.staffInvitationsPaginationConfig.total = action.payload.count;
      })
      .addCase(getStaffInvitations.rejected, (state, action) => {
        state.getStaffInvitationsLoading = false;
        state.getStaffInvitationsFailed = action.payload;
      })

      .addCase(acceptStaffInvitation.pending, state => {
        state.acceptStaffInvitationLoading = true;
        state.acceptStaffInvitationFailed = null;
      })
      .addCase(acceptStaffInvitation.fulfilled, (state, { payload }) => {
        state.acceptStaffInvitationLoading = false;
        state.acceptStaffInvitationSuccess = payload;
      })
      .addCase(acceptStaffInvitation.rejected, (state, { payload }) => {
        state.acceptStaffInvitationLoading = false;
        state.acceptStaffInvitationFailed = payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const { setWorkspace, logout, updateToken, resetAuthState } =
  authSlice.actions;

export default authSlice.reducer as Reducer<AuthStateType>;

import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import studentAPI from './studentAPI';
import {
  BaseStudent,
  Student,
  StudentState,
  GetStudentsQueryParams,
} from './types';

import { resetStoreAction } from 'config';
import { ListResponse } from 'types';

export const getStudents = createAppAsyncThunk(
  'student/getStudents',
  async (params: GetStudentsQueryParams, { rejectWithValue }) => {
    try {
      const response: ListResponse<Student> = await studentAPI.getStudents(
        params,
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createStudent = createAppAsyncThunk(
  'student/createStudent',
  async (student: BaseStudent, { rejectWithValue }) => {
    try {
      const response = await studentAPI.createStudent(student);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateStudent = createAppAsyncThunk(
  'student/updateStudent',
  async (student: Student, { rejectWithValue }) => {
    try {
      const { data } = await studentAPI.updateStudent(student);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteStudent = createAppAsyncThunk(
  'student/deleteStudent',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await studentAPI.deleteStudent(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getRoleStudents = createAppAsyncThunk(
  'student/getRoleStudents',
  async (ids: string, { rejectWithValue }) => {
    try {
      const { results }: ListResponse<Student> =
        await studentAPI.getRoleStudents(ids);

      return results;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: StudentState = {
  getStudentsLoading: false,
  students: [],
  getStudentsFailed: undefined,
  paginationConfig: {},

  createStudentLoading: false,
  createStudentSuccess: undefined,
  createStudentFailed: undefined,

  updateStudentLoading: false,
  updateStudentSuccess: undefined,
  updateStudentFailed: undefined,

  deleteStudentLoading: false,
  deleteStudentSuccess: undefined,
  deleteStudentFailed: undefined,

  getRoleStudentsLoading: false,
  roleStudents: [],
  getRoleStudentsFailed: undefined,
};

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    resetStudentState() {
      return initialState;
    },

    resetRoleStudents(state) {
      state.roleStudents = [];
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getStudents.pending, (state, action) => {
        const {
          meta: {
            arg: { page, page_size },
          },
        } = action;

        state.getStudentsLoading = true;
        state.paginationConfig.current = Number(page);
        state.paginationConfig.pageSize = Number(page_size);
      })
      .addCase(getStudents.fulfilled, (state, action) => {
        state.getStudentsLoading = false;
        state.students = action.payload.results;
        state.paginationConfig.total = action.payload.count;
      })
      .addCase(getStudents.rejected, (state, action) => {
        state.getStudentsLoading = false;
        state.getStudentsFailed = action.payload;
      })

      .addCase(createStudent.pending, state => {
        state.createStudentLoading = true;
        state.createStudentFailed = undefined;
      })
      .addCase(createStudent.fulfilled, (state, action) => {
        state.createStudentLoading = false;
        state.createStudentSuccess = action.payload;
      })
      .addCase(createStudent.rejected, (state, action) => {
        state.createStudentLoading = false;
        state.createStudentFailed = action.payload;
      })

      .addCase(updateStudent.pending, state => {
        state.updateStudentLoading = true;
        state.updateStudentFailed = undefined;
      })
      .addCase(updateStudent.fulfilled, (state, { payload }) => {
        state.updateStudentLoading = false;
        state.updateStudentSuccess = payload;
        state.students = state.students.map(student =>
          student.id !== payload.id ? student : payload,
        );
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.updateStudentLoading = false;
        state.updateStudentFailed = action.payload;
      })

      .addCase(deleteStudent.pending, state => {
        state.deleteStudentLoading = true;
        state.deleteStudentFailed = undefined;
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        state.deleteStudentLoading = false;
        state.deleteStudentSuccess = action.payload;
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.deleteStudentLoading = false;
        state.deleteStudentFailed = action.payload;
      })

      .addCase(getRoleStudents.pending, state => {
        state.getRoleStudentsLoading = true;
      })
      .addCase(getRoleStudents.fulfilled, (state, action) => {
        state.getRoleStudentsLoading = false;
        state.roleStudents = action.payload;
      })
      .addCase(getRoleStudents.rejected, (state, action) => {
        state.getRoleStudentsLoading = false;
        state.getRoleStudentsFailed = action.payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const { resetStudentState, resetRoleStudents } = studentSlice.actions;

export default studentSlice.reducer as Reducer<StudentState>;

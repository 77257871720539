import { Dropdown, Space, Typography, Avatar, Badge, Row } from 'antd';
import { useAccountProfile, useLogout } from 'features/auth/authHooks';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import styled from 'styled-components';
import { LOCALES } from 'app/i18n';
import { NavLink } from 'react-router-dom';
import PATHS from 'router/paths';
import { BellOutlined } from '@ant-design/icons';

import LanguageBtn from 'components/Common/LanguageBtn';

const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .ant-dropdown-menu-title-content {
    display: block;
  }
`;

const Account = ({ notificationsNumber }: { notificationsNumber: number }) => {
  const { accountProfile } = useAccountProfile();
  const logout = useLogout();
  const { t } = useTranslation(LOCALES.LAYOUT);

  const { email, picture, first_name } = accountProfile || {};

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <StyledMenuItem style={{ cursor: 'auto' }}>
          <Avatar src={picture} style={{ backgroundColor: '#f56a00' }}>
            {first_name}
          </Avatar>
          <div
            style={{
              marginLeft: 10,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography.Text style={{ margin: 0 }}>
              {' '}
              {first_name}
            </Typography.Text>
            <Typography.Text type='secondary' style={{ margin: 0 }}>
              {' '}
              {email}
            </Typography.Text>
          </div>
        </StyledMenuItem>
      ),
    },

    {
      type: 'divider',
    },

    {
      key: '2',
      label: (
        <NavLink to={PATHS.account.profile.root}>
          <StyledMenuItem style={{ width: 170 }}>
            <Typography.Text style={{ margin: 0 }}>
              {t('profile')}
            </Typography.Text>
          </StyledMenuItem>
        </NavLink>
      ),
    },
    {
      key: '3',
      label: (
        <NavLink to={PATHS.account.profile.changePassword}>
          <StyledMenuItem>
            <Typography.Text style={{ margin: 0 }}>
              {' '}
              {t('changePassword')}
            </Typography.Text>
          </StyledMenuItem>
        </NavLink>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '5',
      label: (
        <StyledMenuItem onClick={() => logout()}>
          <Typography.Text style={{ margin: 0 }} type='danger'>
            {' '}
            {t('logout')}
          </Typography.Text>
        </StyledMenuItem>
      ),
    },
  ];

  return (
    <Row style={{ width: '30%' }} justify='end' align='middle'>
      <Badge
        count={notificationsNumber}
        style={{ marginRight: 35, marginTop: 5 }}>
        <BellOutlined style={{ marginRight: 35, fontSize: 16, marginTop: 8 }} />
      </Badge>

      <Dropdown
        overlayStyle={{ minWidth: 150 }}
        menu={{ items, selectedKeys: [] }}
        placement='bottomRight'>
        <Space align='center' size={8} style={{ cursor: 'pointer' }}>
          <Avatar style={{ backgroundColor: '#f56a00' }}>{first_name}</Avatar>
          {first_name}
        </Space>
      </Dropdown>

      <LanguageBtn />
    </Row>
  );
};

export default Account;

import { Modal, Table } from 'antd';
import { useAssetViewer, useExcelViewer } from 'features/asset/assetHooks';
import { Tabs } from 'antd';

const ExcelModal = () => {
  const { isExcelModalOpen, onCloseAssetViewer, downloadedFile } =
    useAssetViewer();
  const { dataSource, columns, sheetNames, onWorksheetChange } = useExcelViewer(
    isExcelModalOpen,
    downloadedFile,
  );

  const tabs = sheetNames.map((name: string, index: number) => ({
    key: index,
    label: name,
  }));

  const hasTab = tabs.length > 1;

  return (
    <Modal
      width={1200}
      footer={false}
      open={isExcelModalOpen}
      onCancel={() => onCloseAssetViewer()}
      style={{ height: '100vh', overflow: 'auto' }}>
      {hasTab && (
        <Tabs items={tabs} onChange={key => onWorksheetChange(Number(key))} />
      )}

      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey='tableKey'
        style={!hasTab ? { marginTop: 20 } : {}}
      />
    </Modal>
  );
};

export default ExcelModal;

/* COLORS */
const color = {
  primary: '#da271c',
  secondary: '#1677ff',
  grey: '#f1f1f5',
  red: '#c1112f',
  redLight: '#da271c',
  error: '#ff4d4f',
  subMenuActive: '#c1112f',
  menuItemActive: '#da271c',
  white: '#ffffff',
  textBlack: '#171725',
  black1: '#44444f',
  black2: '#696974',
  black3: '#13131a',
  black4: '#333333',
  greyLight: '#fafafb',
  greyLight2: '#f6f6fc',
  grey1: '#d5d5dc',
  grey2: '#e2e2ea',
  grey3: '#717480',
  grey4: '#92929d',
  grey5: '#d8d8d8',
  grey6: '#707070',
  grey7: '#b2b2b2',
  grey8: '#f6f6fc',
  grey9: '#e5e5e8',
  grey10: '#f1f1f5',
  grey11: '#b5b5be',
  grey12: '#e5e5e5',
  blackLight: '#44444f',
  yellowLight: '#ffc542',
  pink: '#fc5a5a',
  green: '#82c43c',
  green2: '#3dd598',
  orange: '#ff974a',
  orange2: '#eccfbf',
  btnOrange: '#ff4800',
  inputBorder: '#e2e2ea',
  danger: '#da271c',
  textErr: '#f55735',
  checkBox: '#ff4800',
  radio: '#ff4800',
  shadow: '#00000029',
  shadowLight: '#d2d7e0',
  blue: '#50b5ff',
  green1: '#3dd598',
  purple: '#a461d8',
  backgroundGreyLight: 'rgba(213, 213, 220, 0.22)',
  backgroundGreyMedium: 'rgba(213, 213, 220, 0.5)',
  borderGreyLight: 'rgba(213, 213, 220, 0.22)',
  processBarRed: '#ff564a',
  tagGreen: '#3dd598',
  pinkDeep: '#ff9ad5',
};

export default color;

import { Row, Col, Space, Image, Skeleton } from 'antd';
import { Course } from '../types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import InfoItem from 'components/Common/InfoItem';
import { getDisplayingDate } from 'utils';
import CourseStatus from 'components/Common/CourseStatus';

const Information = ({
  course,
  loading,
}: {
  course?: Course;
  loading: boolean;
}) => {
  const { t } = useTranslation(LOCALES.COURSE);
  const photoSrc = typeof course?.photo !== 'string' ? '' : course?.photo;

  return (
    <Row>
      <Col span={6}>
        {loading ? (
          <Skeleton.Image active />
        ) : (
          <Image
            width={96}
            height={96}
            style={{ objectFit: 'contain' }}
            placeholder={true}
            src={photoSrc}
          />
        )}
      </Col>

      <Col span={9}>
        <Space direction='vertical'>
          <InfoItem label={t('class')} value={course?.name} loading={loading} />
          <InfoItem
            label={t('teacher')}
            value={course?.teacher}
            loading={loading}
          />
          <InfoItem
            label={t('numberOfStudent')}
            value={course?.total_student}
            loading={loading}
          />
        </Space>
      </Col>

      <Col span={9}>
        {' '}
        <Space direction='vertical'>
          <InfoItem
            label={t('created')}
            value={getDisplayingDate(course?.created_at)}
            loading={loading}
          />

          <InfoItem
            label={t('description')}
            value={course?.descriptions}
            loading={loading}
          />

          {course?.status && <CourseStatus status={course?.status} />}
        </Space>
      </Col>
    </Row>
  );
};

export default Information;

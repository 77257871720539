const vi = {
  createNewAccount: 'Tạo tài khoản mới',
  fastAndEasy: 'Nhanh chóng và dễ dàng',
  email: 'Email',
  emailPlaceholder: 'Nhập email',
  password: 'Mật khẩu',
  confirmPassword: 'Nhập lại Mật khẩu',
  passwordPlaceholder: 'Nhập mật khẩu',
  phoneNumber: 'Số điện thoại',
  phoneNumberPlaceholder: 'Nhập số điện thoại',
  register: 'Đăng Ký',
  haveAnAccount: 'Bạn đã có tài khoản',
  loginHere: 'Đăng nhập ở đây',
  registerFailed: 'Đăng ký thất bại',
  registerSuccessfully: 'Đăng ký thành công',
};

export default vi;

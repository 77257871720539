const vi = {
  workspace: 'Workspace',
  createWorkspace: 'Tạo Workspace',
  listInvitation: 'Danh sách lời mời',
  student: 'Học sinh',
  listStudents: 'Danh sách học sinh',
  createStudent: 'Tạo học sinh',
  classes: 'Lớp học',
  listClasses: 'Danh sách lớp học',
  createClass: 'Tạo lớp học',
  dashboard: 'Dashboard',
  settings: 'Cài đặt',
  archive: 'Archive',
  books: 'Sách',
  listBooks: 'Danh sách sách',
  createBook: 'Tạo sách mới',
  files: 'File',
  listFiles: 'Danh sách File',
  createFile: 'Tạo File mới',
  staff: 'Nhân viên',
  listStaff: 'Danh sách nhân viên',
  createStaff: 'Thêm nhân viên',
  roles: 'Phân quyền',
  activities: 'Hoạt động',
  logout: 'Đăng xuất',
  changeWorkspace: 'Đổi Workspace',
  confirmLogout: 'Xác nhận đăng xuất',
  playList: 'Playlist',
  listPlayList: 'Danh sách Playlist',
  sureComfirm: 'Bạn có chắc chắn muốn đăng xuất không?',
  profile: 'Profile',
  TREE_FILE: 'Quản lý File',
  loadingProfile: 'Đang tải dữ liệu...',
  profileLoaded: 'Thành công',
  profileLoadFailed: 'Tải thất bại',
  Vietnamese: 'Tiếng Việt',
  English: 'Tiếng Anh',
  youDontHavePermission:
    'Bạn không có quyền truy cập tính năng này. Vui lòng liên hệ với quản trị viên Workspace của bạn để biết thông tin chi tiết.',

  path_student: 'Học sinh',
  path_courses: 'Lớp học',
  path_book: 'Sách',
  path_playlist: 'Danh sách Playlist',
  path_assets: 'Quản lý File',
  path_branch: 'Chi nhánh',
  path_check_in: 'Điểm danh',
  path_staff: 'Nhân viên',
  path_role: 'Phân quyền',
  path_hidden: 'Không có quyền truy cập',
  poweredBy: 'Powered by',
  messageError: 'Đã có lỗi xảy ra',
  changePassword: 'Đổi mật khẩu',
  workspaces: 'Workspaces',
  invitations: 'Lời mời',
  phoneNumber: 'Số điện thoại',

  invitationCode: 'Mã mời',
  download: 'Tải xuống',

  keyword: 'Từ khoá',
  enterKeyword: 'Nhập từ khoá',
};

export default vi;

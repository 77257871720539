import { Button, Modal, Progress } from 'antd';
import {
  useAddAlbumAssets,
  useRemoveUploadingAlbumAsset,
  useResetUploadingAlbumAssets,
  useUploadAlbumAsset,
} from 'features/course/courseHooks/courseAlbumHooks';
import styled from 'styled-components';
import { AlbumAssetWrapper } from './Styled';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useParams } from 'react-router-dom';
import DisplayedTempAlbumAsset from 'components/Common/DisplayedTempAlbumAsset';
import { UploadingAlbumAsset } from 'features/course/types';

export const AlbumAssetsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 10px;
  grid-row-gap: 25px;
  padding: 20px;
  width: 100%;
  height: 100%;
`;

const TempAlbumAssetWrapper = styled(AlbumAssetWrapper)`
  width: 170px;
  height: 210px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;

  .album-file__image-box {
    height: 170px;
  }

  .album-file__info {
    height: 30px;
  }

  .delete-uploading-album-asset-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .album-asset-progress {
    bottom: -10px;
  }
`;

const TempAlbumAssetThumbnailWrapper = styled.div`
  width: 170px;
  height: 195px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const TempAlbumAsset = ({
  asset,
  onDelete,
}: {
  asset: UploadingAlbumAsset;
  onDelete: (id: string) => void;
}) => {
  const { status } = asset;

  return (
    <TempAlbumAssetWrapper className='album-file'>
      <Button
        onClick={() => onDelete(asset.uid)}
        shape='circle'
        icon={<DeleteOutlined />}
        className='delete-uploading-album-asset-btn'
      />

      <div className='album-file__image-box'>
        <DisplayedTempAlbumAsset asset={asset} />
      </div>

      <div className='album-file__info'>
        <p className='album-file__name'>{asset.name}</p>
      </div>

      <Progress
        percent={asset.progress}
        className='album-asset-progress'
        status={
          status === 'queue' || status === 'inprogress'
            ? 'normal'
            : status === 'failed'
            ? 'exception'
            : 'success'
        }
      />
    </TempAlbumAssetWrapper>
  );
};

const AlbumAssetsModal = ({
  isOpen,
  triggerUpload,
  onClose,
}: {
  isOpen: boolean;
  triggerUpload: () => void;
  onClose: () => void;
}) => {
  const handleClose = () => {
    onClose();
    resetUploadingAlbumAssets();
  };

  const resetUploadingAlbumAssets = useResetUploadingAlbumAssets();
  const removeUploadingAlbumAsset = useRemoveUploadingAlbumAsset();

  const { uploadAlbumAssetLoading, uploadingAlbumAssets, albumFilesQueue } =
    useUploadAlbumAsset();

  const { addAlbumAssetsLoading, handleAddAlbumAssets } =
    useAddAlbumAssets(handleClose);

  const { t } = useTranslation(LOCALES.COURSE);

  const allAssets = [...uploadingAlbumAssets, ...albumFilesQueue];

  const { id, albumId } = useParams();

  const handleSubmit = () => {
    if (id && albumId) {
      const assets = uploadingAlbumAssets
        .filter(({ status }) => status === 'succeeded')
        .map(({ name, path }) => ({
          name,
          file: path ?? '',
        }));

      handleAddAlbumAssets({
        courseId: id,
        albumId,
        assets,
      });
    }
  };

  return (
    <Modal
      onCancel={() => {
        if (!uploadAlbumAssetLoading && !addAlbumAssetsLoading) {
          handleClose();
        }
      }}
      onOk={() => handleSubmit()}
      open={isOpen}
      title={t('addToAlbum')}
      width={700}
      okButtonProps={{
        disabled: uploadAlbumAssetLoading,
        loading: addAlbumAssetsLoading,
      }}
      cancelButtonProps={{
        disabled: uploadAlbumAssetLoading || addAlbumAssetsLoading,
      }}
      okText={t('add')}>
      <AlbumAssetsGrid>
        {allAssets.map((asset, index) => (
          <TempAlbumAsset
            key={index}
            asset={asset}
            onDelete={removeUploadingAlbumAsset}
          />
        ))}

        <TempAlbumAssetWrapper onClick={() => triggerUpload()}>
          <TempAlbumAssetThumbnailWrapper>
            <PlusOutlined
              style={{
                color: 'rgb(101, 103, 107)',
                fontSize: 25,
                fontWeight: 'bold',
              }}
            />
          </TempAlbumAssetThumbnailWrapper>
        </TempAlbumAssetWrapper>
      </AlbumAssetsGrid>
    </Modal>
  );
};

export default AlbumAssetsModal;

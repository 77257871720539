import {
  getAlbumAssetFormData,
  getCourseFormData,
  multipartFormConfig,
} from 'utils/formHelper';
import requester from 'utils/requester';
import { BaseCourse, CourseAPI, Course, InviteCodeModel } from './types';
import { getNonTenantEndpoint } from 'utils';
import { AxiosProgressEvent } from 'axios';
import { UploadingProgressModel } from 'features/asset/types';

const courseAPI: CourseAPI = {
  getCourses(params) {
    return requester.get('course/', params);
  },

  createCourse(course: BaseCourse) {
    return requester.post(
      'course/',
      getCourseFormData(course),
      multipartFormConfig,
    );
  },

  updateCourse(course: Course) {
    return requester.put(
      `course/${course.id}/`,
      getCourseFormData(course),
      multipartFormConfig,
    );
  },

  deleteCourse(id: number) {
    return requester.delete(`course/${id}/`);
  },

  getCourse(id: number) {
    return requester.get(`course/${id}/`);
  },

  getCourseStudents({ id, params }) {
    return requester.get(`course/${id}/student/`, params);
  },

  deleteCourseStudent({ courseId, studentId }) {
    return requester.delete(`course/${courseId}/student/${studentId}/`);
  },

  addCourseStudents({ studentIds, courseId }) {
    return requester.post(`course/${courseId}/student/`, {
      student_id: studentIds,
    });
  },

  getCourseInvitation(params) {
    return requester.post('invitation-code/', params);
  },

  getCourseAssets({ id, params }) {
    return requester.get(`course/${id}/assets/`, {
      ...params,
      page_size: 9999,
      file_only: true,
    });
  },

  deleteCourseAsset({ courseId, assetId }) {
    return requester.delete(`course/${courseId}/assets/${assetId}/`);
  },

  addCourseAssets({ courseId, assetIds }) {
    return requester.post(`course/${courseId}/assets/`, {
      assets_id: assetIds,
    });
  },

  orderCourseAssets({ courseId, ids }) {
    return requester.post(`course/${courseId}/assets/order/`, {
      assets_id: ids,
    });
  },

  getRoleCourses(ids) {
    return requester.get('course/', { ids, page_size: 9999 });
  },

  getCourseAlbums(id: string) {
    return requester.get(`course/${id}/album/`);
  },

  uploadAlbumAsset(albumFileModel, updateProgress) {
    return requester.post(
      getNonTenantEndpoint('upload-temp/'),
      getAlbumAssetFormData(albumFileModel.file),
      {
        ...multipartFormConfig,
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (!progressEvent.total) {
            return;
          }

          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );

          const uploadingProgressModel: UploadingProgressModel = {
            uid: albumFileModel.uid,
            percentCompleted,
          };

          updateProgress(uploadingProgressModel);
        },
      },
    );
  },

  createAlbum(courseId, model) {
    return requester.post(`course/${courseId}/album/`, model);
  },

  updateAlbum({ albumId, courseId, updatedAlbum }) {
    return requester.put(`course/${courseId}/album/${albumId}/`, updatedAlbum);
  },

  getAlbum(courseId, albumId) {
    return requester.get(`course/${courseId}/album/${albumId}/`);
  },

  deleteAlbum({ courseId, albumId }) {
    return requester.delete(`course/${courseId}/album/${albumId}/`);
  },

  deleteAlbumAsset({ courseId, albumId, assetId }) {
    return requester.post(
      `course/${courseId}/album/${albumId}/assets/remove/`,
      {
        asset_id: assetId,
      },
    );
  },

  addAlbumAssets({ assets, courseId, albumId }) {
    return requester.post(`course/${courseId}/album/${albumId}/assets/add/`, {
      assets,
    });
  },

  async getInviteCode(courseId) {
    const { results } = await requester.get('course-public-code/');
    const result = results.find(
      ({ course }: { course: Course }) => course.id === Number(courseId),
    );

    return result;
  },

  generateInviteCode(model: InviteCodeModel) {
    return requester.post('course-public-code/', {
      course_id: model.courseId,
    });
  },

  updateInviteCode(model: InviteCodeModel) {
    return requester.put(`course-public-code/${model.codeId}/`, {
      course_id: model.courseId,
      password: model.password,
    });
  },
};

export default courseAPI;

import { Fragment, useState } from 'react';
import { HasFilterPageContent, PageHeader } from 'components/Common/Page';
import { Button, Table, Dropdown, Popconfirm as PopConfirm } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { BaseBranch, Branch } from 'features/branch/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useDeleteBranch,
  useBranches,
  useResetBranchState,
} from 'features/branch/branchHooks';
import BranchForm from 'features/branch/BranchForm';
import FilterPanel from 'features/branch/Branches/Filter';
import type { MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useTableSorting } from 'app/hooks';

const getLocationString = (location: string) => {
  const regExp = /\(([^)]+)\)/;
  const matches = regExp.exec(location);

  return matches ? matches[1] : '';
};

const initValues: BaseBranch = {
  name: '',
  address: '',
  location: '',
};

const Branches = () => {
  const { t } = useTranslation(LOCALES.BRANCH);
  const {
    branches,
    getBranchesLoading,
    paginationConfig,
    refreshAfterCreating,
    refreshAfterDeleting,
  } = useBranches();
  useResetBranchState();

  const { handleSorting, getColumnSorting } = useTableSorting();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [initialBranch, setInitialBranch] = useState<BaseBranch>(initValues);

  const [openedPopConfirm, setOpenedPopConfirm] = useState(-1);
  const { handleDeleteBranch, deleteBranchLoading } = useDeleteBranch(() => {
    setOpenedPopConfirm(-1);
    refreshAfterDeleting();
  });

  const onOpenCreateForm = () => {
    setIsFormOpen(true);
    setInitialBranch(initValues);
  };

  const onOpenEditForm = (branch: Branch) => {
    setIsFormOpen(true);

    setInitialBranch({
      address: branch.address,
      location: getLocationString(branch.location).replace(' ', ','),
      name: branch.name,
      id: branch.id,
    });
  };

  const onCloseForm = () => {
    setIsFormOpen(false);
    setInitialBranch(initValues);
  };

  const columns: ColumnsType<Branch> = [
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      ...getColumnSorting('name'),
    },
    {
      title: t('address'),
      key: 'address',
      dataIndex: 'address',
      ...getColumnSorting('address'),
    },
    {
      title: t('location'),
      key: 'location',
      dataIndex: 'location',
      ...getColumnSorting('location'),
      render: location => {
        return getLocationString(location).replace(' ', ' - ');
      },
    },

    {
      title: '',
      width: 50,
      render: (branch: Branch) => {
        const items: MenuProps['items'] = [
          {
            label: (
              <p
                className='styled-table__action-item'
                onClick={() => onOpenEditForm(branch)}>
                {t('update')}
              </p>
            ),
            key: 'Edit',
          },
          { type: 'divider' },
          {
            label: (
              <p
                className='styled-table__action-item color-error'
                onClick={() => setOpenedPopConfirm(branch.id)}>
                {t('delete')}
              </p>
            ),
            key: 'Delete',
          },
        ];
        return (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            overlayStyle={{ width: 150 }}
            overlayClassName='styled-table__actions'
            placement='bottomRight'>
            <PopConfirm
              placement='topRight'
              title={t('deleteWarning')}
              onConfirm={() => handleDeleteBranch(branch.id)}
              okText={t('delete')}
              cancelText={t('cancel')}
              okButtonProps={{ loading: deleteBranchLoading }}
              cancelButtonProps={{ disabled: deleteBranchLoading }}
              open={openedPopConfirm === branch.id}
              onCancel={() => setOpenedPopConfirm(-1)}>
              <Button size='small' type='text'>
                <EllipsisOutlined />
              </Button>
            </PopConfirm>
          </Dropdown>
        );
      },
      key: 'action',
    },
  ];

  return (
    <Fragment>
      <FilterPanel loading={getBranchesLoading} />

      <HasFilterPageContent>
        <PageHeader title={t('branches')}>
          <Button type='primary' onClick={onOpenCreateForm}>
            {t('create')}
          </Button>
        </PageHeader>

        <Table
          loading={getBranchesLoading}
          dataSource={branches}
          columns={columns}
          pagination={paginationConfig}
          rowKey='id'
          onChange={handleSorting}
        />
      </HasFilterPageContent>

      <BranchForm
        isOpen={isFormOpen}
        onClose={onCloseForm}
        refreshAfterCreating={refreshAfterCreating}
        initValues={initialBranch}
      />
    </Fragment>
  );
};

export default Branches;

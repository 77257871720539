import requester from 'utils/requester';
import { BranchAPI } from './types';

const branchAPI: BranchAPI = {
  getAllBranches() {
    return requester.get('branches/', {
      page: 1,
      page_size: 9999,
    });
  },

  getBranches(params) {
    return requester.get('branches/', params);
  },

  createBranch(branch) {
    return requester.post('branches/', branch);
  },

  updateBranch(branch) {
    return requester.put(`branches/${branch.id}/`, branch);
  },

  deleteBranch(id) {
    return requester.delete(`branches/${id}/`);
  },
};

export default branchAPI;

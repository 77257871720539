import { useState, useEffect } from 'react';
import { Table, Modal, Typography, Space, Image, Row } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Book } from 'features/book/types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useBooksModal,
  useResetBookState,
  BOOK_MODAL_QUERY_PARAMS,
} from 'features/book/bookHooks';
import { useTableSorting } from 'app/hooks';
import { TableRowSelection } from 'antd/es/table/interface';
import SearchBox from 'components/Common/SearchBox';

const BooksModal = ({
  isOpen,
  onCancel,
  addingLoading,
  onSubmit,
  addingSuccess,
  initValues = [],
  isAcceptEmpty = false,
}: {
  isOpen: boolean;
  onCancel: () => void;
  addingLoading: boolean;
  onSubmit: (ids: number[]) => void;
  addingSuccess?: any;
  initValues?: number[];
  isAcceptEmpty?: boolean;
}) => {
  const { t } = useTranslation(LOCALES.BOOK);

  const { handleSorting, getColumnSorting } = useTableSorting(
    BOOK_MODAL_QUERY_PARAMS.ORDERING,
  );

  const { books, getBooksLoading, paginationConfig, goToFirstPage } =
    useBooksModal(isOpen);
  useResetBookState();

  const [selectedBookIds, setSelectedBookIds] = useState<any[]>([]);

  useEffect(() => {
    if (isOpen) {
      setSelectedBookIds(initValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const columns: ColumnsType<Book> = [
    {
      title: t('name'),
      key: 'name',
      ...getColumnSorting('name'),

      render: record => {
        return (
          <Space>
            <Image
              placeholder={true}
              style={{ objectFit: 'contain' }}
              width={30}
              height={30}
              src={record.photo}
            />
            {record.name}
          </Space>
        );
      },
    },
    { title: t('author'), key: 'author', dataIndex: 'author' },
    {
      title: t('readers'),
      key: 'total_reader',
      dataIndex: 'total_reader',
      align: 'right',
      width: 150,
      ...getColumnSorting('total_reader'),
    },
  ];

  const rowSelection: TableRowSelection<Book> = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedBookIds(selectedRowKeys);
    },
  };

  useEffect(() => {
    if (addingSuccess) {
      goToFirstPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingSuccess]);

  return (
    <Modal
      open={isOpen}
      width={1100}
      onCancel={onCancel}
      onOk={() => onSubmit(selectedBookIds)}
      cancelButtonProps={{ disabled: addingLoading }}
      okButtonProps={{
        loading: addingLoading,
        disabled: !isAcceptEmpty && !selectedBookIds.length,
      }}
      okText={t('add')}
      cancelText={t('cancel')}>
      <Row justify='space-between'>
        <Typography.Title level={4}>{t('books')}</Typography.Title>{' '}
        <SearchBox searchKey={BOOK_MODAL_QUERY_PARAMS.SEARCH}></SearchBox>
      </Row>

      <Table
        rowSelection={{
          ...rowSelection,
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedBookIds,
        }}
        loading={getBooksLoading}
        dataSource={books}
        columns={columns}
        pagination={paginationConfig}
        rowKey='id'
        onChange={handleSorting}
      />
    </Modal>
  );
};

export default BooksModal;

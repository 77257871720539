export const UserIcon = () => (
  <svg
    width='25px'
    height='24px'
    viewBox='0 0 25 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'>
    <desc>Created with Lunacy</desc>
    <g id='Component/icon/ic_Someone’s-activity'>
      <path
        d='M2.85488 4.82353C2.85488 2.15957 5.03843 0 7.73197 0C10.4255 0 12.6091 2.15957 12.6091 4.82353C12.6091 6.49625 11.7482 7.9701 10.4407 8.83533C13.3749 9.92091 15.4639 12.7192 15.4639 16L15.4639 18L0 18L0 16C0 12.7192 2.08902 9.92091 5.02328 8.83533C3.71578 7.9701 2.85488 6.49625 2.85488 4.82353ZM7.73197 7.52941C9.24298 7.52941 10.4679 6.31795 10.4679 4.82353C10.4679 3.32911 9.24298 2.11765 7.73197 2.11765C6.22096 2.11765 4.99604 3.32911 4.99604 4.82353C4.99604 6.31795 6.22096 7.52941 7.73197 7.52941ZM2.1424 15.8824C2.2057 12.8829 4.684 10.4706 7.73197 10.4706C10.7799 10.4706 13.2582 12.8829 13.3215 15.8824L2.1424 15.8824Z'
        transform='translate(5.154648 3)'
        id='Shape'
        fill='#92929D'
        fillRule='evenodd'
        stroke='none'
      />
    </g>
  </svg>
);

export const EmailIcon = () => (
  <svg
    width='25px'
    height='24px'
    viewBox='0 0 25 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'>
    <desc>Created with Lunacy</desc>
    <g id='Component/icon/ic_Someone’s-activity'>
      <path
        d='M3.09288 0L19.5877 0C21.2958 0 22.6805 1.34315 22.6805 3L22.6805 3.42764C22.6807 3.43623 22.6807 3.44483 22.6805 3.45341L22.6805 14C22.6805 15.6569 21.2958 17 19.5877 17L3.09288 17C1.38478 17 8.85179e-05 15.6569 8.85179e-05 14L8.85179e-05 3.45393C-3.44843e-05 3.445 -3.46371e-05 3.43606 8.85179e-05 3.42713L8.85179e-05 3C8.85179e-05 1.34315 1.38478 0 3.09288 0ZM2.06195 5.36206L2.06195 14C2.06195 14.5523 2.52351 15 3.09288 15L19.5877 15C20.1571 15 20.6187 14.5523 20.6187 14L20.6187 5.36205L13.1139 10.4578C12.049 11.1809 10.6316 11.1809 9.56671 10.4578L2.06195 5.36206ZM20.6157 2.92279L11.9315 8.81932C11.5765 9.06034 11.1041 9.06034 10.7491 8.81932L2.06497 2.9228C2.1056 2.40659 2.55029 2 3.09288 2L19.5877 2C20.1303 2 20.575 2.40659 20.6157 2.92279Z'
        transform='translate(1.030841 4)'
        id='Shape'
        fill='#92929D'
        fillRule='evenodd'
        stroke='none'
      />
    </g>
  </svg>
);

export const HomeIcon = () => (
  <svg
    width='25px'
    height='24px'
    viewBox='0 0 25 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'>
    <desc>Created with Lunacy</desc>
    <g id='Component/icon/ic_Someone’s-activity'>
      <g id='iconspace_Home-2_25px'>
        <path
          d='M0 0L24.7423 0L24.7423 24L0 24L0 0Z'
          id='Path'
          fill='none'
          stroke='none'
        />
        <path
          d='M18.1929 8.06104L18.1929 17.0588C18.1929 18.6832 16.8353 20 15.1607 20L12.7263 20C12.3914 20 12.1198 19.7366 12.1198 19.4118L12.1198 16.4969C12.1198 15.8361 11.5755 15.3027 10.907 15.3027L9.70287 15.3027C9.03439 15.3027 8.49001 15.8361 8.49001 16.4969L8.49001 19.4118C8.49001 19.7366 8.2185 20 7.88358 20L5.45787 20C3.78326 20 2.42572 18.6832 2.42572 17.0588L2.42572 8.06104L1.00577 9.26622C0.753719 9.48015 0.3706 9.45538 0.150053 9.21089C-0.0704943 8.96639 -0.0449531 8.59477 0.207101 8.38084L9.90996 0.145543C10.1386 -0.0485144 10.48 -0.0485144 10.7086 0.145543L20.4115 8.38084C20.6635 8.59477 20.6891 8.96639 20.4685 9.21089C20.248 9.45538 19.8649 9.48015 19.6128 9.26622L18.1929 8.06104Z'
          transform='translate(2.061859 2)'
          id='Path'
          fill='#92929D'
          stroke='none'
        />
      </g>
    </g>
  </svg>
);

export const PhoneIcon = () => (
  <svg
    width='25px'
    height='24px'
    viewBox='0 0 25 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'>
    <desc>Created with Lunacy</desc>
    <g id='Component/icon/ic_Someone’s-activity'>
      <path
        d='M7.86688 8.79548C7.68014 8.60481 7.64594 8.16193 7.88723 7.63612C8.79183 5.29063 8.91455 4.85539 8.33486 4.26349L4.6139 0.464207C3.8993 -0.265435 2.76389 -0.0796889 2.06181 0.637165C-0.687271 3.44412 -0.687271 7.97489 2.06181 10.7818L9.01273 17.8791C11.7902 20.7151 16.3165 20.7151 19.094 17.8791C19.773 17.1858 19.9412 16.1141 19.2634 15.4221L15.5424 11.6228C14.9188 10.986 14.495 11.1106 12.1707 12.043C11.6348 12.297 11.2672 12.2674 11.1039 12.1006L7.86688 8.79548ZM17.6289 8.20607C17.6289 4.78191 14.7672 2.00607 11.2371 2.00607C10.6678 2.00607 10.2062 1.55836 10.2062 1.00607C10.2062 0.453788 10.6678 0.00607319 11.2371 0.00607319C15.9059 0.00607319 19.6908 3.67734 19.6908 8.20607C19.6908 8.75836 19.2292 9.20607 18.6598 9.20607C18.0905 9.20607 17.6289 8.75836 17.6289 8.20607ZM17.5374 16.5636C15.5688 18.5074 12.4518 18.4867 10.5076 16.5016L3.55666 9.40434C1.60849 7.41516 1.5646 4.19858 3.42501 2.15411L6.52372 5.31805C6.5214 5.32641 6.51902 5.33493 6.51658 5.3436C6.41934 5.6885 6.23968 6.20373 5.97954 6.87979C5.44931 8.03063 5.5499 9.33354 6.37203 10.173L9.60904 13.4781C10.4546 14.3415 11.7968 14.4495 13.0207 13.864C13.5881 13.6386 14.0692 13.4628 14.4016 13.3618L17.5374 16.5636ZM11.2371 4.70607C13.2299 4.70607 14.8454 6.27308 14.8454 8.20607C14.8454 8.75836 15.3069 9.20607 15.8763 9.20607C16.4457 9.20607 16.9072 8.75836 16.9072 8.20607C16.9072 5.16851 14.3686 2.70607 11.2371 2.70607C10.6678 2.70607 10.2062 3.15379 10.2062 3.70607C10.2062 4.25836 10.6678 4.70607 11.2371 4.70607ZM12.0619 8.20607C12.0619 7.76425 11.6926 7.40607 11.2371 7.40607C10.6678 7.40607 10.2062 6.95836 10.2062 6.40607C10.2062 5.85379 10.6678 5.40607 11.2371 5.40607C12.8314 5.40607 14.1237 6.65968 14.1237 8.20607C14.1237 8.75836 13.6622 9.20607 13.0928 9.20607C12.5234 9.20607 12.0619 8.75836 12.0619 8.20607Z'
        transform='translate(2.061859 1.993927)'
        id='Shape'
        fill='#92929D'
        fillRule='evenodd'
        stroke='none'
      />
    </g>
  </svg>
);

export const CheckIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32px'
    height='32px'
    viewBox='0 0 32 32'
    version='1.1'>
    <title>24C5CFBA-F565-4529-BB3F-FD887FC618F8</title>
    <desc>Created with sketchtool.</desc>
    <g
      id='School-Management'
      stroke='none'
      strokeWidth='1'
      fill='#fff'
      fillRule='evenodd'>
      <g
        id='Profile-Updating'
        transform='translate(-1135.000000, -386.000000)'
        fill='#F1F1F5'>
        <g
          id='Button/With-Icon//Btn_Blue---Icon'
          transform='translate(1127.000000, 378.000000)'>
          <g
            id='Component/icon/ic_Check'
            transform='translate(8.000000, 8.000000)'>
            <path
              d='M7.60474028,13.2960056 C7.08143898,12.7779219 6.23722961,12.7821515 5.71914586,13.3054528 C5.20106211,13.8287541 5.20529176,14.6729635 5.72859306,15.1910472 L13.4957223,22.8807347 C14.044074,23.4236192 14.9373619,23.3891027 15.4421873,22.8055238 L26.3417248,10.2056432 C26.8234882,9.64872399 26.7625624,8.80670534 26.2056432,8.3249419 C25.648724,7.84317847 24.8067053,7.90410426 24.3249419,8.46102347 L14.3584147,19.9823429 L7.60474028,13.2960056 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BtnCheckIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'>
    <desc>Created with sketchtool.</desc>
    <g
      id='School-Management'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'>
      <g
        id='Profile-Updating'
        transform='translate(-1107.000000, -462.000000)'
        fill='#FF4800'>
        <g id='#Profile' transform='translate(933.000000, 130.000000)'>
          <g id='Card_Team'>
            <g id='Profile' transform='translate(-104.000000, 129.228137)'>
              <g
                id='Phone-Verification'
                transform='translate(181.000000, 202.771863)'>
                <g id='Group-9'>
                  <g
                    id='Component/icon/ic_Projects'
                    transform='translate(97.000000, 0.000000)'>
                    <path
                      d='M16,1 C19.8659932,1 23,4.13400675 23,8 L23,16 C23,19.8659932 19.8659932,23 16,23 L8,23 C4.13400675,23 1,19.8659932 1,16 L1,8 C1,4.13400675 4.13400675,1 8,1 L16,1 Z M16,3 L8,3 C5.23857625,3 3,5.23857625 3,8 L3,16 C3,18.7614237 5.23857625,21 8,21 L16,21 C18.7614237,21 21,18.7614237 21,16 L21,8 C21,5.23857625 18.7614237,3 16,3 Z M15.2178033,8.36355175 C15.5693039,7.93756381 16.199583,7.87717993 16.6255709,8.22868059 C17.0515589,8.58018125 17.1119427,9.2104603 16.7604421,9.63644825 L16.7604421,9.63644825 L11.8095885,15.6364482 C11.4357478,16.0895103 10.7537809,16.1243169 10.3357557,15.7116706 L10.3357557,15.7116706 L7.28660929,12.701758 C6.8935645,12.3137706 6.88946469,11.6806189 7.27745211,11.2875741 C7.66543953,10.8945293 8.29859123,10.8904295 8.69163602,11.2784169 L8.69163602,11.2784169 L10.9627476,13.5203057 Z'
                      id='Combined-Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ImgUpload = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17px'
    height='18px'
    viewBox='0 0 17 18'
    version='1.1'>
    <desc>Created with sketchtool.</desc>
    <g
      id='School-Management'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'>
      <g id='Profile-Updating' transform='translate(-1063.000000, -531.000000)'>
        <g id='#Profile' transform='translate(933.000000, 130.000000)'>
          <g id='Card_Team'>
            <g id='Profile' transform='translate(-104.000000, 129.228137)'>
              <g
                id='Avatar-Update'
                transform='translate(105.000000, 239.771863)'>
                <g id='Group-8' transform='translate(57.000000, 28.231939)'>
                  <g
                    id='Component/icon/ic_image-fill'
                    transform='translate(68.000000, 0.000000)'>
                    <g
                      id='iconspace_Add-Picture_25px'
                      transform='translate(2.000000, 2.000000)'>
                      <polygon id='Path' points='0 0 20 0 20 20 0 20' />
                      <path
                        d='M7.87803463,10.9747857 L15.0875874,18.8 L4.8,18.8 C3.47641212,18.8 2.36732382,17.8816181 2.07520002,16.6473192 L7.87803463,10.9747857 Z M13.9776834,9.3665557 L18.8,13.774914 L18.8,16 C18.8,17.4890867 17.6375944,18.7066789 16.1706766,18.7948834 L10.7945707,12.9578873 L13.9776834,9.3665557 Z M16,2 C17.5463973,2 18.8,3.2536027 18.8,4.8 L18.8,12.6916888 L14.2125301,8.50233683 C14.0474007,8.35411073 13.7931734,8.36862715 13.6459897,8.53468638 L10.2498033,12.3664173 L8.19022926,10.1302773 C8.037857,9.96341169 7.77761002,9.95579822 7.61574347,10.1134708 L2,15.602202 L2,4.8 C2,3.2536027 3.2536027,2 4.8,2 L16,2 Z M7.6,4.4 C6.7163444,4.4 6,5.1163444 6,6 C6,6.8836556 6.7163444,7.6 7.6,7.6 C8.4836556,7.6 9.2,6.8836556 9.2,6 C9.2,5.1163444 8.4836556,4.4 7.6,4.4 Z M7.6,5.2 C8.0418278,5.2 8.4,5.5581722 8.4,6 C8.4,6.4418278 8.0418278,6.8 7.6,6.8 C7.1581722,6.8 6.8,6.4418278 6.8,6 C6.8,5.5581722 7.1581722,5.2 7.6,5.2 Z'
                        id='Combined-Shape'
                        fill='#B5B5BE'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ShieldIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'>
    <desc>Created with sketchtool.</desc>
    <g
      id='School-Management'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'>
      <g
        id='Profile-Updating'
        transform='translate(-1142.000000, -312.000000)'
        fill='#C1112F'>
        <g id='#Profile' transform='translate(933.000000, 130.000000)'>
          <g id='Card_Team'>
            <g id='Profile' transform='translate(-104.000000, 129.228137)'>
              <g
                id='Email-Verification'
                transform='translate(146.000000, 52.771863)'>
                <g id='Group-10'>
                  <g
                    id='Component/icon/ic_Spam'
                    transform='translate(167.000000, 0.000000)'>
                    <path
                      d='M11.6603555,1.0594461 C11.8798488,0.980184633 12.1201512,0.980184633 12.3396445,1.0594461 L21.3396445,4.3094461 C21.735923,4.45254668 22,4.82867536 22,5.25 L22,12 C22,17.4996419 18.6712061,21.1983018 12.2657497,22.9640421 C12.0940579,23.011371 11.9128414,23.0119889 11.7408309,22.965832 C5.33125415,21.245901 2,17.5445075 2,12 L2,5.25 C2,4.82867536 2.26407701,4.45254668 2.66035554,4.3094461 L11.6603555,1.0594461 Z M12,3.06320329 L4,5.95209218 L4,12 C4,16.4966243 6.58512253,19.4399927 11.9965046,20.9620023 C17.4123019,19.3998454 20,16.4544444 20,12 L20,5.95209218 L12,3.06320329 Z M11.0496652,6.93024554 C11.0496652,6.37796079 11.4973804,5.93024554 12.0496652,5.93024554 C12.6019499,5.93024554 13.0496652,6.37796079 13.0496652,6.93024554 L13.0496652,13.5427856 C13.0496652,14.0950704 12.6019499,14.5427856 12.0496652,14.5427856 C11.4973804,14.5427856 11.0496652,14.0950704 11.0496652,13.5427856 L11.0496652,6.93024554 Z M12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 Z'
                      id='Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const LockIcon = () => (
  <svg
    id='Component_icon_ic_Someone_s_activity'
    data-name='Component/icon/ic_Someone’s activity'
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'>
    <g id='iconspace_Private_25px'>
      <path id='Path' d='M0,0H24V24H0Z' fill='none' />
      <path
        id='Shape'
        d='M14.543,20H1.457A1.466,1.466,0,0,1,0,18.528V8.4A1.466,1.466,0,0,1,1.457,6.924H3.026V5.094a5.045,5.045,0,1,1,10.09,0v1.83h1.427A1.466,1.466,0,0,1,16,8.4V18.528A1.466,1.466,0,0,1,14.543,20ZM1.822,8.742v9.44H14.177V8.742ZM8.07,1.818A3.253,3.253,0,0,0,4.848,5.094V6.9h6.444v-1.8A3.253,3.253,0,0,0,8.07,1.818Z'
        transform='translate(4 2)'
        fill='#b5b5be'
      />
    </g>
  </svg>
);

export const CalendarIcon = () => (
  <svg
    id='Component_icon_ic_Calendar'
    data-name='Component/icon/ic_Calendar'
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'>
    <path
      id='Shape'
      d='M17,20H3a3,3,0,0,1-3-3V3.7A1.9,1.9,0,0,1,1.9,1.8H4.5V1a1,1,0,1,1,2,0v.8h7.9V1a1,1,0,1,1,2,0v.8h1.7A1.9,1.9,0,0,1,20,3.7V17A3,3,0,0,1,17,20ZM2,7.4V17a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V7.4ZM2,3.8V5.4H18V3.8H16.4a1,1,0,0,1-1.991,0H6.5A1,1,0,0,1,4.5,3.8Zm9.8,10.8H4.6a1,1,0,0,1,0-2h7.2a1,1,0,0,1,0,2ZM8.2,11H4.6a1,1,0,1,1,0-2H8.2a1,1,0,1,1,0,2Z'
      transform='translate(2 2)'
      fill='#b5b5b2'
    />
  </svg>
);

const en = {
  no: 'STT',
  create: 'Tạo mới',
  cancel: 'Huỷ',
  update: 'Cập nhật',
  staffs: 'Danh sách nhân viên',
  add: 'Thêm',
  invite: 'Mời',
  inviteStaff: 'Mời nhân viên',

  name: 'Tên',
  email: 'Email',
  invitedAt: 'Ngày mời',
  status: 'Trạng thái',
  unlinked: 'Chưa liên kết',
  linked: 'Đã liên kết',

  deleteWarning: 'Bạn có chắc muốn xóa nhân viên này không?',
  delete: 'Xoá',

  staffGroups: 'Nhóm nhân viên',

  nameIsRequired: 'Vui lòng nhập tên nhân viên',
  updateStaff: 'Cập nhật nhân viên',
  role: 'Phân quyền', 
  updateRole: 'Cập nhật Quyền'
};

export default en;

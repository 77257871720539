import type { TablePaginationConfig } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { CLIENT_DATE_2_FORMAT, CLIENT_DATE_FORMAT } from 'config';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Asset } from 'features/asset/types';
import { Album, AlbumAsset } from 'features/course/types';

export const getNonTenantEndpoint = (path: string) => {
  return `${process.env.REACT_APP_NON_TENANT_BASE_URL}${path}`;
};

export const getNo = (config: TablePaginationConfig, index: number) => {
  const { pageSize, current } = config;

  if (!pageSize || !current) {
    return 0;
  }

  return pageSize * (current - 1) + index + 1;
};

export const getBirthDateValue = (birthDate: Dayjs) => {
  return birthDate.format(CLIENT_DATE_2_FORMAT);
};

export const getPhoneNumberValue = (values: any) => {
  return values.phoneNumberPrefix + values.phone_number;
};

export const getIdPath = (path: string, id?: string) => {
  if (!id) return path;

  return path.replace(':id', id);
};

export const getSortOrder = (
  sortedInfo: SorterResult<any>,
  columnKey: string,
) => {
  return sortedInfo.columnKey === columnKey ? sortedInfo.order : null;
};

export const getDisplayingDate = (
  date?: string,
  format = CLIENT_DATE_2_FORMAT,
) => {
  return dayjs(date, format).format(CLIENT_DATE_FORMAT);
};

export const isPlayableFile = (file: Asset) => {
  return (
    file.extension &&
    ['mp3', 'mp4', 'pdf', 'xlsx', 'csv', 'png', 'jpeg', 'svg', 'webp'].includes(
      file.extension,
    )
  );
};

export const addCachedStatusToAlbumAsset = (
  album: Album,
  id: number,
): Album => {
  const albumAssets = album.assets;

  const nextAlbumAssets: AlbumAsset[] = albumAssets.map(albumAsset => {
    const { asset } = albumAsset;
    const nextAsset = {
      ...asset,
      isCached: asset.id === id ? true : asset.isCached,
    };

    return {
      ...albumAsset,
      asset: nextAsset,
    };
  });

  return {
    ...album,
    assets: nextAlbumAssets,
  };
};

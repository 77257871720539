import { useEffect } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  useSuccess,
} from 'app/hooks';
import {
  addAlbumAssetBase64,
  addAlbumAssets,
  createAlbum,
  deleteAlbum,
  deleteAlbumAsset,
  getAlbum,
  getCourseAlbums,
  removeUploadingAlbumAssets,
  resetUploadingAlbumAssets,
  selectAlbum,
  updateAlbum,
  uploadAlbumAsset,
} from 'features/course/courseSlice';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  AddAlbumAssetsModel,
  CreateAlbumModel,
  DeleteAlbumAssetModel,
  DeleteAlbumModel,
  UpdateAlbumModel,
  UploadingAlbumAssetModel,
} from '../types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import PATHS from 'router/paths';

export const useCourseAlbums = () => {
  const getCourseAlbumsFailed = useAppSelector(
    state => state.course.getCourseAlbumsFailed,
  );
  const getCourseAlbumsLoading = useAppSelector(
    state => state.course.getCourseAlbumsLoading,
  );
  const courseAlbums = useAppSelector(state => state.course.courseAlbums);

  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getCourseAlbums(id));
    }
  }, [dispatch, id]);

  useFailed(getCourseAlbumsFailed);

  const refreshCurrentPage = () => {
    if (id) {
      dispatch(getCourseAlbums(id));
    }
  };

  return {
    getCourseAlbumsLoading,
    courseAlbums,
    refreshCurrentPage,
  };
};

export const useUploadAlbumAsset = () => {
  const uploadAlbumAssetLoading = useAppSelector(
    state => state.course.uploadAlbumAssetLoading,
  );
  const uploadAlbumAssetFailed = useAppSelector(
    state => state.course.uploadAlbumAssetFailed,
  );
  const uploadingAlbumAssets = useAppSelector(
    state => state.course.uploadingAlbumAssets,
  );
  const albumFilesQueue = useAppSelector(state => state.course.albumFilesQueue);

  const dispatch = useAppDispatch();

  const handleUploadAlbumAsset = (albumFile: UploadingAlbumAssetModel) => {
    dispatch(uploadAlbumAsset(albumFile));
  };

  useFailed(uploadAlbumAssetFailed);

  return {
    uploadAlbumAssetLoading,
    uploadingAlbumAssets,
    albumFilesQueue,
    handleUploadAlbumAsset,
  };
};

export const useCreateAlbum = () => {
  const createAlbumLoading = useAppSelector(
    state => state.course.createAlbumLoading,
  );
  const createAlbumSuccess = useAppSelector(
    state => state.course.createAlbumSuccess,
  );
  const createAlbumFailed = useAppSelector(
    state => state.course.createAlbumFailed,
  );
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { t } = useTranslation(LOCALES.MESSAGE);

  const handleCreateAlbum = ({
    courseId,
    model,
  }: {
    courseId: string;
    model: CreateAlbumModel;
  }) => {
    dispatch(createAlbum({ courseId, model }));
  };

  useSuccess(createAlbumSuccess, t<string>('ALBUM_CREATED_SUCCESSFULLY'), () =>
    navigate(generatePath(PATHS.app.courses.details.albums, { id })),
  );

  useFailed(createAlbumFailed);

  return {
    createAlbumLoading,
    handleCreateAlbum,
  };
};

export const useCourseAlbum = (forceFetch?: boolean) => {
  const getAlbumFailed = useAppSelector(state => state.course.getAlbumFailed);
  const getAlbumLoading = useAppSelector(state => state.course.getAlbumLoading);
  const album = useAppSelector(state => state.course.album);
  const courseAlbums = useAppSelector(state => state.course.courseAlbums);

  const dispatch = useAppDispatch();
  const { id, albumId } = useParams();

  useEffect(() => {
    if (albumId && id) {
      const currentAlbum = courseAlbums.find(
        album => album.id === Number(albumId),
      );
      if (currentAlbum && !forceFetch) {
        dispatch(selectAlbum(currentAlbum));
      } else {
        dispatch(getAlbum({ courseId: id, albumId }));
      }
    }
  }, [dispatch, albumId, courseAlbums, id, forceFetch]);

  useFailed(getAlbumFailed);

  return {
    getAlbumLoading,
    album,
  };
};

export const useDeleteAlbum = (onSuccess?: () => void) => {
  const deleteAlbumLoading = useAppSelector(
    state => state.course.deleteAlbumLoading,
  );
  const deleteAlbumSuccess = useAppSelector(
    state => state.course.deleteAlbumSuccess,
  );
  const deleteAlbumFailed = useAppSelector(
    state => state.course.deleteAlbumFailed,
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation(LOCALES.MESSAGE);

  const handleDeleteAlbum = (model: DeleteAlbumModel) => {
    dispatch(deleteAlbum(model));
  };

  useSuccess(
    deleteAlbumSuccess,
    t<string>('ALBUM_DELETED_SUCCESSFULLY'),
    onSuccess,
  );

  useFailed(deleteAlbumFailed);

  return {
    deleteAlbumLoading,
    handleDeleteAlbum,
  };
};

export const useDeleteAlbumAsset = () => {
  const deleteAlbumAssetLoading = useAppSelector(
    state => state.course.deleteAlbumAssetLoading,
  );
  const deleteAlbumAssetSuccess = useAppSelector(
    state => state.course.deleteAlbumAssetSuccess,
  );
  const deleteAlbumAssetFailed = useAppSelector(
    state => state.course.deleteAlbumAssetFailed,
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation(LOCALES.MESSAGE);

  const handleDeleteAlbumAsset = (model: DeleteAlbumAssetModel) => {
    dispatch(deleteAlbumAsset(model));
  };

  useSuccess(deleteAlbumAssetSuccess, t<string>('ASSET_DELETED_SUCCESSFULLY'));

  useFailed(deleteAlbumAssetFailed);

  return {
    deleteAlbumAssetLoading,
    handleDeleteAlbumAsset,
  };
};

export const useUpdateAlbum = () => {
  const updateAlbumLoading = useAppSelector(
    state => state.course.updateAlbumLoading,
  );
  const updateAlbumSuccess = useAppSelector(
    state => state.course.updateAlbumSuccess,
  );
  const updateAlbumFailed = useAppSelector(
    state => state.course.updateAlbumFailed,
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation(LOCALES.MESSAGE);

  const handleUpdateAlbum = (model: UpdateAlbumModel) => {
    dispatch(updateAlbum(model));
  };

  useSuccess(updateAlbumSuccess, t<string>('ALBUM_UPDATED_SUCCESSFULLY'));

  useFailed(updateAlbumFailed);

  return {
    updateAlbumLoading,
    handleUpdateAlbum,
  };
};

export const useResetUploadingAlbumAssets = () => {
  const dispatch = useAppDispatch();

  return () => {
    dispatch(resetUploadingAlbumAssets());
  };
};

export const useRemoveUploadingAlbumAsset = () => {
  const dispatch = useAppDispatch();

  return (uid: string) => {
    dispatch(removeUploadingAlbumAssets(uid));
  };
};

export const useAddAlbumAssets = (onSuccess: () => void) => {
  const addAlbumAssetsLoading = useAppSelector(
    state => state.course.addAlbumAssetsLoading,
  );
  const addAlbumAssetsSuccess = useAppSelector(
    state => state.course.addAlbumAssetsSuccess,
  );
  const addAlbumAssetsFailed = useAppSelector(
    state => state.course.addAlbumAssetsFailed,
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation(LOCALES.MESSAGE);

  const handleAddAlbumAssets = (model: AddAlbumAssetsModel) => {
    dispatch(addAlbumAssets(model));
  };

  useSuccess(
    addAlbumAssetsSuccess,
    t<string>('ASSET_ADDED_SUCCESSFULLY'),
    onSuccess,
  );

  useFailed(addAlbumAssetsFailed);

  return {
    addAlbumAssetsLoading,
    handleAddAlbumAssets,
  };
};

export const useAddAlbumAssetBase64 = () => {
  const dispatch = useAppDispatch();

  return (model: { uid: string; base64: string }) => {
    dispatch(addAlbumAssetBase64(model));
  };
};

import { useEffect } from 'react';
import {
  UseCheckInsReturnType,
  BaseCheckIn,
  UseCreateCheckInReturnType,
  UseUpdateCheckInReturnType,
  UseDeleteCheckInReturnType,
  CheckIn,
} from './types';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  getCheckIns,
  createCheckIn,
  updateCheckIn,
  deleteCheckIn,
  resetCheckInState,
} from './checkInSlice';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';

export const CHECK_IN_QUERY_PARAMS = {
  SEARCH: 'search',
  STAFF_ID: 'staff__id',
  BRANCH_ID: 'branch__id',
};

export const useCheckIns = (): UseCheckInsReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const checkIns = useAppSelector(state => state.checkIn.checkIns);
  const getCheckInsLoading = useAppSelector(
    state => state.checkIn.getCheckInsLoading,
  );
  const getCheckInsFailed = useAppSelector(
    state => state.checkIn.getCheckInsFailed,
  );
  const paginationConfig = useAppSelector(
    state => state.checkIn.paginationConfig,
  );

  const dispatch = useAppDispatch();

  const urlQueryParams = useURLQueryParams(
    Object.values(CHECK_IN_QUERY_PARAMS),
  );
  const onPageChange = usePagination();

  useEffect(() => {
    dispatch(getCheckIns(urlQueryParams));
  }, [urlQueryParams, dispatch]);

  useFailed(getCheckInsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  const refreshCurrentPage = () => {
    dispatch(getCheckIns(urlQueryParams));
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      checkIns.length === 1 && urlQueryParams.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(urlQueryParams.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterCreating = () => {
    if (urlQueryParams.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    checkIns,
    getCheckInsLoading,
    getCheckInsFailed,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    refreshAfterCreating,
    refreshAfterDeleting,
  };
};

export const useCreateCheckIn = (
  callback: () => void,
): UseCreateCheckInReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const createCheckInSuccess = useAppSelector(
    state => state.checkIn.createCheckInSuccess,
  );
  const createCheckInFailed = useAppSelector(
    state => state.checkIn.createCheckInFailed,
  );
  const createCheckInLoading = useAppSelector(
    state => state.checkIn.createCheckInLoading,
  );

  const dispatch = useAppDispatch();

  const handleCreateCheckIn = (student: BaseCheckIn) => {
    dispatch(createCheckIn(student));
  };

  useSuccess(
    createCheckInSuccess,
    t<string>('CHECK_IN_CREATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(createCheckInFailed);

  return {
    createCheckInSuccess,
    createCheckInFailed,
    createCheckInLoading,
    handleCreateCheckIn,
  };
};

export const useUpdateCheckIn = (
  callback: () => void,
): UseUpdateCheckInReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const updateCheckInSuccess = useAppSelector(
    state => state.checkIn.updateCheckInSuccess,
  );
  const updateCheckInFailed = useAppSelector(
    state => state.checkIn.updateCheckInFailed,
  );
  const updateCheckInLoading = useAppSelector(
    state => state.checkIn.updateCheckInLoading,
  );

  const dispatch = useAppDispatch();

  const handleUpdateCheckIn = (student: CheckIn) => {
    dispatch(updateCheckIn(student));
  };

  useSuccess(
    updateCheckInSuccess,
    t<string>('CHECK_IN_UPDATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(updateCheckInFailed);

  return {
    updateCheckInSuccess,
    updateCheckInFailed,
    updateCheckInLoading,
    handleUpdateCheckIn,
  };
};

export const useDeleteCheckIn = (
  callback: () => void,
): UseDeleteCheckInReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const deleteCheckInSuccess = useAppSelector(
    state => state.checkIn.deleteCheckInSuccess,
  );
  const deleteCheckInFailed = useAppSelector(
    state => state.checkIn.deleteCheckInFailed,
  );
  const deleteCheckInLoading = useAppSelector(
    state => state.checkIn.deleteCheckInLoading,
  );

  const dispatch = useAppDispatch();

  const handleDeleteCheckIn = (id: number) => {
    dispatch(deleteCheckIn(id));
  };

  useSuccess(
    deleteCheckInSuccess,
    t<string>('CHECK_IN_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteCheckInFailed);

  return {
    deleteCheckInSuccess,
    deleteCheckInFailed,
    deleteCheckInLoading,
    handleDeleteCheckIn,
  };
};

export const useResetCheckInState = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCheckInState());
    };
  }, [dispatch]);
};

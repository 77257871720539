import { Button, Form, Input, Skeleton, Typography } from 'antd';
import {
  useCourseGenerateInviteCode,
  useCourseInviteCode,
  useCourseUpdateInviteCode,
} from 'features/course/courseHooks/courseInviteCode';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react';
import FormModal from 'components/Styled/FormModal';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { InviteCodeModel } from 'features/course/types';

const QrCodeWrapper = styled.div`
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .course-qr-code-tool-bar {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const CourseQRCode = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { t: validationT } = useTranslation(LOCALES.VALIDATION);
  const { t } = useTranslation(LOCALES.REGISTER);
  const [form] = Form.useForm();

  const onClose = () => {
    form.setFieldsValue({
      password: '',
      confirm_password: '',
    });
    setIsFormOpen(false);
  };

  const { inviteCode, getInviteCodeLoading } = useCourseInviteCode();
  const { handleGenerateInviteCode, generateInviteCodeLoading } =
    useCourseGenerateInviteCode();

  const { handleUpdateInviteCode, updateInviteCodeLoading } =
    useCourseUpdateInviteCode(onClose);

  const { id } = useParams();

  //   const [url, setUrl] = useState<string | undefined>(undefined);

  const onUpdateInviteCode = () => {
    if (id) {
      form.validateFields().then(val => {
        const model: InviteCodeModel = {
          password: val.password,
          courseId: id,
          codeId: inviteCode?.id,
        };
        handleUpdateInviteCode(model);
      });
    }
  };

  //   useEffect(() => {
  //     if (inviteCode) {
  //       const imageUrl = URL.createObjectURL(
  //         new Blob([inviteCode.qr_code], { type: 'image' }),
  //       );
  //       setUrl(imageUrl);
  //     }

  //     return () => {
  //       if (url) {
  //         URL.revokeObjectURL(url);
  //       }
  //     };
  //   }, [inviteCode]);

  if (getInviteCodeLoading) {
    return (
      <QrCodeWrapper>
        <Skeleton.Image active style={{ width: 200, height: 200 }} />
      </QrCodeWrapper>
    );
  }

  return (
    <QrCodeWrapper>
      {!inviteCode ? (
        <div>
          <Button
            loading={generateInviteCodeLoading}
            type='primary'
            onClick={() => {
              if (id) {
                handleGenerateInviteCode({ courseId: id });
              }
            }}>
            Generate Invite Code
          </Button>
        </div>
      ) : (
        <div>
          <div className='course-qr-code-tool-bar'>
            <Button type='primary' onClick={() => setIsFormOpen(true)}>
              Set Password
            </Button>
          </div>
          <QRCodeSVG value={inviteCode.code} />
        </div>
      )}

      <FormModal
        open={isFormOpen}
        onOk={onUpdateInviteCode}
        onCancel={onClose}
        okButtonProps={{
          loading: updateInviteCodeLoading,
        }}
        cancelButtonProps={{
          disabled: updateInviteCodeLoading,
        }}>
        <Typography.Title style={{ marginBottom: 20 }} level={5}>
          Update QR Code
        </Typography.Title>
        <Form
          disabled={updateInviteCodeLoading}
          requiredMark={false}
          autoComplete='off'
          scrollToFirstError
          form={form}
          layout='vertical'>
          <FlatFormItem
            label={t('password')}
            name='password'
            rules={[
              {
                required: true,
                message: validationT<string>('pleaseInputPass'),
              },
            ]}>
            <Input.Password bordered={false} />
          </FlatFormItem>

          <FlatFormItem
            label={t('confirmPassword')}
            name='confirm_password'
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: validationT<string>('pleaseReEnterPass'),
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(validationT<string>('passNoSame')),
                  );
                },
              }),
            ]}>
            <Input.Password bordered={false} />
          </FlatFormItem>
        </Form>
      </FormModal>
    </QrCodeWrapper>
  );
};

export default CourseQRCode;

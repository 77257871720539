import { useEffect, useMemo } from 'react';
import {
  BaseStaff,
  UseCreateStaffReturnType,
  UseUpdateStaffReturnType,
  UseDeleteStaffReturnType,
  Staff,
  InviteStaffModel,
  IStaffDict,
} from './types';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  getStaffs,
  createStaff,
  updateStaff,
  deleteStaff,
  resetStaffState,
  getRoleStaffs,
  resetRoleStaffs,
  inviteStaff,
  updateStaffRole,
  getAllStaffs,
  reInviteStaff,
} from './staffSlice';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGING } from 'config';
import { PERMISSIONS_RESOURCES } from 'config/permissions';
import { useRoleResourceIds } from 'features/role/roleHooks';

export const STAFF_MODAL_QUERY_PARAMS = {
  PAGE: 'staffPage',
  PAGE_SIZE: 'staffPageSize',
  ORDERING: 'staffOrdering',
  SEARCH: 'staffSearch',
};

const { PAGE, PAGE_SIZE, ORDERING, SEARCH } = STAFF_MODAL_QUERY_PARAMS;

const REAL_KEY = {
  [PAGE]: 'page',
  [PAGE_SIZE]: 'page_size',
  [ORDERING]: 'ordering',
  [SEARCH]: 'search',
};

export const useStaffsModalUrlQueryParams = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get(PAGE) ?? DEFAULT_PAGING.PAGE;
  const pageSize = searchParams.get(PAGE_SIZE) ?? DEFAULT_PAGING.PAGE_SIZE;
  const urlQueryParams = useMemo(() => {
    return [ORDERING, SEARCH].reduce(
      (currentParams, paramKey) => ({
        ...currentParams,
        [REAL_KEY[paramKey]]: searchParams.get(paramKey),
      }),
      { page, page_size: pageSize },
    );
  }, [page, pageSize, searchParams]);

  return urlQueryParams;
};

export const useStaffsModal = (isOpen: boolean) => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getStaffsLoading = useAppSelector(
    state => state.staff.getStaffsLoading,
  );
  const staffs = useAppSelector(state => state.staff.staffs);
  const getStaffsFailed = useAppSelector(state => state.staff.getStaffsFailed);
  const paginationConfig = useAppSelector(
    state => state.staff.paginationConfig,
  );

  const dispatch = useAppDispatch();

  const urlQueryParams = useStaffsModalUrlQueryParams();
  const onPageChange = usePagination(
    STAFF_MODAL_QUERY_PARAMS.PAGE,
    STAFF_MODAL_QUERY_PARAMS.PAGE_SIZE,
  );

  const goToFirstPage = () => {
    onPageChange(1);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getStaffs(urlQueryParams));
    }
  }, [urlQueryParams, dispatch, isOpen]);

  useFailed(getStaffsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    getStaffsLoading,
    staffs,
    getStaffsFailed,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    goToFirstPage,
  };
};

export const useStaffs = () => {
  const { roleId } = useParams();
  const { t } = useTranslation(LOCALES.MESSAGE);
  const staffs = useAppSelector(state => state.staff.staffs);
  const getStaffsFailed = useAppSelector(state => state.staff.getStaffsFailed);
  const getStaffsLoading = useAppSelector(
    state => state.staff.getStaffsLoading,
  );
  const paginationConfig = useAppSelector(
    state => state.staff.paginationConfig,
  );

  const dispatch = useAppDispatch();

  const urlQueryParams = useURLQueryParams([]);
  const onPageChange = usePagination();

  useEffect(() => {
    if (roleId) {
      dispatch(getStaffs({ ...urlQueryParams, belong_to_role: roleId }));
    }
  }, [urlQueryParams, dispatch, roleId]);

  useFailed(getStaffsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  const refreshCurrentPage = () => {
    if (roleId) {
      dispatch(getStaffs({ ...urlQueryParams, belong_to_role: roleId }));
    }
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      staffs.length === 1 && urlQueryParams.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(urlQueryParams.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterCreating = () => {
    if (urlQueryParams.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  const goToFirstPage = () => {
    onPageChange(1);
  };
  return {
    staffs,
    getStaffsLoading,
    getStaffsFailed,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    refreshAfterCreating,
    refreshAfterDeleting,
    goToFirstPage,
  };
};

export const useCreateStaff = (
  callback: () => void,
): UseCreateStaffReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const createStaffLoading = useAppSelector(
    state => state.staff.createStaffLoading,
  );
  const createStaffSuccess = useAppSelector(
    state => state.staff.createStaffSuccess,
  );
  const createStaffFailed = useAppSelector(
    state => state.staff.createStaffFailed,
  );

  const dispatch = useAppDispatch();

  const handleCreateStaff = (staff: BaseStaff) => {
    dispatch(createStaff(staff));
  };

  useSuccess(
    createStaffSuccess,
    t<string>('STAFF_CREATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(createStaffFailed);

  return {
    createStaffLoading,
    createStaffSuccess,
    createStaffFailed,
    handleCreateStaff,
  };
};

export const useUpdateStaff = (
  callback: () => void,
): UseUpdateStaffReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const updateStaffLoading = useAppSelector(
    state => state.staff.updateStaffLoading,
  );
  const updateStaffSuccess = useAppSelector(
    state => state.staff.updateStaffSuccess,
  );
  const updateStaffFailed = useAppSelector(
    state => state.staff.updateStaffFailed,
  );

  const dispatch = useAppDispatch();

  const handleUpdateStaff = (staff: Staff) => {
    dispatch(updateStaff(staff));
  };

  useSuccess(
    updateStaffSuccess,
    t<string>('STAFF_UPDATED_SUCCESSFULLY'),
    callback,
  );
  useFailed(updateStaffFailed);

  return {
    updateStaffLoading,
    updateStaffSuccess,
    updateStaffFailed,
    handleUpdateStaff,
  };
};

export const useUpdateStaffRole = (callback: () => void) => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const updateStaffRoleLoading = useAppSelector(
    state => state.staff.updateStaffRoleLoading,
  );
  const updateStaffRoleFailed = useAppSelector(
    state => state.staff.updateStaffRoleFailed,
  );
  const updateStaffRoleSuccess = useAppSelector(
    state => state.staff.updateStaffRoleSuccess,
  );

  const dispatch = useAppDispatch();

  const handleUpdateStaffRole = (model: {
    staffId: number;
    roleIds: number[];
  }) => {
    dispatch(updateStaffRole(model));
  };

  useSuccess(
    updateStaffRoleSuccess,
    t<string>('STAFF_UPDATED_SUCCESSFULLY'),
    callback,
  );
  useFailed(updateStaffRoleFailed);

  return {
    updateStaffRoleLoading,
    updateStaffRoleFailed,
    updateStaffRoleSuccess,
    handleUpdateStaffRole,
  };
};

export const useDeleteStaff = (
  callback: () => void,
): UseDeleteStaffReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const deleteStaffLoading = useAppSelector(
    state => state.staff.deleteStaffLoading,
  );
  const deleteStaffSuccess = useAppSelector(
    state => state.staff.deleteStaffSuccess,
  );
  const deleteStaffFailed = useAppSelector(
    state => state.staff.deleteStaffFailed,
  );

  const dispatch = useAppDispatch();

  const handleDeleteStaff = (id: number) => {
    dispatch(deleteStaff(id));
  };

  useSuccess(
    deleteStaffSuccess,
    t<string>('STAFF_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteStaffFailed);

  return {
    deleteStaffLoading,
    deleteStaffSuccess,
    deleteStaffFailed,
    handleDeleteStaff,
  };
};

export const useInviteStaff = (callback: () => void) => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const inviteStaffLoading = useAppSelector(
    state => state.staff.inviteStaffLoading,
  );
  const inviteStaffFailed = useAppSelector(
    state => state.staff.inviteStaffFailed,
  );
  const inviteStaffSuccess = useAppSelector(
    state => state.staff.inviteStaffSuccess,
  );

  const dispatch = useAppDispatch();

  const handleInviteStaff = (staff: InviteStaffModel) => {
    dispatch(inviteStaff(staff));
  };

  useSuccess(
    inviteStaffSuccess,
    t<string>('STAFF_INVITED_SUCCESSFULLY'),
    callback,
  );

  useFailed(inviteStaffFailed);

  return {
    inviteStaffLoading,
    inviteStaffFailed,
    inviteStaffSuccess,
    handleInviteStaff,
  };
};

export const useResetStaffState = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetStaffState());
    };
  }, [dispatch]);
};

export const useRoleStaffs = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getRoleStaffsLoading = useAppSelector(
    state => state.staff.getRoleStaffsLoading,
  );
  const getRoleStaffsFailed = useAppSelector(
    state => state.staff.getRoleStaffsFailed,
  );
  const roleStaffs = useAppSelector(state => state.staff.roleStaffs);

  const dispatch = useAppDispatch();

  const { ids: staffIds } = useRoleResourceIds(PERMISSIONS_RESOURCES.STAFF);

  useEffect(() => {
    if (staffIds) {
      dispatch(getRoleStaffs(staffIds));
    } else {
      dispatch(resetRoleStaffs());
    }
  }, [staffIds, dispatch]);

  const refetchRoleStaffs = (ids: number[]) => {
    if (ids.length) {
      dispatch(getRoleStaffs(ids.join(',')));
    } else {
      dispatch(resetRoleStaffs());
    }
  };

  useFailed(getRoleStaffsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    getRoleStaffsLoading,
    getRoleStaffsFailed,
    roleStaffs,
    refetchRoleStaffs,
  };
};

export const useAllStaffs = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const allStaffs = useAppSelector(state => state.staff.allStaffs);
  const getAllStaffsLoading = useAppSelector(
    state => state.staff.getAllStaffsLoading,
  );
  const getAllStaffsFailed = useAppSelector(
    state => state.staff.getAllStaffsFailed,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllStaffs());
  }, [dispatch]);

  useFailed(getAllStaffsFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  const allStaffsDict = useMemo(() => {
    return allStaffs.reduce((acc: IStaffDict, staff: Staff) => {
      acc[staff.id] = staff;
      return acc;
    }, {});
  }, [allStaffs]);

  return {
    allStaffs,
    getAllStaffsLoading,
    allStaffsDict,
  };
};

export const useReInviteStaff = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const reInviteStaffLoadingIds = useAppSelector(
    state => state.staff.reInviteStaffLoadingIds,
  );
  const reInviteStaffFailed = useAppSelector(
    state => state.staff.reInviteStaffFailed,
  );
  const reInviteStaffSuccess = useAppSelector(
    state => state.staff.reInviteStaffSuccess,
  );

  const dispatch = useAppDispatch();

  const handleReInviteStaff = (staffId: string) => {
    dispatch(reInviteStaff(staffId));
  };

  useSuccess(reInviteStaffSuccess, t<string>('RESEND__EMAIL__SUCCESS'));
  useFailed(reInviteStaffFailed);

  return {
    reInviteStaffLoadingIds,
    handleReInviteStaff,
  };
};

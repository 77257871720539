const vi = {
  books: 'Danh sách sách',
  no: 'STT',
  create: 'Tạo mới',
  cancel: 'Huỷ',
  update: 'Cập nhật',
  delete: 'Xoá',
  createdBy: 'Người tạo',
  createBook: 'Tạo mới sách',
  updateBook: 'Cập nhật sách',
  bookInformation: "Thông tin sách",
  add: 'Thêm',

  name: 'Tên',
  author: 'Tác giả',
  readers: 'Số người đọc',
  description: 'Mô tả',
  logo: 'Logo',
  updatedAt: 'Ngày Cập nhật',
  updatedBy: 'Người cập nhật',
  reader: 'Người đọc',
  document: 'Tài liệu',

  pleaseInputName: 'Vui lòng nhập tên sách!',
  pleaseInputAuthor: 'Vui lòng nhập tác giả!',
  enterName: 'Nhập tên sách',
  enterAuthor: 'Nhập tên tác giả',
  enterDescription: 'Nhập mô tả',
  inviteCode: 'Mã mời',

  deleteWarning: 'Bạn có chắc muốn xóa sách này không?',
};

export default vi;

const en = {
  checkIns: 'Check in',
  branch: 'Branch',
  staff: 'Staff',
  type: 'Type',
  notes: 'Description',
  time: 'Time',
  check_in: 'Check in',
  check_out: 'Check out',

  create: 'Create',
  cancel: 'Cancel',
  update: 'Update',

  add: 'Add',
  name: 'Name',
  createCheckIn: 'Create check in',
  updateCheckIn: 'Update check in',

  deleteWarning: 'Are you sure want to delete this check in?',
  delete: 'Delete',
  keyword: 'Keyword',
  enterKeyword: 'Enter Keyword',
  search: 'Search',
  nameIsRequired: 'Please Input Check In Name',
  locationIsRequired: 'Please Input Location',
  addressIsRequired: 'Please Input Address',

  location: 'Location',
  address: 'Address',
  photo: 'Photo',

  selectBranch: 'Select Branch',
  selectStaff: 'Select Staff',
};

export default en;

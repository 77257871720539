import React from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Space,
  Typography,
  Row,
  Col,
} from "antd";
import { MailOutlined } from "@ant-design/icons";
import LoginImg from "svgs/LoginImg";
import Logo from "svgs/Logo";
import { Link } from "react-router-dom";
import {
  SignInWrapper,
  FormWrapper,
  LeftFormWrapper,
  LeftHeader,
  LeftFooter,
  RightFormWrapper,
  PositionLogo,
} from "./Styled";
import FlatFormItem from "components/Common/FlatFormItem";
import { useLogin } from "features/auth/authHooks";
import { LoginUser } from "../types";
import { useTranslation } from "react-i18next";
import { LOCALES } from "app/i18n";

const SignIn = () => {
  const { handleLogin, loginLoading } = useLogin();

  const onSubmit = (user: LoginUser) => {
    handleLogin(user);
  };

  const { t } = useTranslation(LOCALES.LOGIN);
  const { t: validationT } = useTranslation(LOCALES.VALIDATION);

  return (
    <SignInWrapper>
      <FormWrapper>
        <Row>
          <Col xl={11} sm={0} xs={0}>
            <LeftFormWrapper>
              <LeftHeader>
                <div className="logo">
                  <Logo />
                </div>
                <Link to="/register">
                  <Button size="large" type="primary">
                    {t("createAccount")}
                  </Button>
                </Link>
              </LeftHeader>
              <div className="left-image">
                <img
                  src="./imgs/loginImage.png"
                  alt="login"
                  className="near-xxl-not-show"
                />
                <LoginImg className="near-xxl-show" />
              </div>

              <LeftFooter>
                <p className="left-footer__slogan--1">{t("easyToManage")}</p>
                <p className="left-footer__slogan--2">
                  {t("comprehensiveSolution")}
                </p>
              </LeftFooter>
            </LeftFormWrapper>
          </Col>

          <Col xl={13} sm={24} xs={24}>
            <RightFormWrapper>
              <PositionLogo>
                <div className="logo">
                  <Logo />
                </div>
                <Link to="/register">
                  <Button size="large" type="primary">
                    {t("createAccount")}
                  </Button>
                </Link>
              </PositionLogo>
              <div className="form-header">
                <p className="md-show">{t("loginToAccount")}</p>
              </div>
              <div className="form-content over-ride-input over-ride-checkbox ">
                <Form
                  disabled={loginLoading}
                  onFinish={onSubmit}
                  hideRequiredMark
                  autoComplete="off"
                  scrollToFirstError
                  layout="vertical"
                >
                  <FlatFormItem
                    label={t("emailOrUsername")}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: validationT<string>("invalidEmail"),
                      },
                      {
                        required: true,
                        message: validationT<string>("pleaseInputEmail"),
                      },
                    ]}
                  >
                    <Input suffix={<MailOutlined />} bordered={false} />
                  </FlatFormItem>

                  <FlatFormItem
                    label={t("password")}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: validationT<string>("pleaseInputPass"),
                      },
                    ]}
                  >
                    <Input.Password bordered={false} />
                  </FlatFormItem>

                  <Form.Item style={{ marginBottom: 40 }}>
                    <Space
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <Checkbox>
                        <p className="check-box-group__remember">
                          {t("remember")}
                        </p>
                      </Checkbox>
                      <Typography.Link>{t("forgotPassword")}</Typography.Link>
                    </Space>
                  </Form.Item>

                  <Form.Item style={{ marginTop: 80 }}>
                    <Button
                      loading={loginLoading}
                      size="large"
                      htmlType="submit"
                      type="primary"
                      style={{ width: "100%" }}
                    >
                      {t("login")}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </RightFormWrapper>
          </Col>
        </Row>
      </FormWrapper>
    </SignInWrapper>
  );
};

export default SignIn;

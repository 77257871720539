import { Typography } from 'antd';
import { LOCALES } from 'app/i18n';
import { useTranslation } from 'react-i18next';

const NoDataTitle = () => {
  const { t } = useTranslation(LOCALES.ROLE);
  return (
    <Typography.Title level={3} style={{ textAlign: 'center', marginTop: 100 }}>
      {t('viewAll')}
    </Typography.Title>
  );
};

export default NoDataTitle;

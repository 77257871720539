import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import roleAPI from './roleAPI';
import { resetStoreAction } from 'config';
import { Role, RoleState, BaseRole } from './types';
import {
  deletePermission,
  deleteResource,
  getPermissionsHasObjectConfig,
  initPermission,
  isEmptyResource,
  isHasNoPermission,
  checkAndInitResource,
  checkAndInitPermission,
  reFormatPermissionJson,
} from './roleUtils';

export const getRoles = createAppAsyncThunk(
  'role/getRoles',
  async (fetchPermissionAlso: boolean, { rejectWithValue }) => {
    try {
      if (fetchPermissionAlso) {
        const [roles, predefinedPermissions] = await Promise.all([
          roleAPI.getRoles(),
          roleAPI.getPredefinedPermissions(),
        ]);

        return {
          roles,
          predefinedPermissions,
          fetchPermissionAlso,
        };
      } else {
        const roles = await roleAPI.getRoles();

        return {
          roles,
        };
      }
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateRole = createAppAsyncThunk(
  'role/updateRole',
  async ({ name }: { name?: string }, { rejectWithValue, getState }) => {
    try {
      const {
        role: { permissionJson, roleDetails },
      } = getState();

      const response = await roleAPI.updateRole({
        ...roleDetails,
        name: name ?? roleDetails?.name,
        permissions_json: reFormatPermissionJson(permissionJson),
      } as Role);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createRole = createAppAsyncThunk(
  'role/createRole',
  async (role: BaseRole, { rejectWithValue }) => {
    try {
      const response = await roleAPI.createRole(role);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const copyRole = createAppAsyncThunk(
  'role/copyRole',
  async (roleId: number, { rejectWithValue }) => {
    try {
      const response = await roleAPI.copyRole(roleId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteRole = createAppAsyncThunk(
  'role/deleteRole',
  async (roleId: number, { rejectWithValue }) => {
    try {
      const response = await roleAPI.deleteRole(roleId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: RoleState = {
  roles: [],
  getRolesLoading: false,
  getRolesFailed: undefined,
  predefinedPermissions: undefined,

  updateRoleLoading: false,
  updateRoleSuccess: undefined,
  updateRoleFailed: undefined,

  createRoleLoading: false,
  createRoleSuccess: undefined,
  createRoleFailed: undefined,

  copyRoleLoading: false,
  copyRoleSuccess: undefined,
  copyRoleFailed: undefined,

  deleteRoleLoading: false,
  deleteRoleSuccess: undefined,
  deleteRoleFailed: undefined,
  deletedRoleId: undefined,

  permissionJson: undefined,
  roleDetails: undefined,
};

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    resetRoleState() {
      return initialState;
    },

    getRoleDetails(state, { payload: roleId }) {
      state.roleDetails = state.roles.find(role => role.id === Number(roleId));
      state.permissionJson = state.roleDetails?.permissions_json || {};
    },

    toggleSelectPermission(state, { payload: { resourceKey, permissionKey } }) {
      const resourceArgs = {
        state,
        resourceKey,
      };

      const permissionArgs = {
        ...resourceArgs,
        permissionKey,
      };

      if (state.permissionJson) {
        checkAndInitResource({ state, resourceKey });

        if (isHasNoPermission(permissionArgs)) {
          initPermission(permissionArgs);
        } else {
          deletePermission(permissionArgs);
        }
      }
    },

    selectAllResourcesPermission(state, { payload: permissionKey }) {
      if (state.permissionJson && state.predefinedPermissions) {
        const resourceKeys = Object.keys(state.predefinedPermissions).filter(
          resourceKey => {
            return state.predefinedPermissions?.[resourceKey]?.permissions?.[
              permissionKey
            ];
          },
        );

        resourceKeys.forEach(resourceKey => {
          checkAndInitResource({ state, resourceKey });

          initPermission({
            state,
            resourceKey,
            permissionKey,
          });
        });
      }
    },

    deselectAllResourcesPermission(state, { payload: permissionKey }) {
      if (state.permissionJson && state.predefinedPermissions) {
        const resourceKeys = Object.keys(state.predefinedPermissions).filter(
          resourceKey => {
            return state.predefinedPermissions?.[resourceKey]?.permissions?.[
              permissionKey
            ];
          },
        );

        resourceKeys.forEach(resourceKey => {
          deletePermission({ state, resourceKey, permissionKey });

          if (isEmptyResource({ state, resourceKey })) {
            deleteResource({ state, resourceKey });
          }
        });
      }
    },

    onSelectedObjectChanged(state, { payload }) {
      const { resourceKey, addedIds, removedIds } = payload;
      if (state.permissionJson && state.predefinedPermissions) {
        checkAndInitResource({ state, resourceKey });

        const resource = state.permissionJson[resourceKey];

        const permissionsHasObjectConfig = getPermissionsHasObjectConfig(
          resourceKey,
          state.predefinedPermissions,
        );

        permissionsHasObjectConfig.forEach(permissionKey => {
          checkAndInitPermission({ state, resourceKey, permissionKey });

          const permission = resource[permissionKey];

          if (Array.isArray(permission.ids)) {
            permission.ids = permission.ids.filter(
              (id: number) => !removedIds.includes(id),
            );

            permission.ids = [...permission.ids, ...addedIds];
          } else {
            permission.ids = addedIds;
          }
        });
      }
    },

    toggleSelectObject(state, { payload }) {
      const { resourceKey, permissionKey, objectId } = payload;

      if (state.permissionJson) {
        checkAndInitResource({ state, resourceKey });
        checkAndInitPermission({ state, resourceKey, permissionKey });

        const permission = state.permissionJson[resourceKey][permissionKey];

        if (typeof permission.ids === 'boolean') {
          permission.ids = [];
        }

        if (permission.ids.includes(objectId)) {
          permission.ids = permission.ids.filter(id => id !== objectId);
        } else {
          permission.ids.push(objectId);
        }
      }
    },

    toggleSelectField(state, { payload }) {
      const { resourceKey, permissionKey, fieldId } = payload;

      if (state.permissionJson && state.predefinedPermissions) {
        checkAndInitResource({ state, resourceKey });
        checkAndInitPermission({ state, resourceKey, permissionKey });

        const permission = state.permissionJson[resourceKey][permissionKey];

        if (permission.fields === true) {
          permission.fields =
            state.predefinedPermissions[resourceKey].available_fields;
        }

        if (permission.fields.includes(fieldId)) {
          permission.fields = permission.fields.filter(id => id !== fieldId);
        } else {
          permission.fields.push(fieldId);
        }
      }
    },

    toggleSelectAllField(state, { payload }) {
      const { resourceKey, permissionKey, isAllFieldChecked } = payload;
      if (state.permissionJson && state.predefinedPermissions) {
        checkAndInitResource({ state, resourceKey });
        checkAndInitPermission({ state, resourceKey, permissionKey });

        const permission = state.permissionJson[resourceKey][permissionKey];
        permission.fields = isAllFieldChecked ? [] : true;
      }
    },

    setPermissionIds(state, { payload }) {
      const { resourceKey, permissionKey, ids } = payload;
      if (state.permissionJson && state.predefinedPermissions) {
        checkAndInitResource({ state, resourceKey });
        checkAndInitPermission({ state, resourceKey, permissionKey });

        const permission = state.permissionJson[resourceKey][permissionKey];
        permission.ids = ids;
      }
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getRoles.pending, state => {
        state.getRolesLoading = true;
      })
      .addCase(getRoles.fulfilled, (state, { payload }) => {
        state.getRolesLoading = false;
        state.roles = payload.roles.results;
        if (payload.fetchPermissionAlso) {
          state.predefinedPermissions = payload.predefinedPermissions;

          if (state.predefinedPermissions) {
            delete state.predefinedPermissions.predefined_permissions;
          }
        }
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.getRolesLoading = false;
        state.getRolesFailed = action.payload;
      })

      .addCase(updateRole.pending, state => {
        state.updateRoleLoading = true;
      })
      .addCase(updateRole.fulfilled, (state, { payload }) => {
        state.updateRoleLoading = false;
        state.updateRoleSuccess = payload;
        state.roles = state.roles.map(role =>
          role.id === payload.id ? payload : role,
        );
        state.roleDetails = payload;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.updateRoleLoading = false;
        state.updateRoleFailed = action.payload;
      })

      .addCase(createRole.pending, state => {
        state.createRoleLoading = true;
      })

      .addCase(createRole.fulfilled, (state, { payload }) => {
        state.createRoleLoading = false;
        state.createRoleSuccess = payload;
        state.roles = [payload, ...state.roles];
        state.roleDetails = payload;
      })
      .addCase(createRole.rejected, (state, action) => {
        state.createRoleLoading = false;
        state.createRoleFailed = action.payload;
      })

      .addCase(copyRole.pending, state => {
        state.copyRoleLoading = true;
      })

      .addCase(copyRole.fulfilled, (state, { payload }) => {
        state.copyRoleLoading = false;
        state.copyRoleSuccess = payload;
        state.roles = [payload, ...state.roles];
      })
      .addCase(copyRole.rejected, (state, action) => {
        state.copyRoleLoading = false;
        state.copyRoleFailed = action.payload;
      })

      .addCase(deleteRole.pending, (state, action) => {
        state.deleteRoleLoading = true;
        state.deletedRoleId = action.meta.arg;
      })

      .addCase(deleteRole.fulfilled, state => {
        state.deleteRoleLoading = false;
        state.deleteRoleSuccess = {};
        state.roles = state.roles.filter(
          role => role.id !== state.deletedRoleId,
        );
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.deleteRoleLoading = false;
        state.deleteRoleFailed = action.payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const {
  resetRoleState,
  getRoleDetails,
  toggleSelectPermission,
  selectAllResourcesPermission,
  deselectAllResourcesPermission,
  onSelectedObjectChanged,
  toggleSelectObject,
  toggleSelectField,
  toggleSelectAllField,
  setPermissionIds,
} = roleSlice.actions;

export default roleSlice.reducer as Reducer<RoleState>;

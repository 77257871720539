import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { LOCALES } from 'app/i18n';
import { useTranslation } from 'react-i18next';
import { COURSE_STATUS } from 'config';

const CourseStatus = ({ status }: { status: string }) => {
  const { t } = useTranslation(LOCALES.COURSE);
  return status === COURSE_STATUS[0] ? (
    <Tag icon={<ClockCircleOutlined />}>{t(status)}</Tag>
  ) : status === COURSE_STATUS[1] ? (
    <Tag icon={<SyncOutlined />} color='processing'>
      {t(status)}
    </Tag>
  ) : (
    <Tag icon={<CheckCircleOutlined />} color='success'>
      {t(status)}
    </Tag>
  );
};

export default CourseStatus;

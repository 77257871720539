import React, { useEffect } from 'react';
import { Layout, Menu } from 'antd';
import Logo from 'svgs/Logo';
import styled from 'styled-components';
import Account from './Account';
import type { MenuProps } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import PATHS from 'router/paths';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { MenuWrapper } from 'components/Common/Page';
import { getClassName } from 'utils/style';
import { useStaffInvitations } from 'features/auth/authHooks';

const { Header } = Layout;

const Wrapper = styled(Header)`
  padding: 0 24px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 4px 4px #00000014;
  z-index: 10;
  justify-content: space-between;
`;

const LayoutHeader = () => {
  const { t } = useTranslation(LOCALES.LAYOUT);
  const { staffInvitations } = useStaffInvitations();
  const navigate = useNavigate();

  useEffect(() => {
    if (staffInvitations.length) {
      navigate(PATHS.account.invitations);
    }
  }, [staffInvitations, navigate]);

  const items: MenuProps['items'] = [
    {
      key: '2',
      label: (
        <NavLink
          className={getClassName}
          to={PATHS.account.root}
          style={{ fontWeight: 500 }}
          end>
          {t('workspaces')}
        </NavLink>
      ),
    },
    {
      key: '3',
      label: (
        <NavLink
          className={getClassName}
          to={PATHS.account.invitations}
          style={{ fontWeight: 500 }}>
          {t('invitations')}
        </NavLink>
      ),
    },
  ];

  return (
    <Wrapper>
      <Logo
        style={{ width: '8%' }}
        onClick={() => navigate(PATHS.account.root)}
      />

      <MenuWrapper style={{ width: '76%', paddingLeft: 20 }}>
        <Menu
          items={items}
          mode='horizontal'
          style={{ borderBottom: 'none' }}
        />
      </MenuWrapper>

      <Account notificationsNumber={staffInvitations.length} />
    </Wrapper>
  );
};

export default LayoutHeader;

import { Button, Form, Input, Upload, Progress } from 'antd';
import {
  ArrowLeftOutlined,
  FileImageOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Link, generatePath, useParams } from 'react-router-dom';
import PATHS from 'router/paths';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import UploadAlbumPlaceholderIcon from 'svgs/UploadAlbumPlaceholderIcon';
import { RcFile } from 'antd/es/upload';
import { AlbumAssetWrapper, AlbumAssetsGrid, Wrapper } from './Styled';
import FileUploader from 'components/Uploader/FileUploader';
import { useRef } from 'react';
import {
  useAddAlbumAssetBase64,
  useCreateAlbum,
  useRemoveUploadingAlbumAsset,
  useUploadAlbumAsset,
} from 'features/course/courseHooks/courseAlbumHooks';
import {
  QueueAlbumAsset as QueueAlbumAssetType,
  UploadingAlbumAsset,
} from 'features/course/types';
import { getBase64 } from 'utils/assets';
import DisplayedTempAlbumAsset from 'components/Common/DisplayedTempAlbumAsset';

const { Dragger } = Upload;

const UploadAlbumPlaceholder = () => {
  const { t } = useTranslation(LOCALES.COURSE);

  return (
    <div className='upload-album-placeholder'>
      <div className='upload-album-placeholder__icon'>
        <UploadAlbumPlaceholderIcon />
      </div>

      <div className='upload-album-placeholder__info'>
        <p className='upload-album-placeholder__title'>{t('dragAlbumTitle')}</p>
        <p className='upload-album-placeholder__description'>
          {' '}
          {t('dragAlbumDescription')}
        </p>
      </div>
    </div>
  );
};

export const TempAlbumAsset = ({ asset }: { asset: UploadingAlbumAsset }) => {
  const removeUploadingAlbumAsset = useRemoveUploadingAlbumAsset();

  const { status } = asset;

  return (
    <AlbumAssetWrapper className='album-file'>
      <Button
        style={{ zIndex: 1000 }}
        onClick={() => removeUploadingAlbumAsset(asset.uid)}
        shape='circle'
        icon={<DeleteOutlined />}
        className='delete-album-file-btn'
      />

      <div className='album-file__image-box'>
        <DisplayedTempAlbumAsset asset={asset} />
      </div>

      <div className='album-file__info'>
        <p className='album-file__name'>{asset.name}</p>
      </div>

      <Progress
        percent={asset.progress}
        className='album-asset-progress'
        status={
          status === 'queue' || status === 'inprogress'
            ? 'normal'
            : status === 'failed'
            ? 'exception'
            : 'success'
        }
      />
    </AlbumAssetWrapper>
  );
};

const QueueAlbumAsset = ({ asset }: { asset: QueueAlbumAssetType }) => {
  return (
    <AlbumAssetWrapper className='album-file'>
      <div className='album-file__image-box'>
        <img
          className='album-file__image'
          alt='placeholder'
          src='https://wiki.dave.eu/images/4/47/Placeholder.png'
        />
      </div>

      <div className='album-file__info'>
        <p className='album-file__name'>{asset.name}</p>
      </div>
    </AlbumAssetWrapper>
  );
};

const AlbumForm = () => {
  const { id } = useParams();
  const { t } = useTranslation(LOCALES.COURSE);
  const [form] = Form.useForm();
  const {
    uploadAlbumAssetLoading,
    uploadingAlbumAssets,
    albumFilesQueue,
    handleUploadAlbumAsset,
  } = useUploadAlbumAsset();

  const { handleCreateAlbum, createAlbumLoading } = useCreateAlbum();

  const fileUploadRef = useRef<any>(null);

  const onUpload = (file: RcFile) => {
    handleUploadAlbumAsset({ uid: file.uid, file });
    getBase64(file, base64 => {
      addAlbumAssetBase64({ uid: file.uid, base64 });
    });
  };

  const addAlbumAssetBase64 = useAddAlbumAssetBase64();

  const beforeUploadByDragger = (file: RcFile) => {
    onUpload(file);

    getBase64(file, base64 => {
      addAlbumAssetBase64({ uid: file.uid, base64 });
    });

    return false;
  };

  const triggerUpload = () => {
    fileUploadRef.current?.click();
  };

  const handleSubmit = () => {
    if (id) {
      form.validateFields().then(album => {
        const assets = uploadingAlbumAssets
          .filter(({ status }) => status === 'succeeded')
          .map(({ name, path }) => ({
            name,
            file: path,
          }));
        handleCreateAlbum({ model: { ...album, assets }, courseId: id });
      });
    }
  };

  const albumFiles = [...uploadingAlbumAssets, ...albumFilesQueue];

  return (
    <Wrapper>
      <div className='album-form-left'>
        <div className='album-form-left__header'>
          <Link to={generatePath(PATHS.app.courses.details.albums, { id })}>
            <Button
              type='primary'
              shape='circle'
              icon={<ArrowLeftOutlined />}
              size={'large'}
            />
          </Link>
        </div>

        <h3 className='album-form-left__title'>{t('createAlbum')}</h3>

        <Form
          className='album-form'
          layout='vertical'
          form={form}
          disabled={createAlbumLoading}>
          <Form.Item
            name='name'
            label={t('albumName')}
            required
            rules={[
              { required: true, message: t<string>('albumNameIsRequired') },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item name='descriptions' label={t('description')}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button
              icon={<FileImageOutlined />}
              disabled={uploadAlbumAssetLoading || createAlbumLoading}
              onClick={() => triggerUpload()}
              size='large'
              className='album-form__upload-btn'>
              {t('uploadPhotosOrVideos')}
            </Button>

            <FileUploader
              ref={fileUploadRef}
              onUpload={onUpload}
              isAlbumUploader
            />
          </Form.Item>
        </Form>

        <div className='album-form-left__footer'>
          <Button
            onClick={handleSubmit}
            disabled={uploadAlbumAssetLoading || !!albumFilesQueue.length}
            loading={createAlbumLoading}
            type='primary'
            size='large'
            className='album-form__submit-btn'>
            {t('submit')}
          </Button>
        </div>
      </div>

      <div className='album-form-right'>
        <Dragger
          openFileDialogOnClick={false}
          beforeUpload={beforeUploadByDragger}
          showUploadList={false}
          className={`album-form-right__uploader ${
            !albumFiles.length && 'album-form-right__uploader--has-placeholder'
          }`}>
          {albumFiles.length ? (
            <AlbumAssetsGrid>
              {uploadingAlbumAssets.map(file => (
                <TempAlbumAsset key={file.uid} asset={file} />
              ))}
              {albumFilesQueue.map(file => (
                <QueueAlbumAsset key={file.uid} asset={file} />
              ))}
            </AlbumAssetsGrid>
          ) : (
            <UploadAlbumPlaceholder />
          )}
        </Dragger>
      </div>
    </Wrapper>
  );
};

export default AlbumForm;

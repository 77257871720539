import requester from 'utils/requester';
import { BaseBook, BookAPI } from './types';
import { getBookFormData, multipartFormConfig } from 'utils/formHelper';

const bookAPI: BookAPI = {
  getBooks(params) {
    return requester.get('book/', params);
  },

  createBook(book: BaseBook) {
    return requester.post('book/', getBookFormData(book), multipartFormConfig);
  },

  updateBook(book: BaseBook) {
    return requester.put(
      `book/${book.id}/`,
      getBookFormData(book),
      multipartFormConfig,
    );
  },

  deleteBook(id: number) {
    return requester.delete(`book/${id}/`);
  },

  getInvitationCode(params) {
    return requester.post('invitation-code/', params);
  },

  getRoleBooks(ids) {
    return requester.get('book/', { ids, page_size: 9999 });
  },
};

export default bookAPI;

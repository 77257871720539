const en = {
  no: 'No',
  name: 'Name',
  roles: 'Roles',
  create: 'Create',
  cancel: 'Cancel',
  update: 'Update',
  changeName: 'Change Name',
  duplicate: 'Duplicate',
  delete: 'Delete',
  deleteWarning: 'Are you sure want to delete this role?',
  view: 'View',
  edit: 'Update',
  destroy: 'Delete',
  viewUserprofile: 'View User Profile',
  can_register_sms_workspace: 'Register SMS Workspace',
  invitationStaff: 'Invite Staff',
  can_resend_email_invite_staff: 'Resend Invitation Email',
  editStaffPermissions: 'Edit Staff Permission',
  editStaffRoles: 'Edit Staff Role',
  editRolePermissions: 'Edit Role Permission',
  createInvitationCode: 'Create Invitation Code',
  resource: 'Resource',
  field: 'Field',

  assets: 'Asset',
  book: 'Book',
  staff: 'Staff',
  role: 'Role',
  student: 'Student',
  workspace: 'Workspace',
  courses: 'Course',
  sharelistassets: 'Playlist',
  viewAll: 'You can access all objects of this resource',
};

export default en;

import requester from 'utils/requester';
import { BookDetailsAPI } from './types';

const bookDetailsAPI: BookDetailsAPI = {
  getBook(id) {
    return requester.get(`/book/${id}/`);
  },

  getBookAssets({ params, id }) {
    return requester.get(`/book/${id}/assets/`, {
      ...params,
      page_size: 9999,
      file_only: true,
    });
  },

  deleteBookAsset({ bookId, assetId }) {
    return requester.delete(`book/${bookId}/assets/${assetId}/`);
  },

  addBookAssets({ bookId, assetIds }) {
    return requester.post(`book/${bookId}/assets/`, {
      assets_id: assetIds,
    });
  },

  getBookReaders({ params, id }) {
    return requester.get(`/book/${id}/reader/`, params);
  },

  orderBookAssets({ ids, bookId }) {
    return requester.post(`book/${bookId}/assets/order/`, { assets_id: ids });
  },
};

export default bookDetailsAPI;

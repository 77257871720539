const Logo = ({
  style = {},
  onClick,
}: {
  style?: any;
  onClick?: () => void;
}) => (
  <svg
    onClick={() => onClick?.()}
    style={{ ...style, cursor: 'pointer' }}
    fill='red'
    width='147px'
    height='47px'
    viewBox='0 0 147 47'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'>
    <desc>Created with Lunacy</desc>
    <g id='ahub-Logo'>
      <g id='Group'>
        <path
          d='M0 0L16.4587 9.35913L0 18.7183L0 0Z'
          id='Path'
          fill='#C1112F'
          stroke='none'
        />
        <path
          d='M0 0L16.4588 9.35913L0 18.6774L0 0Z'
          transform='translate(16.41748 28.03662)'
          id='Path'
          fill='#C1112F'
          stroke='none'
        />
        <path
          d='M16.4588 0L0 9.31826L16.4588 18.6774L16.4588 0Z'
          transform='translate(16.41748 0.04101563)'
          id='Path'
          fill='#FF4800'
          stroke='none'
        />
        <path
          d='M0 0L16.4587 9.35913L0 18.6774L0 0Z'
          transform='translate(0 18.67749)'
          id='Path'
          fill='#FF4800'
          stroke='none'
        />
        <path
          d='M16.4587 0L0 9.35913L16.4587 18.6774L16.4587 0Z'
          transform='translate(0 9.359131)'
          id='Path'
          fill='#DA271C'
          stroke='none'
        />
        <path
          d='M16.4588 0L0 9.31826L16.4588 18.6774L16.4588 0Z'
          transform='translate(16.41748 18.71826)'
          id='Path'
          fill='#DA271C'
          stroke='none'
        />
        <path
          d='M16.4587 0L0 9.35913L16.4587 18.6774L16.4587 0Z'
          transform='translate(0 28.03662)'
          id='Path'
          fill='#DA271C'
          stroke='none'
        />
        <path
          d='M0 0L16.4588 9.35913L0 18.6774L0 0Z'
          transform='translate(16.41748 9.359131)'
          id='Path'
          fill='#EC370E'
          stroke='none'
        />
      </g>
      <g
        fill='#A52A0E'
        stroke='none'
        id='AHUB'
        transform='translate(42 2.877441)'>
        <path d='M19.5469 36.6035L22.8164 36.6035L13.1133 11.3965L10.0547 11.3965L0.386719 36.6035L3.67383 36.6035L6.1875 29.5371L16.875 29.5371L19.5469 36.6035ZM42.5215 36.6035L45.4746 36.6035L45.4746 11.3965L42.5215 11.3965L42.5215 22.4531L29.4785 22.4531L29.4785 11.3965L26.5254 11.3965L26.5254 36.6035L29.4785 36.6035L29.4785 25.1074L42.5215 25.1074L42.5215 36.6035ZM60.9434 37.0254Q51.7676 37.0254 51.7676 26.8125L51.7676 11.3965L54.7207 11.3965L54.7207 26.6191Q54.7207 34.3711 61.2598 34.3711Q67.5703 34.3711 67.5703 26.8828L67.5703 11.3965L70.5234 11.3965L70.5234 26.4082Q70.5234 37.0254 60.9434 37.0254ZM76.8164 11.3965L76.8164 36.6035L84.1641 36.6035Q87.6973 36.6035 90.0176 34.5996Q92.3379 32.5957 92.3379 29.3613Q92.3379 26.7598 90.8086 25.1338Q89.2793 23.5078 86.7305 23.209L86.7305 23.1387Q88.7695 22.4707 89.9297 20.8887Q91.0898 19.3066 91.0898 17.1621Q91.0898 14.5957 89.1738 12.9961Q87.2578 11.3965 83.9883 11.3965L76.8164 11.3965ZM79.7695 22.207L79.7695 14.0684L83.1621 14.0684Q87.9961 14.0684 87.9961 17.7422Q87.9961 19.8691 86.6074 21.0381Q85.2188 22.207 82.793 22.207L79.7695 22.207ZM11.9531 16.1426L15.9082 26.8828L7.17188 26.8828L11.0918 16.1426Q11.3203 15.5273 11.4961 14.4551L11.5664 14.4551Q11.7598 15.6152 11.9531 16.1426ZM79.7695 33.9316L79.7695 24.8613L83.1621 24.8613Q89.2441 24.8613 89.2441 29.3262Q89.2441 31.4707 87.8115 32.7012Q86.3789 33.9316 83.7773 33.9316L79.7695 33.9316Z' />
      </g>
    </g>
  </svg>
);

export const MenuLogo = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='109.265'
    height='35.781'
    viewBox='0 0 109.265 35.781'>
    <g id='ahub_Logo' data-name='ahub Logo' transform='translate(0 -0.094)'>
      <g id='Group' opacity='0.7'>
        <path
          id='Path'
          d='M0,0,13.324,7.169,0,14.337Z'
          transform='translate(0 0.094)'
          fill='#c1112f'
        />
        <path
          id='Path-2'
          data-name='Path'
          d='M0,0,13.324,7.169,0,14.306Z'
          transform='translate(13.291 21.569)'
          fill='#c1112f'
        />
        <path
          id='Path-3'
          data-name='Path'
          d='M13.324,0,0,7.137l13.324,7.169Z'
          transform='translate(13.291 0.125)'
          fill='#ff4800'
        />
        <path
          id='Path-4'
          data-name='Path'
          d='M0,0,13.324,7.169,0,14.306Z'
          transform='translate(0 14.4)'
          fill='#ff4800'
        />
        <path
          id='Path-5'
          data-name='Path'
          d='M13.324,0,0,7.169l13.324,7.137Z'
          transform='translate(0 7.263)'
          fill='#da271c'
        />
        <path
          id='Path-6'
          data-name='Path'
          d='M13.324,0,0,7.137l13.324,7.169Z'
          transform='translate(13.291 14.431)'
          fill='#da271c'
        />
        <path
          id='Path-7'
          data-name='Path'
          d='M13.324,0,0,7.169l13.324,7.137Z'
          transform='translate(0 21.569)'
          fill='#da271c'
        />
        <path
          id='Path-8'
          data-name='Path'
          d='M0,0,13.324,7.169,0,14.306Z'
          transform='translate(13.291 7.263)'
          fill='#ec370e'
        />
      </g>
      <text
        id='AHUB'
        transform='translate(35.265 5)'
        fill='#a52a0e'
        fontSize='24'
        fontFamily='Helvetica'
        opacity='0.51'>
        <tspan x='0' y='18'>
          AHUB
        </tspan>
      </text>
    </g>
  </svg>
);
export default Logo;

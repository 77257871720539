import axios from 'axios';
import mem from 'mem';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { logout, updateToken } from 'features/auth/authSlice';
import { resetStoreAction } from 'config';
import { removeAxiosToken } from './requester';

export const refreshInstant = axios.create({
  baseURL: process.env.REACT_APP_NON_TENANT_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshTokenFn = async (store: ToolkitStore) => {
  try {
    const { refreshToken } = store.getState().auth;

    const {
      data: { access: accessToken },
    }: any = await refreshInstant.post('token/refresh/', {
      refresh: refreshToken,
    });

    store.dispatch(updateToken(accessToken));
    return { accessToken };
  } catch (error) {
    store.dispatch(logout());
    store.dispatch(resetStoreAction());
    removeAxiosToken();
  }
};

const maxAge = 20000;

const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});

export default memoizedRefreshToken;

import React from 'react';
import { FilterPanel } from 'components/Common/Page';
import { Row, Form, Input, Button, Space, Col } from 'antd';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { COURSE_QUERY_PARAMS } from 'features/course/courseHooks';
import { useFilter, useURLQueryParams } from 'app/hooks';

const COURSE_QUERY_PARAMS_VALUES = Object.values(COURSE_QUERY_PARAMS);

const Filter = ({ loading }: { loading: boolean }) => {
  const urlQueryParams = useURLQueryParams(COURSE_QUERY_PARAMS_VALUES);
  const { t } = useTranslation(LOCALES.COURSE);
  const [form] = Form.useForm();
  const { onFilter, onReset } = useFilter(form, COURSE_QUERY_PARAMS);

  return (
    <FilterPanel>
      <Form
        initialValues={urlQueryParams}
        form={form}
        onFinish={onFilter}
        disabled={loading}>
        <Row
          gutter={24}
          style={{ width: '100%', flexWrap: 'nowrap' }}
          justify='space-between'>
          <Col span={6}>
            <FlatFormItem
              name={COURSE_QUERY_PARAMS.NAME}
              label={t('class')}
              style={{ marginBottom: 0, flexGrow: 1 }}>
              <Input bordered={false} placeholder={t<string>('enterName')} />
            </FlatFormItem>
          </Col>

          <Col span={6}>
            <FlatFormItem
              name={COURSE_QUERY_PARAMS.TEACHER}
              label={t('teacher')}
              style={{ marginBottom: 0 }}>
              <Input
                bordered={false}
                placeholder={t<string>('enterTeacherName')}
              />
            </FlatFormItem>
          </Col>

          <Col span={6}>
            <FlatFormItem
              name={COURSE_QUERY_PARAMS.SEARCH}
              label={t('keyword')}
              style={{ marginBottom: 0 }}>
              <Input bordered={false} placeholder={t<string>('enterKeyword')} />
            </FlatFormItem>
          </Col>

          <Space style={{ marginRight: -12 }}>
            <Button disabled={loading} onClick={onReset}>
              Reset
            </Button>
            <Button
              loading={loading}
              htmlType='submit'
              style={{ marginLeft: 10 }}
              type='primary'>
              {t('search')}
            </Button>
          </Space>
        </Row>
      </Form>
    </FilterPanel>
  );
};

export default Filter;

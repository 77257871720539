import styled from 'styled-components';
import { Modal } from 'antd';

const PlaylistFormModal = styled(Modal)`
  .ant-modal-content {
    padding: 20px 32px !important;
  }
`;

export default PlaylistFormModal;

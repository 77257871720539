const vi = {
  BRANCH_CREATED_SUCCESSFULLY: 'Tạo mới chi nhánh thành công',
  BRANCH_UPDATED_SUCCESSFULLY: 'Cập nhật chi nhánh thành công',
  BRANCH_DELETED_SUCCESSFULLY: 'Xoá chi nhánh thành công',

  CHECK_IN_CREATED_SUCCESSFULLY: 'Tạo mới check in thành công',
  CHECK_IN_UPDATED_SUCCESSFULLY: 'Cập nhật check in thành công',
  CHECK_IN_DELETED_SUCCESSFULLY: 'Xoá check in thành công',

  INVITE_CODE_UPDATED_SUCCESSFULLY: 'Cập nhật Code thành công.',

  ALBUM_CREATED_SUCCESSFULLY: 'Tạo mới album thành công.',
  ALBUM_UPDATED_SUCCESSFULLY: 'Cập nhật album thành công.',
  ALBUM_DELETED_SUCCESSFULLY: 'Xoá album thành công.',

  STUDENT_CREATED_SUCCESSFULLY: 'Tạo mới học sinh thành công.',
  STUDENT_UPDATED_SUCCESSFULLY: 'Cập nhật học sinh thành công.',
  STUDENT_DELETED_SUCCESSFULLY: 'Xoá học sinh thành công.',
  STUDENT_ADD_SUCCESSFULLY: 'Thêm học sinh thành công.',

  COURSE_CREATED_SUCCESSFULLY: 'Tạo mới khoá học thành công.',
  COURSE_UPDATED_SUCCESSFULLY: 'Cập nhật khoá học thành công.',
  COURSE_DELETED_SUCCESSFULLY: 'Xoá khoá học thành công.',

  ASSET_CREATED_SUCCESSFULLY: 'Tạo mới tài liệu thành công.',
  ASSET_UPDATED_SUCCESSFULLY: 'Cập nhật tài liệu thành công.',
  ASSET_DELETED_SUCCESSFULLY: 'Xoá tài liệu thành công.',
  ASSET_ADDED_SUCCESSFULLY: 'Thêm tài liệu thành công.',

  PLAYLIST_CREATED_SUCCESSFULLY: 'Tạo mới playlist thành công.',
  PLAYLIST_UPDATED_SUCCESSFULLY: 'Cập nhật playlist thành công.',
  PLAYLIST_DELETED_SUCCESSFULLY: 'Xoá playlist thành công.',

  BOOK_CREATED_SUCCESSFULLY: 'Tạo mới sách thành công.',
  BOOK_UPDATED_SUCCESSFULLY: 'Cập nhật sách thành công.',
  BOOK_DELETED_SUCCESSFULLY: 'Xoá sách thành công.',

  STAFF_CREATED_SUCCESSFULLY: 'Tạo mới nhân viên thành công.',
  STAFF_UPDATED_SUCCESSFULLY: 'Cập nhật nhân viên thành công.',
  STAFF_DELETED_SUCCESSFULLY: 'Xoá nhân viên thành công.',
  STAFF_INVITATION_ACCEPTED_SUCCESSFULLY: 'Chấp nhận lời mời thành công.',
  STAFF_INVITED_SUCCESSFULLY: 'Gửi lời mời thành công.',

  ROLE_CREATED_SUCCESSFULLY: 'Tạo mới Role thành công.',
  ROLE_UPDATED_SUCCESSFULLY: 'Cập nhật Role thành công.',
  ROLE_DELETED_SUCCESSFULLY: 'Xoá Role thành công.',
  ROLE_DUPLICATED_SUCCESSFULLY: 'Sao chép Role thành công.',

  USER__ACCOUNT__INCORRECT: 'Tài khoản hoặc mật khẩu không đúng.',
  USER__PHONE_NUMBER__REQUIRED: 'Vui lòng nhập số điện thoại.',
  USER__PHONE_NUMBER__INVALID: 'Số điện thoại không tồn tại trên hệ thống.',
  USER__PHONE_NUMBER__INCORRECT: 'Số điện thoại chưa được xác nhận',
  USER__VERIFY_KEY__SENDING: 'Gửi mã code thành công',
  USER__CREATE__SUCCESS: 'Đăng ký thành công',
  USER__PASSWORD__INVALID: ' Xác nhận mật khẩu không khớp với mật khẩu ',
  USER__EMAIL__INVALID: 'Vui lòng nhập đúng địa chỉ Email.',
  USER__ACCOUNT__DEACTIVATE: 'Tài khoản đã bị vô hiệu hóa.',
  USER__UPDATE__SUCCESS: 'Cập nhật thành công',
  USER__VERIFY_KEY__EXPIRED: 'Mã xác thực hết hạn! Vui lòng nhập mã khác.',
  USER__VERIFY_KEY__INCORRECT: 'Mã xác thực không đúng! Vui lòng nhập mã khác.',
  USER__AVATAR__REQUIRED: 'Vui lòng chọn avata',
  BOOK__CREATE__SUCCESS: 'Thêm sách thành công.',
  BOOK__UPDATE__SUCCESS: 'Sửa sách thành công.',
  USER__PASSWORD__EMPTY: 'Vui lòng không để trống mật khẩu',
  USER__PASSWORD__REQUIRED: 'Vui lòng nhập mật khẩu',
  USER__PHONE_NUMBER__UNIQUE: 'Số điện thoại đã tồn tại',
  USER__PHONE_NUMBER__EMPTY: 'Số điện thoại đang để trống',
  USER__EMAIL__REQUIRED: 'Vui lòng nhập Email',
  USER__EMAIL__LENGTH: 'Độ dài Email không được quá 52 ký tự',
  USER__EMAIL__UNIQUE: 'Email đã tồn tại',
  USER__EMAIL__EMPTY: 'Vui lòng không để trống Email',
  USER__BIRTHDAY__INVALID_FORMAT: 'Sai định dạng ngày',
  USER__OLD_PASSWORD__EMPTY: 'Vui lòng không để trống nhập lại mật khẩu',
  USER__OLD_PASSWORD__REQUIRED: 'Vui lòng nhập lại mật khẩu',
  USER__NEW_PASSWORD__EMPTY: 'Vui lòng không để trống mật khẩu mới',
  USER__NEW_PASSWORD__REQUIRED: 'Vui lòng nhập mật khẩu mới',
  USER__NEW_PASSWORD_CONFIRM__EMPTY:
    'Vui lòng không để trống trường nhập lại mật khẩu mới',
  USER__NEW_PASSWORD_CONFIRM__REQUIRED: 'Vui lòng nhập lại mật khẩu mới',
  USER__SUID__INVALID: 'Suid không hợp lệ',
  USER__FIELD__UNIQUE: 'Dữ liệu đã tồn tại trong hệ thống',
  ASSETS__RESOURCE_TYPE__EMPTY: 'Loại tệp tin đang để trống',
  ASSETS__RESOURCE_TYPE__REQUIRED: 'Vui lòng nhập loại tập tin',
  ASSETS__RESOURCE_TYPE__INVALID: 'Loại tập tin không hợp lệ',
  ASSETS__NAME__EMPTY: 'Tên tập tin đang để trống',
  ASSETS__NAME__REQUIRED: 'Vui lòng nhập tên tập tin',
  ASSETS__TAGS__EMPTY: 'Tên thẻ đang để trống',
  ASSETS__TAGS__REQUIRED: 'Vui lòng nhập thẻ',
  BOOK__NAME__EMPTY: 'Tên sách đang để trống',
  BOOK__NAME__REQUIRED: 'Vui lòng nhập tên sách',
  BOOK__ASSETS_ID__INVALID: 'ID tập tin không đúng',
  BOOK__USER_PROFILE_ID__INVALID: 'ID hồ sơ người dùng không đúng',
  COURSE__NAME__EMPTY: 'Tên khóa học đang để trống',
  COURSE__NAME__REQUIRED: 'Vui lòng nhập tên khóa học',
  COURSE__NAME__UNIQUE: 'Tên khóa học đã tồn tại',
  COURSE__ASSETS_ID__INVALID: 'ID khóa học không tồn tại',
  COURSE__STUDENT_ID__INVALID: 'ID học sinh không tồn tại',
  WORKSPACE__NAME__EMPTY: 'Tên workspace đang để trống',
  WORKSPACE__NAME__REQUIRED: 'Vui lòng tên workspace',
  WORKSPACE__NAME__UNIQUE: 'Tên workspace đã tồn tại',
  WORKSPACE__WORKSPACE__UNIQUE: 'Workspace đã tồn tại',
  WORKSPACE__WORKSPACE__EMPTY: 'Workspace đang để trống',
  WORKSPACE__WORKSPACE__REQUIRED: 'Vui lòng nhập trường workspace',
  WORKSPACE__CREATE__FAILED: 'Tạo Workspace thất bại',
  USER_PROFILE__DOB__INVALID_FORMAT: 'Sai định dạng ngày sinh',
  STUDENT__DOB__INVALID_FORMAT: 'Sai định dạng ngày sinh',
  INVITATION_CODE__BOOK_ID__INVALID: 'Sai mã sách',
  INVITATION_CODE__COURSE_ID__INVALID: 'Sai mã khóa học',
  INVITATION_CODE__DELETE__SUCCESS: 'Xóa thành công',
  INVITATION_CODE__CREATE__SUCCESS: 'Tạo thành công',
  PROFILE_UPDATED_SUCCESSFULLY: 'Cập nhật Profile thành công',
  WORKSPACE__DELETE__SUCCESS: 'Xóa thành công',
  WORKSPACE_UPDATED_SUCCESSFULLY: 'Cập nhật workspace thành công',
  WORKSPACE_CREATED_SUCCESSFULLY: 'Tạo mới workspace thành công',
  COURSE__ADD_STUDENT__SUCCESS: 'Thêm thành công',
  BOOK__DELETE__SUCCESS: 'Xóa thành công',
  BOOK__INVITE_USER__SUCCESS: 'Thêm thành công',
  USER_CREATE_SUCCESS: 'Cập nhật email thành công',
  VERIFY_KEY__INPUT__INVALID: 'Mã xác thực không đúng',
  INVITATION_CODE__CODE__EXISTS: 'Mã code đã được sử dụng',
  ASSETS__ADD_INVITATION_CODE__SUCCESS: 'Tạo thành công',
  USER__VERIFY_KEY__INVALID: 'Mã xác thực không đúng',
  ASSETS__INVITATION_CODE__INVALID: 'Mã code không đúng.',
  ASSETS__INVITATION_CODE__VERIFIED: 'Xác thực mã code thành công.',
  USERPROFILE__DOB__FORMAT_INVALID: 'Ngày sinh không hợp lệ',
  STAFF__CREATE__SUCCESS: 'Tạo mới nhân viên thành công',
  STAFF__DELETE__SUCCESS: 'Xóa nhân viên thành công',
  ROLE__DELETE__SUCCESS: 'Xóa phân quyền thành công',
  ROLE__CREATE__SUCCESS: 'Tạo mới phân quyền thành công',
  ROLE__DELETE__FAILED: 'Bạn không được xóa phân quyền này',
  STAFF__INVITATION__SUCCESS: 'Gửi lời mời thành công',
  ROLE__UPDATE__SUCCESS: 'Cập nhật phân quyền thành công',
  STAFF_INVITATION__INVITED__BEFORE: 'Nhân viên đã được mời trước đó',
  STAFF__UPDATE__SUCCESS: 'Cập nhật nhân viên thành công',
  SHARE_LIST_ASSETS__CREATE__SUCCESS: 'Tạo thành công',
  SHARE_ASSETS__UPDATE__SUCCESS: 'Cập nhật playlist thành công',
  ASSETS__CODE__VERIFIED: 'Xác thực mã code thành công.',
  PLAYLIST__DELETE__SUCCESS: 'Xóa playlist thành công',
  PLAYLIST__UPDATE__SUCCESS: 'Cập nhật thành công Playlist',
  WORKSPACE__CREATE__NOT_SUPPORT_ON_BETA_VERSION:
    'Tính năng này bị giới hạn ở phiên bản beta',
  BOOK__CREATE_ORDER_ASSETS__SUCCESS: 'Thay đổi vị trí thành công',
  BOOK__UPDATE_ORDER_ASSETS__SUCCESS: 'Thay đổi vị trí thành công',
  COURSE__CREATE_ORDER_ASSETS__SUCCESS: 'Thay đổi vị trí thành công',
  COURSE__UPDATE_ORDER_ASSETS__SUCCESS: 'Thay đổi vị trí thành công',
  COURSES__ADD_STUDENT__SUCCESS: 'Thêm thành công',
  COURSES__DELETE_STUDENT__SUCCESS: 'Xóa thành công',
  COURSES__ADD_ASSETS__SUCCESS: 'Thêm thành công',
  COURSES__DELETE_ASSETS__SUCCESS: 'Xóa thành công',
  BOOK__DELETE_ASSETS__SUCCESS: 'Xóa thành công',
  PLAYLIST__DELETE_ASSETS__SUCCESS: 'Xóa thành công',
  PLAYLIST__ADD_ASSETS__SUCCESS: 'Thêm thành công',
  COURSES_LESSON__DELETE__SUCCESS: 'Xoá buổi học thành công',
  COURSES_LESSON_EXERCISE__ADD__SUCCESS: 'Tạo bài tập thành công',
  COURSES_LESSON_EXERCISE__DELETE__SUCCESS: 'Xoá bài tập thành công',
  COURSES_LESSON__UPDATE__SUCCESS: 'Cập nhật buổi học thành công',
  COURSES_LESSON_EXERCISE__UPDATE__SUCCESS: 'Cập nhật bài tập thành công',
  ASSETS__NAME__UNIQUE: 'Vui lòng nhập tên khác',
  COURSES_LESSON__ADD__SUCCESS: 'Tạo buổi học thành công',
  COURSES_LESSON__NAME__UNIQUE: 'Vui lòng nhập tên khác cho buổi học',
  EXERCISES__UPDATE__SUCCESS: 'Cập nhật bài tập thành công',
  COURSES_LESSON_DOCUMENT__ADD__SUCCESS: 'Thêm tập tin thành công',
  COURSES_LESSON_DOCUMENT__DELETE__SUCCESS: 'Xoá tập tin thành công',
  COURSES_LESSON_DOCUMENT__UPDATE__SUCCESS: 'Cập nhật tập tin thành công',
  ANSWER_EXERCISES__UPDATE__SUCCESS: 'Chấm điểm thành công',
  COURSES_LESSON__LEARNING_CONTENT__EMPTY: 'Nội dung bài học đang trống',
  ATTENDANCE__UPDATE__SUCCESS: 'Cập nhật thông tin điểm danh thành công',
  COURSE_LESSONS__MULTI_CREATE__SUCCESS: 'Tạo buổi học thành công',
  ROLE__UPDATE_PERMISSIONS__SUCCESS: 'Cập nhật phân quyền thành công',
  ROLE__DUPLICATED__SUCCESS: 'Sao chép phân quyền thành công',
  CANT_DELETE_ROLE_HAS_STAFF: 'Xóa phân quyền thất bại',
  PLAYLIST__CREATE__SUCCESS: 'Tạo mới Playlist thành công',
  BOOK__NAME__UNIQUE: 'Vui lòng nhập tên khác cho sách',
  ROLE__NAME__UNIQUE: 'Vui lòng nhập tên khác cho phân quyền',
  STAFF__ROLE__CAN_NOT_DELETE: 'Không thể xoá chủ sở hữu của Workspace',
  STAFF_INVITATION__EMAIL__INVITED_BEFORE: 'Email này đã được mời',
  PASSWORD_UPDATED_SUCCESSFULLY: 'Thay đổi mật khẩu thành công',
  DEFAULT_ERROR_MESSAGE: 'Something went wrong!',
  RESEND__EMAIL__SUCCESS: 'Gửi lại lời mời thành công',
};

export default vi;

import { Fragment } from 'react';
import {
  PageHeader,
  DetailsPageInfoWrapper,
  DetailsPageContentWrapper,
  DetailsPageTabs,
} from 'components/Common/Page';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useCourse, useResetCourseState } from 'features/course/courseHooks';
import type { TabsProps } from 'antd';
import Information from './Information';
import {
  NavLink,
  Outlet,
  generatePath,
  useMatch,
  useParams,
} from 'react-router-dom';
import { getClassName } from 'utils/style';
import PATHS from 'router/paths';

const TABS = {
  STUDENT: 'STUDENT',
  DOCUMENT: 'DOCUMENT',
  ALBUM: 'ALBUM',
  QRCODE: 'QRCODE',
};

const CourseDetails = () => {
  const { t } = useTranslation(LOCALES.COURSE);
  const { course, getCourseLoading } = useCourse();
  const { id } = useParams();
  const isDocumentPage = useMatch(PATHS.app.courses.details.document);
  const isAlbumsPage = useMatch(PATHS.app.courses.details.albums);
  const isAlbumDetailsPage = useMatch(PATHS.app.courses.details.album);
  const isQrCodePage = useMatch(PATHS.app.courses.details.qrCode);

  useResetCourseState();

  const items: TabsProps['items'] = [
    {
      label: (
        <NavLink
          to={generatePath(PATHS.app.courses.details.root, { id })}
          className={getClassName}>
          {t('student')}
        </NavLink>
      ),
      key: TABS.STUDENT,
    },
    {
      label: (
        <NavLink
          to={generatePath(PATHS.app.courses.details.document, { id })}
          className={getClassName}>
          {t('document')}
        </NavLink>
      ),
      key: TABS.DOCUMENT,
    },

    {
      label: (
        <NavLink
          to={generatePath(PATHS.app.courses.details.albums, { id })}
          className={getClassName}>
          {t('album')}
        </NavLink>
      ),
      key: TABS.ALBUM,
    },
    {
      label: (
        <NavLink
          to={generatePath(PATHS.app.courses.details.qrCode, { id })}
          className={getClassName}>
          {t('qrCode')}
        </NavLink>
      ),
      key: TABS.QRCODE,
    },
  ];

  return (
    <Fragment>
      <DetailsPageInfoWrapper>
        <PageHeader
          style={{ marginBottom: 20 }}
          title={t('courseInformation')}
        />

        <Information course={course} loading={getCourseLoading} />

        <Row>
          <DetailsPageTabs
            style={{ marginBottom: 0 }}
            defaultActiveKey={
              isDocumentPage
                ? TABS.DOCUMENT
                : isAlbumsPage || isAlbumDetailsPage
                ? TABS.ALBUM
                : isQrCodePage
                ? TABS.QRCODE
                : TABS.STUDENT
            }
            items={items}
          />
        </Row>
      </DetailsPageInfoWrapper>

      <DetailsPageContentWrapper>
        <Outlet />
      </DetailsPageContentWrapper>
    </Fragment>
  );
};

export default CourseDetails;

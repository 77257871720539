import Groups from './Groups';
import { DividedContentWrapper } from 'components/Common/Page';
import { Row } from 'antd';
import { Outlet } from 'react-router-dom';

const index = () => {
  return (
    <DividedContentWrapper>
      <Row>
        <Groups />
        <Outlet />
      </Row>
    </DividedContentWrapper>
  );
};

export default index;

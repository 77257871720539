import { Input } from 'antd';
import { useSearch } from 'app/hooks';
import { LOCALES } from 'app/i18n';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';

const SearchBox = ({ style = {}, searchKey }: any) => {
  const { t } = useTranslation(LOCALES.LAYOUT);
  const { searchValue, onSearch } = useSearch(searchKey);

  return (
    <FlatFormItem style={style} label={t('keyword')}>
      <Input
        value={searchValue}
        onChange={({ target: { value } }) => {
          onSearch(value);
        }}
        style={{ width: 300 }}
        bordered={false}
        placeholder={t('enterKeyword') || ''}
      />
    </FlatFormItem>
  );
};

export default SearchBox;

import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import bookAPI from './bookAPI';
import { BaseBook, Book, BookState, GetBooksQueryParams } from './types';
import { resetStoreAction } from 'config';
import { InvitationCodeParam } from 'types';

export const getBooks = createAppAsyncThunk(
  'book/getBooks',
  async (params: GetBooksQueryParams, { rejectWithValue }) => {
    try {
      const response = await bookAPI.getBooks(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createBook = createAppAsyncThunk(
  'book/createBook',
  async (book: BaseBook, { rejectWithValue }) => {
    try {
      const response = await bookAPI.createBook(book);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateBook = createAppAsyncThunk(
  'book/updateBook',
  async (book: Book, { rejectWithValue }) => {
    try {
      const { data } = await bookAPI.updateBook(book);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteBook = createAppAsyncThunk(
  'book/deleteBook',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await bookAPI.deleteBook(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getBookInvitationCode = createAppAsyncThunk(
  'course/getBookInvitationCode',
  async (model: InvitationCodeParam, { rejectWithValue }) => {
    try {
      const { data } = await bookAPI.getInvitationCode(model);

      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getRoleBooks = createAppAsyncThunk(
  'Book/getRoleBooks',
  async (ids: string, { rejectWithValue }) => {
    try {
      const { results } = await bookAPI.getRoleBooks(ids);

      return results;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: BookState = {
  getBooksLoading: false,
  books: [],
  getBooksFailed: undefined,
  paginationConfig: {},

  createBookLoading: false,
  createBookSuccess: undefined,
  createBookFailed: undefined,

  updateBookLoading: false,
  updateBookSuccess: undefined,
  updateBookFailed: undefined,

  deleteBookLoading: false,
  deleteBookSuccess: undefined,
  deleteBookFailed: undefined,

  getBookInvitationCodeLoading: false,
  invitationCode: undefined,
  getBookInvitationCodeFailed: undefined,

  getRoleBooksLoading: false,
  roleBooks: [],
  getRoleBooksFailed: undefined,
};

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    resetBookState() {
      return initialState;
    },

    resetRoleBooks(state) {
      state.roleBooks = [];
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getBooks.pending, (state, action) => {
        const {
          meta: {
            arg: { page, page_size },
          },
        } = action;

        state.getBooksLoading = true;
        state.paginationConfig.current = Number(page);
        state.paginationConfig.pageSize = Number(page_size);
      })
      .addCase(getBooks.fulfilled, (state, action) => {
        state.getBooksLoading = false;
        state.books = action.payload.results;
        state.paginationConfig.total = action.payload.count;
      })
      .addCase(getBooks.rejected, (state, action) => {
        state.getBooksLoading = false;
        state.getBooksFailed = action.payload;
      })

      .addCase(createBook.pending, state => {
        state.createBookLoading = true;
        state.createBookFailed = undefined;
      })
      .addCase(createBook.fulfilled, (state, action) => {
        state.createBookLoading = false;
        state.createBookSuccess = action.payload;
      })
      .addCase(createBook.rejected, (state, action) => {
        state.createBookLoading = false;
        state.createBookFailed = action.payload;
      })

      .addCase(updateBook.pending, state => {
        state.updateBookLoading = true;
        state.updateBookFailed = undefined;
      })
      .addCase(updateBook.fulfilled, (state, { payload }) => {
        state.updateBookLoading = false;
        state.updateBookSuccess = payload;
        state.books = state.books.map(book =>
          book.id !== payload.id ? book : payload,
        );
      })
      .addCase(updateBook.rejected, (state, action) => {
        state.updateBookLoading = false;
        state.updateBookFailed = action.payload;
      })

      .addCase(deleteBook.pending, state => {
        state.deleteBookLoading = true;
        state.deleteBookFailed = undefined;
      })
      .addCase(deleteBook.fulfilled, (state, action) => {
        state.deleteBookLoading = false;
        state.deleteBookSuccess = action.payload;
      })
      .addCase(deleteBook.rejected, (state, action) => {
        state.deleteBookLoading = false;
        state.deleteBookFailed = action.payload;
      })

      .addCase(getBookInvitationCode.pending, state => {
        state.getBookInvitationCodeLoading = true;
        state.getBookInvitationCodeFailed = undefined;
      })
      .addCase(getBookInvitationCode.fulfilled, (state, action) => {
        state.getBookInvitationCodeLoading = false;
        state.invitationCode = action.payload;
      })
      .addCase(getBookInvitationCode.rejected, (state, action) => {
        state.getBookInvitationCodeLoading = false;
        state.getBookInvitationCodeFailed = action.payload;
      })

      .addCase(getRoleBooks.pending, state => {
        state.getRoleBooksLoading = true;
      })
      .addCase(getRoleBooks.fulfilled, (state, action) => {
        state.getRoleBooksLoading = false;
        state.roleBooks = action.payload;
      })
      .addCase(getRoleBooks.rejected, (state, action) => {
        state.getRoleBooksLoading = false;
        state.getRoleBooksFailed = action.payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const { resetBookState, resetRoleBooks } = bookSlice.actions;

export default bookSlice.reducer as Reducer<BookState>;

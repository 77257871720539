import { Navigate } from 'react-router-dom';
import PATHS from './paths';
import type { RouteObject } from 'react-router-dom';
import SignIn from 'features/auth/SignIn';
import SignUp from 'features/auth/SignUp';

import AccountProfileLayout from 'components/AccountProfile';
import Workspaces from 'features/auth/Workspace/Workspaces';
import CreateWorkspace from 'features/auth/Workspace/CreateWorkspace';
import Invitations from 'features/auth/Invitations';
import AccountProfile from 'features/auth/Account/Profile';
import ChangePassword from 'features/auth/Account/ChangePassword';
import UpdateWorkspace from 'features/profile/UpdateWorkspace';
import Profile from 'features/profile/Profile';
import AppLayout from 'components/Layout';
import AccountLayout from 'components/AccountLayout';

import Students from 'features/student/Students';
import Courses from 'features/course/Courses';
import Course from 'features/course/Course';
import CourseStudents from 'features/course/Course/CourseStudents';
import CourseAssets from 'features/course/Course/CourseAssets';
import CourseAlbums from 'features/course/Course/CourseAlbums';
import CourseAlbum from 'features/course/Course/CourseAlbum';

import Assets from 'features/asset/Assets';

import Playlists from 'features/playlist/Playlists';
import PlaylistDetails from 'features/playlist/Playlist';

import Books from 'features/book/Books';
import BookReaders from 'features/bookDetails/BookDetails/BookReaders';
import BookAssets from 'features/bookDetails/BookDetails/BookAssets';
import BookDetails from 'features/bookDetails/BookDetails';

import Staffs from 'features/staff/Staffs';
import GroupStaffs from 'features/staff/Staffs/GroupStaffs';

import Roles from 'features/role/Roles';
import RolePermissions from 'features/role/Roles/RolePermissions';
import PermissionTable from 'features/role/Roles/RolePermissions/PermissionTable';
import CustomizeStudent from 'features/role/Roles/RolePermissions/Customizes/CustomizeStudent';
import CustomizeStaff from 'features/role/Roles/RolePermissions/Customizes/CustomizeStaff';
import CustomizeCourse from 'features/role/Roles/RolePermissions/Customizes/CustomizeCourse';
import CustomizeBook from 'features/role/Roles/RolePermissions/Customizes/CustomizeBook';
import CustomizeAsset from 'features/role/Roles/RolePermissions/Customizes/CustomizeAsset';
import CustomizePlaylist from 'features/role/Roles/RolePermissions/Customizes/CustomizePlaylist';
import AlbumForm from 'features/course/Course/AlbumForm/CreateForm';
import EditAlbumForm from 'features/course/Course/AlbumForm/EditForm';
import CourseQRCode from 'features/course/Course/CourseQrCode';
import Branches from 'features/branch/Branches';
import CheckIns from 'features/checkIn/CheckIns';

const routes: RouteObject[] = [
  { path: PATHS.auth.signIn, element: <SignIn /> },
  { path: PATHS.auth.signUp, element: <SignUp /> },

  {
    path: PATHS.account.root,
    element: <AccountLayout />,
    children: [
      { index: true, element: <Workspaces /> },
      { path: PATHS.account.createWorkspace, element: <CreateWorkspace /> },
      { path: PATHS.account.invitations, element: <Invitations /> },
      {
        path: PATHS.account.profile.root,
        element: <AccountProfileLayout />,
        children: [
          { index: true, element: <AccountProfile /> },
          {
            path: PATHS.account.profile.changePassword,
            element: <ChangePassword />,
          },
        ],
      },
    ],
  },

  {
    path: PATHS.app.root,
    element: <AppLayout />,
    children: [
      { index: true, element: <Profile /> },
      { path: PATHS.app.updateWorkspace, element: <UpdateWorkspace /> },
      { path: PATHS.app.students, element: <Students /> },
      { path: PATHS.app.courses.root, element: <Courses /> },
      {
        path: PATHS.app.courses.details.root,
        element: <Course />,
        children: [
          { index: true, element: <CourseStudents /> },
          {
            path: PATHS.app.courses.details.document,
            element: <CourseAssets />,
          },
          {
            path: PATHS.app.courses.details.albums,
            element: <CourseAlbums />,
          },
          {
            path: PATHS.app.courses.details.album,
            element: <CourseAlbum />,
          },
          {
            path: PATHS.app.courses.details.qrCode,
            element: <CourseQRCode />,
          },
        ],
      },

      { path: PATHS.app.playlist.root, element: <Playlists /> },
      { path: PATHS.app.playlist.details, element: <PlaylistDetails /> },

      { path: PATHS.app.assets, element: <Assets /> },
      { path: PATHS.app.books.root, element: <Books /> },

      {
        path: PATHS.app.books.details.root,
        element: <BookDetails />,
        children: [
          { index: true, element: <BookReaders /> },
          {
            path: PATHS.app.books.details.document,
            element: <BookAssets />,
          },
        ],
      },

      {
        path: PATHS.app.staffs.root,
        element: <Staffs />,
        children: [
          { index: true, element: <GroupStaffs /> },
          {
            path: PATHS.app.staffs.group,
            element: <GroupStaffs />,
          },
        ],
      },

      {
        path: PATHS.app.roles.root,
        element: <Roles />,
        children: [
          { index: true, element: <RolePermissions /> },
          {
            path: PATHS.app.roles.details,
            element: <RolePermissions />,
            children: [
              {
                index: true,
                element: <PermissionTable />,
              },
              {
                path: PATHS.app.roles.customizeStudent,
                element: <CustomizeStudent />,
              },
              {
                path: PATHS.app.roles.customizeStaff,
                element: <CustomizeStaff />,
              },
              {
                path: PATHS.app.roles.customizeCourse,
                element: <CustomizeCourse />,
              },
              {
                path: PATHS.app.roles.customizeBook,
                element: <CustomizeBook />,
              },
              {
                path: PATHS.app.roles.customizeAsset,
                element: <CustomizeAsset />,
              },
              {
                path: PATHS.app.roles.customizePlaylist,
                element: <CustomizePlaylist />,
              },
            ],
          },
        ],
      },

      { path: PATHS.app.albumForm.create, element: <AlbumForm /> },
      { path: PATHS.app.albumForm.edit, element: <EditAlbumForm /> },

      { path: PATHS.app.branches.root, element: <Branches /> },
      { path: PATHS.app.checkIns.root, element: <CheckIns /> },
    ],
  },

  {
    path: '*',
    element: <Navigate to={PATHS.account.root} />,
  },
];

export default routes;

import {
  Button,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
  Popconfirm as PopConfirm,
} from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import {
  useRoleTable,
  useRoleDetails,
  useUpdateRole,
  useCopyRole,
  useDeleteRole,
} from 'features/role/roleHooks';
import { EditOutlined } from '@ant-design/icons';
import color from 'config/color';
import RoleForm from './RoleForm';

const PermissionTable = () => {
  const { getRolesLoading, roleDetails } = useRoleDetails();
  const { dataSource, columns } = useRoleTable();
  const { t } = useTranslation(LOCALES.ROLE);
  const { handleCopyRole, copyRoleLoading } = useCopyRole();

  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const { updateRoleLoading, handleUpdateRole } = useUpdateRole(() =>
    setIsUpdatingName(false),
  );

  const [deleteWarningOpen, setDeleteWarningOpen] = useState(false);
  const { handleDeleteRole, deleteRoleLoading } = useDeleteRole(() => {
    setDeleteWarningOpen(false);
  });

  const isLoading = copyRoleLoading || updateRoleLoading || deleteRoleLoading;

  return (
    <Fragment>
      <Row justify='space-between' align='middle' style={{ marginBottom: 26 }}>
        <Row style={{ width: '100%' }} justify='space-between'>
          {isUpdatingName && roleDetails ? (
            <RoleForm
              initValue={roleDetails?.name}
              onCancel={() => {
                setIsUpdatingName(false);
              }}
              onSubmit={handleUpdateRole}
              loading={updateRoleLoading}
            />
          ) : (
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              {roleDetails?.name || ''}
              <EditOutlined
                style={{
                  fontSize: 16,
                  color: color.primary,
                  cursor: 'pointer',
                  marginLeft: 10,
                }}
                onClick={() => setIsUpdatingName(true)}
              />
            </Typography.Title>
          )}

          <Space>
            {roleDetails ? (
              <Fragment>
                <Button
                  loading={updateRoleLoading}
                  disabled={copyRoleLoading}
                  type='primary'
                  onClick={() => handleUpdateRole()}>
                  {t('update')}
                </Button>
                <Button
                  disabled={updateRoleLoading}
                  loading={copyRoleLoading}
                  type='primary'
                  onClick={() => handleCopyRole()}>
                  {t('duplicate')}
                </Button>

                <PopConfirm
                  placement='topRight'
                  title={t('deleteWarning')}
                  onConfirm={() => handleDeleteRole()}
                  okText={t('delete')}
                  cancelText={t('cancel')}
                  okButtonProps={{ loading: deleteRoleLoading }}
                  cancelButtonProps={{ disabled: deleteRoleLoading }}
                  open={deleteWarningOpen}
                  onCancel={() => setDeleteWarningOpen(false)}>
                  <Button
                    disabled={isLoading}
                    onClick={() => setDeleteWarningOpen(true)}>
                    {t('delete')}
                  </Button>
                </PopConfirm>
              </Fragment>
            ) : (
              <Fragment>
                {[0, 1, 2, 3].map(index => (
                  <Skeleton.Button active key={index}></Skeleton.Button>
                ))}
              </Fragment>
            )}
          </Space>
        </Row>
      </Row>

      <Table
        loading={getRolesLoading || updateRoleLoading}
        columns={columns}
        dataSource={dataSource}
        rowKey='resourceKey'
      />
    </Fragment>
  );
};

export default PermissionTable;

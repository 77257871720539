import { useState, useEffect } from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { RcFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LOCALES } from 'app/i18n';

interface ImageUploaderProps {
  label?: string;
  onChange?: (file: RcFile) => void;
  value?: string | RcFile;
  isLoading?: boolean;
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const StyledUpload = styled(Upload)`
  .ant-upload {
    width: 152px !important;
    height: 152px !important;
    overflow: hidden;
  }
`;

export default function ImageUploader({
  label,
  onChange,
  value,
  isLoading,
}: ImageUploaderProps) {
  const { t } = useTranslation(LOCALES.PROFILE);
  const [imageUrl, setImageUrl] = useState(() =>
    typeof value === 'string' ? value : undefined,
  );

  useEffect(() => {
    if (typeof value === 'string') {
      setImageUrl(value);
    }
  }, [value]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(t('acceptImageOnly'));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('imageMustSmallerThan2MB'));
    }

    if (!isJpgOrPng || !isLt2M) {
      return false;
    }

    getBase64(file, base64 => {
      onChange?.(file);
      setImageUrl(base64);
    });

    return false;
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{label}</div>
    </div>
  );

  return (
    <StyledUpload
      listType='picture-card'
      showUploadList={false}
      beforeUpload={beforeUpload}
      style={{ height: '100%', width: '100%' }}>
      {value ? (
        <img
          src={imageUrl}
          alt={label}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      ) : (
        uploadButton
      )}
    </StyledUpload>
  );
}

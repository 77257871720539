import { AxiosProgressEvent } from 'axios';
import { getAssetFormData, multipartFormConfig } from 'utils/formHelper';
import requester, { fileDownloader } from 'utils/requester';
import { AssetAPI, UploadingProgressModel } from './types';

const assetAPI: AssetAPI = {
  getAssets(params) {
    return requester.get('assets/', {
      ...params,
      parent: params?.parent || '',
    });
  },

  createAsset(asset, updateProgress) {
    return requester.post('assets/', getAssetFormData(asset), {
      ...multipartFormConfig,
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (asset.folder || !asset.path_file || !progressEvent.total) {
          return;
        }

        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );

        const uploadingProgressModel: UploadingProgressModel = {
          uid: asset.path_file.uid,
          percentCompleted,
        };

        updateProgress(uploadingProgressModel);
      },
    });
  },

  updateAsset(asset) {
    return requester.put(
      `assets/${asset.id}/`,
      getAssetFormData({ ...asset, id: undefined }),
      multipartFormConfig,
    );
  },

  deleteAsset(id) {
    return requester.delete(`assets/${id}/`);
  },

  getInvitationCode(id) {
    return requester.get(`assets/${id}/share-qrcode/`);
  },

  getRoleAssets(ids) {
    return requester.get('assets/', { ids });
  },

  getPathFile(id) {
    return requester.get(`assets/${id}/path-file/`);
  },

  downloadFile(path) {
    return fileDownloader.downloadFile(path);
  },
};

export default assetAPI;

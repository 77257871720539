import { useEffect, useMemo } from 'react';
import {
  UseBooksReturnType,
  BaseBook,
  UseCreateBookReturnType,
  UseUpdateBookReturnType,
  UseDeleteBookReturnType,
  Book,
  UseBooksModalReturnType,
  UseGetBookInvitationCodeReturnType,
} from './types';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  getBooks,
  createBook,
  updateBook,
  deleteBook,
  resetBookState,
  getBookInvitationCode,
  getRoleBooks,
  resetRoleBooks,
} from './bookSlice';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGING } from 'config';
import { InvitationCodeParam } from 'types';
import { PERMISSIONS_RESOURCES } from 'config/permissions';
import { useRoleResourceIds } from 'features/role/roleHooks';

export const BOOK_QUERY_PARAMS = {
  SEARCH: 'search',
};

export const BOOK_MODAL_QUERY_PARAMS = {
  SEARCH: 'bookSearch',
  PAGE: 'bookPage',
  PAGE_SIZE: 'bookPageSize',
  ORDERING: 'bookOrdering',
};

const { SEARCH, PAGE, PAGE_SIZE, ORDERING } = BOOK_MODAL_QUERY_PARAMS;

const REAL_KEY = {
  [SEARCH]: BOOK_QUERY_PARAMS.SEARCH,
  [PAGE]: 'page',
  [PAGE_SIZE]: 'page_size',
  [ORDERING]: 'ordering',
};

export const useBooksModalUrlQueryParams = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get(SEARCH) ?? undefined;
  const page = searchParams.get(PAGE) ?? DEFAULT_PAGING.PAGE;
  const pageSize = searchParams.get(PAGE_SIZE) ?? DEFAULT_PAGING.PAGE_SIZE;

  const urlQueryParams = useMemo(() => {
    return [ORDERING].reduce(
      (currentParams, paramKey) => ({
        ...currentParams,
        [REAL_KEY[paramKey]]: searchParams.get(paramKey),
      }),
      { search, page, page_size: pageSize },
    );
  }, [search, page, pageSize, searchParams]);

  return urlQueryParams;
};

export const useBooksModal = (isOpen: boolean): UseBooksModalReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getBooksLoading = useAppSelector(state => state.book.getBooksLoading);
  const books = useAppSelector(state => state.book.books);
  const getBooksFailed = useAppSelector(state => state.book.getBooksFailed);
  const paginationConfig = useAppSelector(state => state.book.paginationConfig);

  const dispatch = useAppDispatch();

  const urlQueryParams = useBooksModalUrlQueryParams();
  const onPageChange = usePagination(
    BOOK_MODAL_QUERY_PARAMS.PAGE,
    BOOK_MODAL_QUERY_PARAMS.PAGE_SIZE,
  );

  const goToFirstPage = () => {
    onPageChange(1);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getBooks(urlQueryParams));
    }
  }, [urlQueryParams, dispatch, isOpen]);

  useFailed(getBooksFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    getBooksLoading,
    getBooksFailed,
    books,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    goToFirstPage,
  };
};

export const useBooks = (): UseBooksReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getBooksLoading = useAppSelector(state => state.book.getBooksLoading);
  const books = useAppSelector(state => state.book.books);
  const getBooksFailed = useAppSelector(state => state.book.getBooksFailed);
  const paginationConfig = useAppSelector(state => state.book.paginationConfig);

  const dispatch = useAppDispatch();

  const urlQueryParams = useURLQueryParams(Object.values(BOOK_QUERY_PARAMS));
  const onPageChange = usePagination();

  useEffect(() => {
    dispatch(getBooks(urlQueryParams));
  }, [urlQueryParams, dispatch]);

  useFailed(getBooksFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  const refreshCurrentPage = () => {
    dispatch(getBooks(urlQueryParams));
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      books.length === 1 && urlQueryParams.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(urlQueryParams.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterCreating = () => {
    if (urlQueryParams.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    getBooksLoading,
    getBooksFailed,
    books,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    refreshAfterCreating,
    refreshAfterDeleting,
  };
};

export const useCreateBook = (
  callback: () => void,
): UseCreateBookReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const createBookLoading = useAppSelector(
    state => state.book.createBookLoading,
  );
  const createBookSuccess = useAppSelector(
    state => state.book.createBookSuccess,
  );
  const createBookFailed = useAppSelector(state => state.book.createBookFailed);

  const dispatch = useAppDispatch();

  const handleCreateBook = (book: BaseBook) => {
    dispatch(createBook(book));
  };

  useSuccess(
    createBookSuccess,
    t<string>('BOOK_CREATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(createBookFailed);

  return {
    createBookLoading,
    createBookSuccess,
    createBookFailed,
    handleCreateBook,
  };
};

export const useUpdateBook = (
  callback: () => void,
): UseUpdateBookReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const updateBookLoading = useAppSelector(
    state => state.book.updateBookLoading,
  );
  const updateBookSuccess = useAppSelector(
    state => state.book.updateBookSuccess,
  );
  const updateBookFailed = useAppSelector(state => state.book.updateBookFailed);

  const dispatch = useAppDispatch();

  const handleUpdateBook = (book: Book) => {
    dispatch(updateBook(book));
  };

  useSuccess(
    updateBookSuccess,
    t<string>('BOOK_UPDATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(updateBookFailed);

  return {
    updateBookLoading,
    updateBookSuccess,
    updateBookFailed,
    handleUpdateBook,
  };
};

export const useDeleteBook = (
  callback: () => void,
): UseDeleteBookReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const deleteBookLoading = useAppSelector(
    state => state.book.deleteBookLoading,
  );
  const deleteBookSuccess = useAppSelector(
    state => state.book.deleteBookSuccess,
  );
  const deleteBookFailed = useAppSelector(state => state.book.deleteBookFailed);

  const dispatch = useAppDispatch();

  const handleDeleteBook = (id: number) => {
    dispatch(deleteBook(id));
  };

  useSuccess(
    deleteBookSuccess,
    t<string>('BOOK_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteBookFailed);

  return {
    deleteBookLoading,
    deleteBookSuccess,
    deleteBookFailed,
    handleDeleteBook,
  };
};

export const useResetBookState = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetBookState());
    };
  }, [dispatch]);
};

export const useGetBookInvitationCode =
  (): UseGetBookInvitationCodeReturnType => {
    const getBookInvitationCodeLoading = useAppSelector(
      state => state.book.getBookInvitationCodeLoading,
    );
    const invitationCode = useAppSelector(state => state.book.invitationCode);
    const getBookInvitationCodeFailed = useAppSelector(
      state => state.book.getBookInvitationCodeFailed,
    );
    const dispatch = useAppDispatch();

    const handleGetBookInvitationCode = (params: InvitationCodeParam) => {
      dispatch(getBookInvitationCode(params));
    };

    useFailed(getBookInvitationCodeFailed);

    return {
      getBookInvitationCodeLoading,
      invitationCode,
      getBookInvitationCodeFailed,
      handleGetBookInvitationCode,
    };
  };

export const useRoleBooks = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const roleBooks = useAppSelector(state => state.book.roleBooks);
  const getRoleBooksLoading = useAppSelector(
    state => state.book.getRoleBooksLoading,
  );
  const getRoleBooksFailed = useAppSelector(
    state => state.book.getRoleBooksFailed,
  );
  const dispatch = useAppDispatch();

  const { ids: studentIds } = useRoleResourceIds(PERMISSIONS_RESOURCES.BOOK);

  useEffect(() => {
    if (studentIds) {
      dispatch(getRoleBooks(studentIds));
    } else {
      dispatch(resetRoleBooks());
    }
  }, [studentIds, dispatch]);

  const refetchRoleBooks = (ids: number[]) => {
    if (ids.length) {
      dispatch(getRoleBooks(ids.join(',')));
    } else {
      dispatch(resetRoleBooks());
    }
  };

  useFailed(getRoleBooksFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    roleBooks,
    getRoleBooksLoading,
    getRoleBooksFailed,
    refetchRoleBooks,
  };
};

import { useEffect } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  UseCourseStudentsReturnType,
  UseDeleteCourseStudentReturnType,
  DeleteCourseStudentParams,
  AddCourseStudentsPayload,
  UseAddCourseStudentsReturnType,
  UseCourseInvitationReturnType,
} from 'features/course/types';

import {
  getCourseStudents,
  deleteCourseStudent,
  addCourseStudents,
  getCourseInvitation,
} from 'features/course/courseSlice';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';
import { useParams } from 'react-router-dom';
import { InvitationCodeParam } from 'types';

export const COURSE_STUDENT_QUERY_PARAMS: string[] = ['ordering'];

export const useCourseStudents = (): UseCourseStudentsReturnType => {
  const courseStudents = useAppSelector(state => state.course.courseStudents);
  const getCourseStudentsFailed = useAppSelector(
    state => state.course.getCourseStudentsFailed,
  );
  const getCourseStudentsLoading = useAppSelector(
    state => state.course.getCourseStudentsLoading,
  );
  const courseStudentPaginationConfig = useAppSelector(
    state => state.course.courseStudentPaginationConfig,
  );
  const dispatch = useAppDispatch();

  const params = useURLQueryParams(COURSE_STUDENT_QUERY_PARAMS);
  const onPageChange = usePagination();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCourseStudents({ id, params }));
  }, [params, dispatch, id]);

  useFailed(getCourseStudentsFailed);

  const refreshCurrentPage = () => {
    dispatch(getCourseStudents({ id, params }));
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      courseStudents.length === 1 && params.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(params.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterAdding = () => {
    if (params.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    courseStudents,
    getCourseStudentsLoading,
    getCourseStudentsFailed,
    courseStudentPaginationConfig: {
      ...courseStudentPaginationConfig,
      onChange: onPageChange,
    },
    refreshAfterAdding,
    refreshAfterDeleting,
  };
};

export const useDeleteCourseStudent = (
  callback: () => void,
): UseDeleteCourseStudentReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  // const state = useAppSelector(state => state.course);

  const deleteCourseStudentSuccess = useAppSelector(
    state => state.course.deleteCourseStudentSuccess,
  );
  const deleteCourseStudentFailed = useAppSelector(
    state => state.course.deleteCourseStudentFailed,
  );
  const deleteCourseStudentLoading = useAppSelector(
    state => state.course.deleteCourseStudentLoading,
  );

  const dispatch = useAppDispatch();

  const handleDeleteCourseStudent = (params: DeleteCourseStudentParams) => {
    dispatch(deleteCourseStudent(params));
  };

  useSuccess(
    deleteCourseStudentSuccess,
    t<string>('STUDENT_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteCourseStudentFailed);

  return {
    deleteCourseStudentLoading,
    deleteCourseStudentSuccess,
    deleteCourseStudentFailed,
    handleDeleteCourseStudent,
  };
};

export const useAddCourseStudents = (
  callback: () => void,
): UseAddCourseStudentsReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);
  const addCourseStudentsLoading = useAppSelector(
    state => state.course.addCourseStudentsLoading,
  );
  const addCourseStudentsSuccess = useAppSelector(
    state => state.course.addCourseStudentsSuccess,
  );
  const addCourseStudentsFailed = useAppSelector(
    state => state.course.addCourseStudentsFailed,
  );

  const dispatch = useAppDispatch();

  const handleAddCourseStudents = (params: AddCourseStudentsPayload) => {
    dispatch(addCourseStudents(params));
  };

  useSuccess(
    addCourseStudentsSuccess,
    t<string>('STUDENT_ADDED_SUCCESSFULLY'),
    callback,
  );

  useFailed(addCourseStudentsFailed);

  return {
    addCourseStudentsLoading,
    addCourseStudentsSuccess,
    addCourseStudentsFailed,
    handleAddCourseStudents,
  };
};

export const useCourseInvitation = (): UseCourseInvitationReturnType => {
  const getCourseInvitationLoading = useAppSelector(
    state => state.course.getCourseInvitationLoading,
  );
  const invitationCode = useAppSelector(state => state.course.invitationCode);
  const getCourseInvitationFailed = useAppSelector(
    state => state.course.getCourseInvitationFailed,
  );

  const dispatch = useAppDispatch();

  const handleGetCourseInvitation = (model: InvitationCodeParam) => {
    dispatch(getCourseInvitation(model));
  };

  useFailed(getCourseInvitationFailed);

  return {
    getCourseInvitationLoading,
    invitationCode,
    getCourseInvitationFailed,
    handleGetCourseInvitation,
  };
};

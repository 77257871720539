import { getProfileFormData, multipartFormConfig } from 'utils/formHelper';
import requester from 'utils/requester';
import { ProfileAPI } from './types';

const profileAPI: ProfileAPI = {
  getProfile: () => requester.get('/userprofile/'),
  updateProfile: profile =>
    requester.put(
      `userprofile/${profile.id}/`,
      getProfileFormData(profile),
      multipartFormConfig,
    ),
};

export default profileAPI;

import { useEffect } from 'react';
import { Form, Input, Row, Button, DatePicker } from 'antd';
import { LOCALES } from 'app/i18n';
import FlatFormItem from 'components/Common/FlatFormItem';
import PhoneFormItem from 'components/Common/PhoneFormItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseStudent } from './types';
import { CLIENT_DATE_2_FORMAT } from 'config';
import { useCreateStudent, useUpdateStudent } from './studentHooks';
import { getBirthDateValue, getPhoneNumberValue } from 'utils';
import FormModal from 'components/Styled/FormModal';

interface StudentFormProps {
  isOpen: boolean;
  onClose: () => void;
  initValues: BaseStudent;
  refreshAfterCreating: () => void;
}

const StudentForm = (props: StudentFormProps) => {
  const { isOpen, onClose, initValues, refreshAfterCreating } = props;
  const { t } = useTranslation(LOCALES.STUDENT);
  const [form] = Form.useForm<BaseStudent>();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, form]);

  const onCreateSuccess = () => {
    onCancel();
    refreshAfterCreating();
  };

  const { handleCreateStudent, createStudentLoading } = useCreateStudent(
    onCreateSuccess,
  );

  const { handleUpdateStudent, updateStudentLoading } = useUpdateStudent(
    onCancel,
  );

  const isLoading = createStudentLoading || updateStudentLoading;

  const onFinish = (values: any) => {
    const studentModel = {
      ...values,
      dob: values.dob && getBirthDateValue(values.dob),
      phone_number: getPhoneNumberValue(values),
    };
    if (initValues.id) {
      handleUpdateStudent({ ...studentModel, id: initValues.id });
    } else {
      handleCreateStudent(studentModel);
    }
  };

  return (
    <FormModal
      width={700}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
      title={initValues.id ? t('updateStudent') : t('createStudent')}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        layout='vertical'>
        <FlatFormItem
          label={t('name')}
          name='name'
          style={{ marginTop: 30 }}
          rules={[
            { required: true, message: t<string>('pleaseInputStudentName') },
          ]}>
          <Input bordered={false} />
        </FlatFormItem>

        <FlatFormItem label={t('englishName')} name='english_name'>
          <Input bordered={false} />
        </FlatFormItem>

        <PhoneFormItem required />

        <FlatFormItem label={t('dateOfBirth')} name='dob'>
          <DatePicker
            style={{ width: '100%' }}
            format={CLIENT_DATE_2_FORMAT}
            bordered={false}
          />
        </FlatFormItem>

        <Row justify='center' style={{ marginTop: 40, marginBottom: 10 }}>
          <Button
            disabled={isLoading}
            style={{ marginRight: 10 }}
            onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
          <Button
            loading={isLoading}
            htmlType='submit'
            style={{ marginLeft: 10 }}
            type='primary'>
            {initValues.id ? t('update') : t('create')}
          </Button>
        </Row>
      </Form>
    </FormModal>
  );
};

export default StudentForm;

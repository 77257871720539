import { createSlice, Reducer } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'app/hooks';
import staffAPI from './staffAPI';
import {
  BaseStaff,
  Staff,
  StaffState,
  GetStaffsQueryParams,
  InviteStaffModel,
} from './types';
import { resetStoreAction } from 'config';
import { ListResponse } from 'types';

export const getAllStaffs = createAppAsyncThunk(
  'staff/getAllStaffs',
  async (_, { rejectWithValue }) => {
    try {
      const response: ListResponse<Staff> = await staffAPI.getAllStaffs();
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getStaffs = createAppAsyncThunk(
  'staff/getStaffs',
  async (params: GetStaffsQueryParams, { rejectWithValue }) => {
    try {
      const response: ListResponse<Staff> = await staffAPI.getStaffs(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createStaff = createAppAsyncThunk(
  'staff/createStaff',
  async (staff: BaseStaff, { rejectWithValue }) => {
    try {
      const response = await staffAPI.createStaff(staff);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateStaff = createAppAsyncThunk(
  'staff/updateStaff',
  async (staff: Staff, { rejectWithValue }) => {
    try {
      const { data } = await staffAPI.updateStaff(staff);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateStaffRole = createAppAsyncThunk(
  'staff/updateStaffRole',
  async (
    model: { staffId: number; roleIds: number[] },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await staffAPI.updateStaffRole(model);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteStaff = createAppAsyncThunk(
  'staff/deleteStaff',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await staffAPI.deleteStaff(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const inviteStaff = createAppAsyncThunk(
  'staff/inviteStaff',
  async (staff: InviteStaffModel, { rejectWithValue }) => {
    try {
      const { data } = await staffAPI.invite(staff);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const reInviteStaff = createAppAsyncThunk(
  'staff/reInviteStaff',
  async (staffId: string, { rejectWithValue }) => {
    try {
      const data = await staffAPI.reInvite(staffId);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getRoleStaffs = createAppAsyncThunk(
  'staff/getRoleStaffs',
  async (ids: string, { rejectWithValue }) => {
    try {
      const { results }: ListResponse<Staff> = await staffAPI.getRoleStaffs(
        ids,
      );

      return results;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: StaffState = {
  getAllStaffsLoading: true,
  allStaffs: [],
  getAllStaffsFailed: undefined,

  getStaffsLoading: true,
  staffs: [],
  getStaffsFailed: undefined,
  paginationConfig: {},

  createStaffLoading: false,
  createStaffSuccess: undefined,
  createStaffFailed: undefined,

  updateStaffLoading: false,
  updateStaffSuccess: undefined,
  updateStaffFailed: undefined,

  updateStaffRoleLoading: false,
  updateStaffRoleSuccess: undefined,
  updateStaffRoleFailed: undefined,

  deleteStaffLoading: false,
  deleteStaffSuccess: undefined,
  deleteStaffFailed: undefined,

  inviteStaffLoading: false,
  inviteStaffSuccess: undefined,
  inviteStaffFailed: undefined,

  reInviteStaffLoadingIds: {},
  reInviteStaffSuccess: undefined,
  reInviteStaffFailed: undefined,

  getRoleStaffsLoading: false,
  roleStaffs: [],
  getRoleStaffsFailed: undefined,
};

export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    resetStaffState() {
      return initialState;
    },

    resetRoleStaffs(state) {
      state.roleStaffs = [];
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getAllStaffs.pending, (state, action) => {
        state.getAllStaffsLoading = true;
      })
      .addCase(getAllStaffs.fulfilled, (state, action) => {
        state.getAllStaffsLoading = false;
        state.allStaffs = action.payload.results;
      })
      .addCase(getAllStaffs.rejected, (state, action) => {
        state.getAllStaffsLoading = false;
        state.getAllStaffsFailed = action.payload;
      })

      .addCase(getStaffs.pending, (state, action) => {
        const {
          meta: {
            arg: { page, page_size },
          },
        } = action;

        state.getStaffsLoading = true;
        state.paginationConfig.current = Number(page);
        state.paginationConfig.pageSize = Number(page_size);
      })
      .addCase(getStaffs.fulfilled, (state, action) => {
        state.getStaffsLoading = false;

        state.staffs = action.payload.results;
        state.paginationConfig.total = action.payload.count;
      })
      .addCase(getStaffs.rejected, (state, action) => {
        state.getStaffsLoading = false;
        state.getStaffsFailed = action.payload;
      })

      .addCase(createStaff.pending, state => {
        state.createStaffLoading = true;
        state.createStaffFailed = undefined;
      })
      .addCase(createStaff.fulfilled, (state, action) => {
        state.createStaffLoading = false;
        state.createStaffSuccess = action.payload;
      })
      .addCase(createStaff.rejected, (state, action) => {
        state.createStaffLoading = false;
        state.createStaffFailed = action.payload;
      })

      .addCase(updateStaff.pending, state => {
        state.updateStaffLoading = true;
        state.updateStaffFailed = undefined;
      })
      .addCase(updateStaff.fulfilled, (state, { payload }) => {
        state.updateStaffLoading = false;
        state.updateStaffSuccess = payload;
        state.staffs = state.staffs.map(staff =>
          staff.id !== payload.id ? staff : payload,
        );
      })
      .addCase(updateStaff.rejected, (state, action) => {
        state.updateStaffLoading = false;
        state.updateStaffFailed = action.payload;
      })

      .addCase(updateStaffRole.pending, state => {
        state.updateStaffRoleLoading = true;
        state.updateStaffRoleFailed = undefined;
      })
      .addCase(updateStaffRole.fulfilled, (state, { payload }) => {
        state.updateStaffRoleLoading = false;
        state.updateStaffRoleSuccess = payload;
      })
      .addCase(updateStaffRole.rejected, (state, action) => {
        state.updateStaffRoleLoading = false;
        state.updateStaffRoleFailed = action.payload;
      })

      .addCase(deleteStaff.pending, state => {
        state.deleteStaffLoading = true;
        state.deleteStaffFailed = undefined;
      })
      .addCase(deleteStaff.fulfilled, (state, action) => {
        state.deleteStaffLoading = false;
        state.deleteStaffSuccess = action.payload;
      })
      .addCase(deleteStaff.rejected, (state, action) => {
        state.deleteStaffLoading = false;
        state.deleteStaffFailed = action.payload;
      })

      .addCase(getRoleStaffs.pending, state => {
        state.getRoleStaffsLoading = true;
      })
      .addCase(getRoleStaffs.fulfilled, (state, action) => {
        state.getRoleStaffsLoading = false;
        state.roleStaffs = action.payload;
      })
      .addCase(getRoleStaffs.rejected, (state, action) => {
        state.getRoleStaffsLoading = false;
        state.getRoleStaffsFailed = action.payload;
      })

      .addCase(inviteStaff.pending, state => {
        state.inviteStaffLoading = true;
        state.inviteStaffFailed = undefined;
      })
      .addCase(inviteStaff.fulfilled, (state, action) => {
        const invitedStaff = action.payload.staff;

        state.inviteStaffLoading = false;
        state.inviteStaffSuccess = invitedStaff;
        state.staffs = state.staffs.map(staff =>
          staff.id !== invitedStaff.id
            ? staff
            : {
                ...staff,
                invite: invitedStaff.invite,
              },
        );
      })
      .addCase(inviteStaff.rejected, (state, action) => {
        state.inviteStaffLoading = false;
        state.inviteStaffFailed = action.payload;
      })

      .addCase(reInviteStaff.pending, (state, { meta }) => {
        state.reInviteStaffLoadingIds[meta.arg] = true;
        state.inviteStaffFailed = undefined;
      })
      .addCase(reInviteStaff.fulfilled, (state, { payload, meta }) => {
        state.reInviteStaffLoadingIds[meta.arg] = false;
        state.reInviteStaffSuccess = payload;
      })
      .addCase(reInviteStaff.rejected, (state, { payload, meta }) => {
        state.reInviteStaffLoadingIds[meta.arg] = false;
        state.reInviteStaffFailed = payload;
      })

      .addCase(resetStoreAction, () => {
        return initialState;
      });
  },
});

export const { resetStaffState, resetRoleStaffs } = staffSlice.actions;

export default staffSlice.reducer as Reducer<StaffState>;

import { ReactNode } from 'react';
import { Row, Typography, Tabs } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(Row)`
  margin-bottom: 26px;
  min-height: 32px;
`;

export const PageHeader = ({
  title,
  children,
  style = {},
  level = 4,
}: {
  title: string;
  children?: ReactNode;
  style?: any;
  level?: 1 | 2 | 3 | 4 | 5;
}) => {
  return (
    <Wrapper style={style} justify='space-between' align='middle'>
      <Typography.Title level={level} style={{ marginBottom: 0 }}>
        {title}
      </Typography.Title>
      <div>{children}</div>
    </Wrapper>
  );
};

const PageContentWrapper = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
  margin-bottom: 32px;
  min-height: calc(100% - 90px);
`;

export const PageContent = ({
  children,
  style = {},
}: {
  children: ReactNode;
  style?: any;
}) => {
  return <PageContentWrapper style={style}>{children}</PageContentWrapper>;
};

export const HasFilterPageContent = styled(PageContentWrapper)`
  min-height: calc(100% - 150px);
`;

export const DividedContentWrapper = styled(HasFilterPageContent)`
  margin-bottom: 0px;
  padding: 0;
`;

export const FilterPanel = styled(PageContentWrapper)`
  min-height: 0px;
`;

export const SmallFilterPanel = styled(FilterPanel)`
  padding: 0px;
`;

const ContainerWrapper = styled.div`
  height: calc(100vh - 105px);
  max-width: 1200px;
  margin: 0 auto;
`;

export const Container = ({ children }: { children: ReactNode }) => {
  return <ContainerWrapper>{children}</ContainerWrapper>;
};

export const MenuWrapper = styled.div`
  .ant-menu-item:has(.active-link) {
    color: #da271c;
    background-color: transparent;

    &::after {
      border-width: 2px;
      border-bottom-color: #da271c;
    }

    &:hover {
      color: #da271c !important;
    }
  }

  .ant-menu-item:has(.not-active-link) {
    color: rgba(0, 0, 0, 0.88);

    &::after {
      border-width: 2px;
      border-bottom-color: transparent;
    }
  }

  margin-right: auto;
`;

export const DetailsPageInfoWrapper = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
  margin-bottom: 32px;
  padding-top: 16px;
  padding-bottom: 0;
`;

export const DetailsPageContentWrapper = styled(DetailsPageInfoWrapper)`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const DetailsPageTabs = styled(Tabs)`
  margin-top: 20px;
  min-width: 300px;

  .ant-tabs-tab {
    padding: 0 !important;

    a {
      padding: 12px 0 !important;
      display: block;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;

    &::before {
      border-bottom: 0 !important;
    }
  }
`;

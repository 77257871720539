import { DividedContentWrapper } from 'components/Common/Page';
import { Row } from 'antd';
import { Outlet } from 'react-router-dom';
import RoleMenu from './RoleMenu';
import { useCheckOwnerRole } from '../roleHooks';

const Index = () => {
  const isOwner = useCheckOwnerRole();
  return (
    <DividedContentWrapper>
      <Row>
        <RoleMenu />
        {isOwner ? <div></div> : <Outlet />}
      </Row>
    </DividedContentWrapper>
  );
};

export default Index;

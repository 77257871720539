import { useEffect, useMemo } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  usePagination,
  useSuccess,
} from 'app/hooks';
import {
  UseCoursesReturnType,
  UseCreateCourseReturnType,
  UseUpdateCourseReturnType,
  UseDeleteCourseReturnType,
  Course,
  BaseCourse,
  GetCourseState,
} from 'features/course/types';

import {
  getCourses,
  createCourse,
  updateCourse,
  deleteCourse,
  resetCourseState,
  getCourse,
  getRoleCourses,
  resetRoleCourses,
} from 'features/course/courseSlice';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { useURLQueryParams } from 'app/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGING } from 'config';
import { useRoleResourceIds } from 'features/role/roleHooks';
import { PERMISSIONS_RESOURCES } from 'config/permissions';

export * from './courseStudentHooks';
export * from './courseAssetHooks';

export const COURSE_QUERY_PARAMS = {
  NAME: 'name',
  TEACHER: 'teacher',
  SEARCH: 'search',
};

export const COURSE_MODAL_QUERY_PARAMS = {
  NAME: 'courseName',
  TEACHER: 'courseTeacher',
  SEARCH: 'courseSearch',
  PAGE: 'coursePage',
  PAGE_SIZE: 'coursePageSize',
  ORDERING: 'courseOrdering',
};

const { NAME, TEACHER, SEARCH, PAGE, PAGE_SIZE, ORDERING } =
  COURSE_MODAL_QUERY_PARAMS;

const REAL_KEY = {
  [NAME]: COURSE_QUERY_PARAMS.NAME,
  [TEACHER]: COURSE_QUERY_PARAMS.TEACHER,
  [SEARCH]: COURSE_QUERY_PARAMS.SEARCH,
  [PAGE]: 'page',
  [PAGE_SIZE]: 'page_size',
  [ORDERING]: 'ordering',
};

export const useCoursesModalUrlQueryParams = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get(SEARCH) ?? undefined;
  const page = searchParams.get(PAGE) ?? DEFAULT_PAGING.PAGE;
  const pageSize = searchParams.get(PAGE_SIZE) ?? DEFAULT_PAGING.PAGE_SIZE;

  const urlQueryParams = useMemo(() => {
    return [NAME, TEACHER, ORDERING].reduce(
      (currentParams, paramKey) => ({
        ...currentParams,
        [REAL_KEY[paramKey]]: searchParams.get(paramKey),
      }),
      { search, page, page_size: pageSize },
    );
  }, [search, page, pageSize, searchParams]);

  return urlQueryParams;
};

export const useCoursesModal = (isOpen: boolean) => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getCoursesFailed = useAppSelector(
    state => state.course.getCoursesFailed,
  );
  const getCoursesLoading = useAppSelector(
    state => state.course.getCoursesLoading,
  );
  const courses = useAppSelector(state => state.course.courses);
  const paginationConfig = useAppSelector(
    state => state.course.paginationConfig,
  );

  const dispatch = useAppDispatch();

  const urlQueryParams = useCoursesModalUrlQueryParams();
  const onPageChange = usePagination(
    COURSE_MODAL_QUERY_PARAMS.PAGE,
    COURSE_MODAL_QUERY_PARAMS.PAGE_SIZE,
  );

  const goToFirstPage = () => {
    onPageChange(1);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getCourses(urlQueryParams));
    }
  }, [urlQueryParams, dispatch, isOpen]);

  useFailed(getCoursesFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    getCoursesFailed,
    getCoursesLoading,
    courses,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    goToFirstPage,
  };
};

export const useCourses = (): UseCoursesReturnType => {
  const getCoursesFailed = useAppSelector(
    state => state.course.getCoursesFailed,
  );
  const getCoursesLoading = useAppSelector(
    state => state.course.getCoursesLoading,
  );
  const courses = useAppSelector(state => state.course.courses);
  const paginationConfig = useAppSelector(
    state => state.course.paginationConfig,
  );

  const dispatch = useAppDispatch();

  const urlQueryParams = useURLQueryParams(Object.values(COURSE_QUERY_PARAMS));
  const onPageChange = usePagination();

  useEffect(() => {
    dispatch(getCourses(urlQueryParams));
  }, [urlQueryParams, dispatch]);

  useFailed(getCoursesFailed);

  const refreshCurrentPage = () => {
    dispatch(getCourses(urlQueryParams));
  };

  const refreshAfterDeleting = () => {
    const shouldDecreasePage =
      courses.length === 1 && urlQueryParams.page !== '1';

    if (shouldDecreasePage) {
      onPageChange(Number(urlQueryParams.page) - 1);
    } else {
      refreshCurrentPage();
    }
  };

  const refreshAfterCreating = () => {
    if (urlQueryParams.page === '1') {
      refreshCurrentPage();
    } else {
      onPageChange(1);
    }
  };

  return {
    courses,
    getCoursesLoading,
    getCoursesFailed,
    paginationConfig: {
      ...paginationConfig,
      onChange: onPageChange,
    },
    refreshAfterCreating,
    refreshAfterDeleting,
  };
};

export const useCreateCourse = (
  callback: () => void,
): UseCreateCourseReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const createCourseLoading = useAppSelector(
    state => state.course.createCourseLoading,
  );
  const createCourseSuccess = useAppSelector(
    state => state.course.createCourseSuccess,
  );
  const createCourseFailed = useAppSelector(
    state => state.course.createCourseFailed,
  );
  const dispatch = useAppDispatch();

  const handleCreateCourse = (course: BaseCourse) => {
    dispatch(createCourse(course));
  };

  useSuccess(
    createCourseSuccess,
    t<string>('COURSE_CREATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(createCourseFailed);

  return {
    createCourseLoading,
    createCourseSuccess,
    createCourseFailed,
    handleCreateCourse,
  };
};

export const useUpdateCourse = (
  callback: () => void,
): UseUpdateCourseReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const updateCourseLoading = useAppSelector(
    state => state.course.updateCourseLoading,
  );
  const updateCourseSuccess = useAppSelector(
    state => state.course.updateCourseSuccess,
  );
  const updateCourseFailed = useAppSelector(
    state => state.course.updateCourseFailed,
  );
  const dispatch = useAppDispatch();

  const handleUpdateCourse = (course: Course) => {
    dispatch(updateCourse(course));
  };

  useSuccess(
    updateCourseSuccess,
    t<string>('COURSE_UPDATED_SUCCESSFULLY'),
    callback,
  );

  useFailed(updateCourseFailed);

  return {
    updateCourseLoading,
    updateCourseSuccess,
    updateCourseFailed,
    handleUpdateCourse,
  };
};

export const useDeleteCourse = (
  callback: () => void,
): UseDeleteCourseReturnType => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const deleteCourseLoading = useAppSelector(
    state => state.course.deleteCourseLoading,
  );
  const deleteCourseSuccess = useAppSelector(
    state => state.course.deleteCourseSuccess,
  );
  const deleteCourseFailed = useAppSelector(
    state => state.course.deleteCourseFailed,
  );

  const dispatch = useAppDispatch();

  const handleDeleteCourse = (id: number) => {
    dispatch(deleteCourse(id));
  };

  useSuccess(
    deleteCourseSuccess,
    t<string>('COURSE_DELETED_SUCCESSFULLY'),
    callback,
  );

  useFailed(deleteCourseFailed);

  return {
    deleteCourseLoading,
    deleteCourseSuccess,
    deleteCourseFailed,
    handleDeleteCourse,
  };
};

export const useResetCourseState = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCourseState());
    };
  }, [dispatch]);
};

export const useCourse = (): GetCourseState => {
  const { id } = useParams();

  const course = useAppSelector(state => state.course.course);
  const getCourseFailed = useAppSelector(state => state.course.getCourseFailed);
  const getCourseLoading = useAppSelector(
    state => state.course.getCourseLoading,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getCourse(Number(id)));
    }
  }, [dispatch, id]);

  useFailed(getCourseFailed);

  return {
    course,
    getCourseLoading,
    getCourseFailed,
  };
};

export const useRoleCourses = () => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const getRoleCoursesFailed = useAppSelector(
    state => state.course.getRoleCoursesFailed,
  );
  const roleCourses = useAppSelector(state => state.course.roleCourses);
  const getRoleCoursesLoading = useAppSelector(
    state => state.course.getRoleCoursesLoading,
  );
  const dispatch = useAppDispatch();

  const { ids } = useRoleResourceIds(PERMISSIONS_RESOURCES.COURSE);

  useEffect(() => {
    if (ids) {
      dispatch(getRoleCourses(ids));
    } else {
      dispatch(resetRoleCourses());
    }
  }, [dispatch, ids]);

  const refetchRoleCourses = (ids: number[]) => {
    if (ids.length) {
      dispatch(getRoleCourses(ids.join(',')));
    } else {
      dispatch(resetRoleCourses());
    }
  };

  useFailed(getRoleCoursesFailed, t<string>('DEFAULT_ERROR_MESSAGE'));

  return {
    roleCourses,
    getRoleCoursesLoading,
    getRoleCoursesFailed,
    refetchRoleCourses,
  };
};

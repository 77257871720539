import { useEffect } from 'react';
import {  Form, Input, Row, Button } from 'antd';
import { LOCALES } from 'app/i18n';
import FlatFormItem from 'components/Common/FlatFormItem';
import { useTranslation } from 'react-i18next';
import { BasePlaylist } from 'features/playlist/types';
import {
  useCreatePlaylist,
  useUpdatePlaylist,
} from 'features/playlist/playlistHooks';
import ImageUploader from 'components/Common/ImageUploader';
import FormModal from 'components/Styled/FormModal';

interface PlaylistFormProps {
  isOpen: boolean;
  onClose: () => void;
  initValues: BasePlaylist;
  refreshAfterCreating: () => void;
}

const PlaylistForm = (props: PlaylistFormProps) => {
  const { isOpen, onClose, initValues, refreshAfterCreating } = props;
  const { t } = useTranslation(LOCALES.PLAYLIST);
  const [form] = Form.useForm<BasePlaylist>();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, form]);

  const onCreateSuccess = () => {
    onCancel();
    refreshAfterCreating();
  };

  const { handleCreatePlaylist, createPlaylistLoading } = useCreatePlaylist(
    onCreateSuccess,
  );

  const { handleUpdatePlaylist, updatePlaylistLoading } = useUpdatePlaylist(
    onCancel,
  );

  const isLoading = createPlaylistLoading || updatePlaylistLoading;

  const onFinish = (values: any) => {
    const playlistModel = {
      ...values,
    };
    if (initValues.id) {
      handleUpdatePlaylist({ ...playlistModel, id: initValues.id });
    } else {
      handleCreatePlaylist(playlistModel);
    }
  };

  return (
    <FormModal
      width={700}
      footer={null}
      open={isOpen}
      onCancel={onCancel}
      title={t(initValues.id ? 'updatePlaylist' : 'createPlaylist')}>
      <Form
        disabled={isLoading}
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        layout='vertical'>
        <Row justify='center' align='middle' style={{ marginTop: 20 }}>
          <Form.Item name='photo' style={{ marginBottom: 0 }}>
            <ImageUploader label={t<string>('logo')} />
          </Form.Item>
        </Row>

        <FlatFormItem
          label={t('name')}
          name='name'
          style={{ marginTop: 30 }}
          rules={[
            { required: true, message: t<string>('pleaseInputPlaylistName') },
          ]}>
          <Input bordered={false} placeholder={t<string>('enterName')} />
        </FlatFormItem>

        <FlatFormItem label={t('descriptions')} name='descriptions'>
          <Input bordered={false} placeholder={t<string>('enterDescription')} />
        </FlatFormItem>

        <Row justify='center' style={{ marginTop: 40, marginBottom: 10 }}>
          <Button
            disabled={isLoading}
            style={{ marginRight: 10 }}
            onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
          <Button
            loading={isLoading}
            htmlType='submit'
            style={{ marginLeft: 10 }}
            type='primary'>
            {initValues.id ? t('update') : t('create')}
          </Button>
        </Row>
      </Form>
    </FormModal>
  );
};

export default PlaylistForm;

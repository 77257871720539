const en = {
  create: 'Create',
  cancel: 'Cancel',
  update: 'Update',
  createStudent: 'Create Student',
  updateStudent: 'Update Student',
  add: 'Add',

  students: 'Students',
  name: 'Name',
  englishName: 'English Name',
  phoneNumber: 'Phone Number',
  dateOfBirth: 'Date Of Birth',
  link: 'Link',
  deleteWarning: 'Are you sure want to delete this student?',
  delete: 'Delete',
  editInformation: 'Edit Student Information',
  no: 'No',

  pleaseInputStudentName: 'Please Input Student Name',
  search: 'Search',
  enterName: 'Enter name',
  enterEnglishName: 'Enter english name',
  enterPhoneNumber: 'Enter phone number',
  keyword: 'Keyword',
  enterKeyword: 'Enter Keyword',

  learnedHours: 'Learned Hours',
  inviteCode: 'Invite Code',
  addStudent: 'Add Student',
};

export default en;

import { useEffect, useMemo } from 'react';
import {
  useAppSelector,
  useAppDispatch,
  useFailed,
  useSuccess,
} from 'app/hooks';
import { resetProfileState, getProfile, updateProfile } from './profileSlice';
import { Profile, GetProfileState, UpdateProfileHook } from './types';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import { FormInstance } from 'antd';
import { getBirthDate, getPhoneNumber } from 'utils/formHelper';

export const useProfile = (): GetProfileState => {
  const profile = useAppSelector(state => state.profile.profile);
  const getProfileLoading = useAppSelector(
    state => state.profile.getProfileLoading,
  );
  const getProfileFailed = useAppSelector(
    state => state.profile.getProfileFailed,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProfile());
    return () => {
      dispatch(resetProfileState());
    };
  }, [dispatch]);

  return { profile, getProfileLoading, getProfileFailed };
};

export const useInitProfile = (form: FormInstance) => {
  const { profile, getProfileLoading } = useProfile();
  const initValue = useMemo(() => {
    if (!profile) {
      return { id: -1 };
    }

    return {
      ...profile,
      ...getPhoneNumber(profile.phone_number),
      dob: getBirthDate(profile.dob),
    };
  }, [profile]);

  useEffect(() => {
    form.setFieldsValue(initValue);
  }, [initValue, form]);

  return { initValue, getProfileLoading };
};

export const useUpdateProfile = (): UpdateProfileHook => {
  const { t } = useTranslation(LOCALES.MESSAGE);

  const updateProfileSuccess = useAppSelector(
    state => state.profile.updateProfileSuccess,
  );
  const updateProfileFailed = useAppSelector(
    state => state.profile.updateProfileFailed,
  );
  const updateProfileLoading = useAppSelector(
    state => state.profile.updateProfileLoading,
  );
  const dispatch = useAppDispatch();

  const handleUpdateProfile = (profile: Profile) => {
    dispatch(updateProfile(profile));
  };

  useSuccess(updateProfileSuccess, t<string>('PROFILE_UPDATED_SUCCESSFULLY'));

  useFailed(updateProfileFailed);

  return {
    updateProfileSuccess,
    updateProfileLoading,
    updateProfileFailed,
    handleUpdateProfile,
  };
};

import { createAction } from '@reduxjs/toolkit';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const CLIENT_DATE_FORMAT = 'DD-MM-YYYY';
export const CLIENT_DATE_2_FORMAT = 'DD/MM/YYYY';

export const LANGUAGE = {
  VI: 'vi',
  EN: 'en',
};

export const resetStoreAction = createAction('RESET_STORE');

export const DEFAULT_PAGING = {
  PAGE: '1',
  PAGE_SIZE: '10',
  CHILD_ASSET_PAGE_SIZE: '20',
};

export const COURSE_STATUS = ['preparing', 'started', 'ended'];

export const CHECK_IN_TYPE_OPTIONS = [
  { value: 'check_in', label: 'Check in' },
  { value: 'check_out', label: 'Check out' },
];

export const SORT_TYPE: { ASC: 'ascend'; DESC: 'descend' } = {
  ASC: 'ascend',
  DESC: 'descend',
};

export const URL_SORT_KEY = 'ordering';
export const SORT_DESC_PREFIX = '-';

export const NEW_RECORD_ID = -100;
export const MAX_UPLOAD_REQUEST = 5;

export const HAS_CUSTOM_PERMISSION: { [key: string]: true } = {
  // assets: true,
  book: true,
  staff: true,
  student: true,
  courses: true,
  assets: true,
  sharelistassets: true,
};

export const ASSET_TYPES = {
  MP3: 'audio/mpeg',
  MP4: 'video/mp4',
  PDF: 'application/pdf',
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  WEBP: 'application/octet-stream',
};

export const OWNER_ROLE_NAME = 'owner';

export const SUPPORTED_ALBUM_ASSET_FILE_TYPES =
  'image/png, image/jpeg, image/svg, image/webp, audio/mpeg, video/mp4';

export const IMAGE_EXTENSIONS = [
  'png',
  'jpeg',
  'svg',
  'image/png',
  'image/jpeg',
  'image/svg',
  'webp',
  'image/webp',
  'webp',
];

export const VIDEO_EXTENSIONS = [
  'mp3',
  'mp4',
  'audio/mpeg',
  'video/mpeg',
  'video/mp4',
];

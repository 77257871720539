const size = {
  xxl: "1600.1px",
  xl: "1200.1px",
  lg: "991.1px",
  md: "768.1px",
  sm: "576.1px",
  xs: "375.1px",
};

const device = {
  /* Min-width */
  xxlMin: `(min-width: ${size.xxl})`,
  xlMin: `(min-width: ${size.xl})`,
  lgMin: `(min-width: ${size.lg})`,
  mdMin: `(min-width: ${size.md})`,
  smMin: `(min-width: ${size.sm})`,
  xsMin: `(min-width: ${size.xs})`,

  /* Max-width */
  xxl: `(max-width: ${size.xxl})`,
  xl: `(max-width: ${size.xl})`,
  lg: `(max-width: ${size.lg})`,
  md: `(max-width: ${size.md})`,
  sm: `(max-width: ${size.sm})`,
  xs: `(max-width: ${size.xs})`,
};

const breakpoints = { size, device };

export default breakpoints;

import { Dropdown, Space, Typography, Avatar, Skeleton } from 'antd';
import { useLogout } from 'features/auth/authHooks';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import styled from 'styled-components';
import { LOCALES } from 'app/i18n';
import { useProfile } from 'features/profile/profileHooks';
import { NavLink } from 'react-router-dom';
import PATHS from 'router/paths';
import LanguageBtn from 'components/Common/LanguageBtn';

const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .ant-dropdown-menu-title-content {
    display: block;
  }
`;

const Account = () => {
  const { profile, getProfileLoading } = useProfile();
  const logout = useLogout();
  const { t } = useTranslation(LOCALES.LAYOUT);

  const { email, name, avatar } = profile || {};
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <StyledMenuItem style={{ cursor: 'auto' }}>
          <Avatar style={{ backgroundColor: '#f56a00' }} src={avatar}>
            {email}
          </Avatar>
          <div
            style={{
              marginLeft: 10,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Skeleton loading={getProfileLoading} paragraph={false}>
              <Typography.Text style={{ margin: 0 }}> {name}</Typography.Text>
            </Skeleton>

            <Typography.Text type='secondary' style={{ margin: 0 }}>
              {' '}
              {email}
            </Typography.Text>
          </div>
        </StyledMenuItem>
      ),
    },

    {
      type: 'divider',
    },

    {
      key: '2',
      label: (
        <NavLink to={PATHS.app.root}>
          <StyledMenuItem style={{ width: 170 }}>
            <Typography.Text style={{ margin: 0 }}>
              {t('profile')}
            </Typography.Text>
          </StyledMenuItem>
        </NavLink>
      ),
    },
    {
      key: '3',
      label: (
        <NavLink to={PATHS.app.updateWorkspace}>
          <StyledMenuItem>
            <Typography.Text style={{ margin: 0 }}>
              {t('workspace')}
            </Typography.Text>
          </StyledMenuItem>
        </NavLink>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '5',
      label: (
        <StyledMenuItem onClick={() => logout()}>
          <Typography.Text style={{ margin: 0 }} type='danger'>
            {' '}
            {t('logout')}
          </Typography.Text>
        </StyledMenuItem>
      ),
    },
  ];

  return (
    <Space align='center'>
      <Dropdown
        overlayStyle={{ minWidth: 150 }}
        menu={{ items, selectedKeys: [] }}
        placement='bottomRight'>
        <Space align='center' size={8} style={{ cursor: 'pointer' }}>
          <Avatar src={avatar} style={{ backgroundColor: '#f56a00' }}>
            {name}
          </Avatar>
          <Skeleton
            style={{ width: 100 }}
            loading={getProfileLoading}
            paragraph={false}>
            <Typography.Text style={{ margin: 0 }}> {name}</Typography.Text>
          </Skeleton>
        </Space>
      </Dropdown>

      <div style={{ marginTop: 10, marginLeft: -5 }}>
        <LanguageBtn />
      </div>
    </Space>
  );
};

export default Account;

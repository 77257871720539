import React from 'react';
import { PageContent, PageHeader } from 'components/Common/Page';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'app/i18n';
import WorkspaceFrom from './Form';

const CreateWorkspace = () => {
  const { t } = useTranslation(LOCALES.WORKSPACE);

  return (
    <div style={{ padding: '0 30px' }}>
      <PageHeader title={t('createWorkspace')}></PageHeader>
      <PageContent style={{ padding: 32 }}>
        <WorkspaceFrom />
      </PageContent>
    </div>
  );
};

export default CreateWorkspace;

import React, { useState } from 'react';
import { Layout } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'features/auth/authHooks';
import PATHS from 'router/paths';
import { setAxiosToken } from 'utils/requester';
import Header from './Header';
import Sider from './Sider';

const { Content } = Layout;

const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { token, workspace } = useAuth();

  if (!token) {
    return <Navigate to={PATHS.auth.signIn} />;
  }
  if (!workspace) {
    return <Navigate to={PATHS.account.root} />;
  } else {
    setAxiosToken(token);
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header setCollapsed={setCollapsed} collapsed={collapsed} />

      <Layout
        className='site-layout'
        hasSider={true}
        style={{ height: 'calc(100vh - 64px)' }}>
        <Sider collapsed={collapsed} />
        <Layout>
          <Content
            style={{
              padding: '28px',
              background: '#f0f2f5',
              maxHeight: 'calc(100vh - 64px)',
              overflow: 'auto',
            }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;

import { getPlaylistFormData, multipartFormConfig } from 'utils/formHelper';
import requester from 'utils/requester';
import { BasePlaylist, PlaylistAPI, Playlist } from './types';

const playlistAPI: PlaylistAPI = {
  getPlaylists(params) {
    return requester.get('share-assets/', params);
  },

  createPlaylist(playlist: BasePlaylist) {
    return requester.post(
      'share-assets/',
      getPlaylistFormData(playlist),
      multipartFormConfig,
    );
  },

  updatePlaylist(playlist: Playlist) {
    return requester.put(
      `share-assets/${playlist.id}/`,
      getPlaylistFormData(playlist),
      multipartFormConfig,
    );
  },

  deletePlaylist(id: number) {
    return requester.delete(`share-assets/${id}/`);
  },

  getInvitationCode(id) {
    return requester.get(`share-assets/${id}/share-qrcode/`);
  },

  getPlaylist(id: number) {
    return requester.get(`share-assets/${id}/`);
  },

  getPlaylistAssets({ id, params }) {
    return requester.get(`share-assets/${id}/assets/`, {
      ...params,
      page_size: 9999,
      file_only: true,
    });
  },

  deletePlaylistAsset({ playlistId, assetId }) {
    return requester.delete(`share-assets/${playlistId}/assets/${assetId}/`);
  },

  addPlaylistAssets({ playlistId, assetIds }) {
    return requester.post(`share-assets/${playlistId}/assets/`, {
      assets_id: assetIds,
    });
  },

  orderPlaylistAssets({ playlistId, ids }) {
    return requester.post(`share-assets/${playlistId}/assets/order/`, {
      assets_id: ids,
    });
  },

  getRolePlaylists(ids) {
    return requester.get('share-assets/', { ids, page_size: 9999 });
  },
};

export default playlistAPI;

import {
  PermissionArgs,
  PermissionJson,
  PredefinedPermissions,
  ResourceArgs,
  RoleTableDataItem,
} from './types';

export const isAllResourceHasThePermission = (
  dataSource: RoleTableDataItem[],
  permissionKey: string,
) => {
  const dataSourceThatHasPermission = dataSource.filter(({ permissions }) =>
    permissions.hasOwnProperty(permissionKey),
  );
  const hasNoPermissionResource = dataSourceThatHasPermission.find(resource => {
    return !resource.permissions[permissionKey];
  });

  return !hasNoPermissionResource;
};

export const getPermissionsHasObjectConfig = (
  resourceKey: string,
  predefinedPermissions: PredefinedPermissions,
) => {
  if (!predefinedPermissions) {
    return [];
  }

  const permissions = predefinedPermissions[resourceKey]?.permissions;

  if (!permissions) {
    return [];
  }

  return Object.entries(permissions).reduce(
    (permissionKeys: string[], [permissionKey, permissionConfig]) => {
      if (permissionConfig.need_obj_perm) {
        return [...permissionKeys, permissionKey];
      }

      return permissionKeys;
    },
    [],
  );
};

export const deletePermission = ({
  state,
  resourceKey,
  permissionKey,
}: PermissionArgs) => {
  delete state.permissionJson?.[resourceKey][permissionKey];
};

export const initResource = ({ state, resourceKey }: ResourceArgs) => {
  if (state.permissionJson) {
    state.permissionJson[resourceKey] = {};
  }
};

export const initPermission = ({
  state,
  resourceKey,
  permissionKey,
}: PermissionArgs) => {
  if (state.permissionJson) {
    state.permissionJson[resourceKey][permissionKey] = {
      fields: true,
      ids: true,
    };
  }
};

export const isHasNoResource = ({ state, resourceKey }: ResourceArgs) => {
  return !state.permissionJson?.[resourceKey];
};

export const isHasNoPermission = ({
  state,
  resourceKey,
  permissionKey,
}: PermissionArgs) => {
  return !state.permissionJson?.[resourceKey]?.[permissionKey];
};

export const isEmptyResource = ({ state, resourceKey }: ResourceArgs) => {
  return (
    state.permissionJson &&
    Object.keys(state.permissionJson?.[resourceKey]).length === 0
  );
};

export const deleteResource = ({ state, resourceKey }: ResourceArgs) => {
  delete state.permissionJson?.[resourceKey];
};

export const checkAndInitResource = (args: ResourceArgs) => {
  if (isHasNoResource(args)) {
    initResource(args);
  }
};

export const checkAndInitPermission = (args: PermissionArgs) => {
  if (isHasNoPermission(args)) {
    initPermission(args);
  }
};

export const isAllFieldChecked = ({
  permissionJson,
  resourceKey,
  permissionKey,
  predefinedPermissions,
}: {
  permissionJson: PermissionJson;
  resourceKey: string;
  permissionKey: string;
  predefinedPermissions: PredefinedPermissions;
}) => {
  const fields = permissionJson[resourceKey]?.[permissionKey]?.fields;
  if (!fields) {
    return false;
  }

  const allFields = predefinedPermissions[resourceKey].available_fields;

  return typeof fields === 'boolean' || fields.length === allFields.length;
};

export const getResourceObjectIds = ({
  permissionJson,
  resourceKey,
}: {
  permissionJson?: PermissionJson;
  resourceKey: string;
}) => {
  if (!permissionJson || !permissionJson?.[resourceKey]) {
    return {};
  }

  const ids: { [key: number]: boolean } = {};

  Object.values(permissionJson[resourceKey]).forEach(permission => {
    if (permission.ids) {
      if (typeof permission.ids !== 'boolean') {
        permission.ids.forEach((id: number) => {
          ids[id] = true;
        });
      }
    }
  });

  return ids;
};

export const reFormatPermissionJson = (permissionJson?: PermissionJson) => {
  if (!permissionJson) {
    return {};
  }

  const nextPermissionJson: PermissionJson = JSON.parse(
    JSON.stringify(permissionJson),
  );

  Object.entries(nextPermissionJson).forEach(
    ([resourceKey, resourceConfig]) => {
      if (!Object.keys(resourceConfig).length) {
        delete nextPermissionJson[resourceKey];
      } else {
        Object.values(resourceConfig).forEach(permissionConfig => {
          const resourceIds = getResourceObjectIds({
            permissionJson: nextPermissionJson,
            resourceKey: resourceKey,
          });
          const hasObjects = Object.keys(resourceIds).length;

          if (hasObjects) {
            if (permissionConfig.ids === true) {
              permissionConfig.ids = Object.keys(resourceIds).map(id =>
                Number(id),
              );
            }
          } else {
            permissionConfig.ids = true;
          }
        });
      }
    },
  );

  return nextPermissionJson;
};

import { useEffect, useState } from 'react';
import { Modal, Pagination, Row } from 'antd';
import { useAssetViewer, useAssetViewerUrl } from 'features/asset/assetHooks';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const PDFModal = () => {
  const { isPDFModalOpen, onCloseAssetViewer, downloadedFile } =
    useAssetViewer();
  const url = useAssetViewerUrl(isPDFModalOpen, downloadedFile);

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setTotalPage(numPages);
  };

  useEffect(() => {
    if (!isPDFModalOpen) {
      setTotalPage(0);
      setCurrentPage(1);
    }
  }, [isPDFModalOpen]);

  return (
    <Modal
      footer={false}
      open={isPDFModalOpen}
      onCancel={() => onCloseAssetViewer()}
      width={900}
      style={{ height: '100vh' }}>
      <Row justify='center' style={{ width: '100%', minHeight: '50vh' }}>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={currentPage} />
        </Document>
      </Row>

      <Row>
        <Pagination
          pageSize={1}
          total={totalPage}
          current={currentPage}
          onChange={page => setCurrentPage(page)}></Pagination>
      </Row>
    </Modal>
  );
};

export default PDFModal;

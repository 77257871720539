const vi = {
  no: 'STT',
  create: 'Tạo mới',
  cancel: 'Huỷ',
  update: 'Cập nhật',
  createAsset: 'Tạo mới tài liệu',
  updateAsset: 'Cập nhật tài liệu',
  add: 'Thêm',
  addAsset: 'Thêm tài liệu',
  assets: 'Danh sách tài liệu',
  move: 'Di chuyển',
  ok: 'Ok',

  tags: 'Tags',
  name: 'Tên',
  deleteWarning: 'Bạn có chắc muốn xóa tài liệu này không?',
  delete: 'Xoá',

  search: 'Tìm kiếm',
  enterName: 'Nhập tên',
  enterTag: 'Nhập Tag',
  keyword: 'Từ khoá',
  enterKeyword: 'Nhập từ khoá',

  files: 'Files',
  createFile: 'Tạo mới File',
  folder: 'Thư mục',
  createFolder: 'Tạo mới thư mục',
  loadMore: 'Tải thêm...',
  lastModified: 'Cập nhật',
  invitationCode: 'Mã mời',
  createdAt: 'Ngày tạo',
  public: 'Công khai',

  updateFolder: 'Chỉnh sửa',
  updateFile: 'Chỉnh sửa',
  uploadFolder: 'Upload thư mục',
  uploadFile: 'Upload file',
  deleteFile: 'Xoá file',
  deleteFolder: 'Xoá thư mục',
  upload: 'Upload',
  createSubfolder: 'Tạo thư mục con',

  fileCode: 'Mã tập tin',
  uploadManager: 'Quản lý upload file',
};

export default vi;
